import React, { Component } from 'react';
import { handleCallApi, getDatafromApi } from '../calls'
import { ApiData } from "../config"

class AdditionalServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      netCostCarPrice: [],
      trip_id: this.props.tripId || null,
      car_low_gross_price: 0,
      car_high_gross_price: 0,
      car_low_gross_price_3_traveller: 0,
      car_high_gross_price_3_traveller: 0,
      car_low_gross_price_4_traveller: 0,
      car_high_gross_price_4_traveller: 0,
      car_low_gross_price_upgraded: 0,
      car_high_gross_price_upgraded: 0,
      single_room_low_gross_price: 0,
      single_room_high_gross_price: 0,
      upgrade_hotel_low_gross_price: 0,
      upgrade_hotel_high_gross_price: 0,
      pre_trip_low_gross_price: 0,
      pre_trip_high_gross_price: 0,
      post_trip_low_gross_price: 0,
      post_trip_high_gross_price: 0,
      basicSingleAccomodationPriceLow: 0,
      special_discount: 0,

      car_low_gross_price_edit: 0,
      car_high_gross_price_edit: 0,
      car_low_gross_price_3_traveller_edit: 0,
      car_high_gross_price_3_traveller_edit: 0,
      car_low_gross_price_4_traveller_edit: 0,
      car_high_gross_price_4_traveller_edit: 0,
      car_low_gross_price_upgraded_edit: 0,
      car_high_gross_price_upgraded_edit: 0,
      single_room_low_gross_price_edit: 0,
      single_room_high_gross_price_edit: 0,
      upgrade_hotel_low_gross_price_edit: 0,
      upgrade_hotel_high_gross_price_edit: 0,
      pre_trip_low_gross_price_edit: 0,
      pre_trip_high_gross_price_edit: 0,
      post_trip_low_gross_price_edit: 0,
      post_trip_high_gross_price_edit: 0,
      special_discount_edit: 0,

      isEditing: false,
      cratingData: false,
      currencyValue: Number(this.props.currency) || 1
    };
  }

  async componentDidMount() {
    if(this.props.netCostCarPrice){
      this.setState({netCostCarPrice: this.props.netCostCarPrice});
      this.getAllData()    
    }
    
    }




    async componentDidUpdate(prevProps, prevState) {
      if(this.props.netCostCarPrice !== prevState.netCostCarPrice ){
        this.setState({ netCostCarPrice: this.props.netCostCarPrice});
      }
      if(this.props.basicSingleAccomodationPriceLow !== prevProps.basicSingleAccomodationPriceLow ){
        this.setState({ basicSingleAccomodationPriceLow: this.props.netCostCarPrice});
      }
    }

    getAllData = async () => {
          try {
            if (this.props.tripId){
              const data = await getDatafromApi(ApiData.baseURL + `/gross-price/trip/${this.props.tripId}`);
              console.log('DATAAAA', data);
              if(data){
                this.setState({       
                  car_low_gross_price: data.car_low_gross_price,
                  car_high_gross_price: data.car_high_gross_price,
                  car_low_gross_price_3_traveller: data.car_low_gross_price_3_traveller,
                  car_high_gross_price_3_traveller: data.car_high_gross_price_3_traveller,
                  car_low_gross_price_4_traveller: data.car_low_gross_price_4_traveller,
                  car_high_gross_price_4_traveller: data.car_high_gross_price_4_traveller,
                  car_low_gross_price_upgraded: data.car_low_gross_price_upgraded,
                  car_high_gross_price_upgraded: data.car_high_gross_price_upgraded,
                  single_room_low_gross_price: data.single_room_low_gross_price,
                  single_room_high_gross_price: data.single_room_high_gross_price,
                  upgrade_hotel_low_gross_price: data.upgrade_hotel_low_gross_price,
                  upgrade_hotel_high_gross_price: data.upgrade_hotel_high_gross_price,
                  pre_trip_low_gross_price: data.pre_trip_low_gross_price,
                  pre_trip_high_gross_price: data.pre_trip_high_gross_price,
                  post_trip_low_gross_price: data.post_trip_low_gross_price,
                  post_trip_high_gross_price: data.post_trip_high_gross_price,
                  special_discount: data.special_discount,
        
                  car_low_gross_price_edit: data.car_low_gross_price,
                  car_high_gross_price_edit: data.car_high_gross_price,
                  car_low_gross_price_3_traveller_edit: data.car_low_gross_price_3_traveller,
                  car_high_gross_price_3_traveller_edit: data.car_high_gross_price_3_traveller,
                  car_low_gross_price_4_traveller_edit: data.car_low_gross_price_4_traveller,
                  car_high_gross_price_4_traveller_edit: data.car_high_gross_price_4_traveller,
                  car_low_gross_price_upgraded_edit: data.car_low_gross_price_upgraded,
                  car_high_gross_price_upgraded_edit: data.car_high_gross_price_upgraded,
                  single_room_low_gross_price_edit: data.single_room_low_gross_price,
                  single_room_high_gross_price_edit: data.single_room_high_gross_price,
                  upgrade_hotel_low_gross_price_edit: data.upgrade_hotel_low_gross_price,
                  upgrade_hotel_high_gross_price_edit: data.upgrade_hotel_high_gross_price,
                  pre_trip_low_gross_price_edit: data.pre_trip_low_gross_price,
                  pre_trip_high_gross_price_edit: data.pre_trip_high_gross_price,
                  post_trip_low_gross_price_edit: data.post_trip_low_gross_price,
                  post_trip_high_gross_price_edit: data.post_trip_high_gross_price,
                  special_discount_edit: data.special_discount,
                })
              }else{
                this.setState({
                  netCostCarPrice: [],
                  car_low_gross_price: 0,
                  car_high_gross_price: 0,
                  car_low_gross_price_3_traveller: 0,
                  car_high_gross_price_3_traveller: 0,
                  car_low_gross_price_4_traveller: 0,
                  car_high_gross_price_4_traveller: 0,
                  car_low_gross_price_upgraded: 0,
                  car_high_gross_price_upgraded: 0,
                  single_room_low_gross_price: 0,
                  single_room_high_gross_price: 0,
                  upgrade_hotel_low_gross_price: 0,
                  upgrade_hotel_high_gross_price: 0,
                  pre_trip_low_gross_price: 0,
                  pre_trip_high_gross_price: 0,
                  post_trip_low_gross_price: 0,
                  post_trip_high_gross_price: 0,
                  special_discount: 0,
                  cratingData: true})
              }
    
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }  
    };

    handleEditClick = () => {
      this.setState({ isEditing: true });
    };
    
    handleCancelEditClick = () => {
      this.setState({ 
        isEditing: false,
        car_low_gross_price: this.state.car_low_gross_price_edit,
        car_high_gross_price: this.state.car_high_gross_price_edit,
        car_low_gross_price_3_traveller: this.state.car_low_gross_price_3_traveller,
        car_high_gross_price_3_traveller: this.state.car_high_gross_price_3_traveller,
        car_low_gross_price_4_traveller: this.state.car_low_gross_price_4_traveller,
        car_high_gross_price_4_traveller: this.state.car_high_gross_price_4_traveller,
        car_low_gross_price_upgraded: this.state.car_low_gross_price_upgraded,
        car_high_gross_price_upgraded: this.state.car_high_gross_price_upgraded,
        single_room_low_gross_price: this.state.single_room_low_gross_price_edit,
        single_room_high_gross_price: this.state.single_room_high_gross_price_edit,
        upgrade_hotel_low_gross_price: this.state.upgrade_hotel_low_gross_price_edit,
        upgrade_hotel_high_gross_price: this.state.upgrade_hotel_high_gross_price_edit,
        pre_trip_low_gross_price: this.state.pre_trip_low_gross_price_edit,
        pre_trip_high_gross_price: this.state.pre_trip_high_gross_price_edit,
        post_trip_low_gross_price: this.state.post_trip_low_gross_price_edit,
        post_trip_high_gross_price: this.state.post_trip_high_gross_price_edit,
        special_discount: this.state.special_discount_edit,
});
    };
    
    handleSaveEditClick = async () => {
      let apidata = {
        trip_id: this.props.tripId,
        car_low_gross_price: Number(this.state.car_low_gross_price),
        car_high_gross_price: Number(this.state.car_high_gross_price),
        car_low_gross_price_3_traveller: Number(this.state.car_low_gross_price_3_traveller),
        car_high_gross_price_3_traveller: Number(this.state.car_high_gross_price_3_traveller),
        car_low_gross_price_4_traveller: Number(this.state.car_low_gross_price_4_traveller),
        car_high_gross_price_4_traveller: Number(this.state.car_high_gross_price_4_traveller),
        car_low_gross_price_upgraded: Number(this.state.car_low_gross_price_upgraded),
        car_high_gross_price_upgraded: Number(this.state.car_high_gross_price_upgraded),
        single_room_low_gross_price: Number(this.state.single_room_low_gross_price),
        single_room_high_gross_price: Number(this.state.single_room_high_gross_price),
        upgrade_hotel_low_gross_price: Number(this.state.upgrade_hotel_low_gross_price),
        upgrade_hotel_high_gross_price: Number(this.state.upgrade_hotel_high_gross_price),
        pre_trip_low_gross_price: Number(this.state.pre_trip_low_gross_price),
        pre_trip_high_gross_price: Number(this.state.pre_trip_high_gross_price),
        post_trip_low_gross_price: Number(this.state.post_trip_low_gross_price),
        post_trip_high_gross_price: Number(this.state.post_trip_high_gross_price),
        special_discount: Number(this.state.special_discount),
      }
      let success = this.state.cratingData ? "Created successfully" : 'Updated successfully';
      const method = this.state.cratingData ? "POST" : "PUT";
      const url = this.state.cratingData ? ApiData.baseURL + `/gross-price` :  ApiData.baseURL + `/gross-price/trip/${this.props.tripId}`
      const data = await handleCallApi(method, url, JSON.stringify(apidata), success, '')
      if(this.state.cratingData && data){
        this.setState({       
          car_low_gross_price: data.car_low_gross_price,
          car_high_gross_price: data.car_high_gross_price,
          car_low_gross_price_3_traveller: data.car_low_gross_price_3_traveller,
          car_high_gross_price_3_traveller: data.car_high_gross_price_3_traveller,
          car_low_gross_price_4_traveller: data.car_low_gross_price_4_traveller,
          car_high_gross_price_4_traveller: data.car_high_gross_price_4_traveller,
          car_low_gross_price_upgraded: data.car_low_gross_price_upgraded,
          car_high_gross_price_upgraded: data.car_high_gross_price_upgraded,
          single_room_low_gross_price: data.single_room_low_gross_price,
          single_room_high_gross_price: data.single_room_high_gross_price,
          upgrade_hotel_low_gross_price: data.upgrade_hotel_low_gross_price,
          upgrade_hotel_high_gross_price: data.upgrade_hotel_high_gross_price,
          pre_trip_low_gross_price: data.pre_trip_low_gross_price,
          pre_trip_high_gross_price: data.pre_trip_high_gross_price,
          post_trip_low_gross_price: data.post_trip_low_gross_price,
          post_trip_high_gross_price: data.post_trip_high_gross_price,
          special_discount: data.special_discount,
        })
      }
      this.setState({ isEditing: false, cratingData: false });
    };
    

  render() {
    return (
      <div>
        <h2>Additional Services - Prices to Set</h2>
        <table className="additional-services-table"> 
        <thead>
            <div className='centerTitle'>
                Additional Services - Car
            </div>
        </thead>
          <thead>
            <tr>
              <th colSpan="1">-</th>
              <th colSpan="2">1 Traveller in a Car - Supplement</th>
              <th colSpan="2">3 Traveller in a Car - Discount</th>
              <th colSpan="2">4 Traveller in a Car - Discount</th>
              <th colSpan="2">Car - Upgrade Supplement</th>
            </tr>
            <tr>
              <th>Season</th>
              <th>Gross price</th>
              <th>Cost Price (Delta)</th>
              <th>Gross price</th>
              <th>Cost Price (Delta)</th>
              <th>Gross price</th>
              <th>Cost Price (Delta)</th>
              <th>Gross price</th>
              <th>Cost Price (Delta)</th>
            </tr>
          </thead>
          <tbody>
          <tr>
              <td>Low</td>
              <td className='editable_td' onClick={this.handleEditClick}>
                {this.state.isEditing ? (
                  <input
                    type="number"
                    value={Number(this.state.car_low_gross_price)}
                    onChange={(e) =>
                      this.setState({ car_low_gross_price: e.target.value })
                    }
                  />
                ) : (
                  "€" +
                  (this.state.car_low_gross_price !== 0
                    ? Number(this.state.car_low_gross_price)
                    : 0
                  ).toFixed(2)
                )}
              </td>

              <td>€{((((this.props.basicCarPriceLow / Number(this.props.currency))/1) -
               ((this.props.basicCarPriceLow  / Number(this.props.currency))/2)) * this.props.daysCount).toFixed(2)}</td>

              <td className='editable_td' onClick={this.handleEditClick}>
                {this.state.isEditing ? (
                  <input
                    type="number"
                    value={Number(this.state.car_low_gross_price_3_traveller)}
                    onChange={(e) =>
                      this.setState({ car_low_gross_price_3_traveller: e.target.value })
                    }
                  />
                ) : (
                  "€" +
                  (this.state.car_low_gross_price_3_traveller !== 0
                    ? Number(this.state.car_low_gross_price_3_traveller)
                    : 0
                  ).toFixed(2)
                )}
              </td>

              <td>€{((((this.props.basicCarPriceLow / Number(this.props.currency))/3) -
               ((this.props.basicCarPriceLow  / Number(this.props.currency))/2)) * this.props.daysCount).toFixed(2)}</td>

               <td className='editable_td' onClick={this.handleEditClick}>
                {this.state.isEditing ? (
                  <input
                    type="number"
                    value={Number(this.state.car_low_gross_price_4_traveller)}
                    onChange={(e) =>
                      this.setState({ car_low_gross_price_4_traveller: e.target.value })
                    }
                  />
                ) : (
                  "€" +
                  (this.state.car_low_gross_price_4_traveller !== 0
                    ? Number(this.state.car_low_gross_price_4_traveller)
                    : 0
                  ).toFixed(2)
                )}
              </td>

              <td>€{((((this.props.basicCarPriceLow / Number(this.props.currency))/4) -
               ((this.props.basicCarPriceLow  / Number(this.props.currency))/2)) * this.props.daysCount).toFixed(2)}</td>

               <td className='editable_td' onClick={this.handleEditClick}>
                {this.state.isEditing ? (
                  <input
                    type="number"
                    value={Number(this.state.car_low_gross_price_upgraded)}
                    onChange={(e) =>
                      this.setState({ car_low_gross_price_upgraded: e.target.value })
                    }
                  />
                ) : (
                  "€" +
                  (this.state.car_low_gross_price_upgraded !== 0
                    ? Number(this.state.car_low_gross_price_upgraded)
                    : 0
                  ).toFixed(2)
                )}
              </td>

              <td>€{( (((this.props.upgradeCarPriceLow / Number(this.props.currency))/2) -
               ((this.props.basicCarPriceLow  / Number(this.props.currency))/2)) * this.props.daysCount).toFixed(2)}</td>
            </tr>

            <tr>
              <td>Peak</td>
              <td className='editable_td' onClick={this.handleEditClick}>
                {this.state.isEditing ? (
                  <input
                    type="number"
                    value={Number(this.state.car_high_gross_price)}
                    onChange={(e) =>
                      this.setState({ car_high_gross_price: e.target.value })
                    }
                  />
                ) : (
                  "€" +
                  (this.state.car_high_gross_price !== 0
                    ? Number(this.state.car_high_gross_price)
                    : 0
                  ).toFixed(2)
                )}
              </td>
        
              <td>€{( (((this.props.basicCarPriceHigh / Number(this.props.currency))/1) -
               ((this.props.basicCarPriceHigh  / Number(this.props.currency))/2)) * this.props.daysCount).toFixed(2)}</td>

              <td className='editable_td' onClick={this.handleEditClick}>
                {this.state.isEditing ? (
                  <input
                    type="number"
                    value={Number(this.state.car_high_gross_price_3_traveller)}
                    onChange={(e) =>
                      this.setState({ car_high_gross_price_3_traveller: e.target.value })
                    }
                  />
                ) : (
                  "€" +
                  (this.state.car_high_gross_price_3_traveller !== 0
                    ? Number(this.state.car_high_gross_price_3_traveller)
                    : 0
                  ).toFixed(2)
                )}
              </td>

              <td>€{( (((this.props.basicCarPriceHigh / Number(this.props.currency))/3) -
               ((this.props.basicCarPriceHigh  / Number(this.props.currency))/2)) * this.props.daysCount).toFixed(2)}</td>

              <td className='editable_td' onClick={this.handleEditClick}>
                {this.state.isEditing ? (
                  <input
                    type="number"
                    value={Number(this.state.car_high_gross_price_4_traveller)}
                    onChange={(e) =>
                      this.setState({ car_high_gross_price_4_traveller: e.target.value })
                    }
                  />
                ) : (
                  "€" +
                  (this.state.car_high_gross_price_4_traveller !== 0
                    ? Number(this.state.car_high_gross_price_4_traveller)
                    : 0
                  ).toFixed(2)
                )}
              </td>

              <td>€{( (((this.props.basicCarPriceHigh / Number(this.props.currency))/4) -
               ((this.props.basicCarPriceHigh  / Number(this.props.currency))/2)) * this.props.daysCount).toFixed(2)}</td>

              <td className='editable_td' onClick={this.handleEditClick}>
                {this.state.isEditing ? (
                  <input
                    type="number"
                    value={Number(this.state.car_high_gross_price_upgraded)}
                    onChange={(e) =>
                      this.setState({ car_high_gross_price_upgraded: e.target.value })
                    }
                  />
                ) : (
                  "€" +
                  (this.state.car_high_gross_price_upgraded !== 0
                    ? Number(this.state.car_high_gross_price_upgraded)
                    : 0
                  ).toFixed(2)
                )}
              </td>

              <td>€{( (((this.props.upgradeCarPriceHigh / Number(this.props.currency))/2) -
               ((this.props.basicCarPriceHigh  / Number(this.props.currency))/2)) * this.props.daysCount).toFixed(2)}</td>
            </tr>
          </tbody>
        </table>

{/* HOTEL TABLE */}

        <table className="additional-services-table">
        <thead>
            <div className='centerTitle'>
                Additional Services - Hotel
            </div>
        </thead>
          <thead>
            <tr>
              <th colSpan="1">-</th>
              <th colSpan="2">Hotel - Single Supplement</th>
              <th colSpan="2">Hotel - Upgrade Supplement</th>
              <th colSpan="2">Pre-Night Supplement</th>
              <th colSpan="2">Post-Night Supplement</th>
            </tr>
            <tr>
              <th>Season</th>
              <th>Gross price</th>
              <th>Cost Price (Delta)</th>
              <th>Gross price</th>
              <th>Cost Price (Delta)</th>
              <th>Gross price</th>
              <th>Cost Price (Delta)</th>
              <th>Gross price</th>
              <th>Cost Price (Delta)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
            <td>Low</td>
            <td className='editable_td' onClick={this.handleEditClick}>
                {this.state.isEditing ? (
                  <input
                    type="number"
                    value={Number(this.state.single_room_low_gross_price)}
                    onChange={(e) =>
                      this.setState({ single_room_low_gross_price: e.target.value })
                    }
                  />
                ) : (
                  "€" +
                  (this.state.single_room_low_gross_price !== 0
                    ? Number(this.state.single_room_low_gross_price)
                    : 0
                  ).toFixed(2)
                )}
              </td>
             {/* COST DELTA 1 LOW*/}
               <td>€{(((this.props.netAccomodationPrices.length ? this.props.netAccomodationPrices[0].single_room_basic_total_low_net_price : 0) / Number(this.props.currency)) - (this.props.basicAccomodationPriceLowDouble / Number(this.props.currency))).toFixed(2)}</td>
              <td className='editable_td' onClick={this.handleEditClick}>
                {this.state.isEditing ? (
                  <input
                    type="number"
                    value={Number(this.state.upgrade_hotel_low_gross_price)}
                    onChange={(e) =>
                      this.setState({ upgrade_hotel_low_gross_price: e.target.value })
                    }
                  />
                ) : (
                  "€" +
                  (this.state.upgrade_hotel_low_gross_price !== 0
                    ? (Number(this.state.upgrade_hotel_low_gross_price)/ Number(this.props.currency))
                    : 0
                  ).toFixed(2)
                )}
             
              </td>
              {/* COST DELTA 2 LOW*/}
              <td>€{(((this.props.netAccomodationPrices.length ? this.props.netAccomodationPrices[0].single_room_upgraded_total_low_net_price : 0) / Number(this.props.currency)) - (this.props.basicAccomodationPriceLowDouble / Number(this.props.currency))).toFixed(2)}</td>

              <td className='editable_td' onClick={this.handleEditClick}>
                {this.state.isEditing ? (
                  <input
                    type="number"
                    value={Number(this.state.pre_trip_low_gross_price)}
                    onChange={(e) =>
                      this.setState({ pre_trip_low_gross_price: e.target.value })
                    }
                  />
                ) : (
                  "€" +
                  (this.state.pre_trip_low_gross_price !== 0
                    ? Number(this.state.pre_trip_low_gross_price)
                    : 0
                  ).toFixed(2)
                )}
             
              </td>
              {/* COST DELTA 3 LOW*/}
              <td>€{((this.props.netAccomodationPrices.length ? this.props.netAccomodationPrices[0].pre_trip_low_net_price : 0) / Number(this.props.currency)).toFixed(2)}</td>
              
              <td className='editable_td' onClick={this.handleEditClick}>
                {this.state.isEditing ? (
                  <input
                    type="number"
                    value={Number(this.state.post_trip_low_gross_price)}
                    onChange={(e) =>
                      this.setState({ post_trip_low_gross_price: e.target.value })
                    }
                  />
                ) : (
                  "€" +
                  (this.state.post_trip_low_gross_price !== 0
                    ? Number(this.state.post_trip_low_gross_price)
                    : 0
                  ).toFixed(2)
                )}
              </td>
              {/* COST DELTA 4 LOW*/}
              <td>€{((this.props.netAccomodationPrices.length ? this.props.netAccomodationPrices[0].post_trip_low_net_price : 0) / Number(this.props.currency)).toFixed(2)}</td>
            </tr>

            <tr>
            <td>Peak</td>
              <td className='editable_td' onClick={this.handleEditClick}>
                {this.state.isEditing ? (
                  <input
                    type="number"
                    value={Number(this.state.single_room_high_gross_price)}
                    onChange={(e) =>
                      this.setState({ single_room_high_gross_price: e.target.value })
                    }
                  />
                ) : (
                  "€" +
                  (this.state.single_room_high_gross_price !== 0
                    ? Number(this.state.single_room_high_gross_price)
                    : 0
                  ).toFixed(2)
                )}
              </td>
              {/* COST DELTA 1 HIGH */}
              <td>€{(((this.props.netAccomodationPrices.length ? this.props.netAccomodationPrices[0].single_room_basic_total_high_net_price : 0) / Number(this.props.currency)) - (this.props.basicAccomodationPriceHighDouble / Number(this.props.currency))).toFixed(2)}</td>
              
              <td className='editable_td' onClick={this.handleEditClick}>
                {this.state.isEditing ? (
                  <input
                    type="number"
                    value={Number(this.state.upgrade_hotel_high_gross_price)}
                    onChange={(e) =>
                      this.setState({ upgrade_hotel_high_gross_price: e.target.value })
                    }
                  />
                ) : (
                  "€" +
                  (this.state.upgrade_hotel_high_gross_price !== 0
                    ? Number(this.state.upgrade_hotel_high_gross_price)
                    : 0
                  ).toFixed(2)
                )}
              </td>

              {/* COST DELTA 2 HIGH*/} 

              <td>€{(((this.props.netAccomodationPrices.length ? this.props.netAccomodationPrices[0].single_room_upgraded_total_high_net_price : 0) / Number(this.props.currency)) - (this.props.basicAccomodationPriceHighDouble / Number(this.props.currency))).toFixed(2)}</td>
              
              <td className='editable_td' onClick={this.handleEditClick}>
                {this.state.isEditing ? (
                  <input
                    type="number"
                    value={Number(this.state.pre_trip_high_gross_price)}
                    onChange={(e) =>
                      this.setState({ pre_trip_high_gross_price: e.target.value })
                    }
                  />
                ) : (
                  "€" +
                  (this.state.pre_trip_high_gross_price !== 0
                    ? Number(this.state.pre_trip_high_gross_price)
                    : 0
                  ).toFixed(2)
                )}
              </td>

              {/* COST DELTA 3 HIGH */}
              <td>€{((this.props.netAccomodationPrices.length ? this.props.netAccomodationPrices[0].pre_trip_high_net_price : 0) / Number(this.props.currency)).toFixed(2)}</td>
              
              <td className='editable_td' onClick={this.handleEditClick}>
                {this.state.isEditing ? (
                  <input
                    type="number"
                    value={Number(this.state.post_trip_high_gross_price)}
                    onChange={(e) =>
                      this.setState({ post_trip_high_gross_price: e.target.value })
                    }
                  />
                ) : (
                  "€" +
                  (this.state.post_trip_high_gross_price !== 0
                    ? Number(this.state.post_trip_high_gross_price)
                    : 0
                  ).toFixed(2)
                )}
              </td>
              {/* COST DELTA 4 HIGH */}
              <td>€{((this.props.netAccomodationPrices.length ? this.props.netAccomodationPrices[0].post_trip_high_net_price : 0) / Number(this.props.currency)).toFixed(2)}</td>
            </tr>
          
          </tbody>
          <thead>
      
               Discount - Special Offer (%)
       
               <td className='editable_td' onClick={this.handleEditClick}>
                {this.state.isEditing ? (
                  <input
                    type="number"
                    value={Number(this.state.special_discount)}
                    onChange={(e) =>
                      this.setState({ special_discount: e.target.value })
                    }
                  />
                ) : (
                 
                  (this.state.special_discount !== 0
                    ? Number(this.state.special_discount)
                    : 0  
                  ).toFixed(2) + "%"
                )}
              </td>
        </thead>
        </table>
              {this.state.isEditing ? (
              <div>
                <button className="cancel-edit" onClick={this.handleCancelEditClick}>Cancel</button>
                <button onClick={this.handleSaveEditClick}>Save</button>
              </div>
            ) : null}
      </div>
    );
  }
}

export default AdditionalServices;



