//import references
import React, { Component } from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { handlePhotoUpload } from "./uploadFiles"
import Link from '@material-ui/core/Link';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';

import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import _uniqueId from 'lodash/uniqueId';
import MUIDataTable from 'mui-datatables';
import { CircularProgress, Typography } from '@material-ui/core';
import { Border } from 'devextreme-react/bar-gauge';
import { handleCallApi, getDatafromApi } from './calls'
import WebPoint from './components/WebPoint';
import VG from './components/VG';
import MapboxAutocomplete from 'react-mapbox-autocomplete';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import uploadIcon from './themes/icons/uploadicon.png';
import DeleteIcon from './themes/icons/delete.png';
import imageCompression from 'browser-image-compression';
import { ApiData, MapboxKey, countriesNames } from "./config"

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

let pointNumbers = [];
let selectedRowIndex = [];
let selectedDayData = [];
let gpxLoc;
let daysData;

class ExpentureAttractions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      ExpAttTableData: [['Loading Data...']],
      updateExpRowIndex: '',
      exp_att_photo: '',
      webphotoLoc: '',
      PNCount: 1,
      SNCount: 1,
      exp_att_title: '',
      exp_att_location: '',
      exp_att_address: '',
      exp_att_desc: '',
      exp_att_nature_level: null,
      exp_att_adventure_level: null,
      exp_att_culture_level: null,
      exp_att_groupStructure: '',
      att_extra_data: '',
      att_provider_id: '',
      att_provider_email: '',
      att_provider_address: '',
      att_provider_phone: '',
      att_provider_language: '',
      att_provider_what_to_remember: '',
      att_provider_meeting_point: '',
      att_provider_duration: '',
      att_provider_cancellation_fee: '',


      pricing_net_price_for_expenture: '',
      travelerPrices: [{ "type": '', "price": '', "currency": '' }],

      confirmation_type: '',


      booking_info_email: '',
      booking_info_address: '',
      booking_info_phone: '',

      openingType: '',
      sun_time_from: '',
      sun_time_to: '',
      mon_time_from: '',
      mon_time_to: '',
      tue_time_from: '',
      tue_time_to: '',
      wed_time_from: '',
      wed_time_to: '',
      thu_time_from: '',
      thu_time_to: '',
      fri_time_from: '',
      fri_time_to: '',
      sat_time_from: '',
      sat_time_to: '',




      vg_desc: '',
      vg_gpx: '',
      save_type: 'add',
      openDeleteCnfrm: false,
      selectedTab: 0
    };
    window.bd = this;
  }




  setPoiLocationMap(poiLocation) {

    var poiCoordinates = poiLocation.split(",");
    if (poiCoordinates.length >= 2) {
      if (poiCoordinates[0] > 90)
        poiCoordinates[0] = 90
      if (poiCoordinates[0] < -90)
        poiCoordinates[0] = -90

      mapMarkersOne.forEach((marker) => marker.remove());
      mapMarkersOne = [];
      const marker = new mapboxgl.Marker()
        .setLngLat([poiCoordinates[1], poiCoordinates[0]])
        .addTo(webMapOne);
      mapMarkersOne.push(marker);

    }
  }
  setPoiLocation(latitude, longitude) {
    this.setState({ exp_att_location: latitude + ',' + longitude })
  }

  _suggestionSelected(result, lat, lng, text) {

    window.bd.setPoiLocationMap(lat + ',' + lng);
    window.bd.setPoiLocation(lat, lng)

  }

  handlePhoto = async (event) => {
    try {
      const url = await handlePhotoUpload(event, 'planmytrip-assets', 'expentureAttraction');
      if (url) {
        this.setState({ exp_att_photo: url });
      }
    } catch (error) {
      console.log('Error uploading photo:', error);
    }
  };

  setExpAttData = async (row, save_type, updateGPXRowIndex) => {
    console.log(row)
    if (row.length == 0) {
      this.setState({
        exp_att_title: '',
        exp_att_location: '',
        exp_att_address: '',
        exp_att_desc: '',
        exp_att_nature_level: null,
        exp_att_adventure_level: null,
        exp_att_culture_level: null,
        exp_att_groupStructure: '',
        exp_att_photo: '',

        att_provider_email: '',
        att_provider_address: '',
        att_provider_phone: '',
        att_provider_language: '',
        att_provider_what_to_remember: '',
        att_provider_meeting_point: '',
        att_provider_duration: '',
        att_provider_cancellation_fee: '',
        pricing_net_price_for_expenture: '',
        openingType: '',
        booking_info_email: '',
        booking_info_address: '',
        booking_info_phone: '',
        sun_time_from: '',
        sun_time_to: '',
        mon_time_from: '',
        mon_time_to: '',
        tue_time_from: '',
        tue_time_to: '',
        wed_time_from: '',
        wed_time_to: '',
        thu_time_from: '',
        thu_time_to: '',
        fri_time_from: '',
        fri_time_to: '',
        sat_time_from: '',
        sat_time_to: '',
        save_type: save_type,
        updateGPXRowIndex: updateGPXRowIndex,
        confirmation_type: 1,
        travelerPrices: [{ "type": '', "price": '', "currency": '' }]

      });
    }
    else {

      let loc = '';
      if(row.latitude != null || row.longitude != null){
        loc = row.latitude + ',' + row.longitude
      }
      if (row.location_point != null){
        loc = row.location_point.latitude + ',' + row.location_point.longitude;
      }
      let ticket_types;
      let attraction_provider;
      let confirmation;
      if (typeof row.ticket_types === 'string') {
        ticket_types = JSON.parse(row.ticket_types);
      }else{
        ticket_types = row.ticket_types
      }
      if (typeof row.attraction_provider === 'string') {
        attraction_provider = JSON.parse(row.attraction_provider);
      }else{
        attraction_provider = row.attraction_provider
      }
      if (typeof row.confirmation === 'string') {
        confirmation = JSON.parse(row.confirmation);
      }else{
        confirmation = row.confirmation
      }

      this.setState({
        exp_att_title: row?.name,
        exp_att_location: loc,
        exp_att_photo: row.main_photo_url,
        exp_att_address: row.meeting_point,
        exp_att_desc: row?.about,
        exp_att_nature_level: row?.nature_level,
        exp_att_adventure_level: row?.adventure_level,
        exp_att_culture_level: row?.culture_level,
        exp_att_groupStructure: row?.group_structure,
        save_type: save_type,
        pricing_net_price_for_expenture: row.net_price,
        updateGPXRowIndex: updateGPXRowIndex,
        confirmation_type: row.confirmation_type,
        att_extra_data: row.extra_data,
        travelerPrices: ticket_types,
      });

      if (attraction_provider) {
        this.setState({
          att_provider_id: attraction_provider.id,
          att_provider_email: attraction_provider.email,
          att_provider_address: attraction_provider.address,
          att_provider_phone: attraction_provider.phone,
          openingType: attraction_provider.type,
          att_provider_language: row.language,
          att_provider_what_to_remember: row.what_to_remember,
          att_provider_meeting_point: row.meeting_point,
          att_provider_duration: row.duration,
          att_provider_cancellation_fee: row.cancellation_fee_desc,

        });
        let availability_slots1 = attraction_provider.availability_slots;
        availability_slots1.forEach((sloat) => {
          if (sloat.day == 0) this.setState({ sun_time_from: sloat.from_time, sun_time_to: sloat.to_time })
          if (sloat.day == 1) this.setState({ mon_time_from: sloat.from_time, mon_time_to: sloat.to_time })
          if (sloat.day == 2) this.setState({ tue_time_from: sloat.from_time, tue_time_to: sloat.to_time })
          if (sloat.day == 3) this.setState({ wed_time_from: sloat.from_time, wed_time_to: sloat.to_time })
          if (sloat.day == 4) this.setState({ thu_time_from: sloat.from_time, thu_time_to: sloat.to_time })
          if (sloat.day == 5) this.setState({ fri_time_from: sloat.from_time, fri_time_to: sloat.to_time })
          if (sloat.day == 6) this.setState({ sat_time_from: sloat.from_time, sat_time_to: sloat.to_time })

        })
      }


      if (confirmation) {
        this.setState({
          booking_info_email: confirmation?.email,
          booking_info_address: confirmation?.address,
          booking_info_phone: confirmation?.phone,
        });
      }


    }


  };



  setExpAttTableData = async (data) => {
    this.setState({ ExpAttTableData: data });
  };





  handleClickOpen = () => {
    if (selectedRowIndex.length > 0)
      this.setState({ openDeleteCnfrm: true });
  };

  handleDelete = () => {
    this.setState({ openDeleteCnfrm: false });
    var array = [...this.state.ExpAttTableData]; // make a separate copy of the array
    selectedRowIndex.sort(function (a, b) {
      return b - a;
    });
    selectedRowIndex.forEach(async (i) => {
      let delid = array[i].id;
      array.splice(i, 1);
      await handleCallApi('DELETE', ApiData.apiUrl+`/attractions/${delid}/`, {}, '', '')
    })

    this.setExpAttTableData(array);
    selectedRowIndex = [];
  };


  handleClose = () => {
    this.setState({ openDeleteCnfrm: false });
    selectedRowIndex = [];
  }

  handleExpAttValidation() {
    let errors = {};
    let formIsValid = true;

    if (!this.state.exp_att_title) {
      formIsValid = false;
      errors['exp_att_title'] = '*';
    }
    if (!this.state.exp_att_location) {
      formIsValid = false;
      errors['exp_att_location'] = '*';
    }
    if (!this.state.exp_att_groupStructure) {
      formIsValid = false;
      errors['exp_att_groupStructure'] = 'Please Select Group Structure';
    }

    if (!this.state.att_provider_email) {
      formIsValid = false;
      errors['att_provider_email'] = '*';
    }
    if (!this.state.booking_info_email) {
      formIsValid = false;
      errors['booking_info_email'] = '*';
    }

    if (!this.state.confirmation_type && this.state.confirmation_type != 0) {
      formIsValid = false;
      errors['confirmation_type'] = 'This field is required';
    }
    if (!this.state.openingType && this.state.openingType != 0) {
      formIsValid = false;
      errors['openingType'] = 'This field is required';
    }
    if (!this.state.att_provider_language) {
      formIsValid = false;
      errors['att_provider_language'] = 'This field is required';
    }
    if (!this.state.att_provider_what_to_remember) {
      formIsValid = false;
      errors['att_provider_what_to_remember'] = 'This field is required';
    }
    if (!this.state.att_provider_duration) {
      formIsValid = false;
      errors['att_provider_duration'] = 'This field is required';
    }
    if (!this.state.att_provider_cancellation_fee) {
      formIsValid = false;
      errors['att_provider_cancellation_fee'] = 'This field is required';
    }
    if (!this.state.pricing_net_price_for_expenture) {
      formIsValid = false;
      errors['pricing_net_price_for_expenture'] = 'This field is required';
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  
 handleSaveExpAtt = async (e) => {
    e.preventDefault();
    toast.dismiss();

    if (this.handleExpAttValidation()) {

      let expAttData1 = [...this.state.ExpAttTableData];
      var loc = this.state.exp_att_location.split(",");

      let availability_slots1 = [];
      if (this.state.sun_time_from && this.state.sun_time_to)
        availability_slots1.push({ "day": 0, "from_time": this.state.sun_time_from, "to_time": this.state.sun_time_to })

      if (this.state.mon_time_from && this.state.mon_time_to)
        availability_slots1.push({ "day": 1, "from_time": this.state.mon_time_from, "to_time": this.state.mon_time_to })

      if (this.state.tue_time_from && this.state.tue_time_to)
        availability_slots1.push({ "day": 2, "from_time": this.state.tue_time_from, "to_time": this.state.tue_time_to })

      if (this.state.wed_time_from && this.state.wed_time_to)
        availability_slots1.push({ "day": 3, "from_time": this.state.wed_time_from, "to_time": this.state.wed_time_to })

      if (this.state.thu_time_from && this.state.thu_time_to)
        availability_slots1.push({ "day": 4, "from_time": this.state.thu_time_from, "to_time": this.state.thu_time_to })


      if (this.state.fri_time_from && this.state.fri_time_to)
        availability_slots1.push({ "day": 5, "from_time": this.state.fri_time_from, "to_time": this.state.fri_time_to })


      if (this.state.sat_time_from && this.state.sat_time_to)
        availability_slots1.push({ "day": 6, "from_time": this.state.sat_time_from, "to_time": this.state.sat_time_to })


      let ticket_types = []
      this.state.travelerPrices.forEach((prices1) => {
        if (prices1.type != '' && prices1.price != '' && prices1.currency != '')
          ticket_types.push(prices1);
      })


      let att_provi=  {
      "availability_slots": availability_slots1,
      "email": this.state.att_provider_email,
      "address": this.state.att_provider_address,
      "phone": this.state.att_provider_phone,
      "type": this.state.openingType
    }
    if(this.state?.att_provider_id && this.state?.att_provider_id!='')att_provi.id=this.state?.att_provider_id;
      let apidata = {
        "location_point": { latitude: parseFloat(loc[0]), longitude: parseFloat(loc[1]) },
        "attraction_provider": att_provi,
        "ticket_types": ticket_types,
        "name": this.state.exp_att_title,
        "main_photo_url": this.state.exp_att_photo,
        "about": this.state.exp_att_desc,
        "nature_level": this.state.exp_att_nature_level,
        "adventure_level": this.state.exp_att_adventure_level,
        "culture_level": this.state.exp_att_culture_level,
        "group_structure": this.state.exp_att_groupStructure,
        "language": this.state.att_provider_language,
        "what_to_remember": this.state.att_provider_what_to_remember,
        "meeting_point": this.state.att_provider_meeting_point,
        "duration": this.state.att_provider_duration,
        "cancellation_fee_desc": this.state.att_provider_cancellation_fee,
        "net_price": this.state.pricing_net_price_for_expenture,
        "confirmation_type": this.state.confirmation_type,
        "extra_data": this.state.att_extra_data,
        "confirmation": {
          "email": this.state.booking_info_email,
          "address": this.state.booking_info_address,
          "phone": this.state.booking_info_phone,
        },
      }

      let method = 'POST';
      let url1 = ApiData.apiUrl+`/attractions/`;
      let success = 'Atteaction Added'
      if (this.state.save_type != 'add') {
        method = 'PUT';
        url1 = ApiData.apiUrl+`/attractions/${expAttData1[this.state.updateGPXRowIndex].id}/`;
        success = 'Atteaction Updated'
      }
      let data = await handleCallApi(method, url1, JSON.stringify(apidata), success, '')
            if (data) {
              if (this.state.save_type === 'add') {
              expAttData1.push(data);
              this.setState({ ExpAttTableData: expAttData1 })
            }
            else {
              expAttData1[this.state.updateGPXRowIndex] = data;
              this.setState({ ExpAttTableData: expAttData1 })
            }
            this.setExpAttData([], 'add', '');

            closePopoup();

          }
          // else {
          //   let errors = {};
          //   if (data.about) {
          //     errors['about'] = data.about[0];
          //     this.setState({ errors: errors });
          //   }
          //   if (data.duration) {
          //     errors['att_provider_duration'] = data.duration[0];
          //     this.setState({ errors: errors });
          //   }
          //   if (data.confirmation_type) {
          //     errors['confirmation_type'] = data.confirmation_type[0];
          //     this.setState({ errors: errors });
          //   }
          //   if (data.attraction_provider) {
          //     if (data.attraction_provider.email) {
          //       errors['att_provider_email'] = data.attraction_provider.email[0];
          //       this.setState({ errors: errors });
          //     }
          //     if (data.attraction_provider.type) {
          //       errors['openingType'] = data.attraction_provider.type[0];
          //       this.setState({ errors: errors });
          //     }
          //     if (data.attraction_provider.availability_slots) {
          //       errors['availability_slots'] = "Time has wrong format. Use one of these formats instead: hh:mm[:ss[.uuuuuu]].";
          //       this.setState({ errors: errors });
          //     }
          //   }
          //   if (data.net_price) {
          //     errors['pricing_net_price_for_expenture'] = data.net_price[0];
          //     this.setState({ errors: errors });
          //   }
          //   if (data.ticket_types) {
          //     if (data.ticket_types[0].price)
          //       errors['ticket_types'] = data.ticket_types[0].price[0];
          //     if (data.ticket_types[0].type)
          //       errors['ticket_types'] = data.ticket_types[0].type[0];
          //     if (data.ticket_types[0].currency)
          //       errors['ticket_types'] = data.ticket_types[0].currency[0];
          //     this.setState({ errors: errors });
          //   }

          //   if (data.availability_slots) {
          //     if (data.availability_slots[0].from_time)
          //       errors['availability_slots'] = data.availability_slots[0].from_time[0];
          //     if (data.ticket_types[0].to_time)
          //       errors['availability_slots'] = data.availability_slots[0].to_time[0];
          //     if (data.ticket_types[0].day)
          //       errors['availability_slots'] = data.availability_slots[0].day[0];
          //     this.setState({ errors: errors });
          //   }

          // }
    }
    else {
      toast.error('Missing Information / Fields');
    }
  }




  render() {
    return (
      <div>
        <Dialog
          open={this.state.openDeleteCnfrm}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are You Sure You Want to delete this Attractions
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button onClick={this.handleDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <div className="modal">
          <div className="modal_content">
            <span className="close">&times;</span>
            <div className="model_html">
              <h2>CREATE NEW ATTRACTION</h2>
              <table cellPadding="8" >
                <tbody>
                  <tr>
                    <td >
                      <label>Name</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        variant="outlined"
                        value={this.state.exp_att_title}
                        error={!!this.state.errors['exp_att_title']}
                        onChange={(event) =>
                          this.setState({ exp_att_title: event.target.value })
                        }
                      />
                    </td>

                    <td>
                      <label>Main Photo url</label><br />

                      {this.state.exp_att_photo ? [
                        <div><span className="labelsmall"> Currently:<span className="colorgreen"> {this.state.exp_att_photo}</span></span><br /><br />
                        </div>] : null}



                      <div className="divUpload">

                        <img width="20px" src={uploadIcon} />
                        <Button variant="contained" component="label" style={{ float: 'right', fontSize: 9, }} >
                          UPLOAD FILE
                          <input
                            type="file"
                            hidden
                            value=""
                            id="fileSNPhoto"
                            size="small"
                            onChange={this.handlePhoto}
                          />
                        </Button>

                      </div>
                      <span className="errorMsg">{this.state.errors['exp_att_photo']}</span>
                      <div>
                      {this.state.exp_att_photo && <img style={{height: '140px', width: '180px'}} src={this.state.exp_att_photo}></img>}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <label>About</label><br />
                      <TextField
                        id="txtSNDesc"
                        multiline
                        style={{ width: '80%' }}
                        rows={4}
                        variant="outlined"
                        fullWidth={true}
                        value={this.state.exp_att_desc}
                        error={!!this.state.errors['exp_att_desc']}
                        onChange={(event) =>
                          this.setState({ exp_att_desc: event.target.value })
                        }
                      />
                      <br/>
                      <span className="errorMsg">{this.state.errors['about']}</span>

                    </td>

                  </tr>


                  <tr>
                    <td colspan="2">
                      <table>
                        <tr>
                          <td style={{verticalAlign: "baseline"}}>
                            <label>Location</label><br />
                            <TextField
                              id="txtSNTitle"
                              size="small"
                              variant="outlined"
                              value={this.state.exp_att_location}
                              error={!!this.state.errors['exp_att_location']}
                              onChange={(event) => {
                                this.setState({ exp_att_location: event.target.value });
                                this.setPoiLocationMap(event.target.value)
                              }
                              }
                            />

                            <br /> <span className="errorMsg">{this.state.errors['exp_att_location']}</span><br />

                            OR
                            <MapboxAutocomplete publicKey={mapboxgl.accessToken}
                              inputclassName='form-control search'
                              onSuggestionSelect={this._suggestionSelected}
                              onChange={(e) => { /*console.log("newlocation1", e.target.value)*/ }}
                              resetSearch={false} />

                          </td>
                          <td >
                            <div className="poimap">  <MapboxGLMap prefix={'mapDay-1'} /></div>
                            <span className="labelsmall"> Location Example: "Latitude,Longitude" - "32.0853,34.7818"</span>





                          </td>


                        </tr>
                      </table>
                    </td>
                  </tr>




                  <tr>
                    <td colspan="2">
                      <br />  <br />
                      <table>
                        <tr>
                          <td style={{ paddingLeft: 17, width: '33%' }}>NATURE LEVEL</td>
                          <td style={{ paddingLeft: 17, width: '33%' }}>CULTURE LEVEL</td>
                          <td style={{ paddingLeft: 17, width: '33%' }}>ADVENTURE LEVEL</td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>

                    <td colspan="2">
                      <table>
                        <tr>
                          <td>

                            <label className="boxed">
                              <input
                                type="radio"
                                id="oneNL"
                                value="1"
                                name="NL"
                                onChange={(event) => this.setState({ exp_att_nature_level: event.target.value })}
                                checked={this.state.exp_att_nature_level === '1' || this.state.exp_att_nature_level === 1}
                              />
                              <span>1</span>
                             </label>
                            <label className="boxed">
                              <input
                                type="radio"
                                id="twoNL"
                                value="2"
                                name="NL"
                                onChange={(event) => this.setState({ exp_att_nature_level: event.target.value })}
                                checked={this.state.exp_att_nature_level === '2' || this.state.exp_att_nature_level === 2}
                              />
                              <span>2</span>
                            </label>
                            <label className="boxed">
                              <input
                                type="radio"
                                id="threeNL"
                                value="3"
                                name="NL"
                                onChange={(event) => this.setState({ exp_att_nature_level: event.target.value })}
                                checked={this.state.exp_att_nature_level === '3' || this.state.exp_att_nature_level === 3}
                              />
                              <span>3</span>
                            </label>
                            <br />
                            <span className="errorMsg">{this.state.errors['exp_att_nature_level']}</span>
                          </td>
                          <td>
                            <label className="boxed">
                              <input
                                type="radio"
                                id="oneCL"
                                value="1"
                                name="CL"
                                onChange={(event) => this.setState({ exp_att_culture_level: event.target.value })}
                                checked={this.state.exp_att_culture_level === '1' || this.state.exp_att_culture_level === 1}
                              />
                              <span>1</span>
                            </label>
                            <label className="boxed">
                              <input
                                type="radio"
                                id="twoCL"
                                value="2"
                                name="CL"
                                onChange={(event) => this.setState({ exp_att_culture_level: event.target.value })}
                                checked={this.state.exp_att_culture_level === '2' || this.state.exp_att_culture_level === 2}
                              />
                              <span>2</span>
                            </label>
                            <label className="boxed">
                              <input
                                type="radio"
                                id="threeCL"
                                value="3"
                                name="CL"
                                onChange={(event) => this.setState({ exp_att_culture_level: event.target.value })}
                                checked={this.state.exp_att_culture_level === '3' || this.state.exp_att_culture_level === 3}
                              />
                              <span>3</span>
                            </label>
                            <br />
                            <span className="errorMsg">{this.state.errors['exp_att_culture_level']}</span>
                          </td>
                          <td colSpan="2">
                            <label className="boxed">
                              <input
                                type="radio"
                                id="oneAL"
                                value="1"
                                name="AL"
                                onChange={(event) => this.setState({ exp_att_adventure_level: event.target.value })}
                                checked={this.state.exp_att_adventure_level === '1' || this.state.exp_att_adventure_level === 1}
                              />
                              <span>1</span>
                            </label>
                            <label className="boxed">
                              <input
                                type="radio"
                                id="twoAL"
                                value="2"
                                name="AL"
                                onChange={(event) => this.setState({ exp_att_adventure_level: event.target.value })}
                                checked={this.state.exp_att_adventure_level === '2' || this.state.exp_att_adventure_level === 2}
                              />
                              <span>2</span>
                            </label>
                            <label className="boxed">
                              <input
                                type="radio"
                                id="threeAL"
                                value="3"
                                name="AL"
                                onChange={(event) => this.setState({ exp_att_adventure_level: event.target.value })}
                                checked={this.state.exp_att_adventure_level === '3' || this.state.exp_att_adventure_level === 3}
                              />
                              <span>3</span>
                            </label>
                            <br />
                            <span className="errorMsg">{this.state.errors['exp_att_adventure_level']}</span>
                          </td>


                        </tr>
                      </table>
                    </td>


                  </tr>
                  <tr>
                    <td >
                      <br />
                      <label>Group Structure</label><br />
                      <FormControl style={{ width: '30%' }}>
                        <InputLabel id="demo-simple-select-label">Group Structure</InputLabel>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Type"
                          value={this.state.exp_att_groupStructure}
                          onChange={(event) => { this.setState({ exp_att_groupStructure: event.target.value }); }} >
                          <MenuItem value={"0"}>WITH KIDS</MenuItem>
                          <MenuItem value={"1"}>WITHOUT KIDS</MenuItem>
                        </Select>
                      </FormControl>
                      <br />
                      <span className="errorMsg">{this.state.errors['exp_att_groupStructure']}</span>

                    </td>


                  </tr>



                  <tr>
                    <td>
                      <h4>ATTRACTION PROVIDER</h4>

                    </td>
                  </tr>
                  <tr>
                    <td className="attractionProviderDetail">
                      <table cellPadding="5" cellSpacing="5">
                        <tr  >
                          <td>
                            <label>Email</label><br />
                            <TextField
                              id="txtSNTitle"
                              size="small"
                              variant="outlined"
                              value={this.state.att_provider_email}
                              error={!!this.state.errors['att_provider_email']}
                              onChange={(event) =>
                                this.setState({ att_provider_email: event.target.value })
                              }
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <label>Address</label><br />
                            <TextField
                              id="txtSNTitle"
                              size="small"
                              variant="outlined"
                              value={this.state.att_provider_address}
                              error={!!this.state.errors['att_provider_address']}
                              onChange={(event) =>
                                this.setState({ att_provider_address: event.target.value })
                              }
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <label>Phone Number</label><br />
                            <TextField
                              id="txtSNTitle"
                              size="small"

                              variant="outlined"

                              value={this.state.att_provider_phone}
                              error={!!this.state.errors['att_provider_phone']}
                              onChange={(event) =>
                                this.setState({ att_provider_phone: event.target.value })
                              }
                            />
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td >
                      <h6>OPENING DAYS & HOURS</h6>
                      <table>

                        <tr>
                          <td colSpan={3}>

                            <RadioGroup className="myRadioGroup" aria-label="TYPE" value={this.state.openingType} name="row-radio-buttons-group"
                              onChange={(event) => { console.log('test', event.target); this.setState({ openingType: parseInt(event.target.value) }); }}>
                              <FormControlLabel value={0} control={<Radio />} label="Per Request" />
                              <FormControlLabel value={1} control={<Radio />} label="Fixed Times:" />
                            </RadioGroup>

                            <br />

                            <span className="errorMsg">{this.state.errors['openingType']}</span>
                            <br />
                          </td>

                        </tr>
                        <tr>
                          <td width="50">Day</td>
                          <td colSpan={2}>Hours</td>
                        </tr>
                        <tr>
                          <td width="50" className="daylabel" >SUN</td>
                          <td width="150">
                            <TextField
                              id="txtSNTitle"
                              size="small"
                              variant="outlined"
                              value={this.state.sun_time_from}
                              error={!!this.state.errors['sun_time_from']}
                              onChange={(event) =>
                                this.setState({ sun_time_from: event.target.value })
                              }
                            />
                          </td>
                          <td width="150">
                            <TextField
                              id="txtSNTitle"
                              size="small"
                              variant="outlined"
                              value={this.state.sun_time_to}
                              error={!!this.state.errors['sun_time_to']}
                              onChange={(event) =>
                                this.setState({ sun_time_to: event.target.value })
                              }
                            />
                          </td>
                        </tr>
                        <tr>
                          <td width="50" className="daylabel" >MON</td>
                          <td>  <TextField
                            id="txtSNTitle"
                            size="small"

                            variant="outlined"

                            value={this.state.mon_time_from}
                            error={!!this.state.errors['mon_time_from']}
                            onChange={(event) =>
                              this.setState({ mon_time_from: event.target.value })
                            }
                          />
                          </td>
                          <td>  <TextField
                            id="txtSNTitle"
                            size="small"

                            variant="outlined"

                            value={this.state.mon_time_to}
                            error={!!this.state.errors['mon_time_to']}
                            onChange={(event) =>
                              this.setState({ mon_time_to: event.target.value })
                            }
                          />
                          </td>
                        </tr>

                        <tr>
                          <td width="50" className="daylabel" >TUE</td>
                          <td>  <TextField
                            id="txtSNTitle"
                            size="small"

                            variant="outlined"

                            value={this.state.tue_time_from}
                            error={!!this.state.errors['tue_time_from']}
                            onChange={(event) =>
                              this.setState({ tue_time_from: event.target.value })
                            }
                          />
                          </td>
                          <td>  <TextField
                            id="txtSNTitle"
                            size="small"

                            variant="outlined"

                            value={this.state.tue_time_to}
                            error={!!this.state.errors['tue_time_to']}
                            onChange={(event) =>
                              this.setState({ tue_time_to: event.target.value })
                            }
                          />
                          </td>
                        </tr>
                        <tr>
                          <td width="50" className="daylabel" >WED</td>
                          <td>  <TextField
                            id="txtSNTitle"
                            size="small"

                            variant="outlined"

                            value={this.state.wed_time_from}
                            error={!!this.state.errors['wed_time_from']}
                            onChange={(event) =>
                              this.setState({ wed_time_from: event.target.value })
                            }
                          />
                          </td>
                          <td>  <TextField
                            id="txtSNTitle"
                            size="small"

                            variant="outlined"

                            value={this.state.wed_time_to}
                            error={!!this.state.errors['wed_time_to']}
                            onChange={(event) =>
                              this.setState({ wed_time_to: event.target.value })
                            }
                          />
                          </td>
                        </tr>
                        <tr>
                          <td width="50" className="daylabel" >THU</td>
                          <td>  <TextField
                            id="txtSNTitle"
                            size="small"

                            variant="outlined"

                            value={this.state.thu_time_from}
                            error={!!this.state.errors['thu_time_from']}
                            onChange={(event) =>
                              this.setState({ thu_time_from: event.target.value })
                            }
                          />
                          </td>
                          <td>  <TextField
                            id="txtSNTitle"
                            size="small"

                            variant="outlined"

                            value={this.state.thu_time_to}
                            error={!!this.state.errors['thu_time_to']}
                            onChange={(event) =>
                              this.setState({ thu_time_to: event.target.value })
                            }
                          />
                          </td>
                        </tr>

                        <tr>
                          <td width="50" className="daylabel" >FRI</td>
                          <td>  <TextField
                            id="txtSNTitle"
                            size="small"

                            variant="outlined"

                            value={this.state.fri_time_from}
                            error={!!this.state.errors['fri_time_from']}
                            onChange={(event) =>
                              this.setState({ fri_time_from: event.target.value })
                            }
                          />
                          </td>
                          <td>  <TextField
                            id="txtSNTitle"
                            size="small"

                            variant="outlined"

                            value={this.state.fri_time_to}
                            error={!!this.state.errors['fri_time_to']}
                            onChange={(event) =>
                              this.setState({ fri_time_to: event.target.value })
                            }
                          />
                          </td>
                        </tr>
                        <tr>
                          <td width="50" className="daylabel" >SAT</td>
                          <td>  <TextField
                            id="txtSNTitle"
                            size="small"

                            variant="outlined"

                            value={this.state.sat_time_from}
                            error={!!this.state.errors['sat_time_from']}
                            onChange={(event) =>
                              this.setState({ sat_time_from: event.target.value })
                            }
                          />
                          </td>
                          <td>  <TextField
                            id="txtSNTitle"
                            size="small"

                            variant="outlined"

                            value={this.state.sat_time_to}
                            error={!!this.state.errors['sat_time_to']}
                            onChange={(event) =>
                              this.setState({ sat_time_to: event.target.value })
                            }
                          />
                          </td>
                          <td width={500}></td>
                        </tr>
                      </table>
                      <br />
                      <span className="errorMsg">{this.state.errors['availability_slots']}</span>



                    </td>
                  </tr>


                  <tr>
                    <td colSpan={2}>
                      <label>Language</label><br />
                      <TextField
                        id="txtSNTitle"
                        style={{ width: '40%' }}
                        variant="outlined"
                        value={this.state.att_provider_language}
                        error={!!this.state.errors['att_provider_language']}
                        onChange={(event) =>
                          this.setState({ att_provider_language: event.target.value })
                        }
                      />
                    </td>
                  </tr>


                  <tr>
                    <td colSpan={2}>
                      <label>What to remember:</label><br />
                      <TextField
                        id="txtSNTitle"
                        style={{ width: '50%' }}
                        variant="outlined"
                        value={this.state.att_provider_what_to_remember}
                        error={!!this.state.errors['att_provider_what_to_remember']}
                        onChange={(event) =>
                          this.setState({ att_provider_what_to_remember: event.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <label>Meeting Point (Description):</label><br />
                      <TextField
                        id="txtSNTitle"
                        style={{ width: '40%' }}
                        variant="outlined"
                        value={this.state.att_provider_meeting_point}
                        error={!!this.state.errors['att_provider_meeting_point']}
                        onChange={(event) =>
                          this.setState({ att_provider_meeting_point: event.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <label>Extra Information:</label><br />
                      <TextField
                        id="txtSNTitle"
                        style={{ width: '40%' }}
                        variant="outlined"
                        value={this.state.att_extra_data}
                        onChange={(event) =>
                          this.setState({ att_extra_data: event.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <label>Duration:</label><br />
                      <TextField
                        id="txtSNTitle"
                        style={{ width: '10%' }}
                        variant="outlined"
                        value={this.state.att_provider_duration}
                        error={!!this.state.errors['att_provider_duration']}
                        onChange={(event) =>
                          this.setState({ att_provider_duration: event.target.value })
                        }
                      />
                      <br />
                      <span className="errorMsg">{this.state.errors['att_provider_duration']}</span>

                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <label>Cancellation Fee:</label><br />
                      <TextField
                        id="txtSNDesc"
                        multiline
                        style={{ width: '50%' }}
                        rows={2}
                        variant="outlined"
                        fullWidth={true}
                        value={this.state.att_provider_cancellation_fee}
                        error={!!this.state.errors['att_provider_cancellation_fee']}
                        onChange={(event) =>
                          this.setState({ att_provider_cancellation_fee: event.target.value })
                        }
                      />

                    </td>
                  </tr>

                  <tr>
                    <td>
                      <h4 style={{ marginBottom: 0 }}> PRICING AND TICKETS:</h4>
                    </td>
                    <td style={{ paddingLeft: 100 }}>
                      <h4 style={{ marginBottom: 0 }}> AVAILIBILITY:</h4>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: 'baseline' }} width={'50%'}>
                      <table>
                        <tr>
                          <td colSpan={3}>
                            <label>Net Price For Experience:</label><br />
                            <TextField
                              id="txtSNTitle"
                              style={{ width: '70%' }}
                              variant="outlined"
                              value={this.state.pricing_net_price_for_expenture}
                              error={!!this.state.errors['pricing_net_price_for_expenture']}
                              onChange={(event) =>
                                this.setState({ pricing_net_price_for_expenture: event.target.value })
                              }
                            />
                            <br />
                            <span className="errorMsg">{this.state.errors['pricing_net_price_for_expenture']}</span>


                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            <br />

                            <label style={{ fontSize: 20 }}>Traveler Prices </label><br /><br />


                          </td>
                        </tr>
                        <tr>
                          <td width={'50%'}>
                            <label>Ticket type<br /> (i.e Adult, kids, Group etc.):</label>
                          </td>
                          <td>
                            <label>Price:</label>
                          </td>
                          <td>
                            <label>currency:</label>
                          </td>
                        </tr>
                        {this.state.travelerPrices.map((item, i) =>
                          <tr>
                            <td>
                              <TextField
                                id="txtSNTitle"
                                style={{ width: '70%' }}
                                variant="outlined"
                                value={item.type}

                                onChange={(event) => {
                                  var a = [...this.state.travelerPrices];
                                  a[i].type = event.target.value;
                                  this.setState({ travelerPrices: a });
                                }
                                }
                              />

                            </td>
                            <td>
                              <TextField
                                id="txtSNTitle"
                                style={{ width: '70%' }}
                                variant="outlined"
                                value={item.price}

                                onChange={(event) => {
                                  var a = [...this.state.travelerPrices];
                                  a[i].price = event.target.value;
                                  this.setState({ travelerPrices: a });
                                }
                                }
                              />

                            </td>
                            <td>


                              <FormControl style={{ maxWidth: '200px' }} className="dropdownWithborder">
                                <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Type"
                                  value={item.currency}
                                  onChange={(event) => {
                                    var a = [...this.state.travelerPrices];
                                    a[i].currency = event.target.value;
                                    this.setState({ travelerPrices: a });
                                  }}
                                >
                                  <MenuItem value={"$"}>$</MenuItem>
                                  <MenuItem value={"RS"}>RS</MenuItem>
                                  <MenuItem value={"INR"}>INR</MenuItem>
                                  <MenuItem value={"€"}>€</MenuItem>
                                </Select>
                              </FormControl>


                            </td>
                            {i != 0 &&
                              <td>

                                <img style={{ cursor: 'pointer' }} onClick={() => {
                                  var a = [...this.state.travelerPrices];
                                  a.splice(i, 1);
                                  this.setState({ travelerPrices: a });
                                }} width="20px" src={DeleteIcon} />
                              </td>
                            }
                          </tr>
                        )}



                        <tr>
                          <td colSpan="3">
                            <span className="errorMsg">{this.state.errors['ticket_types']}</span>
                            <br />
                            <Link onClick={() => {
                              var a = [...this.state.travelerPrices];
                              a.push({ "ticketType": '', "price": '', "currancy": '' });
                              this.setState({ travelerPrices: a });
                            }}> + ADD TICKET TYPE </Link>
                          </td>
                        </tr>
                      </table>

                    </td>
                    <td style={{ verticalAlign: "baseline", paddingLeft: 100 }}>
                      <table cellPadding="5" cellSpacing="5">
                        <tr style={{ verticalAlign: "baseline", paddingTop: "30px" }}>
                          <td>

                          <RadioGroup className="myRadioGroup" aria-label="TYPE" value={this.state.confirmation_type} name="row-radio-buttons-group"
                            onChange={(event) => { this.setState({ confirmation_type: parseInt(event.target.value) }); }}>
                            <FormControlLabel value={0} control={<Radio />} label="Instant Confirmation" />
                            <FormControlLabel value={1} control={<Radio />} label="Request:" checked={this.state.confirmation_type === 1} />
                          </RadioGroup>

                            <span className="errorMsg">{this.state.errors['confirmation_type']}</span>
                            {this.state.errors['confirmation_type'] &&
                              <br />
                            }
                            <br />
                            Booking information (\local hero info)-
                          </td>
                        </tr>
                        <tr style={{ verticalAlign: "baseline", paddingTop: "30px" }}>
                          <td>
                            <label>Email</label><br />
                            <TextField
                              id="txtSNTitle"
                              size="small"
                              variant="outlined"
                              value={this.state.booking_info_email}
                              error={!!this.state.errors['booking_info_email']}
                              onChange={(event) =>
                                this.setState({ booking_info_email: event.target.value })
                              }
                            />
                          </td>
                        </tr>
                        <tr>

                          <td>
                            <label>Address</label><br />
                            <TextField
                              id="txtSNTitle"
                              size="small"
                              variant="outlined"
                              value={this.state.booking_info_address}
                              error={!!this.state.errors['booking_info_address']}
                              onChange={(event) =>
                                this.setState({ booking_info_address: event.target.value })
                              }
                            />
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <label>Phone Number</label><br />
                            <TextField
                              id="txtSNTitle"
                              size="small"
                              variant="outlined"
                              value={this.state.booking_info_phone}
                              error={!!this.state.errors['booking_info_phone']}
                              onChange={(event) =>
                                this.setState({ booking_info_phone: event.target.value })
                              }
                            />
                          </td>
                        </tr>
                      </table>
                    </td>

                  </tr>



                  <tr>
                    <td colSpan="4">
                      {this.state.save_type=='add' &&
                      <Button
                        id="btnSaveSN"
                        variant="contained"
                        color="primary"
                        onClick={this.handleSaveExpAtt.bind(this)}
                      >
                        CREATE
                      </Button>
  }
   {this.state.save_type!='add' &&
                      <Button
                        id="btnSaveSN"
                        variant="contained"
                        color="primary"
                        onClick={this.handleSaveExpAtt.bind(this)}
                      >
                        Save Changes
                      </Button>
  }
                    </td>
                  </tr>
                </tbody>
              </table>


            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
        <ToastContainer />
        <table cellPadding="10" className="viewdata">
          <tbody>

            <tr>
              <td>
                <h2>PLAN YOUR TRIP  ATTRACTIONS</h2>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan="3"  ></td>
              <td>&nbsp;
                <Button
                  id="btnSave"
                  variant="contained"
                  color="#15cc09"
                  className='deleteBtn'
                  onClick={() => { this.handleClickOpen(); console.log('dvdsg', selectedRowIndex) }}
                  style={{ float: 'right', fontSize: 12, color: '#fff' }}
                >
                  DELETE SELECTED
                </Button>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <ExpAttractionTable setPoiLocationMap={this.setPoiLocationMap} expAttData={this.state.ExpAttTableData} setExpAttTableData={this.setExpAttTableData} setExpAttData={this.setExpAttData} />
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <Link href="#" onClick={() => { this.setExpAttData([], 'add', ''); openPopup(); initializeMap(-1, 'mapDay-1'); }}> + ADD NEW ATTRACTION  </Link>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <div className="borderDiv" />
              </td>
            </tr>

          </tbody>
        </table>

      </div>


    );
  }
}

export default ExpentureAttractions;

/* openPopup= () =>
{
  const modal = document.querySelector(".modal")
  const closeBtn = document.querySelector(".close")
  modal.style.display = "block";
  closeBtn.addEventListener("click", () => {
    modal.style.display = "none";
  })
}
 */
mapboxgl.accessToken = MapboxKey;

let mapMarkersOne = [];
let mapMarkersTwo = [];
let webmapMarkers = [];
let vgmapMarkers = [];

let webMapOne;
let webMapTwo;
let webMap;
let vgMap;




const initializeMap = (z, id) => {
  if (id == 'mapDayVG') {
    vgMap = new mapboxgl.Map({
      container: id,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: z,
    });
    //Add navigation controls to the mapbox if zoom is lower
    if (z < 11) vgMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    vgMap.on('click', function (e) {
      if (selectedRowIndex) {
        addVgMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      } else {
        toast.error('Please select a trip first by clicking VG button against the record');
      }
    });
  } else if (id == 'mapDayStop') {
    webMap = new mapboxgl.Map({
      container: id,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: z,
    });
    //Add navigation controls to the mapbox if zoom is lower
    if (z < 11) webMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    webMap.on('click', function (e) {
      if (selectedRowIndex) {
        addWebMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      } else {
        addWebMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
        // toast.error('11Please select a trip first by clicking Web button against the record');
      }
    });
  } else {
    if (id == 'mapDay-1') {
      webMapOne = new mapboxgl.Map({
        container: id,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: z,
      });
      webMapOne.on('click', function (e) {



        window.bd.setPoiLocation(e.lngLat.lat.toFixed(6), e.lngLat.lng.toFixed(6));

        $('#txtStartLocDay').val(e.lngLat.lat.toFixed(6) + ',' + e.lngLat.lng.toFixed(6));
        // this.setState({ startloc: e.lngLat.lng.toFixed(4) + ',' + e.lngLat.lat.toFixed(4) });
        mapMarkersOne.forEach((marker) => marker.remove());
        mapMarkersOne = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapOne);
        mapMarkersOne.push(marker);
      });
    } else {
      webMapTwo = new mapboxgl.Map({
        container: id,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: z,
      });

      webMapTwo.on('click', function (e) {
        $('#txtEndLocDay').val(e.lngLat.lng.toFixed(6) + ',' + e.lngLat.lat.toFixed(6));
        mapMarkersTwo.forEach((marker) => marker.remove());
        mapMarkersTwo = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapTwo);
        mapMarkersTwo.push(marker);
      });
    }
  }
};

class MapboxGLMap extends Component {
  constructor(props) {
    super(props);
    // this.id = _uniqueId(props.prefix + "-");
    this.id = props.prefix;
    this.latitude = 0;
    this.longitude = 0;
  }

  render() {
    const id = this.id;
    return (
      <div id={id} className="mapEA">
        {' '}
      </div>
    );
  }
}

class ExpAttractionTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [['Loading Data...']],
      loading: false,
      expAttRowsSelected: []
    };
  }

  async componentDidMount() {
    let data = await this.getData();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.has('id')){
     let id = urlParams.getAll('id');
     this.setAttractionExpenture(id,data)
    }
  }

  setAttractionExpenture = (id, data) => {
    let filterId = parseInt(id[0])
    let filterRow = data.filter(row => row.id === filterId)[0]
    let getIndexRow = data.findIndex(row => row.id === filterId)
    var loc1 = '';
    if (filterRow.location_point && filterRow.location_point != null)
      loc1 = filterRow.location_point?.latitude + ',' + filterRow.location_point?.longitude;
    this.props.setExpAttData(filterRow, 'update', getIndexRow);
     openPopup();
     initializeMap(-1, 'mapDay-1');
     if (filterRow.location_point && filterRow.location_point != null) this.props.setPoiLocationMap(loc1);
  }

  getData = () => {
    this.setState({ loading: true });
    let dataRows = this.xhrRequest().then((data) => {
      if(data.length){
        this.setState({ data, loading: false });
        data.sort((a, b) => a.id - b.id);
        this.props.setExpAttTableData(data);
      }
      return data
    });
    return dataRows
  };


  xhrRequest = (url) => {
    return new Promise(async (resolve, reject) => {
      let data = await getDatafromApi(ApiData.apiUrl+'/attractions/pyt')
      if(data){
        daysData = data;
        resolve(data);
      }
    });
  };


  render() {
    const columns = [

      {
        name: 'id', options: {
          label: 'ID', filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            //  const editrow = tableMeta.tableData[tableMeta.rowIndex];
            var editrow = {};
            var rowIndexForUpdate = 0;
            var i = 0;
            tableMeta.tableData.forEach((row) => {
              if (row.id == value) {
                editrow = row;
                rowIndexForUpdate = i;
              }
              i++;
            })


            return (
              //att_provider_email,  att_provider_address,att_provider_phone,booking_info_email,booking_info_address,booking_info_phone,sun_time_from,mon_time_from,tue_time_from,wed_time_from,thu_time_from,fri_time_from,sat_time_from, save_type, updateGPXRowIndex
              <Link href="#" onClick={() => {
                var loc1 = '';
                if (editrow.location_point && editrow.location_point != null)
                  loc1 = editrow.location_point?.latitude + ',' + editrow.location_point?.longitude;
                this.props.setExpAttData(editrow, 'update', rowIndexForUpdate);
                openPopup(editrow.id);
                initializeMap(-1, 'mapDay-1');
                if (editrow.location_point && editrow.location_point != null) this.props.setPoiLocationMap(loc1);
              }}>  {value}</Link>
            );
          },

        }
      },
      { name: 'name', label: 'Name', options: { filter: false } },
      { name: 'main_photo_url', options: { filter: false } },
      { name: 'about', label: 'About' },
      // { name: 'location_point', label: 'Location', options: { filter: false } },

      {
        name: 'location_point', options: {
          label: 'Location', filter: false,
          customBodyRender: (value) => {
            if (value != null)
              return value.latitude + ',' + value.longitude;

          }
        }
      },


    ];

    const onRowsSelect = (curRowSelected, rowsSelected) => {
      selectedRowIndex = [];
      for (let i = 0; i < rowsSelected.length; i++) {
        selectedRowIndex.push(rowsSelected[i].dataIndex);
      }
      activeDeactiveDeleteBtn();
    };
    const { data } = this.state;

    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      download: false,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: 'none',
      rowsSelected: selectedRowIndex,
      onRowSelectionChange: onRowsSelect,
    };
    return (
      <div>
        <MUIDataTable
          title={
            <Typography variant="subtitle2">
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: 'relative', top: 4 }}
                />
              )}
            </Typography>
          }
          data={this.props.expAttData}
          columns={columns}
          options={options}
          rowsSelected={onRowsSelect}
        />
      </div>
    );
  }
}



function addWebMarker(coordinates) {

  const id = _uniqueId('webmarker-');

  let marker;
  var el = document.createElement('div');
  el.className = 'marker';
  el.id = id;
  el.addEventListener(
    'click',
    (e) => {
      e.stopPropagation();
      let target_id = e.target.id;
      // let queriedMarkerObj = webmapMarkers.find((marker) => marker.id === query);
      // queriedMarkerObj.marker.remove();
      for (let i = 0; i < webmapMarkers.length; i++) {
        if (webmapMarkers[i].id === target_id) {
          document.getElementById(webmapMarkers[i].id).remove();
          webmapMarkers.splice(i, 1);
        }
      }
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(webMap);
  marker.properties = { id: id };
  webmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}

function addVgMarker(coordinates, type = false) {
  const id = _uniqueId('vgmarker-');
  let marker;
  var el = document.createElement('div');
  el.className = 'marker';
  el.id = id;
  if (type) {
    el.style.backgroundImage = `url('https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/${getSvg(
      type
    )}.svg')`;
  }
  el.addEventListener(
    'click',
    (e) => {
      e.stopPropagation();
      let query = e.target.id;

      let queriedMarkerObj = vgmapMarkers.find((marker) => marker.id === query);
      queriedMarkerObj.marker.remove();
      pointNumbers.splice(
        pointNumbers.findIndex((v) => v.id === query),
        1
      );
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(vgMap);
  vgmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}

function getSvg(type) {
  type = parseInt(type);
  switch (type) {
    case 1: {
      return 'straight';
    }
    case 2: {
      return 'right';
    }
    case 3: {
      return 'left';
    }
    case 4: {
      return 'fork-left';
    }
    case 5: {
      return 'fork-right';
    }
    case 6: {
      return 'poi';
    }
    case 7: {
      return 'info';
    }
    case 8: {
      return 'swimming';
    }
    case 9: {
      return 'rafting';
    }
    case 10: {
      return 'hotel';
    }
    default: {
      return 'poi';
    }
  }
}


function openPopup(id ='') {
  if(id !== ''){
    window.history.pushState(null, null, `?page=Plan Your Trip Attractions&id=${id}`);
  }
  const modal = document.querySelector(".modal")
  const closeBtn = document.querySelector(".close")
  modal.style.display = "block";
  document.querySelector(".viewdata").style.display = "none";
  closeBtn.addEventListener("click", () => {
    window.history.pushState(null, null, `?page=Plan Your Trip Attractions`);
    modal.style.display = "none";
    document.querySelector(".viewdata").style.display = "inline-table";
  })
}
function closePopoup() {
  document.querySelector(".modal").style.display = "none";
  document.querySelector(".viewdata").style.display = "inline-table";
}

function activeDeactiveDeleteBtn() {
  const btn = document.querySelector(".deleteBtn")
  if (selectedRowIndex.length > 0) btn.style.backgroundColor = 'blue';
  else btn.style.backgroundColor = '#e0e0e0';
}
