//import references
import React, { Component, useEffect } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import { handlePhotoUpload } from "./uploadFiles"
import Link from '@material-ui/core/Link';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import _uniqueId from 'lodash/uniqueId';
import MUIDataTable from 'mui-datatables';
import { Checkbox, CircularProgress, Typography } from '@material-ui/core';
import MapboxAutocomplete from 'react-mapbox-autocomplete';
import uploadIcon from './themes/icons/uploadicon.png';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ApiData, MapboxKey, countriesNames } from "./config"

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

let pointNumbers = [];
let selectedRowIndex = false;
let selectedRowIDs = [];
class StopsPoisPersonalized extends Component {

  constructor(props) {
    super(props);
    this.state = {
      poiData: [['Loading Data...']],
      fields: {},
      errors: {},
      photourl: '',
      webphotoLoc: '',
      PNCount: 1,
      SNCount: 1,
      stop_title: '',
      stop_country: '',
      stop_location: '',
      stop_type: '',
      no_navigatable: false,
      stop_desc: '',
      stop_photo: '',
      save_type: 'add',
      updateStopPoiRowIndex: '',
      openDeleteCnfrm: false,
      selRowIDs: []
    };
    window.bd = this;
  }

  setPoiLocationMap(poiLocation) {
    var poiCoordinates = poiLocation.split(",");
    if (poiCoordinates.length >= 2) {
      if (poiCoordinates[0] > 90)
        poiCoordinates[0] = 90
      if (poiCoordinates[0] < -90)
        poiCoordinates[0] = -90

      mapMarkersOne.forEach((marker) => marker.remove());
      mapMarkersOne = [];
      const marker = new mapboxgl.Marker()
        .setLngLat([poiCoordinates[1], poiCoordinates[0]])
        .addTo(webMapOne);
      mapMarkersOne.push(marker);
    }
  }

  _suggestionSelected(result, lat, lng, text) {
    window.bd.setPoiLocationMap(lat + ',' + lng);
    window.bd.setPoiLocation(lat, lng)
  }

  setPoiLocation(latitude, longitude) {
    this.setState({ stop_location: latitude + ',' + longitude })
    $('.react-mapbox-ac-input').val('');
  }

  handleSNValidation() {
    let errors = {};
    let formIsValid = true;

    if (!this.state.stop_title) {
      formIsValid = false;
      errors['stop_title'] = '*';
    }
    // if ((this.state.stop_type != 2 || this.state.stop_type != '2') && !this.state.stop_photo) {
    //   formIsValid = false;
    //   errors['stop_photo'] = 'Photo is Required';
    // }
    if (!this.state.stop_type) {
      formIsValid = false;
      errors['stop_type'] = 'Type is Required';
    }
    if (!this.state.stop_location) {
      formIsValid = false;
      errors['stop_location'] = 'Location is Required';
    }
    /*  else{
       var loc = this.state.stop_location.split(",");
       let pattern = new RegExp('^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}');
       if(pattern.test(loc[0]) && pattern.test(loc[1]))
       console.log('valid')
       else
       {
           formIsValid = false;
           errors['stop_location'] = 'Not a Valid Location';
         }
       } */

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSaveSN(e) {
    e.preventDefault();
    toast.dismiss();
    if (this.handleSNValidation()) {
      if (this.state.location === '') {
        toast.error('No Map Marker found. Please draw at least one');
        return;
      }

      var countryName;
      var countryCode = this.state.stop_country;
      countriesNames.map(function (item) {
        if (item.code === countryCode)
          countryName = item.name;
      });
      var short_description = this.state.stop_desc.substring(0, 100);
      if (this.state.stop_desc.length > 100)
        short_description = this.state.stop_desc.substring(0, 100) + '...';

      var loc = this.state.stop_location.split(",");


      let photoUrl = '';
      let a = this.state.stop_photo.split(' ');

      for (let i = 0; i < a.length; i++) {
        if (i != 0)
          photoUrl = photoUrl + '%20';
        photoUrl = photoUrl + a[i];
      }




      let apidata = {
        country: countryName,
        description: this.state.stop_desc,
        gpx: 'test',
        id: 1,
        location: { latitude: parseFloat(loc[0]), longitude: parseFloat(loc[1]) },
        name: this.state.stop_title,
        package: null,
        photo: photoUrl,
        type: parseInt(this.state.stop_type),
        personalized: true,
        no_navigatable: this.state.no_navigatable
      }

      let poiData1 = [...this.state.poiData];
      let method = 'POST';
      let url1 = ApiData.apiUrl + `/global-poi/`;
      if (this.state.save_type != 'add') {
        method = 'PUT';
        url1 = ApiData.apiUrl + `/global-poi/${poiData1[this.state.updateStopPoiRowIndex].id}/`;
      }
      let requestOptions = {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(apidata),
      };

      fetch(url1, requestOptions)
        .then(async (response) => {
          const data = await response.json();
          data.location = parseFloat(data.location.latitude).toFixed(4) + ',' + parseFloat(data.location.longitude).toFixed(4);
          data.type = data.type.toString();
          data.short_description = data.description.substring(0, 100);
          if (data.description.length > 100)
            data.short_description = data.description.substring(0, 100) + '...';
          if (this.state.save_type === 'add') {
            poiData1.push(data);
            this.setState({ poiData: poiData1 })
            toast.success('Global Poi Saved');
          }
          else {
            poiData1[this.state.updateStopPoiRowIndex] = data;
            this.setState({ poiData: poiData1 })
            toast.success('Global Poi Updated');
          }
          this.setState({
            stop_title: '',
            stop_desc: '',
            stop_photo: '',
            stop_location: ''
          });

          initializeMap(-1, 'mapDay-1');
          closePopup()
        });

    } else {
      toast.error('Missing Information / Fields');
    }
  }


  handlePhoto = async (event) => {
    try {
      const url = await handlePhotoUpload(event, 'planmytrip-assets', 'stopPoi');
      if (url) {
        this.setState({ stop_photo: url });
      }
    } catch (error) {
      console.log('Error uploading photo:', error);
    }
  };


  setStopTitle = async (no_navigatable, title, country, location, type, desc, photo, save_type, updateStopPoiRowIndex) => {
    this.setState({ no_navigatable: no_navigatable, stop_title: title, stop_desc: desc, stop_country: country, stop_location: location, stop_type: type, stop_photo: photo, save_type: save_type, updateStopPoiRowIndex: updateStopPoiRowIndex });
  };

  setPoiData = async (data) => {
    this.setState({ poiData: data });
  };

  setSelectedRowIDs = async (selectedRowIDs1) => {
    this.setState({ selRowIDs: selectedRowIDs1 })
  }

  handleClickOpen = () => {
    if (selectedRowIDs.length > 0)
      this.setState({ openDeleteCnfrm: true });
  };

  handleClose = () => {
    this.setState({ openDeleteCnfrm: false });
  };
  handleDelete = () => {
    this.setState({ openDeleteCnfrm: false });
    var array = [...this.state.poiData]; // make a separate copy of the array
    selectedRowIndex.sort(function (a, b) {
      return b - a;
    });

    selectedRowIndex.forEach((i) => {
      let requestOptions = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },

      };
      let delid = array[i].id;
      array.splice(i, 1);
      fetch(ApiData.apiUrl + `/global-poi/${delid}/`, requestOptions)
        .then((response) => response)
        .then((data) => {
        });
    })

    this.setPoiData(array);
  };

  render() {
    return (
      <div>

        {/* conform box */}
        <div>

          <Dialog
            open={this.state.openDeleteCnfrm}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Delete"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are You Sure You Want to delete this Stop Point
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose}>Cancel</Button>
              <Button onClick={this.handleDelete} autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {/* conform box */}




        <div className="modal" >
          <div  >
            <span className="close">&times;</span>
            <div className="model_html">
              <h2>Add new point\stop</h2>
              <table cellPadding="8" >
                <tbody>
                  <tr>
                    <td width="50%">
                      Title
                      <br />
                      <TextField
                        id="txtSNTitle"
                        size="small"

                        variant="outlined"

                        value={this.state.stop_title}
                        error={!!this.state.errors['stop_title']}
                        onChange={(event) =>
                          this.setState({ stop_title: event.target.value })
                        }
                      />
                    </td>
                    <td>
                      Photo<br />
                      {this.state.stop_photo ? [
                        <div>

                          {/*   <img
                          style={{width:150}}
      src={this.state.stop_photo}
      alt="new"
      /> */}
                          <span className="lablesmall"> Currently:<span className="colorgreen"> {this.state.stop_photo}</span></span><br /><br />
                        </div>] : null}
                      <div className="divUpload">

                        <img width="20px" src={uploadIcon} />
                        <Button variant="contained" component="label" style={{ float: 'right', fontSize: 9, }} >
                          UPLOAD FILE
                          <input
                            type="file"
                            hidden
                            id="fileSNPhoto"
                            size="small"
                            value=""
                            onChange={this.handlePhoto}
                          />
                          {/*  <input
                            type="file"
                            hidden
                            id="fileSNPhoto"
                            size="small"
                            value=""
                            onChange={this.handlePhotoUploadAssets}
                          /> */}
                        </Button>

                      </div>
                      <span className="errorMsg">{this.state.errors['stop_photo']}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Country<br />

                      <ReactFlagsSelect
                        selected={this.state.stop_country}
                        id={'selectCountriesDay'}
                        showSelectedLabel={true}
                        showOptionLabel={true}
                        onSelect={(code) => { this.setState({ stop_country: code }); }}
                        placeholder={'Select a Country'}
                        countries={['GR', 'CR', 'NO', 'GE', 'MX', 'ME', 'DK', 'PL', 'PT', 'IS', 'MA', 'IL', 'TR', 'EC', 'ZA', 'IN', 'LT', 'IE', 'ES',]}
                      />






                    </td>
                    <td rowSpan="3" >
                      Location<br />
                      <TextField
                        id="txtStartLocDay"
                        size="small"
                        variant="outlined"
                        value={this.state.stop_location}
                        onChange={(event) => { /* console.log("newlocation", this.state.stop_location); */ this.setState({ stop_location: event.target.value }); this.setPoiLocationMap(event.target.value) }

                        }
                      />
                      <br /> <span className="errorMsg">{this.state.errors['stop_location']}</span><br />

                      OR
                      <MapboxAutocomplete publicKey={mapboxgl.accessToken}
                        inputClass='form-control search'
                        onSuggestionSelect={this._suggestionSelected}
                        onChange={(e) => { /*console.log("newlocation1", e.target.value)*/ }}
                        resetSearch={false} />


                      <br /> <br /> <br />
                      <div className="poimap">  <MapboxGLMap prefix={'mapDay-1'} /></div>
                      <span className="lablesmall"> Location Example: "Latitude,Longitude" - "32.0853,34.7818"</span>

                    </td>
                  </tr>

                  <tr>
                    <td>
                      Type<br />
                      <RadioGroup row aria-label="TYPE" value={this.state.stop_type.toString()} name="row-radio-buttons-group"
                        onChange={(event) => { this.setState({ stop_type: event.target.value }); }}>
                        <FormControlLabel value='0' control={<Radio />} label="Point of interest" />
                        <FormControlLabel value='1' control={<Radio />} label="Local Hero Tip" />
                        <FormControlLabel value='2' control={<Radio />} label="GPX" />
                        <FormControlLabel value='3' control={<Radio />} label="PACKAGE" />
                      </RadioGroup>
                      <br />
                      <span className="errorMsg">{this.state.errors['stop_type']}</span>
                      {this.state.stop_type == "1" &&
                        <>
                          <br />
                          Not Navigatable<br />

                          <Checkbox
                            checked={this.state.no_navigatable}
                            onChange={(event) => {
                              console.log('event.target.checked', event.target.checked)
                              this.setState({ no_navigatable: event.target.checked })
                            }}

                          ></Checkbox>
                        </>}

                    </td>

                  </tr>


                  <tr>
                    <td >
                      Detailed information<br />
                      <TextField
                        id="txtSNDesc"
                        multiline
                        rows={4}
                        variant="outlined"
                        fullWidth={true}
                        value={this.state.stop_desc}
                        error={!!this.state.errors['descpsn']}
                        onChange={(event) => this.setState({ stop_desc: event.target.value })
                        }
                      />
                    </td>
                    <td></td>
                  </tr>



                  <tr>
                    <td >
                      {this.state.save_type == 'add' &&
                        <Button
                          id="btnSaveSN1"
                          variant="contained"
                          color="primary"
                          onClick={this.handleSaveSN.bind(this)}
                        >
                          CREATE
                        </Button>
                      }
                      {this.state.save_type != 'add' &&
                        <Button
                          id="btnSaveSN1"
                          variant="contained"
                          color="primary"
                          onClick={this.handleSaveSN.bind(this)}
                        >
                          SAVE CHANGES
                        </Button>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>


            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
        <ToastContainer />

        <table className="viewdata" cellPadding="10">
          <tbody>

            <tr>
              <td>
                <h2>Stops\POIs Module</h2>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan="3"  ></td>
              <td>&nbsp;

                <Button
                  id="btnSave"
                  variant="contained"
                  className='deleteBtn'
                  onClick={() => { this.handleCsetPoiDatalickOpen(); }}
                  style={{ float: 'right', fontSize: 12, color: '#fff' }}

                >
                  DELETE SELECTED
                </Button>

              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <POITable setRowIDs={this.setSelectedRowIDs} poiData={this.state.poiData} setPoiData={this.setPoiData} title={this.state.stop_title} setTitle={this.setStopTitle} setPoiLocationMap={this.setPoiLocationMap} />
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <Link href="#" onClick={() => { this.setStopTitle(false, '', '', '', '', '', '', 'add', ''); openPopup(); initializeMap(-1, 'mapDay-1'); }}> + ADD NEW POINT\STOP  </Link>



              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <div className="borderDiv" />
              </td>
            </tr>

          </tbody>
        </table>

      </div>


    );

  }
}

export default StopsPoisPersonalized;

/* openPopup= () =>
{
  const modal = document.querySelector(".modal")
  const closeBtn = document.querySelector(".close")
  modal.style.display = "block";
  closeBtn.addEventListener("click", () => {
    modal.style.display = "none";
  })
}
 */
//Mapbox Key
mapboxgl.accessToken = MapboxKey;
let mapMarkersOne = [];
let mapMarkersTwo = [];
let webmapMarkers = [];
let vgmapMarkers = [];

let webMapOne;
let webMapTwo;
let webMap;
let vgMap;




const initializeMap = (z, id) => {
  if (id == 'mapDayVG') {
    vgMap = new mapboxgl.Map({
      container: id,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: z,
    });
    //Add navigation controls to the mapbox if zoom is lower
    if (z < 11) vgMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    vgMap.on('click', function (e) {
      if (selectedRowIndex) {
        addVgMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      } else {
        toast.error('Please select a trip first by clicking VG button against the record');
      }
    });
  } else if (id == 'mapDayStop') {
    webMap = new mapboxgl.Map({
      container: id,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: z,
    });
    //Add navigation controls to the mapbox if zoom is lower
    if (z < 11) webMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    webMap.on('click', function (e) {
      if (selectedRowIndex) {
        addWebMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      } else {
        addWebMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
        // toast.error('11Please select a trip first by clicking Web button against the record');
      }
    });
  } else {
    if (id == 'mapDay-1') {
      webMapOne = new mapboxgl.Map({
        container: id,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: z,
      });
      webMapOne.on('click', function (e) {



        window.bd.setPoiLocation(e.lngLat.lat.toFixed(6), e.lngLat.lng.toFixed(6));

        $('#txtStartLocDay').val(e.lngLat.lat.toFixed(6) + ',' + e.lngLat.lng.toFixed(6));
        // this.setState({ startloc: e.lngLat.lng.toFixed(4) + ',' + e.lngLat.lat.toFixed(4) });
        // console.log("mapclickedlocation", e.lngLat.lng.toFixed(6) + ',' + e.lngLat.lat.toFixed(6))
        mapMarkersOne.forEach((marker) => marker.remove());
        mapMarkersOne = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapOne);
        mapMarkersOne.push(marker);
      });
    } else {
      webMapTwo = new mapboxgl.Map({
        container: id,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: z,
      });

      webMapTwo.on('click', function (e) {
        $('#txtEndLocDay').val(e.lngLat.lng.toFixed(6) + ',' + e.lngLat.lat.toFixed(6));
        mapMarkersTwo.forEach((marker) => marker.remove());
        mapMarkersTwo = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapTwo);
        mapMarkersTwo.push(marker);
      });
    }
  }
};

class MapboxGLMap extends Component {
  constructor(props) {
    super(props);
    // this.id = _uniqueId(props.prefix + "-");
    this.id = props.prefix;
    this.latitude = 0;
    this.longitude = 0;
  }

  componentDidMount() {
    initializeMap(-1, this.id)
  }
  render() {
    const id = this.id;
    return (
      <div id={id} className="mapEA">
        {' '}
      </div>
    );
  }
}

class POITable extends Component {



  constructor(props) {
    super(props);
    // console.log('props', props)
    this.state = {
      data: [['Loading Data...']],
      loading: false,
      poiRowsSelected: [],
      currentPath: ''
    };
  }

  async componentDidMount() {
    let data = await this.getData();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has('id')) {
      let id = urlParams.getAll('id');
      this.setStopPoisData(id, data)
    }

  }

  componentWillUnmount() {

  }

  setStopPoisData = (id, data) => {
    let filterId = parseInt(id[0])
    let filterRow = data.filter(row => row.id === filterId)[0]
    let getIndexRow = data.findIndex(row => row.id === filterId)

    var countryCode;
    var countryName = filterRow.country
    countriesNames.map(function (item) {
      if (item.name === countryName)
        countryCode = item.code;
    });
    this.props.setTitle(filterRow.no_navigatable, filterRow.name, countryCode, filterRow.location, filterRow.type, filterRow.description, filterRow.photo, 'update', getIndexRow);
    openPopup(); initializeMap(-1, 'mapDay-1'); this.props.setPoiLocationMap(filterRow.location)
  }


  getData = () => {
    this.setState({ loading: true });
    let dataRows = this.xhrRequest().then((data) => {
      this.setState({ data, loading: false });
      this.props.setPoiData(data);
      return data
    });
    return dataRows
  };

  xhrRequest = () => {
    console.log('ApiData.apiUrl', ApiData.apiUrl)
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
      };
      fetch(ApiData.apiUrl + '/global-poi?personalized=true', requestOptions)
        .then((response) => response.json())
        .then((data) => {
          data.results.sort((a, b) => a.id - b.id);
          data.results.forEach((item) => {
            item.location = parseFloat(item.location.latitude).toFixed(4) + ',' + parseFloat(item.location.longitude).toFixed(4);
            item.type = item.type.toString();
            item.short_description = item.description;

            if (item.description != null && item.description.length > 100)
              item.short_description = item.description.substring(0, 100) + '...';
          })
          resolve(data.results);
        });
    });
  };

  render() {
    const columns = [
      {
        name: 'id', options: {
          label: 'ID', filter: false,

          customBodyRender: (value, tableMeta, updateValue) => {
            // const editrow = tableMeta.tableData[tableMeta.rowIndex];
            var editrow = {};
            var rowIndexForUpdate = 0;
            var i = 0;
            tableMeta.tableData.forEach((row) => {
              if (row.id == value) {
                editrow = row;
                rowIndexForUpdate = i;
              }
              i++;
            })
            //this.props.setPoiLocationMap(editrow.location)
            var countryCode;
            var countryName = editrow.country
            countriesNames.map(function (item) {
              if (item.name === countryName)
                countryCode = item.code;
            });
            return (
              //  title,country,location,type,desc,photo
              <Link href="#" onClick={() => {
                this.props.setTitle(editrow.no_navigatable, editrow.name, countryCode, editrow.location, editrow.type, editrow.description, editrow.photo, 'update', rowIndexForUpdate);
                window.history.pushState(null, null, `?page=PersonalizedStopsPOIs&id=${editrow.id}`);
                openPopup(); initializeMap(-1, 'mapDay-1'); this.props.setPoiLocationMap(editrow.location)
              }}>  {value}</Link>
            );
          },

        }
      },


      { name: 'name', label: 'Name', options: { filter: false } },
      { name: 'short_description', label: 'Description', options: { filter: false } },
      { name: 'location', label: 'Location', options: { filter: false } },
      {
        name: 'type', options: {
          label: 'Type', filter: false,
          customBodyRender: (value) => {
            if (value == 0) return 'Point of interest';
            if (value == 1) return 'Local Hero Tip';
            if (value == 2) return 'GPX';
            if (value == 3) return 'PACKAGE';
          }
        }
      },
      { name: 'photo', label: 'Photo', options: { display: false, filter: false } },
      { name: 'country', label: 'Country', options: { display: false, filter: true } },

    ];

    const onRowsSelect = (curRowSelected, rowsSelected) => {
      selectedRowIDs = [];
      selectedRowIndex = [];
      for (let i = 0; i < rowsSelected.length; i++) {
        selectedRowIDs.push(this.props.poiData[rowsSelected[i].dataIndex].id);
        selectedRowIndex.push(rowsSelected[i].dataIndex);
      }
      activeDeactiveDeleteBtn();
    };

    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      download: false,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: 'none',
      rowsSelected: this.state.poiRowsSelected,
      // count:20000,
      // selectableRows: 'single',
      onRowSelectionChange: onRowsSelect,
    };
    return (
      <div>
        <MUIDataTable
          title={
            <Typography variant="subtitle2">
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: 'relative', top: 4 }}
                />
              )}
            </Typography>
          }
          data={this.props.poiData}
          columns={columns}
          options={options}
          rowsSelected={onRowsSelect}
        />
      </div>
    );
  }
}

function addWebMarker(coordinates) {
  const id = _uniqueId('webmarker-');
  let marker;
  var el = document.createElement('div');
  el.className = 'marker';
  el.id = id;
  el.addEventListener(
    'click',
    (e) => {
      e.stopPropagation();
      let target_id = e.target.id;
      // let queriedMarkerObj = webmapMarkers.find((marker) => marker.id === query);
      // queriedMarkerObj.marker.remove();
      for (let i = 0; i < webmapMarkers.length; i++) {
        if (webmapMarkers[i].id === target_id) {
          document.getElementById(webmapMarkers[i].id).remove();
          webmapMarkers.splice(i, 1);
        }
      }
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(webMap);
  marker.properties = { id: id };
  webmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}

function addVgMarker(coordinates, type = false) {
  const id = _uniqueId('vgmarker-');
  let marker;
  var el = document.createElement('div');
  el.className = 'marker';
  el.id = id;
  if (type) {
    el.style.backgroundImage = `url('https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/${getSvg(
      type
    )}.svg')`;
  }
  el.addEventListener(
    'click',
    (e) => {
      e.stopPropagation();
      let query = e.target.id;

      let queriedMarkerObj = vgmapMarkers.find((marker) => marker.id === query);
      queriedMarkerObj.marker.remove();
      pointNumbers.splice(
        pointNumbers.findIndex((v) => v.id === query),
        1
      );
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(vgMap);
  vgmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}

function getSvg(type) {
  type = parseInt(type);
  switch (type) {
    case 1: {
      return 'straight';
    }
    case 2: {
      return 'right';
    }
    case 3: {
      return 'left';
    }
    case 4: {
      return 'fork-left';
    }
    case 5: {
      return 'fork-right';
    }
    case 6: {
      return 'poi';
    }
    case 7: {
      return 'info';
    }
    case 8: {
      return 'swimming';
    }
    case 9: {
      return 'rafting';
    }
    case 10: {
      return 'hotel';
    }
    default: {
      return 'poi';
    }
  }
}


function openPopup() {
  const modal = document.querySelector(".modal")
  const closeBtn = document.querySelector(".close")
  modal.style.display = "block";
  document.querySelector(".viewdata").style.display = "none";
  closeBtn.addEventListener("click", () => {
    window.history.pushState(null, null, `?page=PersonalizedStopsPOIs`);
    modal.style.display = "none";
    document.querySelector(".viewdata").style.display = "inline-table";
  })
}
function closePopup() {
  const modal = document.querySelector(".modal")
  modal.style.display = "none";
  document.querySelector(".viewdata").style.display = "inline-table";

}
function activeDeactiveDeleteBtn() {
  const btn = document.querySelector(".deleteBtn")
  if (selectedRowIDs.length > 0) btn.style.backgroundColor = 'blue';
  else btn.style.backgroundColor = '#e0e0e0';
}

/* jQuery(document).ready(function($) {

  if (window.history && window.history.pushState) {

    window.history.pushState('forward', null, './#forward');

    $(window).on('popstate', function() {
      alert('Back button was pressed.');
    });

  }
}); */

