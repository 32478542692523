import React from "react";

const ChangeLogSelectedTable = ({ data, differentKeys }) => {
  // const tableKeys = [    "days",    "name",    "photo",    "region",    "country",    "how_long",    "days_sort",    "trip_type",    "days_count",    "highlights",    "nature_level",    "culture_level",    "internal_name",    "adventure_level",    "group_structure",  ];
  const tableKeys = Object.keys(data);
  return (
    <table style={{ border: "1px solid black" }}>
      <tbody>
        {tableKeys.map((key) => (
          <tr key={key}>
            <td style={{ border: "1px solid black", fontWeight: "bold" }}>
              {key}
            </td>
            <td
              style={{
                border: "1px solid black",
                backgroundColor: differentKeys.includes(key) ? "yellow" : "white",
              }}
            >
              {data[key]}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ChangeLogSelectedTable;