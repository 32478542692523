import { toast } from 'react-toastify';

export const handleCallApi = async (method, url, bodyData, success, error) => {

  if (typeof bodyData !== 'string') {
    bodyData = JSON.stringify(bodyData);
  }
  
  try {
    let requestOptions = {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      body: bodyData,
    };
    let response = await fetch(url, requestOptions);
    let dataResponse = await response.json();
    if (response.ok) {
      if (success !== '') {
        toast.success(success);
      }
      return dataResponse;
    } else {
      console.log('error', response)
      if (error !== '') {
        toast.error(error);
      }
    }
  } catch (error) {
    ;
    // throw new Error(error.message || "Something went wrong");
  }
};

export const getDatafromApi = async (url) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    },
  };
  try {
    let response = await fetch(url, requestOptions);
    let dataResponse = await response.json();
    if (response.ok) {
      return dataResponse;
    }
  } catch (error) {
    console.error('error', error);
  }
}
