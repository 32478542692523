//import references
import React, { Component, useState } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import Link from '@material-ui/core/Link';
import MUIDataTable from 'mui-datatables';
import { CircularProgress, Typography } from '@material-ui/core';

import DataGrid, {
  Column,
  Selection,
  RowDragging,
  Scrolling,
  Paging,
  Sorting,

} from 'devextreme-react/data-grid';
import { ApiData } from "../config"

let daysData;



class VGPersonalized extends Component {



  constructor(props) {
    super(props);
    console.log('props', props)

    this.onReorder = this.onReorder.bind(this);

    this.state = {
      dataPOI: [['Loading Data...']],
      dataGPX: [['Loading Data...']],
      dataTrialPKG: [['Loading Data...']],
      loading: false,
      loading1: false,
      loading1: false,
      dragWebPOIsKeys: [],
      dragWebPOIsKeys1: [],
      dragWebPOIsKeys2: [],

      defaultSelectedRowPOI: [],
      defaultSelectedRowGPX: [],
      defaultSelectedRowTrialPKG: []
    };
  }


  onReorder(e) {
    const visibleRows = e.component.getVisibleRows();
    const newWebPOI = [...this.props.selectedVG];
    const toIndex = newWebPOI.indexOf(visibleRows[e.toIndex].data);
    const fromIndex = newWebPOI.indexOf(e.itemData);

    newWebPOI.splice(fromIndex, 1);
    newWebPOI.splice(toIndex, 0, e.itemData);
    this.props.setSelectedVG(newWebPOI);
   // let selectedWebPOI = newWebPOI.filter((day) => this.state.dragWebPOIsKeys.includes(day.id));
    const selectedRowKeys = newWebPOI.map(( item ) => 
    {
      if(item.type=="POI")
        return item.id
       else 
       return item.poi
    });
    this.props.setglobalPoiSet(selectedRowKeys)
  }


  onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    this.setState({ dragWebPOIsKeys: selectedRowKeys });
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({ loading: true, loading1: true, loading2: true });
    let a=0;
    this.xhrRequest().then((data) => {
      this.setState({ dataPOI: data, loading: false });

this.props.setVgpoiall(data)
      this.setselectedRecord();
      a=a+1
      this.setOrder(a)
    });
    this.xhrRequest1().then((data) => {
      this.setState({ dataGPX: data, loading1: false });
      this.setGPXselectedRecord();
      a=a+1
      this.setOrder(a)
    });
    this.xhrRequest2().then((data) => {
     // this.setState({ dataTrialPKG: data, loading2: false });
     // this.setTrialPackageselectedRecord();
      a=a+1
      this.setOrder(a)

    });

  };


  setOrder(i1) {
    if(i1 == 3)
    {
      console.log('lp12', this.props.selectedVG);
    }
    if (i1 == 3) {
      let vgpoikey = [];
      for (let i = 0; i < this.props.globalPoiSet.length; i++) {
        for (let j = 0; j < this.props.selectedVG.length; j++) {
          if (this.props.globalPoiSet[i] == this.props.selectedVG[j].id && this.props.selectedVG[j].type=="POI")
            vgpoikey.push(this.props.selectedVG[j])
            else if(this.props.selectedVG[j].type!="POI" && this.props.globalPoiSet[i] == this.props.selectedVG[j].poi )
            vgpoikey.push(this.props.selectedVG[j])
        }
      }

     /*  for (let k = 0; k < this.props.selectedVG.length; k++) {
        if (this.props.globalPoiSet.indexOf(this.props.selectedVG[k].id) == -1)
          vgpoikey.push(this.props.selectedVG[k])
      } */
      this.props.setSelectedVG(vgpoikey)
    }
  }

  xhrRequest = (url) => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
        'Authorization' :'Bearer '+ localStorage.getItem('token')
      },
      };
      //medraft comment 9993827008
      fetch(ApiData.apiUrl+'/global-poi/', requestOptions)
        .then((response) => response.json())
        .then((data1) => {
          data1.results.sort((a,b) => a.id - b.id);
          let data = data1.results.filter(function (item) { return (item.type == 0 || item.type == 1); });
          for (let i = 0; i < data.length; i++) {
            if(data[i].type==0)
            data[i].originalType = "POI";
            if(data[i].type==1)
            data[i].originalType = "INFORMATION POINT";
            if(data[i].type==2)
            data[i].originalType = "GPX";
            if(data[i].type==3)
            data[i].originalType = "TRAILPACKAGE";

            if (this.props.globalPoiSet.indexOf(data[i].id) > -1) {
              data[i].selected1 = 'true';
            }
          }

          daysData = data;
          resolve(data);
        });

    });
  };

  xhrRequest1 = (url) => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
        'Authorization' :'Bearer '+ localStorage.getItem('token')
      },
      };
      fetch(ApiData.apiUrl+'/gpxs?take=3000', requestOptions)
        .then((response) => response.json())
        .then((data) => {
          data.results.sort((a,b) => a.id - b.id);
          let gpxs = data.results.filter(function (item) { return (item.type == 0 && item.poi); });
          for (let i = 0; i < gpxs.length; i++) {
            if(gpxs[i].type==0)
            gpxs[i].originalType = "POI";
            if(gpxs[i].type==1)
            gpxs[i].originalType = "INFORMATION POINT";
            if(gpxs[i].type==2)
            gpxs[i].originalType = "GPX";
            if(gpxs[i].type==3)
            gpxs[i].originalType = "TRAILPACKAGE";
            if (this.props.globalPoiSet.indexOf(gpxs[i].poi) > -1) {
              gpxs[i].selected1 = 'true';
            }
          }
          daysData = gpxs;
          resolve(gpxs);
        });
    });
  };

  xhrRequest2 = (url) => {
    return new Promise((resolve, reject) => {
      resolve([])
      /* const requestOptions = {
        method: 'GET',
         headers: { 'Content-Type': 'application/json',
        'Authorization' :'Bearer '+ localStorage.getItem('token')
      },
      };
      fetch(ApiData.apiUrl+'/trail-packages/', requestOptions)
        .then((response) => response.json())
        .then((data) => {

          let trailPackages = data.results.filter(function (item) { return item.poi; });



          for (let i = 0; i < trailPackages.length; i++) {
            if(trailPackages[i].type==0)
            trailPackages[i].originalType = "POI";
            if(trailPackages[i].type==1)
            trailPackages[i].originalType = "INFORMATION POINT";
            if(trailPackages[i].type==2)
            trailPackages[i].originalType = "GPX";
            if(trailPackages[i].type==3)
            trailPackages[i].originalType = "TRAILPACKAGE";
            if (this.props.globalPoiSet.indexOf(trailPackages[i].poi) > -1) {
              trailPackages[i].selected1 = 'true';
            }


          }


          daysData = trailPackages;
          resolve(trailPackages);
        }); */
    });
  };


  setselectedRecord() {
    let selectedWebPOIsKeys1 = [...this.props.selectedVG];


    let defaultSelectedRow1 = [];
    for (let i = 0; i < this.state.dataPOI.length; i++) {
      if (this.state.dataPOI[i].selected1) {
        let flg = 1
        for (let j = 0; j < this.props.selectedVG.length; j++) {
          if (this.props.selectedVG[j].id == this.state.dataPOI[i].id && this.props.selectedVG[j].type == 'POI') {
            flg = 0
          }
        }
        defaultSelectedRow1.push(i);
        if (flg == 1) {
          let r1 = this.state.dataPOI[i];
          r1.type = 'POI';
          selectedWebPOIsKeys1.push(r1)
        }
      }

    }
    this.setState({ defaultSelectedRowPOI: defaultSelectedRow1 });
    this.setSelectedRows(selectedWebPOIsKeys1, 'POI')
  }

  setGPXselectedRecord() {
    let selectedWebPOIsKeys1 = [...this.props.selectedVG];
    let defaultSelectedRow2 = [];
    for (let i = 0; i < this.state.dataGPX.length; i++) {
      if (this.state.dataGPX[i].selected1) {
        let flg = 1
        for (let j = 0; j < this.props.selectedVG.length; j++) {
          if (this.props.selectedVG[j].id == this.state.dataGPX[i].id && this.props.selectedVG[j].type == 'GPX') {
            flg = 0
          }
        }
        defaultSelectedRow2.push(i);

        if (flg == 1) {
          //this.props.selectedVG.push(this.state.dataPOI[i])
          let r1 = this.state.dataGPX[i];
          r1.type = 'GPX';
          r1.originalType = "GPX";
          selectedWebPOIsKeys1.push(r1)
        }

      }

    }


    this.setState({ defaultSelectedRowGPX: defaultSelectedRow2 });

    this.setSelectedRows(selectedWebPOIsKeys1, 'GPX')
  }


  setTrialPackageselectedRecord() {
    let selectedWebPOIsKeys1 = [...this.props.selectedVG];
    let defaultSelectedRow2 = [];
    for (let i = 0; i < this.state.dataTrialPKG.length; i++) {
      if (this.state.dataTrialPKG[i].selected1) {
        let flg = 1
        for (let j = 0; j < this.props.selectedVG.length; j++) {
          if (this.props.selectedVG[j].id == this.state.dataTrialPKG[i].id && this.props.selectedVG[j].type == 'TRIALPACKAGE') {
            flg = 0;
          }
        }
        defaultSelectedRow2.push(i);

        if (flg == 1) {
          let r1 = this.state.dataTrialPKG[i];
          r1.type = 'TRIALPACKAGE';
          r1.originalType = "TRAILPACKAGE";
          selectedWebPOIsKeys1.push(r1)
        }
      }
    }
    this.setState({ defaultSelectedRowTrialPKG: defaultSelectedRow2 });
    this.setSelectedRows(selectedWebPOIsKeys1, 'TRIALPACKAGE')
  }


  setSelectedRows(selectedWebPOIsKeys1, currentSelectType) {
    let selectedWebPOIsKeysNew = []
    for (let i = 0; i < selectedWebPOIsKeys1.length; i++) {
      for (let j = 0; j < this.state.dataPOI.length; j++) {
        if ((this.state.dataPOI[j].id == selectedWebPOIsKeys1[i].id) && this.state.dataPOI[j].selected1 && selectedWebPOIsKeys1[i].type == 'POI') {
          let a = this.state.dataPOI[j];
          a.type = 'POI';
          selectedWebPOIsKeysNew.push(a)
        }
      }


      for (let k = 0; k < this.state.dataGPX.length; k++) {

        if ((this.state.dataGPX[k].id == selectedWebPOIsKeys1[i].id) && this.state.dataGPX[k].selected1 && selectedWebPOIsKeys1[i].type == 'GPX') {
          let a = this.state.dataGPX[k];
          a.type = 'GPX';
          selectedWebPOIsKeysNew.push(a)

        }
      }

      for (let l = 0; l < this.state.dataTrialPKG.length; l++) {

        if ((this.state.dataTrialPKG[l].id == selectedWebPOIsKeys1[i].id) && this.state.dataTrialPKG[l].selected1 && selectedWebPOIsKeys1[i].type == 'TRIALPACKAGE') {
          console.log("selectedWebPOIsKeysNew333")
          let a = this.state.dataTrialPKG[l];
          a.type = 'TRIALPACKAGE';
          selectedWebPOIsKeysNew.push(a)

        }
      }
    }
    this.props.setSelectedVG(selectedWebPOIsKeysNew);
  }

  render() {
    const columns = [
      { name: 'id', options: { label: 'ID', filter: false, } },
      { name: 'name', label: 'Name', options: { filter: false } },
      { name: 'country', label: 'Country' },
    ];

    const onRowsSelect = (curRowSelected, rowsSelected, rowMeta) => {
      let globalPoiSet1 = [...this.props.globalPoiSet];
      if (this.state.dataPOI[curRowSelected[0].dataIndex].selected1) {
        this.state.dataPOI[curRowSelected[0].dataIndex].selected1 = false;
        let matchId = this.state.dataPOI[curRowSelected[0].dataIndex].id;
        let a = globalPoiSet1.filter(function (item) { return item != matchId; });
        this.props.setglobalPoiSet(a);
      }
      else {
        this.state.dataPOI[curRowSelected[0].dataIndex].selected1 = true;
        globalPoiSet1.push(this.state.dataPOI[curRowSelected[0].dataIndex].id);
        this.props.setglobalPoiSet(globalPoiSet1);
      }
      this.setselectedRecord();
    };

    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      download: false,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: 'none',
      rowsSelected: this.state.defaultSelectedRowPOI,
      onRowSelectionChange: onRowsSelect,
    };

    //end for webpoi


    //for gpx
    const columnsGPX = [
      { name: 'id', options: { label: 'ID', filter: false, } },
      { name: 'name', label: 'Name', options: { filter: false } },
      { name: 'country', label: 'Country' },
    ];

    const onRowsSelectGPX = (curRowSelected, rowsSelected, rowMeta) => {
      let globalPoiSet2 = [...this.props.globalPoiSet];
      if (this.state.dataGPX[curRowSelected[0].dataIndex].selected1) {
        this.state.dataGPX[curRowSelected[0].dataIndex].selected1 = false;

        let matchPOI = this.state.dataGPX[curRowSelected[0].dataIndex].poi;
        let a = globalPoiSet2.filter(function (item) {
          return item != matchPOI;
        });
        this.props.setglobalPoiSet(a);
      }
      else {

        if (this.state.dataGPX[curRowSelected[0].dataIndex].poi) {
          this.state.dataGPX[curRowSelected[0].dataIndex].selected1 = true;
          globalPoiSet2.push(this.state.dataGPX[curRowSelected[0].dataIndex].poi);
          this.props.setglobalPoiSet(globalPoiSet2);
        }
      }

      this.setGPXselectedRecord();


    };

    const optionsGPX = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      download: false,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: 'none',
      rowsSelected: this.state.defaultSelectedRowGPX,
      onRowSelectionChange: onRowsSelectGPX,
    };

    //end for gpx



    //for trialPackage
    const columnstrialPackage = [
      { name: 'id', options: { label: 'ID', filter: false, } },
      { name: 'name', label: 'Name', options: { filter: false } },
      { name: 'country', label: 'Country' },
    ];

    const onRowsSelecttrialPackage = (curRowSelected, rowsSelected, rowMeta) => {
      let globalPoiSet3 = [...this.props.globalPoiSet];
      if (this.state.dataTrialPKG[curRowSelected[0].dataIndex].selected1) {
        this.state.dataTrialPKG[curRowSelected[0].dataIndex].selected1 = false;
        let matchPOI = this.state.dataTrialPKG[curRowSelected[0].dataIndex].poi;
        let a = globalPoiSet3.filter(function (item) {
          return item != matchPOI;
        });
        this.props.setglobalPoiSet(a);
      }
      else {

        if (this.state.dataTrialPKG[curRowSelected[0].dataIndex].poi) {
          this.state.dataTrialPKG[curRowSelected[0].dataIndex].selected1 = true;
          globalPoiSet3.push(this.state.dataTrialPKG[curRowSelected[0].dataIndex].poi);
          this.props.setglobalPoiSet(globalPoiSet3);
        }
      }

      this.setTrialPackageselectedRecord();


    };

    const optionstrialPackage = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      download: false,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: 'none',
      rowsSelected: this.state.defaultSelectedRowTrialPKG,
      onRowSelectionChange: onRowsSelecttrialPackage,
    };

    //end for trialPackage


    return (
      <table>

        <tr>
          <td style={{ width: '40%' }}>
            <h4> Select from country POIs: </h4>
          </td>
          <td style={{ width: '5%' }}>

          </td>
          <td style={{ width: '55%' }}>
            <h4> Selected POIs, GPXs and Packages: </h4>
          </td>
        </tr>

        <tr>
          <td style={{ width: '40%' }}>

            <div>
              <MUIDataTable
                title={
                  <Typography variant="subtitle2">
                    {this.state.loading && (
                      <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: 'relative', top: 4 }}
                      />
                    )}
                  </Typography>
                }
                data={this.state.dataPOI}
                columns={columns}
                options={options}
                rowsSelected={onRowsSelect}
                rowMeta={[0, 1]}
              />
            </div>

            <h4>  Select from country GPXs:</h4>
            <div>
              <MUIDataTable
                title={
                  <Typography variant="subtitle2">
                    {this.state.loading1 && (
                      <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: 'relative', top: 4 }}
                      />
                    )}
                  </Typography>
                }
                data={this.state.dataGPX}
                columns={columnsGPX}
                options={optionsGPX}
                rowsSelected={onRowsSelect}
                rowMeta={[0, 1]}
              />
            </div>
        
          </td>
          <td style={{ width: '10%' }}>

          </td>
          <td style={{ width: '45%', WebkitPaddingEnd: 100, verticalAlign: 'baseline' }} class="mytable">

            <DataGrid
              // height={440}
              dataSource={this.props.selectedVG}
              keyExpr="id"
              showBorders={true}
              ref={(ref) => (this.dataGrid = ref)}
              selectableRows={false}
              expandableRowsHeader={false}
              onSelectionChanged={this.onSelectionChanged}
              selectedRowKeys={this.state.dragWebPOIsKeys}
            >
              <RowDragging
                allowReordering={true}
                onReorder={this.onReorder}
                showDragIcons={true}
              />

              <Scrolling mode="virtual" />
              <Sorting mode="none" />

              <Column dataField="id" width={60} />
              <Column dataField="name" />
              <Column dataField="country" />
              <Column dataField="originalType" />

              <Paging defaultPageSize={10} />
            </DataGrid>

          </td>
        </tr>
      </table >
    );
  }
}
export default VGPersonalized;
