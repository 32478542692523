import React, { Component } from 'react';

class AttractionsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Estados para la tabla
      cost_per_pax: 0,
      cost_per_attraction_2_pax: 0,
      cost_per_attraction_3_pax: 0,
      cost_per_attraction_4_pax: 0,
    };
  }

  // async componentDidUpdate(prevProps, prevState) {
  //   if (this.props.currency !== prevProps.currency){
  //       this.setState(this.props.currency);
  //   } 
  // }

  render() {
    return (
      <div>
        <h2>Attractions Table</h2>
        <table className="attractions-table">
          <thead>
            <tr>
              <th>Cost Per Pax</th>
              <th>Cost Per Attraction 2 Pax</th>
              <th>Cost Per Attraction 3 Pax</th>
              <th>Cost Per Attraction 4 Pax</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{this.state.cost_per_pax}</td>
              <td>{this.state.cost_per_attraction_2_pax}</td>
              <td>{this.state.cost_per_attraction_3_pax}</td>
              <td>{this.state.cost_per_attraction_4_pax}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default AttractionsTable;
