import React, { Component } from 'react';
import { handleCallApi, getDatafromApi } from '../calls'
import { ApiData } from "../config"


class PackagePricePax extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trip_id: this.props.tripId || null,
      high_gross_price: 0,
      low_gross_price: 0,
      low_lh_handling_fee: 0,
      high_lh_handling_fee: 0,
      basicAccomodationPriceLowDouble: this.props.basicAccomodationPriceLowDouble || null,
      basicAccomodationPriceHighDouble: this.props.basicAccomodationPriceHighDouble || null,
      basicCarPriceLow: this.props.basicCarPriceLow || null,
      basicCarPriceHigh: this.props.basicCarPriceHigh || null,
      cratingData: false,
      isEditing: false,
      currencyValue: Number(this.props.currency) || 1,
        high_gross_price_edit: 0,
        low_gross_price_edit: 0,
        low_lh_handling_fee_edit: 0,
        high_lh_handling_fee_edit: 0,
    };
  }

  
  async componentDidMount() {
      this.getAllData()    
    }

    getAllData = async () => {
          try {
            if (this.props.tripId){
              const data = await getDatafromApi(ApiData.baseURL + `/package-price/trip/${this.props.tripId}`);
              if(data[0]){
                this.setState({       
                  high_gross_price: data[0].high_gross_price,
                  low_gross_price: data[0].low_gross_price,
                  low_lh_handling_fee: data[0].low_lh_handling_fee,
                  high_lh_handling_fee: data[0].high_lh_handling_fee,
                  high_gross_price_edit: data[0].high_gross_price,
                  low_gross_price_edit: data[0].low_gross_price,
                  low_lh_handling_fee_edit: data[0].low_lh_handling_fee,
                  high_lh_handling_fee_edit: data[0].high_lh_handling_fee,
                })
              }else{
                this.setState({cratingData: true})
              }
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }  
    };

    handleEditClick = () => {
      this.setState({ isEditing: true });
    };
    
    handleCancelEditClick = () => {
      this.setState({ 
        isEditing: false,
        high_gross_price: this.state.high_gross_price_edit,
        low_gross_price: this.state.low_gross_price_edit,
        low_lh_handling_fee: this.state.low_lh_handling_fee_edit,
        high_lh_handling_fee: this.state.high_lh_handling_fee_edit 
      });
    };
    
    handleSaveEditClick = async () => {
      let apidata = {
        trip_id: this.props.tripId,
        high_gross_price: Number(this.state.high_gross_price),
        low_gross_price: Number(this.state.low_gross_price),
        low_lh_handling_fee: Number(this.state.low_lh_handling_fee),
        high_lh_handling_fee: Number(this.state.high_lh_handling_fee),
      }
      let success = this.state.cratingData ? "Created successfully" : 'Updated successfully';
      const method = this.state.cratingData ? "POST" : "PUT";
      const url = this.state.cratingData ? ApiData.baseURL + `/package-price` :  ApiData.baseURL + `/package-price/trip/${this.props.tripId}`
      const data = await handleCallApi(method, url, JSON.stringify(apidata), success, '')
      if(this.state.cratingData && data){
        this.setState({       
          high_gross_price: data.high_gross_price,
          low_gross_price: data.low_gross_price,
          low_lh_handling_fee: data.low_lh_handling_fee,
          high_lh_handling_fee: data.high_lh_handling_fee,
        })
      }
      this.setState({ isEditing: false, cratingData: false });
    };
    

  render() {
    return (
      <div>
        <h2>Package Price per Pax</h2>
        <table className="price-table">
          <thead>
            <tr>
              <th>Season</th>
              <th>Gross Price</th>
              <th>Margin</th>
              <th>Margin %</th>
              <th>Financing Costs (5%)</th>
              <th>Net Cost</th>
              <th>LH Handling Fee</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Low</td>

              {/* LOW GROSS PRICE */}
              <td className='editable_td' onClick={this.handleEditClick}>
                {this.state.isEditing ? (
                  <input
                    type="number"
                    value={Number(this.state.low_gross_price)}
                    onChange={(e) =>
                      this.setState({ low_gross_price: e.target.value })
                    }
                  />
                ) : (
                  "€" +
                  (this.state.low_gross_price !== 0
                    ? Number(this.state.low_gross_price)
                    : 0
                  ).toFixed(2)
                )}
              </td>

              {/* LOW MARGIN  */}
              <td>€{(this.state.low_gross_price - ((this.props.basicAccomodationPriceLowDouble / Number(this.props.currency)) + (((this.props.basicCarPriceLow * this.props.daysCount) / 2) / Number(this.props.currency)) 
              + Number(this.state.low_lh_handling_fee)) - (this.state.low_gross_price * 0.05)).toFixed(2)}</td>

                {/* LOW MARGIN % */}
              <td>{((((this.state.low_gross_price - ((this.props.basicAccomodationPriceLowDouble / Number(this.props.currency)) + (((this.props.basicCarPriceLow * this.props.daysCount) / 2) / Number(this.props.currency)) + 
                Number(this.state.low_lh_handling_fee)) - (this.state.low_gross_price * 0.05)) 
                / Number(this.state.low_gross_price))* 100)).toFixed(1)}%</td>

              {/* LOW FINANCING COSTS */}
              <td>€{(this.state.low_gross_price * 0.05).toFixed(2)}</td>

              {/* LOW NET COST */}
              <td>€{((this.props.basicAccomodationPriceLowDouble / Number(this.props.currency)) + (((this.props.basicCarPriceLow * this.props.daysCount) / Number(this.props.currency)) / 2) 
              + Number(this.state.low_lh_handling_fee)).toFixed(2)}</td>

              {/* LOW HANDLING FEE % */}
              <td className='editable_td' onClick={this.handleEditClick}>
                {this.state.isEditing ? (
                  <input
                    type="number"
                    value={Number(this.state.low_lh_handling_fee)}
                    onChange={(e) =>
                      this.setState({ low_lh_handling_fee: e.target.value })
                    }
                  />
                ) : (
                  "€" +
                  (this.state.low_lh_handling_fee !== 0                  
                    ? Number(this.state.low_lh_handling_fee)
                    : 0
                  ).toFixed(2)
                )}
              </td>
            </tr>
            <tr>
              <td>Peak</td>
              <td className='editable_td' onClick={this.handleEditClick}>
                {this.state.isEditing ? (
                  <input
                    type="number"
                    value={Number(this.state.high_gross_price)}
                    onChange={(e) =>
                      this.setState({ high_gross_price: e.target.value })
                    }
                  />
                ) : (
                  "€" +
                  (this.state.high_gross_price !== 0
                    ? Number(this.state.high_gross_price)
                    : 0
                  ).toFixed(2)
                )}
              </td>

              {/* HIGH MARGIN */}
              <td>€{(this.state.high_gross_price - ((this.props.basicAccomodationPriceHighDouble / Number(this.props.currency)) + (((this.props.basicCarPriceHigh * this.props.daysCount) / 2) / Number(this.props.currency)) 
                + Number(this.state.high_lh_handling_fee)) - (this.state.high_gross_price * 0.05)).toFixed(2)}</td>
      
                {/* HIGH MARGIN % */}
              <td>{(((this.state.high_gross_price - ((this.props.basicAccomodationPriceHighDouble / Number(this.props.currency)) + (((this.props.basicCarPriceHigh * this.props.daysCount) / 2) / Number(this.props.currency)) 
                + Number(this.state.high_lh_handling_fee)) - (this.state.high_gross_price * 0.05)) / Number(this.state.high_gross_price)) * 100).toFixed(1)}%</td>

                {/* HIGH FINANCING COSTS */}
              <td>€{(this.state.high_gross_price * 0.05).toFixed(2)}</td>

                {/* HIGH NET COST */}
              <td>€{((this.props.basicAccomodationPriceHighDouble / Number(this.props.currency)) + (((this.props.basicCarPriceHigh * this.props.daysCount) / 2) / Number(this.props.currency)) 
                + Number(this.state.high_lh_handling_fee)).toFixed(2)}</td>

                {/* HIGH HANDLING FEE */}
              <td className='editable_td' onClick={this.handleEditClick}>
                {this.state.isEditing ? (
                  <input
                    type="number"
                    value={Number(this.state.high_lh_handling_fee)}
                    onChange={(e) =>
                      this.setState({ high_lh_handling_fee: e.target.value })
                    }
                  />
                ) : (
                  "€" +
                  (this.state.high_lh_handling_fee !== 0
                     ? Number(this.state.high_lh_handling_fee) 
                    : 0
                  ).toFixed(2)
                )}
              </td>

            </tr>
          </tbody>
        </table>
        {this.state.isEditing ? (
              <div>
                <button className="cancel-edit" onClick={this.handleCancelEditClick}>Cancel</button>
                <button onClick={this.handleSaveEditClick}>Save</button>
              </div>
            ) : null}
      </div>
    );
  }
}

export default PackagePricePax;
