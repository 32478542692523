import imageCompression from 'browser-image-compression';
import { ApiData } from "./config"
import { v4 as uuidv4 } from 'uuid';

export const handlePhotoUpload = async (event, bucket, dirName) => {
  try {
    const imageFile = event.target.files[0];
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1500,
      useWebWorker: true
    }

    const compressedFile = await imageCompression(imageFile, options);
    const blob = new Blob([compressedFile], { type: compressedFile.type });
    const file = new File([blob], compressedFile.name, { lastModified: compressedFile.lastModified });
    const formData = new FormData();
    formData.append('file', file);

    const apiUrl = ApiData.baseURL + `/uploads?bucket=${bucket}&dirName=${dirName}`;
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      body: formData
    };

    const response = await fetch(apiUrl, requestOptions);
    if (response.ok) {
      const data = await response.json();
      return data.url;
    } else {
      console.log('Photo could not be uploaded');
    }
  } catch (error) {
    console.log('Error uploading photo:', error);
    throw error;
  }
};

export const handleBase64Upload = async (base64Image, bucket, dirName) => {
  try {
    // Convertir el string base64 a un Blob
    const blob = await fetch(base64Image).then((response) => response.blob());

    // Aplicar compresión a la imagen usando imageCompression
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1500,
      useWebWorker: true
    };
    const compressedBlob = await imageCompression(blob, options);

    const uniqueId = uuidv4();
    const fileName = `compressed_image_${uniqueId}.jpg`;
    const file = new File([compressedBlob], fileName, { type: compressedBlob.type });

    // Clear FormData y agregar el archivo
    const formData = new FormData();
    formData.append('file', file);

    // Configurar la URL del servidor
    const apiUrl = ApiData.baseURL + `/uploads?bucket=${bucket}&dirName=${dirName}`;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      body: formData
    };

    // Realizar la solicitud al servidor
    const response = await fetch(apiUrl, requestOptions);

    // Manejar la respuesta del servidor
    if (response.ok) {
      const data = await response.json();
      console.log('Compressed image uploaded successfully', data.url);
      return data.url;
    } else {
      console.log('Compressed image could not be uploaded');
    }
  } catch (error) {
    console.log('Error uploading compressed image:', error);
    throw error;
  }
};

export const handleTrailPhotoUpload = async (event, bucket, dirName) => {
  try {
    const imageFile = event.target.files[0];
    const options = {
      maxSizeMB: .2,
      maxWidthOrHeight: 400,
      useWebWorker: true
    }

    const compressedFile = await imageCompression(imageFile, options);
    const blob = new Blob([compressedFile], { type: compressedFile.type });
    const file = new File([blob], compressedFile.name, { lastModified: compressedFile.lastModified });
    const formData = new FormData();
    formData.append('file', file);

    const apiUrl = ApiData.baseURL + `/uploads?bucket=${bucket}&dirName=${dirName}`;
    const requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      body: formData
    };

    const response = await fetch(apiUrl, requestOptions);
    if (response.ok) {
      const data = await response.json();
      console.log('Photo uploaded successfully', data.url);
      return data.url;
    } else {
      console.log('Photo could not be uploaded');
    }
  } catch (error) {
    console.log('Error uploading photo:', error);
    throw error;
  }
};

export const handleUploadFile = async (event, bucket, dirName) => {
  try {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    const method = 'POST';
    const apiUrl = ApiData.baseURL + `/uploads?bucket=${bucket}&dirName=${dirName}`;
    const requestOptions = {
      method: method,
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      body: formData
    };

    const response = await fetch(apiUrl, requestOptions);
    if (response.ok) {
      const data = await response.json();
      console.log('File uploaded successfully', data.url);
      return data.url;
    } else {
      console.log('File could not be uploaded');
    }
  } catch (error) {
    console.log('Error uploading file:', error);
    throw error;
  }
};
