import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ReactFlagsSelect from 'react-flags-select';

import Link from '@material-ui/core/Link';
import $ from 'jquery';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { CircularProgress, Typography } from '@material-ui/core';
import _uniqueId from 'lodash/uniqueId';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MUIDataTable from 'mui-datatables';
import MapboxAutocomplete from 'react-mapbox-autocomplete';


import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ApiData, MapboxKey, countriesNames } from "./config";
import { getDatafromApi, handleCallApi } from './calls';


// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


let pointNumbers = [];
let selectedRowIndex = false;
let selectedRowIDs = [];

class Purchases extends Component {

  constructor(props) {
    super(props);
    this.state = {
      purchaseData: [['Loading Data...']],
      fields: {},
      errors: {},
      country: '',
      inventory_name: '',
      price: '',
      status: '',
      userid: '',
      updateStopPoiRowIndex: '',
      openDeleteCnfrm: false,
      selRowIDs: []
    };
    window.bd = this;

  }

  setPurchaseTitle = async (country, inventory_name, price, status, userId, createdAt, updatePurchaseRowIndex) => {
    this.setState({ country, inventory_name, price, status, userId, createdAt, updatePurchaseRowIndex });
  };

  setPurchaseData = async (data) => {
    this.setState({ purchaseData: data });
  };

  setSelectedRowIDs = async (selectedRowIDs1) => {
    this.setState({ selRowIDs: selectedRowIDs1 })
  }

  handleClickOpen = () => {
    if (selectedRowIDs.length > 0)
      this.setState({ openDeleteCnfrm: true });
  };

  handleClose = () => {
    this.setState({ openDeleteCnfrm: false });
  };

  handleDelete = () => {
    this.setState({ openDeleteCnfrm: false });
    var array = [...this.state.purchaseData]; // make a separate copy of the array
    selectedRowIndex.sort(function (a, b) {
      return b - a;
    });
    selectedRowIndex.forEach(async (i) => {
      let idToDelete = array[i].id;
      array.splice(i, 1);
      await handleCallApi('DELETE', ApiData.baseURL+`/purchase/${idToDelete}/`, {}, '', '')
    })
    this.setPurchaseData(array);
  };

  render() {
    return (
      <div>
        {/* conform box */}
        <div>
          <Dialog
            open={this.state.openDeleteCnfrm}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Delete"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are You Sure You Want to delete this Stop Point
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose}>Cancel</Button>
              <Button onClick={this.handleDelete} autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
        {/* conform box */}

        <div className="modal" >
          <div  >
            <span className="close">&times;</span>
            <div className="model_html">
              <h2>Add new Purchase</h2>
              <table cellPadding="8" >
                <tbody>
                  <tr>
                    <td width="50%">
                      Title
                      <br />
                      <TextField
                        id="txtSNTitle"
                        size="small"

                        variant="outlined"

                        value={this.state.inventory_name}
                        error={!!this.state.errors['inventory_name']}
                        onChange={(event) =>
                          this.setState({ inventory_name: event.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Country<br />

                      <ReactFlagsSelect
                        selected={this.state.stop_country}
                        id={'selectCountriesDay'}
                        showSelectedLabel={true}
                        showOptionLabel={true}
                        onSelect={(code) => { this.setState({ stop_country: code }); }}
                        placeholder={'Select a Country'}
                        countries={[ 'GR','CR', 'NO','GE',  'MX', 'ME', 'DK',  'PL', 'PT','IS', 'MA',  'IL',  'TR',   'EC','ZA', 'IN','LT','IE','ES',     ]}
                      />
                    </td>
                    <td rowSpan="3" >
                      Location<br />
                      <TextField
                        id="txtStartLocDay"
                        size="small"
                        variant="outlined"
                        value={this.state.stop_location}
                        onChange={(event) => { this.setState({ stop_location: event.target.value }); this.setPoiLocationMap(event.target.value) }

                        }
                      />
                      <br /> <span className="errorMsg">{this.state.errors['stop_location']}</span><br />

                      OR
                      <MapboxAutocomplete publicKey={mapboxgl.accessToken}
                        inputclassName='form-control search'
                        onSuggestionSelect={this._suggestionSelected}
                        onChange={(e) => {  }}
                        resetSearch={false} />


                      <br /> <br /> <br />
                      <div className="poimap">  <MapboxGLMap prefix={'mapDay-1'} /></div>
                      <span className="labelsmall"> Location Example: "Latitude,Longitude" - "32.0853,34.7818"</span>

                    </td>
                  </tr>

                  <tr>
                    <td>
                      Type<br />
                      <RadioGroup row aria-label="TYPE" value={this.state.stop_type} name="row-radio-buttons-group"
                        onChange={(event) => { this.setState({ stop_type: event.target.value }); }}>
                        <FormControlLabel value='0' control={<Radio />} label="Point of interest" />
                        <FormControlLabel value='1' control={<Radio />} label="Local Hero Tip" />
                        <FormControlLabel value='2' control={<Radio />} label="GPX" />
                        <FormControlLabel value='3' control={<Radio />} label="PACKAGE" />
                      </RadioGroup>
                      <br />
                      <span className="errorMsg">{this.state.errors['stop_type']}</span>
                    </td>

                  </tr>


                  <tr>
                    <td >
                    Detailed information<br />
                      <TextField
                        id="txtSNDesc"
                        multiline
                        rows={4}
                        variant="outlined"
                        fullWidth={true}
                        value={this.state.stop_desc}
                        error={!!this.state.errors['descpsn']}
                        onChange={(event) => this.setState({ stop_desc: event.target.value })
                        }
                      />
                    </td>
                    <td></td>
                  </tr>

                  <tr>
                    <td >
                      {this.state.save_type === 'add' &&
                        <Button
                          id="btnSaveSN1"
                          variant="contained"
                          color="primary"
                        //   onClick={this.handleSaveSN.bind(this)}
                        >
                          CREATE
                        </Button>
                      }
                      {this.state.save_type != 'add' &&
                        <Button
                          id="btnSaveSN1"
                          variant="contained"
                          color="primary"
                        //   onClick={this.handleSaveSN.bind(this)}
                        >
                          SAVE CHANGES
                        </Button>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>


            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
        <ToastContainer />

        <table className="viewdata" cellPadding="10">
          <tbody>

            <tr>
              <td>
                <h2>Purchases</h2>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan="3"></td>
              <td>&nbsp;

              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <PurchaseTable setRowIDs={this.setSelectedRowIDs} purchaseData={this.state.purchaseData} setPurchaseData={this.setPurchaseData} title={this.state.inventory_name} setTitle={this.setPurchaseTitle} />
              </td>
            </tr>
            <tr>
              <td colSpan="4">
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <div className="borderDiv" />
              </td>
          </tr>
          </tbody>
        </table>
      </div>
    );

  }
}

export default Purchases;

mapboxgl.accessToken = MapboxKey;
let mapMarkersOne = [];
let mapMarkersTwo = [];
let webmapMarkers = [];
let vgmapMarkers = [];

let webMapOne;
let webMapTwo;
let webMap;
let vgMap;


const initializeMap = (z, id) => {
  if (id === 'mapDayVG') {
    vgMap = new mapboxgl.Map({
      container: id,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: z,
    });
    //Add navigation controls to the mapbox if zoom is lower
    if (z < 11) vgMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    vgMap.on('click', function (e) {
      if (selectedRowIndex) {
        addVgMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      } else {
        toast.error('Please select a trip first by clicking VG button against the record');
      }
    });
  } else if (id === 'mapDayStop') {
    webMap = new mapboxgl.Map({
      container: id,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: z,
    });
    //Add navigation controls to the mapbox if zoom is lower
    if (z < 11) webMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    webMap.on('click', function (e) {
      if (selectedRowIndex) {
        addWebMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      } else {
        addWebMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
        // toast.error('11Please select a trip first by clicking Web button against the record');
      }
    });
  } else {
    if (id === 'mapDay-1') {
      webMapOne = new mapboxgl.Map({
        container: id,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: z,
      });
      webMapOne.on('click', function (e) {
        window.bd.setPoiLocation(e.lngLat.lat.toFixed(6), e.lngLat.lng.toFixed(6));
        $('#txtStartLocDay').val(e.lngLat.lat.toFixed(6) + ',' + e.lngLat.lng.toFixed(6));
        // this.setState({ startloc: e.lngLat.lng.toFixed(4) + ',' + e.lngLat.lat.toFixed(4) });
        mapMarkersOne.forEach((marker) => marker.remove());
        mapMarkersOne = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapOne);
        mapMarkersOne.push(marker);
      });
    } else {
      webMapTwo = new mapboxgl.Map({
        container: id,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: z,
      });

      webMapTwo.on('click', function (e) {
        $('#txtEndLocDay').val(e.lngLat.lng.toFixed(6) + ',' + e.lngLat.lat.toFixed(6));
        mapMarkersTwo.forEach((marker) => marker.remove());
        mapMarkersTwo = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapTwo);
        mapMarkersTwo.push(marker);
      });
    }
  }
};

class MapboxGLMap extends Component {
  constructor(props) {
    super(props);
    // this.id = _uniqueId(props.prefix + "-");
    this.id = props.prefix;
    this.latitude = 0;
    this.longitude = 0;
  }

  componentDidMount() {
    initializeMap(-1, this.id)
  }
  render() {
    const id = this.id;
    return (
      <div id={id} className="mapEA">
        {' '}
      </div>
    );
  }
}

class PurchaseTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [['Loading Data...']],
      loading: false,
      purchaseRowsSelected: [],
      currentPath: ''
    };
  }

 async componentDidMount() {
    let data = await this.getData();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has('id')){
     let id = urlParams.getAll('id');
     this.setPurchaseDataId(id,data)
    }
  }

  componentWillUnmount() {

  }

  setPurchaseDataId = (id, data) => {
    let filterId = parseInt(id[0])
    let filterRow = data.filter(row => row.id === filterId)[0]
    let getIndexRow = data.findIndex(row => row.id === filterId)

    var countryCode;
    var countryName = filterRow.country
    countriesNames.map(function (item) {
      if (item.name === countryName)
        countryCode = item.code;
    });
      this.props.setTitle(filterRow.name, countryCode, filterRow.location, filterRow.type, filterRow.description, filterRow.photo, 'update', getIndexRow);
  }

  getData = () => {
    this.setState({ loading: true });
    let dataRows = this.xhrRequest().then((data) => {
      this.setState({ data, loading: false });
      this.props.setPurchaseData(data);
      return data
    });
    return dataRows;
  };

  xhrRequest =  () => {
    return new Promise(async (resolve, reject) => {
      const data = await getDatafromApi(ApiData.baseURL + '/purchase?take=3000');
      if (data){
        data.results.sort((a,b) => b.id - a.id);
        let dataPurchases = [];
        data.results.forEach((item) => {
          let itemRow = {};

          itemRow.id = item.id;
          itemRow.country = item.extra_data;
          itemRow.createdAt = (new Date(item.createdAt)).toLocaleString('en-GB');;
          itemRow.inventory_name = item.inventory && item.inventory.name || '';
          const currency = item.currency ? item.currency : 'EUR';
          itemRow.price = item.price + ' ' + currency;
          if (item.status && item.status === 1){
            itemRow.status = 'Failed';
          }
          if (item.status && item.status === 3){
            itemRow.status = 'Success';
          }
          if (item.status && item.status === 5){
            itemRow.status = 'Refunded';
          }
          itemRow.recurring = item.recurringPayments === true ? 'V' : 'X';
          itemRow.userId = item.user.id;
          itemRow.userName = item.user.first_name + ' ' + item.user.last_name;
          itemRow.userEmail = item.user.email;
          dataPurchases.push(itemRow);
        });
        resolve(dataPurchases);
      }
    });
  };

  render() {
    const columns = [
      {
        name: 'id', options: {
          label: 'ID', filter: false,

          customBodyRender: (value, tableMeta, updateValue) => {
            // const editrow = tableMeta.tableData[tableMeta.rowIndex];
            var editrow = {};
            var rowIndexForUpdate = 0;
            var i = 0;
            tableMeta.tableData.forEach((row) => {
              if (row.id === value) {
                editrow = row;
                rowIndexForUpdate = i;
              }
              i++;
            })
            //this.props.setPoiLocationMap(editrow.location)
            var countryCode;
            var countryName = editrow.country
            countriesNames.map(function (item) {
              if (item.name === countryName)
                countryCode = item.code;
            });
            return (
              //  title,country,location,type,desc,photo
              <Link href="#" onClick={() => {
               /*  this.props.setTitle(editrow.name, countryCode, editrow.location, editrow.type, editrow.description, editrow.photo, 'update', rowIndexForUpdate);
                window.history.pushState(null, null, `?page=Stops/POIs&id=${editrow.id}`);
                openPopup(); initializeMap(-1, 'mapDay-1'); this.props.setPoiLocationMap(editrow.location) */
              }}>  {value}</Link>
            );
          },

        }
      }, 

     
      { name: 'country', label: 'Country', options: { filter: false } },
      { name: 'inventory_name', label: 'Inventory Name', options: { filter: false } },
      { name: 'price', label: 'Price', options: { filter: false } },
      { name: 'status', label: 'Status', options: { filter: false } },
      { name: 'recurring', label: 'Recurring', options: { filter: false } },
      { name: 'userId', label: 'User Id', options: { filter: false } },
      { name: 'userName', label: 'User name', options: { filter: false } },
      { name: 'userEmail', label: 'User email', options: { filter: false } },
      { name: 'createdAt', label: 'Created At', options: { filter: false } },

    ];

    const onRowsSelect = (curRowSelected, rowsSelected) => {
      selectedRowIDs = [];
      selectedRowIndex = [];

      for (let i = 0; i < rowsSelected.length; i++) {
        selectedRowIDs.push(this.props.purchaseData[rowsSelected[i].dataIndex].id);
        selectedRowIndex.push(rowsSelected[i].dataIndex);
      }
      activeDeactiveDeleteBtn();
    };

    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      download: false,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: 'none',
      rowsSelected: this.state.purchaseRowsSelected,
     // count:20000,
      // selectableRows: 'single',
      onRowSelectionChange: onRowsSelect,
    };
    return (
      <div>
        <MUIDataTable
          title={
            <Typography variant="subtitle2">
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 8, position: 'relative', top: 4 }}
                />
              )}
            </Typography>
          }
          data={this.props.purchaseData}
          columns={columns}
          options={options}
          rowsSelected={onRowsSelect}
        />
      </div>
    );
  }
}

function addWebMarker(coordinates) {

  const id = _uniqueId('webmarker-');

  let marker;
  var el = document.createElement('div');
  el.className = 'marker';
  el.id = id;
  el.addEventListener(
    'click',
    (e) => {
      e.stopPropagation();
      let target_id = e.target.id;
      // let queriedMarkerObj = webmapMarkers.find((marker) => marker.id === query);
      // queriedMarkerObj.marker.remove();
      for (let i = 0; i < webmapMarkers.length; i++) {
        if (webmapMarkers[i].id === target_id) {
          document.getElementById(webmapMarkers[i].id).remove();
          webmapMarkers.splice(i, 1);
        }
      }
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(webMap);
  marker.properties = { id: id };
  webmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}

function addVgMarker(coordinates, type = false) {
  const id = _uniqueId('vgmarker-');
  let marker;
  var el = document.createElement('div');
  el.className = 'marker';
  el.id = id;
  if (type) {
    el.style.backgroundImage = `url('https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/${getSvg(
      type
    )}.svg')`;
  }
  el.addEventListener(
    'click',
    (e) => {
      e.stopPropagation();
      let query = e.target.id;

      let queriedMarkerObj = vgmapMarkers.find((marker) => marker.id === query);
      queriedMarkerObj.marker.remove();
      pointNumbers.splice(
        pointNumbers.findIndex((v) => v.id === query),
        1
      );
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(vgMap);
  vgmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}

function handleGpx(xmlData) {
  const converter = require('@tmcw/togeojson');
  const geojson = converter.gpx(xmlData[2]);
  if (typeof vgMap.getLayer('day-route-line') !== 'undefined') {
    vgMap.removeLayer('day-route-line').removeSource('day-route');
  }
  vgMap.addSource('day-route', {
    type: 'geojson',
    data: geojson,
  });

  vgMap.addLayer({
    id: 'day-route-line',
    type: 'line',
    source: 'day-route',
    paint: {
      'line-color': '#15cc09',
      'line-width': 4,
    },
  });

  var coordinates = geojson.features[0].geometry.coordinates;
  var bounds = coordinates.reduce(function (bounds, coord) {
    return bounds.extend(coord);
  }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

  vgMap.fitBounds(bounds, {
    padding: 20,
  });
}

function getSvg(type) {
  type = parseInt(type);
  switch (type) {
    case 1: {
      return 'straight';
    }
    case 2: {
      return 'right';
    }
    case 3: {
      return 'left';
    }
    case 4: {
      return 'fork-left';
    }
    case 5: {
      return 'fork-right';
    }
    case 6: {
      return 'poi';
    }
    case 7: {
      return 'info';
    }
    case 8: {
      return 'swimming';
    }
    case 9: {
      return 'rafting';
    }
    case 10: {
      return 'hotel';
    }
    default: {
      return 'poi';
    }
  }
}




function openPopup() {
  const modal = document.querySelector(".modal")
  const closeBtn = document.querySelector(".close")
  modal.style.display = "block";
  document.querySelector(".viewdata").style.display = "none";
  closeBtn.addEventListener("click", () => {
    window.history.pushState(null, null, `?page=Stops/POIs`);
    modal.style.display = "none";
    document.querySelector(".viewdata").style.display = "inline-table";
  })
}
function closePopup() {
  const modal = document.querySelector(".modal")
  modal.style.display = "none";
  document.querySelector(".viewdata").style.display = "inline-table";

}
function activeDeactiveDeleteBtn() {
  const btn = document.querySelector(".deleteBtn")
  if (selectedRowIDs.length > 0) btn.style.backgroundColor = 'blue';
  else btn.style.backgroundColor = '#e0e0e0';
}

/* jQuery(document).ready(function($) {

  if (window.history && window.history.pushState) {

    window.history.pushState('forward', null, './#forward');

    $(window).on('popstate', function() {
      alert('Back button was pressed.');
    });

  }
}); */

