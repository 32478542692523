import { Box, Chip, CircularProgress, FormControl, MenuItem, Modal, Typography } from '@material-ui/core'; 
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { Stack } from '@mui/material';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MUIDataTable from 'mui-datatables';
import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ApiData } from "./config";
import { handlePhotoUpload } from "./uploadFiles";
import { handleCallApi, getDatafromApi } from './calls'

const SPACED_DATE_FORMAT = "DD MMM YYYY";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

let selectedRowIndex = false;
let selectedRowIds = [];

class User extends Component {
	constructor(props) {
		super(props);
		this.state = {
			fields: {},
			errors: {},
			photourl: '',
			webphotoLoc: '',
			userTableData: [['Loading Data...']],
			searchData: [],
			user_first_name: '',
			user_last_name: '',
			user_email: '',
			user_phone: '',
			user_role: '',
			cpassword: '',
			newPassword: '',
			confirmPassword: '',
			trip_id: '',
			selectedUserTrip: [],
			updateUserRowIndex: '',
			device: [],
			currentTrip: '',
			save_type: 'add',
			openDeleteCnfrm: false,
			allTrip: [],
			selectedTrip: [],
			editState: false,
			loading: false,
			openFilterByDate: false,
			userIdSelected: 0,
			dateFrom: '',
			dateTo: '',
		};
	}

	async componentDidMount() {
		this.getTrip();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.openModalDay !== prevState.openModalDay) {
		   if(!this.state.openModalDay){
			 this.setState({
			   relatedDays: [],
			   loadingRelated: true
			 });
		   }
		}
	  }

	async getTrip() {
		this.setState({ loading: true });
		let data = await getDatafromApi(ApiData.apiUrl + '/trips/simple-trips')
		if (data) {
			data.sort((a, b) => a.id - b.id);
			this.setState({ allTrip: data });
			this.setState({ loading: false });
		}
	}

	setUserSearchData = async (data) => {
		this.setState({ searchData: data });
	};

	handleUserValidation() {
		let errors = {};
		let formIsValid = true;

		if (!this.state.user_first_name) {
			formIsValid = false;
			errors['user_first_name'] = '*';
		}
		if (!this.state.user_email) {
			formIsValid = false;
			errors['user_email'] = 'Email is Required';
		}
		/*  if (!this.state.localHero_country) {
		   formIsValid = false;
		   errors['localHero_country'] = 'Please Select Country';
		 } */
		/*  if (!this.state.localHero_availabilityTimeFrom) {
		   formIsValid = false;
		   errors['localHero_availabilityTimeFrom'] = 'Time In  hh:mm:ss Formate';
		 }
		 if (!this.state.localHero_availabilityTimeTo) {
		   formIsValid = false;
		   errors['localHero_availabilityTimeTo'] = 'Time In  hh:mm:ss Formate';
		 } */

		this.setState({ errors: errors });
		return formIsValid;
	}

	handleUserChangePasswordValidation() {
		let errors = {};
		let formIsValid = true;

		if (!this.state.newPassword) {
			formIsValid = false;
			errors['newPassword'] = 'New Password is Required';
		}
		if (!this.state.confirmPassword) {
			formIsValid = false;
			errors['confirmPassword'] = 'Confirm Password is Required';
		}
		if (this.state.newPassword != this.state.confirmPassword) {
			formIsValid = false;
			errors['confirmPassword'] = 'New password and Confirm Password does not match';
		}
		this.setState({ errors: errors });
		return formIsValid;
	}

	handleChangePassword = async (e) => {
		e.preventDefault();
		toast.dismiss();
		if (this.handleUserChangePasswordValidation()) {
			let userData1 = [...this.state.userTableData];
			let apidata = {
				//  "currentPassword": this.state.cpassword,
				"newPassword": this.state.newPassword,
			}
			let url1 = ApiData.baseURL + `/auth/change-password/${this.state.userIdSelected}`;
			let data = await handleCallApi('POST', url1, JSON.stringify(apidata), 'password changed successfully', '')
			if (data) {
				closePopoup();
			}
		}
		else {
			toast.error('Missing Information / Fields');
		}
	}

	handleSaveUser = async (e) => {
		e.preventDefault();
		toast.dismiss();
		if (this.handleUserValidation()) {
			let currentTrip = [];
			if (this.state.trip_id && this.state.trip_id !== "No Current Trip") {
				currentTrip = await getDatafromApi(ApiData.apiUrl + `/trips/${this.state.trip_id}`)
			}
			let userData1 = [...this.state.userTableData];
			let searchData1 = [...this.state.searchData];
			let apidata = {
				"id": 0,
				"first_name": this.state.user_first_name,
				"last_name": this.state.user_last_name,
				"role": this.state.user_role,
				"email": this.state.user_email,
				"phone": this.state.user_phone,
				"avatar": "",
				"currentTrip": currentTrip,
				"currentTripId": this.state.trip_id,
				"updatedAt1": ""
			}
			let method = 'POST';
			let url1 = ApiData.baseURL + `/user`;
			let success = 'User Added'
			if (this.state.save_type != 'add') {
				method = 'PUT';
				success = 'User Updated';
				url1 = ApiData.baseURL + `/user/admin-panel/${this.state.userIdSelected}/`;
			}
			let data = await handleCallApi(method, url1, JSON.stringify(apidata), success, '')
			if (data) {
				if (this.state.save_type === 'add') {
					userData1.push(data);
					if(this.state.searchData && this.state.searchData.length){
						let searchData1 = [...this.state.searchData];
						searchData1.push(data)
						this.setState({ searchData: searchData1 })
					  }
					this.setState({ userTableData: userData1 })
				}
				else {
					console.log('DATITA', data)
					if (data.currentTrip?.id)
						data.trip_id = data.currentTrip.id;
					let rd = new Date(data.createdAt);
					let registrationTime = rd.toLocaleTimeString("en-US");;
					data.registrationDate = String(rd.getDate()).padStart(2, '0') + '-'
						+ String(parseInt(rd.getMonth() + 1)).padStart(2, '0') + '-'
						+ rd.getFullYear() + " "
						+ registrationTime;
					let updateUser = await this.handleUserTableData(data)
					if(this.state.searchData && this.state.searchData.length > 0){
						searchData1[this.state.updateUserRowIndex] = updateUser;
						this.setState({ searchData: searchData1 });	

					} else{
						userData1[this.state.updateUserRowIndex] = updateUser;
						this.setState({ userTableData: userData1 });
					}
				}
				this.setState({
					user_first_name: '',
					user_last_name: '',
					user_email: '',
					user_phone: '',
					trip_id: '',
					user_role: '',
				});
				closePopoup();
			}
		}
		else {
			toast.error('Missing Information / Fields');
		}
	}

	handleUserTableData = (data) => {
		let dataForTable = []

		let userData = {}
		userData.authProvider = data.authProvider;
		userData.id = data.id;
		userData.createdAt = data.createdAt;
		userData.updatedAt = data.updatedAt;
		userData.first_name = data.first_name;
		userData.last_name = data.last_name;
		userData.email = data.email;
		userData.password = data.password;
		userData.phone = data.phone;
		userData.avatar = data.avatar;
		userData.role = data.role;
		userData.plan = data.plan;
		userData.support_trip = data.support_trip;
		userData.verification_token = data.verification_token;
		userData.reset_password_token = data.reset_password_token;
		userData.reset_password_expires_at = data.reset_password_expires_at;
		userData.passwordChangedAt = data.passwordChangedAt;
		if (data.currentTrip !== null) {
			userData.currentTrip = data.currentTrip;
			userData.currentTripId = data.currentTripId
		} else {
			userData.currentTrip = 'No Current Trip'
			userData.currentTripId = 'No Current Trip'
		}
		if (data.devices && data.devices.length > 0) {
			let result = [];
			data.__devices__.forEach(function (device) {
				//let dataDevice = `OS: ${device.os} - Device Id: ${device.deviceId}, `;
				let dataDevice = `${device.os}, `;
				result.push(dataDevice);
			});
			userData.devices = result;
		} else {
			userData.devices = ['No devices found']
		}

		userData.registrationDate = data.registrationDate;
		dataForTable.push(userData)

		return userData
	}

	handlePhoto = async (event) => {
		try {
			const url = await handlePhotoUpload(event, 'planmytrip-assets', 'localHeros');
			if (url) {
				this.setState({ localHero_photo: url });
			}
		} catch (error) {
			console.log('Error uploading photo:', error);
		}
	};

	setUserData = async (id, save_type) => {
		this.setState({ loading: true });
		let user = []
		let updateUserRowIndex= 0
		this.setState({userIdSelected: id})
		if(this.state.searchData && this.state.searchData.length > 0){
			user = this.state.searchData.filter(user => user.id === id);
			updateUserRowIndex = this.state.searchData.findIndex(user => user.id === id);
		} else {
			user = this.state.userTableData.filter(user => user.id === id);
			updateUserRowIndex = this.state.userTableData.findIndex(user => user.id === id);
		}
		let userSelectedTrip;
		if (user && user.length > 0) {
			const trip = user[0].currentTrip;
			if (trip != "No Current Trip") {
				const selectedTrip = [{ internal_name: trip.internal_name, id: trip.id }]
				userSelectedTrip = selectedTrip
			} else {
				userSelectedTrip = "No Current Trip"
			}
		} else {
			userSelectedTrip = "No Current Trip"
		}
		this.setState({ user_first_name: user[0].first_name, user_last_name: user[0].last_name, user_email: user[0].email, user_phone: user[0].phone, user_role: user[0].role, trip_id: user[0].currentTripId, cpassword: user[0].password, currentTrip: user[0].currentTrip, save_type: save_type, updateUserRowIndex: updateUserRowIndex, selectedUserTrip: userSelectedTrip, errors: {} });
		this.setState({ loading: false });
	};

	setUserTrip = async (tripId, userId) => {
		let tempUserTableData;
		if(this.state.searchData && this.state.searchData.length > 0) {
			tempUserTableData = this.state.searchData
		}else{
			tempUserTableData =	this.state.userTableData;
		}
		const user = tempUserTableData.filter(user => user.id === userId);
		const sortedIds = user[0].currentTrip.days_sort.split(',').map(Number);
		let trip = user[0].currentTrip;
		const trip_sorted = sortedIds.map(id => trip.days.find(day => day.id === id));
		trip.days = trip_sorted
		this.setState({ selectedTrip: trip, save_type: 'tripView' });
	};

	setUserTableData = async (data) => { this.setState({ userTableData: data }); };

	handleClickOpen = () => {
		if (selectedRowIndex.length > 0)
			this.setState({ openDeleteCnfrm: true });
	};

	handleDelete = () => {
		this.setState({ openDeleteCnfrm: false });
		var array = [...this.state.userTableData]; // make a separate copy of the array
		selectedRowIndex.sort(function (a, b) {
			return b - a;
		});

		selectedRowIndex.forEach(async (i) => {
			let delid = array[i].id;
			let delemail = array[i].email;
			array.splice(i, 1);
			let apidata = {
				"email": delemail
			}
			await handleCallApi('DELETE', ApiData.baseURL + `/user`, JSON.stringify(apidata), '', '')
		})
		this.setUserTableData(array);
		selectedRowIndex = [];
	};

	handleClose = () => {
		this.setState({ openDeleteCnfrm: false });
		selectedRowIndex = [];
	};

	openFilterByDate = () => {
		this.setState({ openFilterByDate: true });
	};
	
	handleToDateChange = (event) => {
		const toDate = event.target.value;
		this.setState({ dateTo: toDate });
	}
	  
	handleFromDateChange = (event) => {
		const fromDate = event.target.value;
		this.setState({ dateFrom: fromDate });
	}

	handleFilterByDate = () => {
		this.xhrRequestFilterbyDate(ApiData.baseURL + `/user/filterbydates?dateFrom=${this.state.dateFrom}&dateTo=${this.state.dateTo}`).then((data) => {
		this.setUserSearchData(data);
	});
	}

	handleCloseDateFilter = () => {
		this.setUserSearchData([])
		this.setState({ dateTo: '', dateFrom: '', openFilterByDate: false})
	}

	xhrRequestFilterbyDate = (url) => {
			return new Promise(async (resolve, reject) => {
				let data = await getDatafromApi(url)
				if (data) {
					data.sort((a, b) => a.id - b.id);
					for (let i = 0; i < data.length; i++) {
						if (data[i].currentTrip?.id)
						data[i].trip_id = data[i].currentTrip.id;
						let rd = new Date(data[i].createdAt);
						let registrationTime = rd.toLocaleTimeString("en-US");;
						data[i].registrationDate = String(rd.getDate()).padStart(2, '0') + '-'
						+ String(parseInt(rd.getMonth() + 1)).padStart(2, '0') + '-'
						+ rd.getFullYear() + " "
						+ registrationTime;
					}
					resolve(data);
				}
			});
		
	};

	render() {
		return (
			<div>

				<Dialog
					open={this.state.openDeleteCnfrm}
					onClose={this.handleClose} user_first_name
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						{"Delete"}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Are You Sure You Want to delete this User
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={this.handleClose}>Cancel</Button>
						<Button onClick={this.handleDelete} autoFocus>
							Delete
						</Button>
					</DialogActions>
				</Dialog>


				<div className="modal">
					<div className="modal_content">
						<span className="close">&times;</span>
						<div className="model_html">
							{this.state.loading ? (
								<div>Loading...</div>
							) : (
								this.state.save_type === "update" && (
									<>
										<h2>UPDATE USER</h2>
										<table cellPadding="8" >
											<tbody>
												<tr>
													<td >
														<label>First Name</label><br />
														<TextField
															id="txtSNTitle"
															size="small"
															variant="outlined"
															value={this.state.user_first_name}
															error={!!this.state.errors['user_first_name']}
															onChange={(event) =>
																this.setState({ user_first_name: event.target.value })
															}
														/>
													</td>
													<td >
														<label>Last Name</label><br />
														<TextField
															id="txtSNTitle"
															size="small"

															variant="outlined"

															value={this.state.user_last_name}
															error={!!this.state.errors['user_last_name']}
															onChange={(event) =>
																this.setState({ user_last_name: event.target.value })
															}
														/>
													</td>
													{/*   <td>
                      <label>Main Photo url</label><br />
                      {this.state.localHero_photo ? [
                        <div><span className="labelsmall"> Currently:<span className="colorgreen"> {this.state.localHero_photo}</span></span><br /><br />
                        </div>] : null}



                      <div className="divUpload">

                        <img width="20px" src={uploadIcon} />
                        <Button variant="contained" component="label" style={{ float: 'right', fontSize: 9, }} >
                          UPLOAD FILE
                          <input
                            type="file"
                            hidden
                            id="fileSNPhoto"
                            size="small"
                            value=""
                            onChange={this.handlePhoto}
                          />
                        </Button>

                      </div>
                      <span className="errorMsg">{this.state.errors['localHero_photo']}</span>


                    </td> */}
												</tr>

												<tr>
													<td >
														<label>Email</label><br />
														<TextField
															id="txtSNTitle"
															size="small"

															variant="outlined"

															value={this.state.user_email}
															error={!!this.state.errors['localHero_email']}
															onChange={(event) =>
																this.setState({ user_email: event.target.value })
															}
														/><br />
														<span className="errorMsg">{this.state.errors['user_email']}</span>

													</td>
													<td >
														<label>Phone</label><br />
														<TextField
															id="txtSNTitle"
															size="small"

															variant="outlined"

															value={this.state.user_phone}
															error={!!this.state.errors['localHero_phone']}
															onChange={(event) =>
																this.setState({ localHero_phone: event.target.value })
															}
														/>
													</td>


												</tr>



												<tr>

													<td style={{ verticalAlign: 'baseline' }}>
														<label>Trip</label><br />
														<FormControl style={{ width: '70%' }} className="dropdownWithborder">
															<Select labelId="demo-simple-select-label" id="demo-simple-select" label="Type"
																value={this.state.selectedUserTrip !== "No Current Trip"
																	? `${this.state.selectedUserTrip[0].internal_name}(${this.state.selectedUserTrip[0].id})`
																	: "No Current Trip"}
																onChange={(event) => { console.log(event.target.value); this.setState({ trip_id: event.target.value.id, currentTrip: event.target.value.id, selectedUserTrip: [event.target.value] }); }}
															>
																<MenuItem disabled value={this.state.selectedUserTrip !== "No Current Trip"
																	? `${this.state.selectedUserTrip[0].internal_name}(${this.state.selectedUserTrip[0].id})`
																	: "No Current Trip"}>

																	{this.state.selectedUserTrip !== "No Current Trip"
																		? `${this.state.selectedUserTrip[0].internal_name}(${this.state.selectedUserTrip[0].id})`
																		: "No Current Trip"}

																</MenuItem>
																{this.state.allTrip.map((item, i) =>
																(
																	<MenuItem key={i} value={item}>{item.internal_name}({item.id})</MenuItem>
																)
																)}
															</Select>
														</FormControl>

													</td>


													<td style={{ verticalAlign: 'baseline' }}>
														<label>Role</label><br />
														<FormControl style={{ width: '30%' }} className="dropdownWithborder">
															<Select labelId="demo-simple-select-label" id="demo-simple-select" label="Type"
																value={this.state.user_role}
																onChange={(event) => { this.setState({ user_role: event.target.value }); }}
															>
																<MenuItem value={"SUPERADMIN"}>SUPERADMIN</MenuItem>
																<MenuItem value={"ADMIN"}>ADMIN</MenuItem>
																<MenuItem value={"USER"}>USER</MenuItem>
															</Select>
														</FormControl>

													</td>
												</tr>
												<tr>
													<td colSpan="4">
														{this.state.save_type == 'add' &&
															<Button
																id="btnSaveSN"
																variant="contained"
																color="primary"
																onClick={this.handleSaveUser.bind(this)}
															>
																CREATE
															</Button>
														}

														{this.state.save_type != 'add' &&
															<Button
																id="btnSaveSN"
																variant="contained"
																color="primary"
																onClick={this.handleSaveUser.bind(this)}
															>
																SAVE CHANGES
															</Button>
														}
													</td>
												</tr>
											</tbody>
										</table>
									</>
								)
							)}
							{this.state.save_type == "changePassword" &&
								<>
									<h2>UPDATE USER</h2>
									<table cellPadding="8" >
										<tbody>
											<tr>
												<td >
													<label>New Password</label><br />
													<TextField
														id="txtSNTitle"
														size="small"
														type="password"
														variant="outlined"

														value={this.state.newPassword}
														error={!!this.state.errors['newPassword']}
														onChange={(event) =>
															this.setState({ newPassword: event.target.value })
														}
													/>
												</td>
												<td >
													<label>Confirm Password</label><br />
													<TextField
														id="txtSNTitle"
														size="small"
														type="password"
														variant="outlined"

														value={this.state.confirmPassword}
														error={!!this.state.errors['confirmPassword']}
														onChange={(event) =>
															this.setState({ confirmPassword: event.target.value })
														}
													/>
												</td>

											</tr>




											<tr>
												<td colSpan="4">

													<Button
														id="btnSaveSN"
														variant="contained"
														color="primary"
														onClick={this.handleChangePassword.bind(this)}
													>
														Change Password
													</Button>

												</td>
											</tr>
										</tbody>
									</table>
								</>
							}

							{(this.state.save_type === "tripView") && (
								<>
									{this.state.selectedTrip ? (
										<>
											<h2>User Trip</h2>
											<table cellPadding="8">
												<tbody>
													<tr>
														<td>
															<label>Internal Name</label><br />
															<h4>{this.state.selectedTrip.internal_name}</h4>
														</td>
														<td>
															<label>Name</label><br />
															<h4>{this.state.selectedTrip.name}</h4>
														</td>
													</tr>
													<tr>
														<td>
															<label>Id</label><br />
															<h4>{this.state.selectedTrip.id}</h4>
														</td>
														<td>
															<label>Country</label><br />
															<h4>{this.state.selectedTrip.country}</h4>
														</td>
													</tr>
													<tr>
														<td>
															<label>Trip Days</label><br />
															{this.state.selectedTrip.days.map((day, index) => (
																<h5 key={day.id}>{`${index + 1}) id ${day.id} - ${day.name}`}</h5>
															))}
														</td>
													</tr>
												</tbody>
											</table>
										</>
									) : (
										<h4>Trip not found</h4>
									)}
								</>
							)}

						</div>
					</div>
				</div>
				<ToastContainer
					position="top-right"
					autoClose={3000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss={false}
					draggable
					pauseOnHover
				/>
				<ToastContainer />
				<table cellPadding="10" className="viewdata">
					<tbody>

						<tr>
							<td>
								<h2>Users</h2>
								{(this.state.openFilterByDate) && (
				<div style={{ position: 'absolute', top: '0px', right: '200px', border: "1px solid black", marginBottom: '2rem'}}>
					<div style={{ display: 'flex', padding: '10px', justifyContent: 'center', alignContent: 'center', flexDirection: 'rows' }}>
						<h4 style={{margin: 0}}>Filter by dates</h4>
						<label htmlFor="fromDate">From:</label>
						<input type="date" id="fromDate" onChange={this.handleFromDateChange} />
						<label htmlFor="toDate">To:</label>
						<input type="date" id="toDate" onChange={this.handleToDateChange} />
						<button style={{marginLeft: '5px'}} onClick={this.handleFilterByDate}>Sumbit</button>
						<p  style={{marginLeft: '10px', cursor: 'pointer'}} onClick={this.handleCloseDateFilter}>X</p>
					</div>
				</div>
				)}

							</td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
		
						<tr>
							<td colSpan="3"  ></td>
							<td>&nbsp;
								<Button
									id="btnSave"
									variant="contained"
									color="#15cc09"
									className='deleteBtn'
									onClick={() => { this.openFilterByDate(); }}
									style={{ float: 'right', fontSize: 12, color: '#fff', marginLeft: '10px', cursor: 'pointer' }}
								>
									Filter by Date
								</Button>
								<Button
									id="btnSave"
									variant="contained"
									color="#15cc09"
									className='deleteBtn'
									onClick={() => { this.handleClickOpen(); console.log('dvdsg', selectedRowIndex) }}
									style={{ float: 'right', fontSize: 12, color: '#fff', cursor: 'pointer' }}
								>
									DELETE SELECTED
								</Button>
							</td>
						</tr>
						<tr>
							<td colSpan="4">
								<UserTable openFilterByDate={this.state.openFilterByDate} userData={this.state.userTableData} searchData={this.state.searchData} setUserSearchData={this.setUserSearchData} setUserTableData={this.setUserTableData} setUserData={this.setUserData} setUserTrip={this.setUserTrip} />
							</td>
						</tr>
						{/*   <tr>
           
              <td colSpan="4">
                <Link href="#" onClick={() => { this.setUserData(  '', '', '', '','','', 'add', ''); console.log('username', this.state.user_name); openPopup() }}> + ADD NEW User  </Link>
              </td>
            </tr> */}
						<tr>
							<td colSpan="4">
								<div className="borderDiv" />
							</td>
						</tr>

					</tbody>
				</table>
		
			</div>


		);
	}
}

export default User;






class UserTable extends Component {



	constructor(props) {
		super(props);
		this.state = {
			data: [],
			loadingData: [["Loding Data"]],
			loading: false,
			localHeroRowsSelected: [],
			openDeleteModal: false,
			indexUserForDelete: '',
			idUserDevice: '',
			page: 0,
			count: 0,
			rowsPerPage: 10,
			filterData: [],
			filterBy: '',
			filterValue: '',
			allUsers:[],
			filterState: false,
			ascendingSort: true,
			reload: true,
		};
	}



	componentDidMount() {
		this.getData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.userData !== prevProps.userData) {
			this.setState({ data: this.props.userData });
		}
		if (this.props.searchData !== prevProps.searchData && this.props.searchData.length > 0) {
			this.setState({ filterState: true });
		}
		if (this.props.openFilterByDate !== prevProps.openFilterByDate && !this.props.openFilterByDate) {
			this.setState({ filterState: false });
		}
		if (this.state.page !== prevState.page || this.state.rowsPerPage !== prevState.rowsPerPage) {
			this.getData();
		}
	}


	getData = () => {
		this.setState({ loading: true, reload: false });

		this.xhrRequest(ApiData.baseURL + `/user?take=${this.state.rowsPerPage}&skip=${this.state.rowsPerPage * this.state.page}`).then((data) => {
			data.devices = data.__devices__;
			let dataForTable = this.setDataForTable(data)

			const users = [...this.state.data, ...dataForTable];
			const uniqueUsers = users.reduce((acc, user) => {
				const existingUser = acc.find((u) => u.id === user.id);
				if (!existingUser) {
					acc.push(user);
				}
				return acc;
			}, []);
			this.setState({ data: uniqueUsers, loading: false, reload: true });
			this.props.setUserTableData(uniqueUsers);
		});
	};

	setDataForTable = (data) => {
		let dataForTable = [];
		data.forEach((item) => {
			let userData = {
				authProvider: item.authProvider,
				id: item.id,
				createdAt: item.createdAt,
				updatedAt: item.updatedAt,
				first_name: item.first_name,
				last_name: item.last_name,
				email: item.email,
				password: item.password,
				phone: item.phone,
				avatar: item.avatar,
				role: item.role,
				plan: item.plan,
				support_trip: item.support_trip,
				verification_token: item.verification_token,
				reset_password_token: item.reset_password_token,
				reset_password_expires_at: item.reset_password_expires_at,
				passwordChangedAt: item.passwordChangedAt,
			};

			if (item.currentTrip !== null) {
				userData.currentTrip = item.currentTrip;
				userData.currentTripId = item.currentTripId;
			} else {
				userData.currentTrip = 'No Current Trip';
				userData.currentTripId = 'No Current Trip';
			}
			if (item.__devices__.length > 0) {
				let result = [];
				item.__devices__.forEach((device) => {
					//let dataDevice = `OS: ${device.os} - Device Id: ${device.deviceId}, `;
					let dataDevice = `${device.os}, `;
					result.push(dataDevice);
				});
				userData.devices = result;
			} else {
				userData.devices = ['No devices found'];
			}

			userData.registrationDate = item.registrationDate;
			dataForTable.push(userData);
		});
		return dataForTable
	}

	xhrRequest = (url) => {
		return new Promise(async (resolve, reject) => {
			// let data = await getDatafromApi(ApiData.baseURL + `/user?take=10&skip=10`)
			let data = await getDatafromApi(url)
			data.results.sort((a, b) => a.id - b.id);
			for (let i = 0; i < data.results.length; i++) {
				if (data.results[i].currentTrip?.id)
				data.results[i].trip_id = data.results[i].currentTrip.id;
				let rd = new Date(data.results[i].createdAt);
				let registrationTime = rd.toLocaleTimeString("en-US");;
				data.results[i].registrationDate = String(rd.getDate()).padStart(2, '0') + '-'
				+ String(parseInt(rd.getMonth() + 1)).padStart(2, '0') + '-'
				+ rd.getFullYear() + " "
				+ registrationTime;
			}
			this.setState({ count: data.count });
			resolve(data.results);
		});
	};

	xhrRequestSort = async (url) => {
		return new Promise(async (resolve, reject) => {
			// let data = await getDatafromApi(ApiData.baseURL + `/user?take=10&skip=10`)
			let data = await getDatafromApi(url)
			data.sort((a, b) => a.id - b.id);
			for (let i = 0; i < data.length; i++) {
				if (data[i].currentTrip?.id)
				data[i].trip_id = data[i].currentTrip.id;
				let rd = new Date(data[i].createdAt);
				let registrationTime = rd.toLocaleTimeString("en-US");;
				data[i].registrationDate = String(rd.getDate()).padStart(2, '0') + '-'
				+ String(parseInt(rd.getMonth() + 1)).padStart(2, '0') + '-'
				+ rd.getFullYear() + " "
				+ registrationTime;
			}
			resolve(data);
		});
	};

	getFilteredData = async () => {
		if (this.state.searchTerm.length > 0 || this.state.filterValue.length > 0) {
			this.setState({ loading: true })
			this.xhrRequestFilterData().then((data) => {
				this.setState({ loading: false }, async () => {
					const dataForTable =  this.setDataForTable(data)
					this.props.setUserSearchData(dataForTable);
					this.setState({ filteredData: dataForTable, filterState: true });
				})
			});
		}
	};

	xhrRequestFilterData = () => {
		if (this.state.searchTerm.length > 0 || this.state.filterValue.length > 0) {
			return new Promise(async (resolve, reject) => {
				let data = await getDatafromApi(ApiData.baseURL + `/user/find?${this.state.searchTerm ? `q=${this.state.searchTerm}` : ''}${this.state.filterValue ? `${this.state.filterBy}=${this.state.filterValue}` : ''}`)
				if (data) {
					data.sort((a, b) => a.id - b.id);

					for (let i = 0; i < data.length; i++) {
						if (data[i].currentTrip?.id)
						data[i].trip_id = data[i].currentTrip.id;
						let rd = new Date(data[i].createdAt);
						let registrationTime = rd.toLocaleTimeString("en-US");;
						data[i].registrationDate = String(rd.getDate()).padStart(2, '0') + '-'
						+ String(parseInt(rd.getMonth() + 1)).padStart(2, '0') + '-'
						+ rd.getFullYear() + " "
						+ registrationTime;
					}
					resolve(data);
				}
			});
		}
	};

	handleDeleteDevice = async () => {
		if (this.state.idUserDevice) {
			this.setState({ openDeleteModal: false });
			let apiUrl = ApiData.baseURL + `/user-devices/clear/${this.state.idUserDevice}`;
			await handleCallApi('POST', apiUrl, {}, 'Device deleted successfully', 'Device could not be deleted')
			let userDataLocal=this.props.userData;
			for(let i1=0;i1<userDataLocal.length;i1++)
			{
				if(userDataLocal[i1].id==this.state.idUserDevice)
				{
					userDataLocal[i1].devices=['No devices found']
				}
			}

			let searchDataLocal=this.props.searchData;
			for(let i2=0;i2<searchDataLocal.length;i2++)
			{
				if(searchDataLocal[i2].id==this.state.idUserDevice)
				{
					searchDataLocal[i2].devices=['No devices found']
				}
			}

			this.props.setUserSearchData(searchDataLocal);
			//this.setState({ filteredData: dataForTable, filterState: true });


			this.props.setUserTableData(userDataLocal);
		}

	}

	handleChangeOntripSupport = async (userid, ontripsupport) => {
		let apiUrl = ApiData.baseURL + `/user/support-trip/` + userid;
		await handleCallApi('PUT', apiUrl, {}, 'Support Trip Changed successfully', 'Support Trip could not be Changed')
	}
	handleChangePlan = async (userid, plan) => {
		let apiUrl = ApiData.baseURL + `/user/plan`;
		await handleCallApi('PUT', apiUrl, { "userId": userid, "plan": plan }, 'Plan Changed successfully', 'Plan could not be Changed')
	}

	handleOpenModal = (id, index) => {
		this.setState({ idUserDevice: id, indexUserForDelete: index });
		this.setState({ openDeleteModal: true });
	}

	handleCloseModal = () => {
		this.setState({ openDeleteModal: false });
	}

	render() {
		const columns = [
			{
				name: 'id', options: {
					label: 'ID', filter: false,
					customBodyRender: (value, tableMeta, updateValue) => {
						var editrow = {};
						var rowIndexForUpdate = 0;
						var i = 0;
						tableMeta.tableData.forEach((row) => {
							if (row[0] == value) {
								editrow = row;
								rowIndexForUpdate = i;
							}
							i++;
						})

						var trip_id = 0;
						if (editrow.currentTrip?.id) trip_id = editrow.currentTrip.id;

						return (
							<Link href="#" onClick={async () => {
								await this.props.setUserData(value, 'update');
								openPopup();
							}}> {value}</Link>
						);
					},
				}
			},
			{ name: 'first_name', label: 'First Name', options: { filter: false } },
			{ name: 'last_name', label: 'Last Name', options: { filter: false } },
			{ name: 'email', label: 'Email', options: { filter: false } },
			{
				name: 'currentTripId', label: 'Active Trip', options: {
					filter: false,
					customBodyRender: (value, tableMeta, updateValue) => {
						let disabled = false;
						if (value === 'No Current Trip') {
							disabled = true;
						}

						return (
							!disabled ? (
								<Link href="#" onClick={async () => {
									await this.props.setUserTrip(value, tableMeta.rowData[0]);
									openPopup();
								}}>
									{value}
								</Link>
							) : (
								<p>{value}</p>
							)
						);
					}
				}
			},
			{
				name: 'registrationDate', type: 'date', label: 'Date of registration', options: {
					filter: false,
				}
			},
			{ name: 'role', label: 'Role', options: { filter: false } },
			{ name: 'plan', label: 'plan', options: { filter: false, display: "none" } },
			{
				name: 'id', options: {
					label: 'plan', filter: false,

					customBodyRender: (value, tableMeta, updateValue) => {
						var editrow = {};
						var countryCode = {};
						var rowIndexForUpdate = 0;
						var i = 0;
						tableMeta.tableData.forEach((row) => {
							if (row[0] == value || row.id==value) {
								editrow = row;
								rowIndexForUpdate = i;
							}
							i++;
						})
						var plan="";
						var userId = 0;
						if(editrow.plan)
						{
							plan=editrow.plan;
							userId=editrow.id
						}
						else
						{
						plan=editrow[7];
						userId=editrow[0]
						}

						var id=-1;
						if(tableMeta.tableData[tableMeta.rowIndex][0])
						 id=tableMeta.tableData[tableMeta.rowIndex][0];
						 else
						 id=tableMeta.tableData[tableMeta.rowIndex].id;
						return (
						<>
						{ plan && 
							<FormControl className="dropdownWithborder" style={{ maxWidth: 150 }}>
							<Select labelId="demo-simple-select-label"  label="Type"
							value={plan}
							  style={{width:150}}
							  name={"plan" + userId}
							  id={"plan" + userId}
							  onChange={(event) => {  

								let tempUserTableData;
								if(this.props.searchData && this.props.searchData.length > 0) {
									tempUserTableData = this.props.searchData
								}else{
									tempUserTableData =	this.props.userData;
								}
							
								var i1=0
								tempUserTableData.forEach((row1) => {
									if(row1.id==editrow.id || row1.id==editrow[0]) 
									{
									tempUserTableData[i1].plan=event.target.value;
										this.handleChangePlan(tempUserTableData[i1].id,event.target.value);
									}
									i1++;
								})
									this.props.setUserTableData(tempUserTableData);
									this.setState({reload:true})
							  }}
							>
							  <MenuItem  value={"free"}>Free</MenuItem>
							  <MenuItem  value={"basic"}>Basic</MenuItem>
							  <MenuItem  value={"premium"}>Premium</MenuItem>
							</Select>
						  </FormControl>
					}
							
							 </>
						);
					},
				}
			},
			{ name: 'support_trip', label: 'support_trip', options: { filter: false, display: "none" } },
			{
				name: 'id', options: {
					label: 'On Trip Support', filter: false,
					customBodyRender: (value, tableMeta, updateValue) => {
						var editrow = {};
						var countryCode = {};
						var rowIndexForUpdate = 0;
						var i = 0;
						tableMeta.tableData.forEach((row) => {
							if (row[0] == value || row.id == value) {
								editrow = row;
								rowIndexForUpdate = i;
							}
							i++;
						})
						var plan = "";
						var userId = 0;
						if (editrow.plan) {
							plan = editrow.plan;
							userId = editrow.id
						}
						else {
							plan = editrow[7]
							userId = editrow[0]
						}

						var ontripsupport = false;

						if (editrow?.support_trip == true)
							ontripsupport = editrow.support_trip
						else if (editrow[9] && editrow[9] == true)
							ontripsupport = editrow[9]
						else
							ontripsupport = false

						var id = -1;
						if (tableMeta.tableData[tableMeta.rowIndex][0])
							id = tableMeta.tableData[tableMeta.rowIndex][0];
						else
							id = tableMeta.tableData[tableMeta.rowIndex].id;
						return (
							<>
								{(ontripsupport || plan == "premium") &&
									<Checkbox {..."label"}
										defaultChecked={true}
										disabled={plan == "premium" ? 'disabled' : ""}
										name={"tripsupprot" + userId}
										onChange={(event) => {
											let tempUserTableData;
											if(this.props.searchData && this.props.searchData.length > 0) {
												tempUserTableData = this.props.searchData
											}else{
												tempUserTableData =	this.props.userData;
											}
											var i1 = 0
											tempUserTableData.forEach((row1) => {
												if (row1.id == editrow.id || row1.id == editrow[0]) {

													this.handleChangeOntripSupport(tempUserTableData[i1].id, event.target.checked);
												}
												i1++;
											})
										}} />
								}
								{(!ontripsupport && plan != "premium") &&
									<Checkbox {..."label"}
										name={"tripsupprot" + userId}
										onChange={(event) => {
											let tempUserTableData;
											if(this.props.searchData && this.props.searchData.length > 0) {
												tempUserTableData = this.props.searchData
											}else{
												tempUserTableData =	this.props.userData;
											}
											var i1 = 0
											tempUserTableData.forEach((row1) => {
												if (row1.id == editrow.id || row1.id == editrow[0]) {
													this.handleChangeOntripSupport(tempUserTableData[i1].id, event.target.checked);
												}
												i1++;
											})
										}} />
								}
							</>
						);
					},

				}
			},
			{
				name: 'id', options: {
					label: 'Change Password', filter: false,
					customBodyRender: (value, tableMeta, updateValue) => {
						// const editrow = tableMeta.tableData[tableMeta.rowIndex];
						var editrow = {};
						var countryCode = {};
						var rowIndexForUpdate = 0;
						var i = 0;
						tableMeta.tableData.forEach((row) => {
							if (row[0] == value) {
								editrow = row;
								rowIndexForUpdate = i;
							}
							i++;
						})

						var trip_id = 0;
						if (editrow.currentTrip?.id) trip_id = editrow.currentTrip.id;
						return (
							<Link href="#" onClick={() => {
								console.log('value',value)
								this.props.setUserData(value, 'changePassword');
								openPopup();
							}}> Change Password</Link>
						);
					},
				}
			},
			{ name: 'devices',  options: { label: 'Devices',filter: false,
		
		
		
			customBodyRender: (value, tableMeta, updateValue) => {
				
				const devices = value;
				if (Array.isArray(devices)) {
					return (
						<>
							{devices.map((device, index) => (
								<Stack direction="row" key={index} spacing={1}>
									<Chip label={device} />
								</Stack>
							))}
						</>
					);
				} else {
					return <span>No Devices</span>;
				}
			},
		
		
		} },
			/* {
				name: 'Devices',
				options: {
					customBodyRender: (value, tableMeta, updateValue) => {
						const rowData = this.state.data[tableMeta.rowIndex];
						if (!rowData || !rowData.devices) {
							return null;
						}
						const devices = rowData.devices;
						if (Array.isArray(devices)) {
							return (
								<>
									{devices.map((device, index) => (
										<Stack direction="row" key={index} spacing={1}>
											<Chip label={device} />
										</Stack>
									))}
								</>
							);
						} else {
							return <span>No Devices</span>;
						}
					},
				},
			}, */
			{
				name: 'id', options: {
					label: 'Delete Devices', filter: false,

					customBodyRender: (value, tableMeta, updateValue) => {
						const editrow = tableMeta.tableData[tableMeta.rowIndex];
						return (
							<Link href="#" onClick={() => {
								/* console.log('deletedevice',editrow[0]);
								console.log('tableMeta.rowIndex',tableMeta.rowIndex); */

								this.handleOpenModal(value, tableMeta.rowIndex)
							}}>Delete</Link>
						);
					},

				}
			},
		];

		const onRowsSelect = (curRowSelected, rowsSelected) => {
			selectedRowIndex = [];
			for (let i = 0; i < rowsSelected.length; i++) {
				selectedRowIndex.push(rowsSelected[i].dataIndex);
			}
			activeDeactiveDeleteBtn()

		};

		const handleChangePage = (event, newPage) => {
			this.setState({ page: event }, () => {
				this.getData();
			});
		};

		const handleChangeRowsPerPage = (event) => {
			this.setState({ rowsPerPage: event, page: 0 });
		};

		const handleSortChange = async (changedColumn, direction) => {
			this.setState({ loading: true }); // Establecer loading en true
			
			try {
				if(changedColumn === 'registrationDate'){

				}else{
					let isAscending = this.state.ascendingSort;
					console.log('changedColumn', changedColumn);
					console.log('direction', direction);
					let dir = direction === "asc" ? "desc" : "asc";
				  
					if (this.state.filterState && this.state.searchData.length) {
					  const searchData = this.props.searchData;
					  searchData.sort((a, b) => {
						if (a.id < b.id) {
						  return isAscending ? -1 : 1;
						}
						if (a.id > b.id) {
						  return isAscending ? 1 : -1;
						}
						return 0;
					  });
					  this.props.setUserSearchData(searchData);
					} else {
					  let searchBy = changedColumn === 'registrationDate' ? 'createdAt' : changedColumn;
				  
					  const sortedData = await new Promise((resolve, reject) => {
						this.xhrRequestSort(ApiData.baseURL + `/user/sort?ascending=${dir}&variable=${searchBy}&rows=${this.state.rowsPerPage}`)
						  .then(data => resolve(data))
						  .catch(error => reject(error));
					  });
				  
					  const formatData = this.setDataForTable(sortedData);
					  console.log('formatData', formatData);
					  this.props.setUserTableData(formatData);
					}
				  
					this.setState({ ascendingSort: !isAscending });
				}
			} catch (error) {
			  console.error(error);
			} finally {
			  this.setState({ loading: false }); // Establecer loading en false después de que se complete la solicitud
			}
		  };
		  
		  

		// const handleSortChange = async (changedColumn, direction) => {
		// 	let isAscending = this.state.ascendingSort
		// 	console.log('changedColumn', changedColumn)
		// 	console.log('direction', direction)
		// 	let dir;
		// 	if(direction === "asc"){
		// 		dir = "desc"
		// 	}else{
		// 		dir = "asc"
		// 	}
		// 	if (this.state.filterState && this.state.searchData.length) {
		// 		const searchData = this.props.searchData;
		// 		searchData.sort((a, b) => {
		// 			if (a.id < b.id) {
		// 				return isAscending ? -1 : 1;
		// 			}
		// 			if (a.id > b.id) {
		// 				return isAscending ? 1 : -1;
		// 			}
		// 			return 0;
		// 		});
		// 		this.props.setUserSearchData(searchData)
		// 	} else {
		// 		let searchBy='';
		// 		if(changedColumn === 'registrationDate'){
		// 			searchBy = 'createdAt'
		// 		}else{
		// 			searchBy = changedColumn
		// 		}
		// 		const sortedData = await this.xhrRequestSort(ApiData.baseURL + `/user/sort?ascending=${dir}&variable=${searchBy}&rows=${this.state.rowsPerPage}`).then((data) => {
		// 		const formatData = this.setDataForTable(sortedData)
		// 		console.log('formatData', formatData)
		// 		this.props.setUserTableData(formatData); 
		// 		})
		// 	}
		// 	this.setState({ ascendingSort: !isAscending });
		// };

		const handleOnFilterChange = (changedColumn, filterList) => {
			let filterBy = changedColumn;
			let filterValue = (filterList[3].join('') || filterList[4].join(''));
			this.setState({ filterBy, filterValue }, () => {
				this.getFilteredData()
			});
		};

		const handleOnFilterClose = () => {
			if (this.state.filterBy.length && !this.state.filterValue.length) {
				deleteFilterData()
			}
		}
		const deleteFilterData = () => {
			this.setState({ filterBy: '', filterValue: '' }, () => {
				if (this.state.searchTerm.length > 0) {
					this.getFilteredData()
				} else {
					this.setState({ filterData: [], filterState: false });
					this.props.setUserSearchData([])
				}
			})
		}

		const deleteSearchData = () => {
			this.setState({ searchTerm: '' }, () => {
				if (this.state.filterValue.length > 0) {
					this.getFilteredData()
				} else {
					this.setState({ filterData: [], filterState: false, searchTerm: '', loading: false });
					this.props.setUserSearchData([])
				}
			});
		}

		let searchTimer;
		const searchByUserName = (searchTerm) => {
			if (searchTerm) {
			  if (!isNaN(Number(searchTerm))) {
				clearTimeout(searchTimer); 
				searchTimer = setTimeout(() => {
				  this.setState({ searchTerm }, () => {
					this.getFilteredData();
				  });
				}, 1000); 
			  }
			  if (isNaN(Number(searchTerm)) && searchTerm.length > 3) {
				clearTimeout(searchTimer); 
				searchTimer = setTimeout(() => {
				  this.setState({ searchTerm }, () => {
					this.getFilteredData();
				  });
				}, 1000); 
			  }
			} else {
			  this.setState({ searchTerm: '' });
			  deleteSearchData();
			  selectedRowIndex = [];
			  selectedRowIds = [];
			  activeDeactiveDeleteBtn();
			}
		  };

		  
		const { data, filterData, page, rowsPerPage, count, filterState } = this.state;
		const { userData, searchData } = this.props;

		const options = {
			filter: true,
			filterType: 'dropdown',
			responsive: 'standard',
			download: true,
			print: false,
			selectableRowsOnClick: false,
			selectToolbarPlacement: 'none',
			rowsPerPage: rowsPerPage,
			page: page,
			count: count,
			pagination: filterState ? false : true,
			rowsSelected: selectedRowIndex,
			onChangePage: handleChangePage,
			onChangeRowsPerPage: handleChangeRowsPerPage,
			onSearchChange: searchByUserName,
			onFilterChange: handleOnFilterChange,
			onFilterDialogClose: handleOnFilterClose,
			onFilterChipClose: deleteFilterData,
			onSearchClose: deleteSearchData,
			onRowSelectionChange: onRowsSelect,
			onColumnSortChange: handleSortChange,
			customSort: (data, colIndex, order, meta) => {

				return data.sort((a, b) => {

					if (colIndex == 5) {

						let adate = a.data[colIndex].split('-');
						let bdate = b.data[colIndex].split('-');
						let anewdate = "";
						let bnewdate = "";
						if (adate.length == 3)
							anewdate = new Date(adate[1] + '-' + adate[0] + '-' + adate[2]).getTime();
						if (bdate.length == 3)
							bnewdate = new Date(bdate[1] + '-' + bdate[0] + '-' + bdate[2]).getTime();

						return (anewdate < bnewdate ? -1 : 1) * (order === 'desc' ? 1 : -1);
					}
					else
						return (a.data[colIndex] < b.data[colIndex] ? -1 : 1) * (order === 'desc' ? 1 : -1);
				});
			}

		};
		return (
			<div>
				{this.state.loading ? (
					<p style={{ position: 'absolute', top: '80px' }}>Loading Data...</p>
				) : (
					this.state.filterState ? <p style={{ position: 'absolute', top: '80px' }}>{this.props.searchData.length} users found</p> : null
				)}
				{/* {this.state.reload && */}
				<MUIDataTable
					title={
						<Typography variant="subtitle2">
							{this.state.loading && (
								<CircularProgress
									size={24}
									style={{ marginLeft: 15, position: 'relative', top: 4 }}
								/>
							)}
						</Typography>
					}
					data={filterState ? searchData : (this.state.loading ? this.state.loadingData : userData)}
					columns={columns}
					options={options}
					rowsSelected={onRowsSelect}
				/>
				{/* } */}
				<div>
					<Modal
						open={this.state.openDeleteModal}
						onClose={this.handleCloseModal}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<Box sx={style}>
							<Typography id="modal-modal-title" variant="h6" component="h2">
								Are you sure?
							</Typography>
							<Typography id="modal-modal-description" sx={{ mt: 2 }}>
								You won't be able to revert this!
							</Typography>
							<Button onClick={this.handleDeleteDevice}>Yes, delete it!</Button>
							<Button onClick={this.handleCloseModal}>Cancel</Button>
						</Box>
					</Modal>
				</div>
			</div>
		);
	}
}

function openPopup() {
	try {
		const modal = document.querySelector(".modal")
		const closeBtn = document.querySelector(".close")
		if (modal) {
			modal.style.display = "block";
			document.querySelector(".viewdata").style.display = "none";
		}
		if (closeBtn) {
			closeBtn.addEventListener("click", () => {
				modal.style.display = "none";
				document.querySelector(".viewdata").style.display = "inline-table";
			})
		}
	} catch (err) {
		console.log(err);
	}
}

function closePopoup() {
	document.querySelector(".modal").style.display = "none";
	document.querySelector(".viewdata").style.display = "inline-table";
}

function activeDeactiveDeleteBtn() {
	const btn = document.querySelector(".deleteBtn")
	if (selectedRowIndex.length > 0) btn.style.backgroundColor = 'blue';
	else btn.style.backgroundColor = '#e0e0e0';
}

