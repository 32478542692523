//import references
import React, { Component } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Autocomplete } from '@mui/material';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
 

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';


import Link from '@material-ui/core/Link';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import S3FileUpload from 'react-s3';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import _uniqueId from 'lodash/uniqueId';
import MUIDataTable from 'mui-datatables';
import { CircularProgress, Typography } from '@material-ui/core';
import SelectDaysPersonalized from './components/SelectDaysPersonalized';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import uploadIcon from './themes/icons/uploadicon.png';
import { ApiData } from "./config"
import DeleteIcon from './themes/icons/delete.png';
import { handleCallApi, getDatafromApi } from './calls'

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const configDaysGPX = {
  bucketName: 'expenture-img',
  dirName: 'daysgpx',
  region: 'eu-central-1',
  accessKeyId: 'AKIATWCXQUQBN73AGXAE',
  secretAccessKey: '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',
};

const configDaysSN = {
  bucketName: 'expenture-img',
  dirName: 'dayssn',
  region: 'eu-central-1',
  accessKeyId: 'AKIATWCXQUQBN73AGXAE',
  secretAccessKey: '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',
};

const countriesNames = [
  { name: 'Israel', code: 'IL', centroid: [34.8516, 32.0] },
  { name: 'Greece', code: 'GR', centroid: [21.8243, 39.0742] },
  { name: 'Montenegro', code: 'ME', centroid: [19.2912337, 42.6988483] },

  { name: 'Iceland', code: 'IS', centroid: [41.1145237, 42.2989208] },
  { name: 'Norway', code: 'NO', centroid: [41.1145237, 42.2989208] },
  { name: 'South Africa', code: 'ZA', centroid: [41.1145237, 42.2989208] },
  { name: 'Costa Rica', code: 'CR', centroid: [41.1145237, 42.2989208] },
  { name: 'India', code: 'IN', centroid: [41.1145237, 42.2989208] },

  { name: 'Georgia', code: 'GE', centroid: [41.1145237, 42.2989208] },

  { name: 'Portugal', code: 'PT', centroid: [24.9668, 45.9432] },
  { name: 'South Korea', code: 'KR', centroid: [24.9668, 45.9432] },
  { name: 'Sri Lanka', code: 'LK', centroid: [24.9668, 45.9432] },
  { name: 'Chile', code: 'CL', centroid: [24.9668, 45.9432] },
  { name: 'Argentina', code: 'AR', centroid: [24.9668, 45.9432] },
  { name: 'UAE', code: 'AE', centroid: [24.9668, 45.9432] },
  { name: 'Tanzania', code: 'TZ', centroid: [24.9668, 45.9432] },
  { name: 'Bulgaria', code: 'BG', centroid: [24.9668, 45.9432] },
  { name: 'Ecuador', code: 'EC', centroid: [24.9668, 45.9432] },
  { name: 'Italy', code: 'IT', centroid: [24.9668, 45.9432] },

  { name: 'Ireland', code: 'IE', centroid: [41.1145237, 42.2989208] },
  { name: 'Lithuania', code: 'LT', centroid: [41.1145237, 42.2989208] },
  { name: 'Turkey', code: 'TR', centroid: [41.1145237, 42.2989208] },

  { name: 'Romania', code: 'RO', centroid: [24.9668, 45.9432] },
  { name: 'Spain', code: 'ES', centroid: [24.9668, 45.9432] },
  { name: 'Bulgaria', code: 'BG', centroid: [25.4858, 42.7339] },
  { name: 'Albania', code: 'AL', centroid: [20.1683, 41.1533] },
  { name: 'Armenia', code: 'AM', centroid: [45.0382, 40.0691] },
  { name: 'United Arab Emirates', code: 'AE', centroid: [53.8478, 23.4241], },
  { name: 'Tanzania', code: 'TZ', centroid: [34.8888, -6.369] },
  { name: 'Slovakia', code: 'SK', centroid: [19.699, 48.669] },
  { name: 'Czech Republic', code: 'CZ', centroid: [15.473, 49.8175] },
  { name: 'Mexico', code: 'MX', centroid: [15.473, 49.8175] },
  { name: 'Denmark', code: 'DK', centroid: [15.473, 49.8175] },
  { name: 'Poland', code: 'PL', centroid: [15.473, 49.8175] },
  { name: 'Peru', code: 'PE', centroid: [15.473, 49.8175] },
  { name: 'Morocco', code: 'MA', centroid: [15.473, 49.8175] },
];

let pointNumbers = [];
let selectedRowIndex = false;
let selectedRowIds = [];
let selectedDayData = [];
let gpxLoc;
let daysData;

class TripsPersonalized extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      tripTableData: [['Loading Data...']],
      updateTripRowIndex: '',
      tripName: '',
      trip_photo: '',
      tripInternalName: '',
      createBy: '',
      createdById: 0,
      country: '',
      region: '',
      tripType: 'normal',
      nature_level: '',
      culture_level: '',
      adventure_level: '',
      group_structure: '',
      save_type: 'add',
      isDuplicate:false,
      openDeleteCnfrm: false,
      selectedDaysPersonalized: [{ "id": '', "name": "Loading...", "country": "" }],
      showSelectedDay: false,
      highLights: [""],
      overnight_pre_night: "",
      overnight_post_night: "", 
      days_sort: '',
      how_long: '',
      searchTerm: '', 
      tripsOptions:[]
    };
  }

  async getTrip() {
    let data = await getDatafromApi(ApiData.apiUrl + '/trips/simple-trips?personalized=true')
    if(data){
      data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      this.setState({ allTrip: data });
    }
  }

  async setDuplicateTripData(tripId)
  {
    console.log('setduplicatetrip',tripId);
    let data = await getDatafromApi(ApiData.apiUrl + '/trips/'+tripId)
    if(data){
     console.log('data',data)
     var countryCode;
     var countryname = data.country;
     countriesNames.map(function (item) {
       if (item.name === countryname)
         countryCode = item.code;
     });

      var rowHighLight=[];
      if(data.highlights)
      rowHighLight=data.highlights


     const firstName = JSON.parse(localStorage.getItem('userData')).first_name;
     const lastName = JSON.parse(localStorage.getItem('userData')).last_name;
     const createdById = JSON.parse(localStorage.getItem('userData')).id;
     const createdBy = `${firstName} ` + `${lastName}`;

     console.log('data tripppppp', data)
  
     this.setTrip(data.name, data.internal_name, createdBy, createdById, countryCode, data.region, data.nature_level, data.culture_level, data.adventure_level, data.group_structure.toUpperCase(), data.trip_type, data.days, 'add', '',rowHighLight, data.overnight_pre_night, data.overnight_post_night, data.photo, data.how_long); 
     this.setState({isDuplicate:false});
     openPopup()
    }
  }
 
  componentDidMount() {
    this.getTrip();
  
  }
  setDayTableData = async (data) => {
    this.setState({ dayTableData: data });
  };



  handleTripValidation() {
    let errors = {};
    let formIsValid = true;

    if (!this.state.tripInternalName) {
      formIsValid = false;
      errors['tripInternalName'] = '*';
    }
    /* if (!this.state.createBy) {
      formIsValid = false;
      errors['createBy'] = '*';
    } */
    if (!this.state.tripName) {
      formIsValid = false;
      errors['tripName'] = '*';
    }

    // if (!this.state.group_structure) {
    //   formIsValid = false;
    //   errors['group_structure'] = 'Please Select Group Structured';
    // }

    if (!this.state.country) {
      formIsValid = false;
      errors['country'] = 'Please Select Country';
    }
    if (!this.state.adventure_level) {
      formIsValid = false;
      errors['AL'] = 'Please Select How Long';
    }
    if (!this.state.culture_level) {
      formIsValid = false;
      errors['CL'] = 'Please Select Culture';
    }
    if (!this.state.nature_level) {
      formIsValid = false;
      errors['NL'] = 'Please Select Nature';
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSaveTrip(e) {
    e.preventDefault();
    toast.dismiss();
    if (this.handleTripValidation()) {
      let tripData1 = [...this.state.tripTableData];
      var countryCode = this.state.country;
      var countryname = '';
      countriesNames.map(function (item) {
        if (item.code === countryCode)
          countryname = item.name;
      });

      let hl = [];
      let allhighlights = this.state.highLights;
      for (let j = 0; j < allhighlights.length; j++) {
        if (allhighlights[j] != "")
          hl.push(allhighlights[j])
      }
      console.log('hl', hl);

      let daysArray = [];
      this.state.selectedDaysPersonalized.forEach((day) => { daysArray.push(day.id); })

      let apidata = {
        adventure_level: this.state.adventure_level,
        country: countryname,
        created_by: this.state.createBy,
        createdById: this.state.createById,
        culture_level: this.state.culture_level,
        group_structure: this.state.group_structure,
        name: this.state.tripName,
        nature_level: this.state.nature_level,
        photo: this.state.trip_photo,
        region: this.state.region,
        trip_length: this.state.selectedDaysPersonalized.length,
        days: daysArray,
        internal_name: this.state.tripInternalName,
        trip_type: this.state.tripType,
        how_long: this.state.adventure_level,
        highlights: hl,
        personalized: true,
        overnight_pre_night: this.state.overnight_pre_night,
        overnight_post_night: this.state.overnight_post_night,
        custom: false,
      }

      let method = 'POST';
      let url1 = ApiData.apiUrl + `/trips/`;
      if (this.state.save_type != 'add') {
        method = 'PUT';
        url1 = ApiData.apiUrl + `/trips/${tripData1[this.state.updateTripRowIndex].id}/`;
      }
      let requestOptions = {
        method: method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        body: JSON.stringify(apidata),
      };
      //medraft comment
      fetch(url1, requestOptions)
        .then((response) => response.json())
        .then((data) => {

          if (this.state.save_type === 'add') {
            tripData1.unshift(data);
            this.setState({ tripTableData: tripData1 })
            toast.success(`Trip ${data.id} created`);
          }
          else {

            tripData1[this.state.updateTripRowIndex] = data;
            this.setState({ tripTableData: tripData1 })
            toast.success('Trip Updated');
          }

          this.setState({
            updateTripRowIndex: '',
            tripName: '',
            tripInternalName: '',
            createBy: '',
            country: '',
            region: '',
            nature_level: '',
            culture_level: '',
            adventure_level: '',
            group_structure: '',
            save_type: 'add',
            highLights: [""],
            overnight_pre_night: "",
            overnight_post_night: ""
          });
          closePopoup();
        });




      //end medraft comment


    } else {
      toast.error('Missing Information / Fields');
    }
  }



  setTrip = async (tripName, tripInternalName, createBy, createdById, country, region, nature_level1, culture_level, adventure_level, group_structure, trip_type, selectedDay, save_type, updateTripRowIndex, high_Lights, overnight_pre_night, overnight_post_night, trip_photo, how_long) => { 
    this.setState({ tripName: tripName, tripInternalName: tripInternalName, createBy: createBy, createdById, country: country, region: region, nature_level: nature_level1, culture_level: culture_level, adventure_level: adventure_level, group_structure: group_structure, save_type, save_type: save_type, tripType: trip_type, updateTripRowIndex: updateTripRowIndex, selectedDaysPersonalized: selectedDay, showSelectedDay: false, highLights:high_Lights, overnight_pre_night, overnight_post_night, trip_photo, how_long });
    setTimeout(function () {
      this.setState({ showSelectedDay: true });
    }.bind(this), 500)
  };


  setTripTableData = async (data) => {
    console.log('tripTableData', data)
    this.setState({ tripTableData: data });
  };

  setSelectedDays = async (data) => {
    this.setState({ selectedDaysPersonalized: data });
  };


  handleClickOpen = () => {
    if (selectedRowIds.length > 0)
      this.setState({ openDeleteCnfrm: true });
  };



  handleDelete = () => {
    this.setState({ openDeleteCnfrm: false });
    var array = [...this.state.tripTableData]; // make a separate copy of the array
  //  var array1= [...this.state.searchData];

    selectedRowIndex.sort(function (a, b) {
      return b - a;
    });


   /*  selectedRowIndex.forEach(async (i) => {
      let delid = array[i].id;
     
      array.splice(i, 1);
      console.log('delete id',delid)
      await handleCallApi('DELETE', ApiData.apiUrl + `/trips/${delid}`, {}, '', '')
    })

    this.setTripTableData(array);
    selectedRowIndex = []; */



    selectedRowIds.forEach(async (delid) => {
  
      for(let a1=0;a1<array.length;a1++)
      {
        if(delid==array[a1].id)
           array.splice(a1, 1);
      }
      /* for(let a1=0;a1<array1.length;a1++)
      {
        if(delid==array1[a1].id)
           array1.splice(a1, 1);
      } */
     
      await handleCallApi('DELETE', ApiData.apiUrl + `/trips/${delid}`, {}, '', '')
    })
   
    this.setTripTableData(array);
    selectedRowIndex = []; 
    selectedRowIds = [];


  };

  handleDeleteold = () => {
    console.log('dvdsg', selectedRowIndex);
    this.setState({ openDeleteCnfrm: false });

    var array = [...this.state.tripTableData]; // make a separate copy of the array
    selectedRowIndex.sort(function (a, b) {
      return b - a;
    });


    selectedRowIndex.forEach((i) => {
      console.log('i', i);
      let requestOptions = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },

      };


      let delid = array[i].id;
      array.splice(i, 1);

      //medraft comment

      fetch(ApiData.apiUrl + `/trips/${delid}`, requestOptions)
        .then((response) => response)
        .then((data) => {

        });

      //end medraft comment

    })

    this.setTripTableData(array);
    selectedRowIndex = [];


  };


  handleClose = () => {
    this.setState({ openDeleteCnfrm: false });
    selectedRowIndex = [];
    selectedRowIds = [];
  };

  async handleTripChange(event) {
    this.setState({ searchTerm: event.target.value }, async () => {
      if (this.state.searchTerm === '') {
        return;
      }
      const options = [];
      this.xhrRequestFilterData().then((data) => {
        if (data) {
          data.forEach(trip => {
            let option = `${trip.internal_name}-${trip.id}`
            options.push(option);
          });
          this.setState({ tripsOptions: options });
        }
      });
    });
  }
  
  async handleTripSelect(event) {
      let match = event.match(/\d+$/);
      let id = match ? match[0] : null;
    if (event && event.length > 0) { 
      const data = await this.findTripSelected(id);      
        if(data){
          var countryCode;
          var countryname = data.country;
          countriesNames.map(function (item) {
            if (item.name === countryname)
              countryCode = item.code;
          });
     
           var rowHighLight=[];
           if(data.highlights)
           rowHighLight=data.highlights
     
     
          const firstName = JSON.parse(localStorage.getItem('userData')).first_name;
          const lastName = JSON.parse(localStorage.getItem('userData')).last_name;
          const createdById = JSON.parse(localStorage.getItem('userData')).id;
          const createdBy = `${firstName} ` + `${lastName}`;
       
          this.setTrip(data.name, data.internal_name, createdBy, createdById, countryCode, data.region, data.nature_level, data.culture_level, data.adventure_level, data.group_structure.toUpperCase(), data.trip_type, data.days, 'add', '',rowHighLight, data.overnight_pre_night, data.overnight_post_night, data.photo, data.how_long); 
          this.setState({isDuplicate:false, searchTerm: ''});
          openPopup()
        }
      
    } else {
      console.error('Invalid event or value:', event);
    }
  }

  findTripSelected = (id) => {
    return new Promise(async (resolve, reject) => {
      // let data1 = await getDatafromApi(ApiData.baseURL + `/trips/findTrips?${nameTrip ? `internal_name=${nameTrip.toLocaleUpperCase()}` : ''}&custom=false`)
      let data1 = await getDatafromApi(ApiData.apiUrl + `/trips/${id}`)
      const data = [data1]
      if (data) {
        if (data.length) {
          data.forEach((trip) => {
            const order = trip.days_sort.split(',').map(Number);
            if (order.length === trip.days.length) {
              const sortedDays = trip.days.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));
              trip.days = sortedDays;
            } else {
              const filteredDays = trip.days.filter((day) => order.includes(day.id));
              const sortedDays = filteredDays.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));
              trip.days = sortedDays;
            }
          })
          resolve(data[0]);
        } else {
          this.setState({ filterData: [], searchTerm: '' })
          this.props.setTripSearchData([])
        }
      }
    })
  }
  
  xhrRequestFilterData = () => {
    if (this.state.searchTerm.length > 0) {
      return new Promise(async (resolve, reject) => {
        let url;
        if (!isNaN(this.state.searchTerm)) { 
          url = ApiData.baseURL + `/trips/findTrips?id=${this.state.searchTerm}`;
        } else {
          url = ApiData.baseURL + `/trips/findTrips?internal_name=${this.state.searchTerm.toLocaleUpperCase()}`;
        }
        let data = await getDatafromApi(url);
  
        if (data && data.length > 0) {
          data.forEach((trip) => {
            const order = trip.days_sort.split(',').map(Number);
            if (trip.days && order.length === trip.days.length) {
              const sortedDays = trip.days.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));
              trip.days = sortedDays;
            } else {
              console.log('Trip days is undefined or order length is not equal to trip days length.');
              // Aquí puedes manejar la situación en la que trip.days es undefined
            }
          });
        
          resolve(data);
        } else {
          this.setState({ filterData: [] });
          this.props.setTripSearchData([]);
        }
        
        
      });
    }
  };
  




  render() {
    return (
      <div>
        <Dialog
          open={this.state.openDeleteCnfrm}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are You Sure You Want to delete this Trip
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button onClick={this.handleDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <div class="modal">
          <div class="modal_content">
            <span class="close">&times;</span>
            {this.state.isDuplicate &&
            <div class="model_html">
            <h2>Select Existing Trip</h2>
            <table cellPadding="8" >
              <tbody>
               
                <tr>
                  <td>
                    <table>
                      <tr>
                        <td>
                          <lable>Select Trip</lable><br />

                    <Autocomplete
                      freeSolo
                      options={this.state.tripsOptions}
                      defaultValue={''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Trip Name"
                          onChange={(event) => {
                            // Actualizar el estado searchTerm
                            this.setState({ searchTerm: event.target.value });
                            // Llamar a la función para filtrar opciones de viaje
                            this.handleTripChange(event);
                          }}
                          value={this.state.searchTerm}
                          sx={{ width: 400 }}
                        />
                      )}
                      onChange={(event, value) => this.handleTripSelect(value)}
                    />
                        </td>

                       
                      </tr>
                    </table>


                  </td>
                 
                </tr>







             
               


               

             
              </tbody>
            </table>


          </div>
            }
            {!this.state.isDuplicate &&
            <div class="model_html">
              <h2>CREATE NEW TRIP</h2>
              <table cellPadding="8" >
                <tbody>
                  <tr>
                    <td width="50%">
                      <lable>Catalog \ Internal  name</lable><br />
                      <TextField
                        id="tripInternalName"
                        size="Small"
                        style={{ width: '92%' }}
                        variant="outlined"

                        value={this.state.tripInternalName}
                        error={!!this.state.errors['tripInternalName']}
                        onChange={(event) =>
                          this.setState({ tripInternalName: event.target.value })
                        }
                      />
                    </td>
                    <td>
                      <lable>Created By</lable><br />
                      <TextField
                        id="txtSNTitle"
                        size="Small"
                        style={{ width: '50%' }}
                        variant="outlined"
                        editable={false}
                        disabled={true}
                        value={this.state.createBy}
                        error={!!this.state.errors['createBy']}

                        onChange={(event) =>
                          this.setState({ createBy: event.target.value })
                        }
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <lable>Trip name</lable><br />
                      <TextField
                        id="txtSNTitle"
                        size="Small"
                        style={{ width: '95%' }}
                        variant="outlined"

                        value={this.state.tripName}
                        error={!!this.state.errors['tripName']}
                        onChange={(event) =>
                          this.setState({ tripName: event.target.value })
                        }
                      />
                    </td>
                    <td>

                      {/*    <br />
                          <lable>Photo</lable><br />
                        
                      {this.state.trip_photo ? [
                        <div>
                         
                          <span class="lablesmall"> Currently:<span class="colorgreen"> {this.state.trip_photo}</span></span><br /><br />
                        </div>] : null}
                      <div class="divUpload">
                        <img width="20px" src={uploadIcon} />
                        <Button variant="contained" component="label" style={{ float: 'right', fontSize: 9, }} >
                          UPLOAD FILE
                          <input
                            type="file"
                            hidden
                            id="fileSNPhoto"
                            size="Small"
                            onChange={this.handlePhotoUpload}
                          />
                        </Button>
                      </div>
                      <span className="errorMsg">{this.state.errors['trip_photo']}</span>
 */}

                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Overnight pre night</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        style={{ width: '95%' }}
                        variant="outlined"

                        value={this.state.overnight_pre_night}
                        error={!!this.state.errors['overnight_pre_night']}
                        onChange={(event) =>
                          this.setState({ overnight_pre_night: event.target.value })
                        }
                      />
                    </td>
                    <td>
                      <label>Overnight post night</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        style={{ width: '95%' }}
                        variant="outlined"

                        value={this.state.overnight_post_night}
                        error={!!this.state.errors['overnight_post_night']}
                        onChange={(event) =>
                          this.setState({ overnight_post_night: event.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table>
                        <tr>
                          <td>
                            <lable>Country</lable><br />
                            <ReactFlagsSelect
                              selected={this.state.country}
                              id={'selectCountriesDay'}
                              showSelectedLabel={true}
                              showOptionLabel={true}
                              onSelect={(code) => { this.setState({ country: code }); console.log("countrycode", code) }}
                              placeholder={'Select a Country'}
                              countries={['GR', 'CR', 'NO', 'GE', 'MX', 'ME', 'DK', 'PL', 'PT', 'IS', 'MA', 'IL', 'TR', 'EC', 'ZA', 'IN', 'LT', 'IE', 'ES',]}

                            />
                            <span className="errorMsg">{this.state.errors['country']}</span>
                          </td>

                          <td style={{ verticalAlign: 'baseline', paddingLeft: '20px' }}><lable>Region</lable><br />

                            <FormControl class="dropdownWithborder">
                              <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Type"
                                value={this.state.region}
                                onChange={(event) => { this.setState({ region: event.target.value }); }}
                              >
                                <MenuItem value={"r1"}>Region 1</MenuItem>
                                <MenuItem value={"r2"}>Region 2</MenuItem>
                                <MenuItem value={"South"}>South</MenuItem>
                              </Select>
                            </FormControl>
                          </td>
                        </tr>
                      </table>


                    </td>
                    <td style={{ verticalAlign: 'baseline', paddingTop: '10px' }}>
                      <lable>Trip Type</lable><br />

                      <FormControl class="dropdownWithborder" style={{ maxWidth: 150 }}>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Type"
                          value={this.state.tripType}
                          onChange={(event) => { this.setState({ tripType: event.target.value }); }}
                        >
                          <MenuItem value={"normal"}>Dynamic</MenuItem>
                          <MenuItem value={"star"}>Relaxed</MenuItem>

                        </Select>
                      </FormControl>

                    </td>
                  </tr>



                  {/*    <tr>
                    <td colspan="2">
                      <table>
                        <tr>
                            <td>HOW LONG</td>
                          <td>NATURE LEVEL</td>
                          <td>CULTURE LEVEL</td>
                        
                        </tr>
                      </table>
                    </td>
                  </tr> */}
                  <tr>

                    <td colspan="2">
                      <table>

                        <tr>
                          <td style={{ paddingLeft: 15 }}>HOW LONG</td>
                          <td style={{ paddingLeft: 15 }}>NATURE LEVEL</td>
                          <td style={{ paddingLeft: 15 }}>CULTURE LEVEL</td>

                        </tr>
                        <tr>
                          <td >
                            {this.state.tripType == 'normal' &&
                              <label className="boxed">
                                <input
                                  type="radio"
                                  id="oneAL"
                                  value="1"
                                  name="AL"
                                  onChange={(event) => this.setState({ adventure_level: event.target.value })}
                                  checked={this.state.adventure_level === 1 || this.state.adventure_level === '1'}
                                />
                                <span>S</span>
                              </label>
                            }
                            <label className="boxed">
                              <input
                                type="radio"
                                id="twoAL"
                                value="2"
                                name="AL"
                                onChange={(event) => this.setState({ adventure_level: event.target.value })}
                                checked={this.state.adventure_level === 2 || this.state.adventure_level === '2'}
                              />
                              <span>M</span>
                            </label>
                            <label className="boxed">
                              <input
                                type="radio"
                                id="threeAL"
                                value="3"
                                name="AL"
                                onChange={(event) => this.setState({ adventure_level: event.target.value })}
                                checked={this.state.adventure_level === 3 || this.state.adventure_level === '3'}
                              />
                              <span>L</span>
                            </label>
                            <br />
                            <span className="errorMsg">{this.state.errors['AL']}</span>
                          </td>

                          <td>
                            <label className="boxed">
                              <input
                                type="radio"
                                id="oneNL"
                                value="1"
                                name="NL"
                                onChange={(event) => this.setState({ nature_level: event.target.value })}
                                checked={this.state.nature_level === 1 || this.state.nature_level === '1'}
                              />
                              <span>1</span>
                            </label>
                            <label className="boxed">
                              <input
                                type="radio"
                                id="twoNL"
                                value="2"
                                name="NL"
                                onChange={(event) => this.setState({ nature_level: event.target.value })}
                                checked={this.state.nature_level === 2 || this.state.nature_level === '2'}
                              />
                              <span>2</span>
                            </label>
                            {/*   <label className="boxed">
                              <input
                                type="radio"
                                id="threeNL"
                                value="3"
                                name="NL"
                                onChange={(event) => this.setState({ nature_level: event.target.value })}
                                checked={this.state.nature_level === 3 || this.state.nature_level === '3'}
                              />
                              <span>3</span>
                            </label> */}
                            <br />
                            <span className="errorMsg">{this.state.errors['NL']}</span>
                          </td>
                          <td >
                            <label className="boxed">
                              <input
                                type="radio"
                                id="oneCL"
                                value="1"
                                name="CL"
                                onChange={(event) => this.setState({ culture_level: event.target.value })}
                                checked={this.state.culture_level === 1 || this.state.culture_level === '1'}
                              />
                              <span>1</span>
                            </label>
                            <label className="boxed">
                              <input
                                type="radio"
                                id="twoCL"
                                value="2"
                                name="CL"
                                onChange={(event) => this.setState({ culture_level: event.target.value })}
                                checked={this.state.culture_level === 2 || this.state.culture_level === '2'}
                              />
                              <span>2</span>
                            </label>
                            {/*   <label className="boxed">
                              <input
                                type="radio"
                                id="threeCL"
                                value="3"
                                name="CL"
                                onChange={(event) => this.setState({ culture_level: event.target.value })}
                                checked={this.state.culture_level === 3 || this.state.culture_level === '3'}
                              />
                              <span>3</span>
                            </label> */}
                            <br />
                            <span className="errorMsg">{this.state.errors['CL']}</span>
                          </td>



                        </tr>
                      </table>
                    </td>


                  </tr>





                  <tr>
                    <td >



                      <FormControl style={{ width: '30%' }}>
                        <InputLabel id="demo-simple-select-label">Group Structured</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Type"
                          value={this.state.group_structure}
                          onChange={(event) => { this.setState({ group_structure: event.target.value }); console.log("group_structure", event.target.value) }}
                        >

                          <MenuItem value={"WITH KIDS"}>With Kids</MenuItem>
                          <MenuItem value={"WITHOUT KIDS"}>Without Kids</MenuItem>
                        </Select>
                      </FormControl>

                      <br />
                      <span className="errorMsg">{this.state.errors['group_structure']}</span>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colSpan={2}>



                      <table>
                        <tr>
                          <td colSpan={3}>
                            <br />

                            <lable style={{ fontSize: 20 }}>Highlights </lable><br /><br />


                          </td>
                        </tr>

                        {this.state.highLights.map((item, i) =>
                          <tr>
                            <td>
                              <TextField
                                id="txtSNTitle"
                                style={{ width: '95%' }}
                                variant="outlined"
                                value={item}

                                onChange={(event) => {
                                  var a = [...this.state.highLights];
                                  a[i] = event.target.value;
                                  this.setState({ highLights: a });
                                }
                                }
                              />

                            </td>

                            {i != 0 &&
                              <td>

                                <img style={{ cursor: 'pointer' }} onClick={() => {
                                  var a = [...this.state.highLights];
                                  a.splice(i, 1);
                                  this.setState({ highLights: a });
                                }} width="20px" src={DeleteIcon} />
                              </td>
                            }
                          </tr>
                        )}



                        <tr>
                          <td colSpan="3">
                            <span className="errorMsg">{this.state.errors['ticket_types']}</span>
                            <br />
                            <Link onClick={() => {
                              var a = [...this.state.highLights];
                              a.push("");
                              this.setState({ highLights: a });
                            }}> + ADD TICKET TYPE </Link>
                          </td>
                        </tr>
                      </table>

                      <br />

                    </td>

                  </tr>


                  <tr>
                    <td><br /><br /><br /><br />
                      <h5>Select days to trip:</h5>
                    </td>
                  </tr>


                  <tr >
                    <td colspan="2" style={{ borderColor: '#000', border: 'solid 1px #ccc' }}>
                      {this.state.showSelectedDay &&
                        <SelectDaysPersonalized selectedDaysPersonalized={this.state.selectedDaysPersonalized} setSelectedDays={this.setSelectedDays} />
                      }
                    </td>
                  </tr>


                  <tr>
                    <td colSpan="4">
                      {this.state.save_type == 'add' &&

                        <Button
                          id="btnSaveSN"
                          variant="contained"
                          color="primary"
                          onClick={this.handleSaveTrip.bind(this)}
                        /*  disabled={
                           this.state.tripInternalName=="" ?  true
                         : this.state.tripName=="" ? true
                         : this.state.tri=="" ? true
                         :false
                         } */
                        >
                          CREATE
                        </Button>
                      }



                      {this.state.save_type != 'add' &&
                        <Button
                          id="btnSaveSN"
                          variant="contained"
                          color="primary"
                          onClick={this.handleSaveTrip.bind(this)}
                        >
                          SAVE CHANGES
                        </Button>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>


            </div>
  }
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
        <ToastContainer />
        <table cellPadding="10" class="viewdata">
          <tbody>

            <tr>
              <td>
                <h2>Trips Personalized Module</h2>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan="3"  ></td>
              <td>&nbsp;
                <Button
                  id="btnSave"
                  variant="contained"
                  className='deleteBtn'
                  color="#15cc09"
                  onClick={() => { this.handleClickOpen(); console.log('dvdsg', selectedRowIndex) }}
                  style={{ float: 'right', fontSize: 12, color: '#fff' }}
                >
                  DELETE SELECTED
                </Button>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <TripTable tripData={this.state.tripTableData} setTripTableData={this.setTripTableData} setTrip={this.setTrip} />
              </td>
            </tr>
            <tr>
              <td width={170}>
                <Link href="#" onClick={() => { this.setTrip('', '', '', 0, '', '', '', '', '', '', '', '', 'add', '',[""], '', '', '', ''); console.log('tripName', this.state.tripName);
                this.setState({isDuplicate:false});
                openPopup() }}> + ADD NEW TRIP  </Link>



              </td>
              <td colSpan="1">
                <Link href="#" onClick={() => { this.setTrip('', '', '', 0, '', '', '', '', '', '', '', '', 'add', '',[""], '', '', '', ''); this.setState({isDuplicate:true}); 
                openPopup1() 
               
                }} style={{textTransform:"uppercase"}}>+ Duplicate from existing trip </Link>



              </td>
              <td colSpan="10">&nbsp;</td>
            </tr>
            <tr>
              <td colSpan="4">
                <div className="borderDiv" />
              </td>
            </tr>

          </tbody>
        </table>

      </div>


    );
  }
}

export default TripsPersonalized;

/* openPopup= () =>
{
  const modal = document.querySelector(".modal")
          const closeBtn = document.querySelector(".close")
          modal.style.display = "block";
  closeBtn.addEventListener("click", () => {
            modal.style.display = "none";
  })
}
          */
//Mapbox Key
mapboxgl.accessToken =
  'pk.eyJ1IjoidXJpYWJyaWRnaWZ5IiwiYSI6ImNrb3IybjRtYjEwd2wydnBpdGxvNWVoYzIifQ.jUaSkJhni2GW103YouzXOQ';
let mapMarkersOne = [];
let mapMarkersTwo = [];
let webmapMarkers = [];
let vgmapMarkers = [];

let webMapOne;
let webMapTwo;
let webMap;
let vgMap;

const initializeMap = (z, id) => {
  if (id == 'mapDayVG') {
    vgMap = new mapboxgl.Map({
      container: id,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: z,
    });
    //Add navigation controls to the mapbox if zoom is lower
    if (z < 11) vgMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    vgMap.on('click', function (e) {
      if (selectedRowIndex) {
        addVgMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      } else {
        toast.error('Please select a trip first by clicking VG button against the record');
      }
    });
  } else if (id == 'mapDayStop') {
    webMap = new mapboxgl.Map({
      container: id,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: z,
    });
    //Add navigation controls to the mapbox if zoom is lower
    if (z < 11) webMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    webMap.on('click', function (e) {
      if (selectedRowIndex) {
        addWebMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      } else {
        addWebMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
        // toast.error('11Please select a trip first by clicking Web button against the record');
      }
    });
  } else {
    if (id == 'mapDay-1') {
      webMapOne = new mapboxgl.Map({
        container: id,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: z,
      });
      webMapOne.on('click', function (e) {
        $('#txtStartLocDay').val(e.lngLat.lng.toFixed(6) + ',' + e.lngLat.lat.toFixed(6));
        // this.setState({startloc: e.lngLat.lng.toFixed(4) + ',' + e.lngLat.lat.toFixed(4) });
        mapMarkersOne.forEach((marker) => marker.remove());
        mapMarkersOne = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapOne);
        mapMarkersOne.push(marker);
      });
    } else {
      webMapTwo = new mapboxgl.Map({
        container: id,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: z,
      });

      webMapTwo.on('click', function (e) {
        $('#txtEndLocDay').val(e.lngLat.lng.toFixed(6) + ',' + e.lngLat.lat.toFixed(6));
        mapMarkersTwo.forEach((marker) => marker.remove());
        mapMarkersTwo = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapTwo);
        mapMarkersTwo.push(marker);
      });
    }
  }
};

class MapboxGLMap extends Component {
  constructor(props) {
    super(props);
    // this.id = _uniqueId(props.prefix + "-");
    this.id = props.prefix;
  }
  componentDidMount() {
    
    initializeMap(-1, this.id);
  }
  render() {
    const id = this.id;
    return (
      <div id={id} className="mapEA">
        {' '}
      </div>
    );
  }
}

class TripTable extends Component {



  constructor(props) {
    super(props);
    console.log('props', props)
    this.state = {
      data: [['Loading Data...']],
      loading: false,
    };
  }

  async componentDidMount() {
    let data = await this.getData();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has('id')) {
      let id = urlParams.getAll('id');
      this.setTripData(id, data)
    }
  }

  setTripData = (id, data) => {
    let filterId = parseInt(id[0])
    let filterRow = data.filter(row => row.id === filterId)[0]
    let getIndexRow = data.findIndex(row => row.id === filterId)

    var countryCode;
    var countryname = filterRow.country;
    countriesNames.map(function (item) {
      if (item.name === countryname)
        countryCode = item.code;
    });
    this.props.setTrip(filterRow.name, filterRow.internal_name, filterRow.created_by, filterRow.created_by_id, countryCode, filterRow.region, filterRow.nature_level, filterRow.culture_level, filterRow.adventure_level, filterRow.group_structure.toUpperCase(), filterRow.trip_type, filterRow.days, 'update', getIndexRow, [""], filterRow.overnight_pre_night, filterRow.overnight_post_night, filterRow.photo, filterRow.how_long);
    openPopup()
  }

  getData = () => {
    this.setState({ loading: true });
    let dataRows = this.xhrRequest().then((data) => {
      this.setState({ data, loading: false });
      this.props.setTripTableData(data);
      return data
    });
    return dataRows
  };


  xhrRequest = (url) => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
      };
      //medraft comment
      fetch(ApiData.apiUrl + '/trips?take=3000&personalized=true&custom=false', requestOptions)
        .then((response) => response.json())
        .then((data) => {
          data.results.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          daysData = data.results;
          console.log('daysData', daysData)
          for(let t1=0;t1<daysData.length;t1++)
          {
            let daysids="";
            let daysOvernight="";
            if(daysData[t1].days.length>0)
            {
             for(let d1=0;d1<daysData[t1].days.length;d1++)
             {
              if(d1!=0)daysids+=";";
              daysids+=daysData[t1].days[d1].id;

              if(daysData[t1].days[d1].overnight && daysData[t1].days[d1].overnight!=null && daysData[t1].days[d1].overnight!=""){
                if(d1!=0) daysOvernight+="; ";
              daysOvernight+=daysData[t1].days[d1].id + " => " + daysData[t1].days[d1].overnight;
              }
            }
             daysData[t1].allDayIDs=daysids
             daysData[t1].allDayOvernight=daysOvernight
            }
          }
          resolve(data.results);
          console.log('aaa')
        });
      //end medraft comment
    });
  };

  render() {
    const columns = [
      {
        name: 'id', options: {
          label: 'ID', filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            //var editrow = tableMeta.tableData[tableMeta.rowIndex];
            var editrow = {};
            var rowIndexForUpdate = 0;
            var i = 0;
            tableMeta.tableData.forEach((row) => {
              if (row.id == value) {
                editrow = row;
                rowIndexForUpdate = i;
              }
              i++;
            })


            var countryCode;
            var countryname = editrow.country;
            countriesNames.map(function (item) {
              if (item.name === countryname)
                countryCode = item.code;
            });

var rowHighLight=[];
if(editrow.highlights)
rowHighLight=editrow.highlights

//console.log('edithighlights',rowHighLight)
            return (
              // tripName, tripInternalName, createBy, country, region, naturalLeval, culture_level, adventure_level
              <Link href="#" onClick={() => { console.log('value', tableMeta); this.props.setTrip(editrow.name, editrow.internal_name, editrow.created_by, editrow.created_by_id, countryCode, editrow.region, editrow.nature_level, editrow.culture_level, editrow.adventure_level, editrow.group_structure.toUpperCase(), editrow.trip_type, editrow.days, 'update', rowIndexForUpdate,rowHighLight, editrow.overnight_pre_night, editrow.overnight_post_night, editrow.photo, editrow.how_long); console.log('dvdsg tripName', editrow); openPopup(editrow.id) }}>  {value}</Link>
            );
          },
        }
      },
      { name: 'name', label: 'Trip Name', options: { filter: false } },
      { name: 'internal_name', label: 'Catalog Name', options: { filter: false } },
      { name: 'country', label: 'Country' },
      { name: 'region', label: 'region' },
      { name: 'overnight_pre_night', label: 'Overnight pre night', options: { filter: false } },
      { name: 'overnight_post_night', label: 'Overnight post night', options: { filter: false } },
     
     /*  {
        name: 'id', options: {
          label: 'DayIds', filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
           
            var editrow = {};
            var dayIDS="";
            tableMeta.tableData.forEach((row) => { if (row.id == value)  editrow = row;  } )
          
           if(editrow?.days)
            editrow.days.forEach((row) => { if(dayIDS!="")dayIDS +=",";  dayIDS +=row.id; })
            
            return ( dayIDS );
          },
        }
      },
      {
        name: 'id', options: {
          label: 'Overnight', filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
           
            var editrow = {};
            var overnights="";
            tableMeta.tableData.forEach((row) => { if (row.id == value)  editrow = row;  } )
          
           if(editrow?.days)
            editrow.days.forEach((row) => { 
            if(row?.overnight && row.overnight!=null && row.overnight!= "")
              {if(overnights!="") overnights +="; ";
              overnights +=row.id+" => "+row.overnight; }
              })
            
            return ( overnights );
          },
        }
      }, */
     
     /*  { name: 'allDayIDs', label: 'DayIds' },
      { name: 'allDayOvernight', label: 'Overnight' }, */
    ];

    


    const onRowsSelect = (curRowSelected, rowsSelected) => {
      selectedRowIndex = [];
      let d1 = this.props.tripData;
      for (let j = 0; j < d1.length; j++) {
       selectedRowIds.splice(selectedRowIds.indexOf(d1[j].id), 1 )
      }




      for (let i = 0; i < rowsSelected.length; i++) {
        selectedRowIndex.push(rowsSelected[i].index);
        selectedRowIds.push(d1[rowsSelected[i].index].id);
        // console.log('aaaa',d1[rowsSelected[i].index].id)
      }


      activeDeactiveDeleteBtn();
     // console.log('selectedRowIds', selectedRowIds)
    };

    const onRowsSelectOld = (curRowSelected, rowsSelected) => {
      selectedRowIndex = [];
      for (let i = 0; i < rowsSelected.length; i++) {
        selectedRowIndex.push(rowsSelected[i].index);
        console.log('rowsSelected[i].index',rowsSelected[i].index)
      }
      activeDeactiveDeleteBtn();
    };





    const { data } = this.state;

    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      download: true,
      print: true,
      selectableRowsOnClick: false,
      selectToolbarPlacement: 'none',
      rowsSelected: selectedRowIndex,
      onRowSelectionChange: onRowsSelect,
    };
    return (
      <div>
        <MUIDataTable
          title={
            <Typography variant="subtitle2">
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: 'relative', top: 4 }}
                />
              )}
            </Typography>
          }
          data={this.props.tripData}
          columns={columns}
          options={options}
          rowsSelected={onRowsSelect}
        />
      </div>
    );
  }
}


function addWebMarker(coordinates) {

  const id = _uniqueId('webmarker-');

  let marker;
  var el = document.createElement('div');
  el.className = 'marker';
  el.id = id;
  el.addEventListener(
    'click',
    (e) => {
      e.stopPropagation();
      let target_id = e.target.id;
      // let queriedMarkerObj = webmapMarkers.find((marker) => marker.id === query);
      // queriedMarkerObj.marker.remove();
      for (let i = 0; i < webmapMarkers.length; i++) {
        if (webmapMarkers[i].id === target_id) {
          document.getElementById(webmapMarkers[i].id).remove();
          webmapMarkers.splice(i, 1);
        }
      }
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(webMap);
  marker.properties = { id: id };
  webmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}

function addVgMarker(coordinates, type = false) {
  const id = _uniqueId('vgmarker-');
  let marker;
  var el = document.createElement('div');
  el.className = 'marker';
  el.id = id;
  if (type) {
    el.style.backgroundImage = `url('https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/${getSvg(
      type
    )}.svg')`;
  }
  el.addEventListener(
    'click',
    (e) => {
      e.stopPropagation();
      let query = e.target.id;

      let queriedMarkerObj = vgmapMarkers.find((marker) => marker.id === query);
      queriedMarkerObj.marker.remove();
      pointNumbers.splice(
        pointNumbers.findIndex((v) => v.id === query),
        1
      );
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(vgMap);
  vgmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}



function getSvg(type) {
  type = parseInt(type);
  switch (type) {
    case 1: {
      return 'straight';
    }
    case 2: {
      return 'right';
    }
    case 3: {
      return 'left';
    }
    case 4: {
      return 'fork-left';
    }
    case 5: {
      return 'fork-right';
    }
    case 6: {
      return 'poi';
    }
    case 7: {
      return 'info';
    }
    case 8: {
      return 'swimming';
    }
    case 9: {
      return 'rafting';
    }
    case 10: {
      return 'hotel';
    }
    default: {
      return 'poi';
    }
  }
}


function openPopup1() {
  const modal = document.querySelector(".modal")
  const closeBtn = document.querySelector(".close")
  modal.style.display = "block";
  document.querySelector(".viewdata").style.display = "none";
  closeBtn.addEventListener("click", () => {
    window.history.pushState(null, null, `?page=PersonalizedTrips`);
    modal.style.display = "none";
    document.querySelector(".viewdata").style.display = "inline-table";
  })
}

function openPopup(id = '') {
  if (id !== '') {
    window.history.pushState(null, null, `?page=PersonalizedTrips&id=${id}`);
  }
  const modal = document.querySelector(".modal")
  const closeBtn = document.querySelector(".close")
  modal.style.display = "block";
  document.querySelector(".viewdata").style.display = "none";
  closeBtn.addEventListener("click", () => {
    window.history.pushState(null, null, `?page=PersonalizedTrips`);
    modal.style.display = "none";
    document.querySelector(".viewdata").style.display = "inline-table";
  })


}
function closePopoup() {
  document.querySelector(".modal").style.display = "none";
  document.querySelector(".viewdata").style.display = "inline-table";
}

function activeDeactiveDeleteBtn() {
  const btn = document.querySelector(".deleteBtn")
  if (selectedRowIndex.length > 0) btn.style.backgroundColor = 'blue';
  else btn.style.backgroundColor = '#e0e0e0';
}
