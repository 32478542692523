//import references
import React, { Component, useState } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import Link from '@material-ui/core/Link';
import MUIDataTable from 'mui-datatables';
import { CircularProgress, Typography } from '@material-ui/core';

import DataGrid, {
  Column,
  Selection,
  RowDragging,
  Scrolling,
  Paging,
  Sorting,

} from 'devextreme-react/data-grid';
import { ApiData } from "../config"

let daysData;
let selectedRowIndex = false;


class WebPointPersonalized extends Component {



  constructor(props) {
    super(props);
    console.log('props', props)

    this.onReorder = this.onReorder.bind(this);

    this.state = {
      data: [['Loading Data...']],
      loading: false,
      dragWebPOIsKeys: [],
      selectedWebPOIsKeys: [{ "id": '', "name": "Loading...", "country": "" },],
      defaultSelectedRow: [0, 1, 3, 4]
    };
  }


  onReorder(e) {
    const visibleRows = e.component.getVisibleRows();
    const newWebPOI = [...this.props.selectedWebPOIsKeys];
    const toIndex = newWebPOI.indexOf(visibleRows[e.toIndex].data);
    const fromIndex = newWebPOI.indexOf(e.itemData);

    newWebPOI.splice(fromIndex, 1);
    newWebPOI.splice(toIndex, 0, e.itemData);
    /*  this.setState({
       selectedWebPOIsKeys: newWebPOI,
     }); */
    this.props.setSelectedWebPoi(newWebPOI);
   

    const selectedRowKeys = newWebPOI.map(( item ) => item.id);
    this.props.setglobalPoiSet(selectedRowKeys)
    console.log(selectedRowKeys);
  }


  onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    // this.selectionChangedBySelectBox = false;
    console.log(selectedRowKeys);
    this.setState({
      dragWebPOIsKeys: selectedRowKeys,
    });
  };
  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({ loading: true });
    this.xhrRequest().then((data) => {
      this.setState({ data, loading: false });
    });
  };
  xhrRequest = (url) => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json',
        'Authorization' :'Bearer '+ localStorage.getItem('token')
      },
      };



//medraft comment

      fetch(ApiData.apiUrl+'/global-poi/', requestOptions)
        .then((response) => response.json())
        .then((data) => {
          data.results.sort((a,b) => a.id - b.id);
          console.log('this.props.globalPoiSet',this.props.globalPoiSet)
          for (let i = 0; i < data.results.length; i++) {
           
            if (this.props.globalPoiSet.indexOf(data.results[i].id) > -1) {
              data.results[i].selected1 = 'true';
            }
         

          }


          console.log('testetst1',this.props.selectedWebPOIsKeys)


          daysData = data.results;

          resolve(data.results);
          this.setselectedRecord();
          
         
        
         let webpoikey=[];
         for(let i=0;i<this.props.globalPoiSet.length;i++)
         {
           for(let j=0;j<this.props.selectedWebPOIsKeys.length;j++)
           {
              if(this.props.globalPoiSet[i]==this.props.selectedWebPOIsKeys[j].id)
              webpoikey.push(this.props.selectedWebPOIsKeys[j])
           }
         }
         this.props.setSelectedWebPoi(webpoikey);
          console.log('aaa')
        });



        
     //end medraft comment


















    });
  };


  setselectedRecord() {
    let selectedWebPOIsKeys1 = [...this.props.selectedWebPOIsKeys];


    let defaultSelectedRow1 = []
    for (let i = 0; i < daysData.length; i++) {
      if (daysData[i].selected1) {
        let flg = 1
        for (let j = 0; j < this.props.selectedWebPOIsKeys.length; j++) {
          if (this.props.selectedWebPOIsKeys[j].id == daysData[i].id) {
            console.log("selectedWebPOIsKeys11", this.props.selectedWebPOIsKeys[j].id);
            flg = 0
          }
        }
        defaultSelectedRow1.push(i);


        if (flg == 1) {
          //this.props.selectedWebPOIsKeys.push(daysData[i])
          selectedWebPOIsKeys1.push(daysData[i])
        }
      }

    }

    let selectedWebPOIsKeysNew = []

    for (let i = 0; i < selectedWebPOIsKeys1.length; i++) {
      for (let j = 0; j < daysData.length; j++) {

        if ((daysData[j].id == selectedWebPOIsKeys1[i].id) && daysData[j].selected1) {
          console.log("selectedWebPOIsKeysNew111")

          selectedWebPOIsKeysNew.push(daysData[j])

        }
      }


    }

    console.log("selectedWebPOIsKeysNew", selectedWebPOIsKeysNew)
    // this.setState({ selectedWebPOIsKeys: selectedWebPOIsKeys1 });
    // this.setState({ selectedWebPOIsKeys: selectedWebPOIsKeysNew });
    this.props.setSelectedWebPoi(selectedWebPOIsKeysNew);
   
    this.setState({ defaultSelectedRow: defaultSelectedRow1 });
  }

  render() {
    const columns = [
      { name: 'id', options: { label: 'ID', filter: false, } },
      { name: 'name', label: 'Name', options: { filter: false } },
      { name: 'country', label: 'Country' },

    ];

    const onRowsSelect = (curRowSelected, rowsSelected, rowMeta) => {
      /*  console.log('curRowSelected', curRowSelected[0].index)
       console.log('rowMeta', rowMeta)
       console.log('rowsSelected', rowsSelected) */
      /*  if (rowsSelected.length > 0) {
         selectedRowIndex = daysData[rowsSelected[0].dataIndex].id;
       } else {
         selectedRowIndex = false;
       } */
       let globalPoiSet1 = [...this.props.globalPoiSet];

      if (daysData[curRowSelected[0].dataIndex].selected1) {
        daysData[curRowSelected[0].dataIndex].selected1 = false;
        let a= globalPoiSet1.filter(function(item){ return item != daysData[curRowSelected[0].dataIndex].id;
      });
        this.props.setglobalPoiSet(a);
      }
      else {
        daysData[curRowSelected[0].dataIndex].selected1 = true;
        globalPoiSet1.push(daysData[curRowSelected[0].dataIndex].id);
        this.props.setglobalPoiSet(globalPoiSet1);
      }

      this.setselectedRecord();

      //new medraft
 this.props.setSelectedVgByWeb(daysData[curRowSelected[0].dataIndex])
 // end new medraft
      //this.onReorder();

    };
    const { data } = this.state;

    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      download: false,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: 'none',
      rowsSelected: this.state.defaultSelectedRow,
      onRowSelectionChange: onRowsSelect,
    };

    return (
      <table>

        <tr>
          <td style={{ width: '40%' }}>
            <h4> Select from country POIs: </h4>
          </td>
          <td style={{ width: '5%' }}>

          </td>
          <td style={{ width: '55%' }}>
            <h4> order Selected POIs: </h4>
          </td>
        </tr>

        <tr>
          <td style={{ width: '40%' }}>

            <div>
              <MUIDataTable
                title={
                  <Typography variant="subtitle2">
                    {this.state.loading && (
                      <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: 'relative', top: 4 }}
                      />
                    )}
                  </Typography>
                }
                data={data}
                columns={columns}
                options={options}
                rowsSelected={onRowsSelect}
                rowMeta={[0, 1]}
              />
            </div>
          </td>
          <td style={{ width: '10%' }}>

          </td>
          <td style={{ width: '45%', WebkitPaddingEnd: 100,verticalAlign:'baseline' }} class="mytable">

            <DataGrid
              // height={440}
              dataSource={this.props.selectedWebPOIsKeys}
              keyExpr="id"
              showBorders={true}
              ref={(ref) => (this.dataGrid = ref)}
              selectableRows={false}
              expandableRowsHeader={false}
              onSelectionChanged={this.onSelectionChanged}
              selectedRowKeys={this.state.dragWebPOIsKeys}
            >
              <RowDragging
                allowReordering={true}
                onReorder={this.onReorder}
                showDragIcons={true}
              />

              <Scrolling mode="virtual" />
              <Sorting mode="none" />

              <Column dataField="id" width={60} />
              <Column dataField="name" />
              <Column dataField="country" />

              <Paging defaultPageSize={10} />
            </DataGrid>

          </td>
        </tr>
      </table >
    );
  }
}
export default WebPointPersonalized;