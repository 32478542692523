//import references
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handlePhotoUpload } from "./uploadFiles"
import 'mapbox-gl/dist/mapbox-gl.css';
import _uniqueId from 'lodash/uniqueId';
import uploadIcon from './themes/icons/uploadicon.png';
import { ApiData, MapboxKey } from "./config"
import imageCompression from 'browser-image-compression';


class UploadAssets extends Component {

  
  constructor(props) {
    super(props);
    this.state = {
      photourl: '',
      stop_photo: '',
    };
    window.bd = this;

  }

  handlePhoto = async (event) => {
    try {
      const url = await handlePhotoUpload(event, 'planmytrip-assets', 'assets/resources/countries');
      if (url) {
        this.setState({ gpx_photo: url });
      }
    } catch (error) {
      console.log('Error uploading photo:', error);
    }
  };


  render() {
    return (
      <div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
        <ToastContainer />

        <table className="viewdata" cellPadding="10">
          <tbody>

            <tr>
              <td>
                <h2>Upload Assets</h2>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
           
            <tr>
                    
                    <td>
                      Photo<br />
                      {this.state.stop_photo ? [
                        <div>

                         
                          <span className="labelsmall"> Currently:<span className="colorgreen"> {this.state.stop_photo}</span></span><br /><br />
                        </div>] : null}
                      <div className="divUpload">

                        <img width="20px" src={uploadIcon} />
                        <Button variant="contained" component="label" style={{ float: 'right', fontSize: 9, }} >
                          UPLOAD FILE
                      
                          <input
                            type="file"
                            hidden
                            id="fileSNPhoto"
                            size="small"
                            value=""
                            onChange={this.handlePhoto}
                          />
                        </Button>

                      </div>
                      
                    </td>
                  </tr>
           
            <tr>
              <td colSpan="4">
                <div className="borderDiv" />
              </td>
            </tr>

          </tbody>
        </table>

      </div>


    );
  }
}

export default UploadAssets;


