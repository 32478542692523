//import references
import React, { Component, useState } from 'react';

import 'react-toastify/dist/ReactToastify.css';
import Link from '@material-ui/core/Link';
import MUIDataTable from 'mui-datatables';
import { CircularProgress, Typography } from '@material-ui/core';
import { handleCallApi, getDatafromApi } from '../calls'
import DataGrid, {
  Column,
  Selection,
  RowDragging,
  Scrolling,
  Paging,
  Sorting,

} from 'devextreme-react/data-grid';
import { ApiData } from "../config"
import { off } from 'devextreme/events';

let daysData;
let selectedRowIndex = false;


class WebPoint extends Component {



  constructor(props) {
    super(props);
    console.log('props', props)

    this.onReorder = this.onReorder.bind(this);

    this.state = {
      data: [['Loading Data...']],
      loading: false,
      dragWebPOIsKeys: [],
      selectedWebPOIsKeys: [{ "id": '', "name": "Loading...", "country": "" },],
      defaultSelectedRow: [],
    };
  }


  onReorder(e) {
    const visibleRows = e.component.getVisibleRows();
    const newWebPOI = [...this.props.selectedWebPOIsKeys];
    const toIndex = newWebPOI.indexOf(visibleRows[e.toIndex].data);
    const fromIndex = newWebPOI.indexOf(e.itemData);

    newWebPOI.splice(fromIndex, 1);
    newWebPOI.splice(toIndex, 0, e.itemData);
     this.setState({
       selectedWebPOIsKeys: newWebPOI,
     });
    this.props.setSelectedWebPoi(newWebPOI);
   
    this.props.setSelectedWebPOIsKeys(newWebPOI)
    const selectedRowKeys = newWebPOI.map(( item ) => item.id);
    this.props.setglobalPoiSet(selectedRowKeys)
  }


  onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    // this.selectionChangedBySelectBox = false;
    this.setState({
      dragWebPOIsKeys: selectedRowKeys,
    });
  };

  async componentDidMount() {
   await this.getData()
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.globalPoiSet && this.props.openModalDay && this.props.saveType !== 'add'){
      if (this.state.data !== prevState.data || this.props.globalPoiSet !== prevProps.globalPoiSet) {
        if(this.state.data){
          let poiDefault = []
          let poiArr = this.props.globalPoiSet.map(Number);
          let index = []
          this.state.data.map((poi, i) => {
              poiArr.forEach(el => {
                if (el === poi.id) {
                  poiDefault.push(poi)
                  index.push(i)
                }
              
            });
          }); 
          let sortedData = poiDefault.sort((a, b) => poiArr.indexOf(a.id) - poiArr.indexOf(b.id));
          this.props.setSelectedWebPOIsKeys(sortedData)
          this.setState({defaultSelectedRow: index})
        }
      }
    }
    if(this.props.openModalDay !== prevProps.openModalDay){
      if(!this.props.openModalDay){
        let unSelectPois = this.state.data.map(poi => {
          if (poi.selected1 === true) {
            poi.selected1 = false;
          }
          return poi;
        });
        this.setState({data: unSelectPois, defaultSelectedRow: []})
      }
    }

  }

  getData = () => {
    this.setState({ loading: true });
    this.xhrRequest().then((data) => {
      this.setState({ data, loading: false });

    });
  };
  xhrRequest = (url) => {
    return new Promise(async (resolve, reject) => {
      let data1 = await getDatafromApi(ApiData.apiUrl+'/global-poi/')
      if(data1){

let data = data1.results.filter(function (item) { return (item.personalized == false); });

        if(this.props.globalPoiSet){
          let poiArr = this.props.globalPoiSet.map(Number);
          let poiDefault = []
          let poisWithSelected = data.map(poi => {
            if (poiArr.includes(poi.id)) {
              poiDefault.push(poi)
              return { ...poi, selected1: true };
            }
            return poi;
          });
          let sortedData = poiDefault.sort((a, b) => poiArr.indexOf(a.id) - poiArr.indexOf(b.id));
          this.props.setSelectedWebPOIsKeys(sortedData)
          daysData = poisWithSelected;

          resolve(poisWithSelected);
          this.setselectedRecord();
        }else{
          daysData = data;
          resolve(data);
        }
        
        let webpoikey=[];
       for(let i=0;i<this.props.globalPoiSet.length;i++)
       {
         for(let j=0;j<this.props.selectedWebPOIsKeys.length;j++)
         {
            if(this.props.globalPoiSet[i]==this.props.selectedWebPOIsKeys[j].id)
            webpoikey.push(this.props.selectedWebPOIsKeys[j])
         }
       }
       this.props.setSelectedWebPoi(webpoikey);
      }
    });
  };


  setselectedRecord() {
    let selectedWebPOIsKeys1 = [...this.props.selectedWebPOIsKeys];


    let defaultSelectedRow1 = []
  
    for (let i = 0; i < daysData.length; i++) {
      if (daysData[i].selected1) {
        let flg = 1
        for (let j = 0; j < this.props.selectedWebPOIsKeys.length; j++) {
          if (this.props.selectedWebPOIsKeys[j].id == daysData[i].id) {
            flg = 0
          }
        }
        defaultSelectedRow1.push(i);


        if (flg == 1) {
          //this.props.selectedWebPOIsKeys.push(daysData[i])
          selectedWebPOIsKeys1.push(daysData[i])
        }
      }

    }

    let selectedWebPOIsKeysNew = []

    for (let i = 0; i < selectedWebPOIsKeys1.length; i++) {
      for (let j = 0; j < daysData.length; j++) {

        if ((daysData[j].id == selectedWebPOIsKeys1[i].id) && daysData[j].selected1) {
          selectedWebPOIsKeysNew.push(daysData[j])
        }
      }
    }
    this.props.setSelectedWebPoi(selectedWebPOIsKeysNew);
    this.setState({ defaultSelectedRow: defaultSelectedRow1 });
  }

  render() {
    const columns = [
      { name: 'id', options: { label: 'ID', filter: false, } },
      { name: 'name', label: 'Name', options: { filter: false } },
      { name: 'country', label: 'Country' },

    ];

    const onRowsSelect = (curRowSelected, rowsSelected, rowMeta) => {
      const daysPois = [...this.state.data]
      const selectedPois = [...this.state.defaultSelectedRow]
       let globalPoiSet1 = [...this.props.globalPoiSet];
       let selectedWebPOIsKeys1 = [...this.props.selectedWebPOIsKeys];
      if (daysPois[curRowSelected[0].dataIndex].selected1) {
        daysPois[curRowSelected[0].dataIndex].selected1 = false;
        let a= globalPoiSet1.filter(function(item){ return item != daysPois[curRowSelected[0].dataIndex].id});
        let b= selectedWebPOIsKeys1.filter(function(item){ return item.id != daysPois[curRowSelected[0].dataIndex].id;
      });
        this.props.setglobalPoiSet(a);
        this.props.setSelectedWebPOIsKeys(b)
        this.setState({})
      }
      else {
        selectedPois.push(curRowSelected[0].dataIndex)
        daysPois[curRowSelected[0].dataIndex].selected1 = true;
        globalPoiSet1.push(String(daysPois[curRowSelected[0].dataIndex].id));
        selectedWebPOIsKeys1.push(daysPois[curRowSelected[0].dataIndex])
        this.props.setglobalPoiSet(globalPoiSet1);
        this.props.setSelectedWebPOIsKeys(selectedWebPOIsKeys1)
        this.setState({defaultSelectedRow: selectedPois})
      }

      this.setselectedRecord();

      //new medraft
    this.props.setSelectedVgByWeb(daysPois[curRowSelected[0].dataIndex])
 // end new medraft
      //this.onReorder();

    };
    const { data } = this.state;
    data.sort((a, b) => a.id - b.id);
    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      download: false,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: 'none',
      rowsSelected: this.state.defaultSelectedRow,
      onRowSelectionChange: onRowsSelect,
    };

    return (
      <table>

        <tr>
          <td style={{ width: '40%' }}>
            <h4> Select from country POIs: </h4>
          </td>
          <td style={{ width: '5%' }}>

          </td>
          <td style={{ width: '55%' }}>
            <h4> order Selected POIs: </h4>
          </td>
        </tr>

        <tr>
          <td style={{ width: '40%' }}>

            <div>
              <MUIDataTable
                title={
                  <Typography variant="subtitle2">
                    {this.state.loading && (
                      <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: 'relative', top: 4 }}
                      />
                    )}
                  </Typography>
                }
                data={data}
                columns={columns}
                options={options}
                rowsSelected={onRowsSelect}
                rowMeta={[0, 1]}
              />
            </div>
          </td>
          <td style={{ width: '10%' }}>

          </td>
          <td style={{ width: '45%', WebkitPaddingEnd: 100,verticalAlign:'baseline' }} className="mytable">

            <DataGrid
              // height={440}
              dataSource={this.props.selectedWebPOIsKeys}
              keyExpr="id"
              showBorders={true}
              ref={(ref) => (this.dataGrid = ref)}
              selectableRows={false}
              expandableRowsHeader={false}
              onSelectionChanged={this.onSelectionChanged}
              selectedRowKeys={this.state.dragWebPOIsKeys}
            >
              <RowDragging
                allowReordering={true}
                onReorder={this.onReorder}
                showDragIcons={true}
              />

              <Scrolling mode="virtual" />
              <Sorting mode="none" />

              <Column dataField="id" width={60} />
              <Column dataField="name" />
              <Column dataField="country" />

              <Paging defaultPageSize={10} />
            </DataGrid>

          </td>
        </tr>
      </table >
    );
  }
}
export default WebPoint;
