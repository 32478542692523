//import references
import React, { Component } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import imageCompression from 'browser-image-compression';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { handlePhotoUpload } from "./uploadFiles"
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Link from '@material-ui/core/Link';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import _uniqueId from 'lodash/uniqueId';
import MUIDataTable from 'mui-datatables';
import { CircularProgress, Typography } from '@material-ui/core';
import { Border } from 'devextreme-react/bar-gauge';
import SelectDays from './components/SelectDays';
import WebPoint from './components/WebPoint';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import uploadIcon from './themes/icons/uploadicon.png';
import DeleteIcon from './themes/icons/delete.png';
import { ApiData, MapboxKey, countriesNames } from "./config"
import MapboxAutocomplete from "react-mapbox-autocomplete";
import { handleCallApi, getDatafromApi } from './calls'

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


let pointNumbers = [];
let selectedRowIndex = false;
let selectedDayData = [];
let gpxLoc;
let daysData;

class HotelPriceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      hotelTableData: [['Loading Data...']],
      searchData: [],
      updateHotelRowIndex: '',
      countryFilter: '',
      tripName: '',
      trip_photo: '',
      tripInternalName: '',
      createBy: '',
      region: '',
      tripType: 'normal',
      nature_level: '',
      culture_level: '',
      adventure_level: '',
      group_structure: '',
      save_type: 'add',
      openDeleteCnfrm: false,
      selectedDays: [{ "id": '', "name": "Loading...", "country": "" }],
      showSelectedDay: false,
      editableHotelId: '',
      personalizedHotel: false,
      highLights: [""],
      ticketType: [""],
      overnight_pre_night: "",
      overnight_post_night: "",
      stop_location: '',
      // Data hotels 
      country: '',
      overnight_city: "",
      hotel_name: "",
      site_link: "",
      hotel_level_basic: true,
      low_season_price_double: 0,
      low_season_price_single: 0,
      high_season_price_double: 0,
      high_season_price_single: 0,

    };
    
    window.bd = this;
  }

  setDayTableData = async (data) => {
    this.setState({ dayTableData: data });
  };

  handlePhoto = async (event) => {
    try {
      const url = await handlePhotoUpload(event, 'planmytrip-assets', 'other');
      if (url) {
        this.setState({ trip_photo: url });
      }
    } catch (error) {
      console.log('Error uploading photo:', error);
    }
  };


   handleHotelValidation() {
    let errors = {};
    let formIsValid = true;
    if (!this.state.country) {
      formIsValid = false;
      errors['country'] = 'Please Select Country';
    }
    if (!this.state.stop_location) {
      formIsValid = false;
      errors['overnight_city'] = 'Please fill overnight city';
    }
    if (!this.state.overnight_city) {
      formIsValid = false;
      errors['stop_location'] = 'Please fill location';
    }
    if (!this.state.hotel_name) {
      formIsValid = false;
      errors['hotel_name'] = 'Please fill hotel name';
    }
    if (!this.state.site_link) {
      formIsValid = false;
      errors['site_link'] = 'Please fill site link';
    }
    if (!this.state.low_season_price_double || isNaN(this.state.low_season_price_double)) {
      formIsValid = false;
      errors['low_season_price_double'] = 'Please fill low season price double and it should be a number';
    }
    
    if (!this.state.low_season_price_single || isNaN(this.state.low_season_price_single)) {
      formIsValid = false;
      errors['low_season_price_single'] = 'Please fill low season price single and it should be a number';
    }
    
    if (!this.state.high_season_price_double || isNaN(this.state.high_season_price_double)) {
      formIsValid = false;
      errors['high_season_price_double'] = 'Please fill high season price double and it should be a number';
    }
    
    if (!this.state.high_season_price_single || isNaN(this.state.high_season_price_single)) {
      formIsValid = false;
      errors['high_season_price_single'] = 'Please fill high season price double and it should be a number';
    }
  
    this.setState({ errors: errors });
    return formIsValid;
  }

  async handleSaveHotel(e) {
    e.preventDefault();
    toast.dismiss();
    if (this.handleHotelValidation()) {
      let hotelData1 = [...this.state.hotelTableData];
      console.log('hotelData1',hotelData1)
      var countryCode = this.state.country;
      var countryname = '';
      countriesNames.map(function (item) {
        if (item.code === countryCode)
          countryname = item.name;
      });
      const stopLocation = this.state.stop_location;
        const [latitude, longitude] = stopLocation.split(',');

        // Ahora, latitude y longitude contienen los valores separados
        console.log('Latitude:', latitude);
        console.log('Longitude:', longitude);
      let apidata = {
        country: countryname.trim(),
        overnight_city: this.state.overnight_city.trim(),
        hotel_name: this.state.hotel_name.trim(),
        site_link: this.state.site_link.trim(),
        hotel_level_basic: this.state.hotel_level_basic,
        low_season_price_double: Number(this.state.low_season_price_double),
        low_season_price_single: Number(this.state.low_season_price_single),
        high_season_price_double: Number(this.state.high_season_price_double),
        high_season_price_single: Number(this.state.high_season_price_single),
        latitude: latitude,
        longitude: longitude,
        personalized: false
        }
      let method = 'POST';
      let url1 = ApiData.baseURL + `/hotels-prices-list/`;
      let success = 'Hotels prices list Added';
      if (this.state.save_type != 'add') {
        method = 'PUT';
        url1 = ApiData.baseURL + `/hotels-prices-list/${this.state.editableHotelId}/`;
        success = 'Hotels prices list Updated';
      }
      let data = await handleCallApi(method, url1, JSON.stringify(apidata), success, '')
    if(data){
      if (this.state.save_type === 'add') {
        if(this.state.searchData && this.state.searchData.length){
          let searchData = [...this.state.searchData];
          if(this.state.countryFilter && this.state.countryFilter.length){
            if(data.country === this.state.countryFilter){
              searchData.push(data)
              this.setState({ searchData })
            }
          }else{
            searchData.push(data)
            this.setState({ searchData })
          }
        }
        hotelData1.push(data);
        this.setState({ hotelTableData: hotelData1 })
      }
      else {
        if(this.state.searchData && this.state.searchData.length){
          this.state.searchData[this.state.updateHotelRowIndex] = data;
          this.setState({ searchData: this.state.searchData })
        }
        else{
          hotelData1[this.state.updateHotelRowIndex] = data[0];
          this.setState({ hotelTableData: hotelData1 })
        }
      }

      this.setState({
        updateHotelRowIndex: "",
        personalizedHotel: false,
        country: "",
        overnight_city: "",
        hotel_name: "",
        site_link: "",
        hotel_level_basic: true,
        low_season_price_double: 0,
        low_season_price_single: 0,
        high_season_price_double: 0,
        high_season_price_single: 0
      });

      closePopoup();
    }
    } else {
      toast.error('Missing Information / Fields');
    }
  }

  setHotel = async (save_type, updateHotelRowIndex, personalizedHotel, country, overnight_city, hotel_name, site_link, hotel_level_basic, low_season_price_double, low_season_price_single, high_season_price_double, high_season_price_single) => {
    this.setState({ save_type, updateHotelRowIndex, personalizedHotel, country, overnight_city, hotel_name, site_link, hotel_level_basic, low_season_price_double, low_season_price_single, high_season_price_double, high_season_price_single });
    setTimeout(() => {
      this.setState({ showSelectedDay: true });
    }, 500);
  };

  setHotelTableData = async (data) => {
    this.setState({ hotelTableData: data });
  };
  setHotelSearchData = async (data) => {
    this.setState({ searchData: data });
  };

  setSelectedDays = async (data) => {
    this.setState({ selectedDays: data });
  };

  handleClickOpen = () => {
    if (selectedRowIndex.length > 0)
      this.setState({ openDeleteCnfrm: true });
  };

  handleDelete = () => {
    this.setState({ openDeleteCnfrm: false });
    var array = [...this.state.hotelTableData]; // make a separate copy of the array
    selectedRowIndex.sort(function (a, b) {
      return b - a;
    });


    selectedRowIndex.forEach(async (i) => {
      let delid = array[i].id;
      array.splice(i, 1);
      await handleCallApi('DELETE', ApiData.baseURL + `/hotels-prices-list/${delid}`, {}, '', '')
    })

    this.setHotelTableData(array);
    selectedRowIndex = [];
  };

  handleClose = () => {
    this.setState({ openDeleteCnfrm: false });
    selectedRowIndex = [];
  };

  setEditableHotelId = (id) => {
    this.setState({ editableHotelId: id });
  }
  setCountryFilter = (value) => {
    this.setState({ countryFilter: value });
  }

  handleChangePersonalized = () => {
    this.setState({ personalizedHotel: !this.state.personalizedHotel });
  }
  handleCloseTripSelected = () => {
    this.setState({ selectedDays: [] });
  }

  setHotelLocationMap(poiLocation) {
    var poiCoordinates = poiLocation.split(",");
    if (poiCoordinates.length >= 2) {
      if (poiCoordinates[0] > 90) poiCoordinates[0] = 90;
      if (poiCoordinates[0] < -90) poiCoordinates[0] = -90;

      mapMarkersOne.forEach((marker) => marker.remove());
      mapMarkersOne = [];
      const marker = new mapboxgl.Marker()
        .setLngLat([poiCoordinates[1], poiCoordinates[0]])
        .addTo(webMapOne);
      mapMarkersOne.push(marker);
    }
    window.bd.setHotelLocation(poiCoordinates[0], poiCoordinates[1])
  }
  

  _suggestionSelected(result, lat, lng, text) {
    window.bd.setHotelLocationMap(lat + ',' + lng);
    window.bd.setHotelLocation(lat, lng)
    console.log("_suggestionSelected")
  }


  setHotelLocation(latitude, longitude) {
    this.setState({ stop_location: latitude + ',' + longitude });
    $('.react-mapbox-ac-input').val('');
    console.log("setHotelLocation")
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.state.openDeleteCnfrm}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are You Sure You Want to delete this Hotel
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button onClick={this.handleDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <div className="modal">
          <div className="modal_content">
            <span className="close" onClick={this.handleCloseTripSelected}>&times;</span>
            <div className="model_html">
              <h2>{this.state.save_type === 'add' ? 'CREATE NEW HOTEL' : `EDIT HOTEL ${this.state.editableHotelId}`}</h2>
              <table cellPadding="8" >
                <tbody>
                  

                  <tr>
                    <td>
                      <label>Hotel name</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        style={{ width: '95%' }}
                        variant="outlined"

                        value={this.state.hotel_name}
                        error={!!this.state.errors['hotel_name']}
                        onChange={(event) =>
                          this.setState({ hotel_name: event.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Overnight City</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        style={{ width: '95%' }}
                        variant="outlined"

                        value={this.state.overnight_city}
                        error={!!this.state.errors['overnight_city']}
                        onChange={(event) =>
                          this.setState({ overnight_city: event.target.value })
                        }
                      />
                    </td>
                    <td>
                      <label>Site link</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        style={{ width: '95%' }}
                        variant="outlined"

                        value={this.state.site_link}
                        error={!!this.state.errors['site_link']}
                        onChange={(event) =>
                          this.setState({ site_link: event.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table>
                        <tr>
                          <td>
                            <label>Country</label><br />
                            <ReactFlagsSelect
                              selected={this.state.country}
                              id={'selectCountriesDay'}
                              showSelectedLabel={true}
                              showOptionLabel={true}
                              onSelect={(code) => { this.setState({ country: code }); }}
                              placeholder={'Select a Country'}
                              countries={['GR', 'CR', 'NO', 'GE', 'MX', 'ME', 'DK', 'PL', 'PT', 'IS', 'MA', 'IL', 'TR', 'EC', 'ZA', 'IN', 'LT', 'IE', 'ES',]}
                            />
                            <span className="errorMsg">{this.state.errors['country']}</span>
                          </td>
                        </tr>
                      </table>


                    </td>
                    <td style={{ verticalAlign: 'baseline', paddingTop: '10px' }}>
                      <label>Hotel level basic</label><br />
                    
                      <FormControl className="dropdownWithborder" style={{ maxWidth: 150 }}>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Type"
                          value={this.state.hotel_level_basic}
                          onChange={(event) => { this.setState({ hotel_level_basic: event.target.value }); }}
                        >
                          <MenuItem value={true}>Basic</MenuItem>
                          <MenuItem value={false}>Upgraded</MenuItem>

                        </Select>
                      </FormControl><br/>
                      <span className="errorMsg">{this.state.errors['hotel_level_basic']}</span>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label>Low season price double</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        style={{ width: '95%' }}
                        variant="outlined"

                        value={this.state.low_season_price_double}
                        error={!!this.state.errors['low_season_price_double']}
                        onChange={(event) =>
                          this.setState({ low_season_price_double: event.target.value })
                        }
                      />
                    </td>
                    <td>
                      <label>Low season price single</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        style={{ width: '95%' }}
                        variant="outlined"

                        value={this.state.low_season_price_single}
                        error={!!this.state.errors['low_season_price_single']}
                        onChange={(event) =>
                          this.setState({ low_season_price_single: event.target.value })
                        }
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label>High season price double</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        style={{ width: '95%' }}
                        variant="outlined"

                        value={this.state.high_season_price_double}
                        error={!!this.state.errors['high_season_price_double']}
                        onChange={(event) =>
                          this.setState({ high_season_price_double: event.target.value })
                        }
                      />
                    </td>
                    <td>
                      <label>High season price single</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        style={{ width: '95%' }}
                        variant="outlined"

                        value={this.state.high_season_price_single}
                        error={!!this.state.errors['high_season_price_single']}
                        onChange={(event) =>
                          this.setState({ high_season_price_single: event.target.value })
                        }
                      />
                    </td>
                    <td rowSpan="4" >
                      Location<br />
                      <TextField
                        id="hotelLocation"
                        size="small"
                        variant="outlined"
                        value={this.state.stop_location}
                        onChange={(event) => {
                        this.setState({ stop_location: event.target.value });
                         this.setHotelLocationMap(event.target.value) }

                        }
                      />
                      <br /> <span className="errorMsg">{this.state.errors['stop_location']}</span><br />

                      OR
                      <MapboxAutocomplete publicKey={mapboxgl.accessToken}
                        inputclassName='form-control search'
                        onSuggestionSelect={this._suggestionSelected}
                        onChange={(e) => { /*console.log("newlocation1", e.target.value)*/ }}
                        resetSearch={false} />


                      <br /> <br /> <br />
                      <div className="poimap">  <MapboxGLMap prefix={'mapDay-1'} /></div>
                      <span className="labelsmall"> Location Example: "Latitude,Longitude" - "32.0853,34.7818"</span>

                    </td>
                  </tr>

                  <tr>
                 
                  </tr>
                    <br />
                  <tr>
                    <td >
                      {this.state.save_type == 'add' &&
                        <Button
                          id="btnSaveSN"
                          variant="contained"
                          color="primary"
                          onClick={this.handleSaveHotel.bind(this)}
                        /*  disabled={
                           this.state.tripInternalName=="" ?  true
                         : this.state.tripName=="" ? true
                         : this.state.tri=="" ? true
                         :false
                         } */
                        >
                          CREATE
                        </Button>
                      }



                      {this.state.save_type != 'add' &&
                        <Button
                          id="btnSaveSN"
                          variant="contained"
                          color="primary"
                          onClick={this.handleSaveHotel.bind(this)}
                        >
                          SAVE CHANGES
                        </Button>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>


            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
        <ToastContainer />
        <table cellPadding="10" className="viewdata">
          <tbody>

            <tr>
              <td>
                <h2>Hotel Price List</h2>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan="3"  ></td>
              <td>&nbsp;
                <Button
                  id="btnSave"
                  variant="contained"
                  className='deleteBtn'
                  color="#15cc09"
                  onClick={() => { this.handleClickOpen(); }}
                  style={{ float: 'right', fontSize: 12, color: '#fff' }}
                >
                  DELETE SELECTED
                </Button>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <HotelPriceListTable 
                hotelData={this.state.hotelTableData} 
                searchData={this.state.searchData} 
                setCountryFilter={this.setCountryFilter} 
                setHotelTableData={this.setHotelTableData} 
                setHotelSearchData={this.setHotelSearchData} 
                setHotel={this.setHotel} 
                setEditableHotelId={this.setEditableHotelId} 
                setHotelLocationMap={this.setHotelLocationMap} 
                />
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <Link href="#" onClick={() => { this.setHotel('add', false, 0, '', '', '', '', true, 0, 0, 0, 0); openPopup(); initializeMap(-1, "mapDay-1"); this.setHotelLocation(0,0) }}>+ ADD NEW HOTEL  </Link>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <div className="borderDiv" />
              </td>
            </tr>

          </tbody>
        </table>

      </div>

    );
  }
}

export default HotelPriceList;

/* openPopup= () =>
{
  const modal = document.querySelector(".modal")
          const closeBtn = document.querySelector(".close")
          modal.style.display = "block";
  closeBtn.addEventListener("click", () => {
            modal.style.display = "none";
  })
}
          */
mapboxgl.accessToken = MapboxKey;
let mapMarkersOne = [];
let mapMarkersTwo = [];
let webmapMarkers = [];
let vgmapMarkers = [];

let webMapOne;
let webMapTwo;
let webMap;
let vgMap;

const initializeMap = (z, id) => {
  if (id == 'mapDayVG') {
    vgMap = new mapboxgl.Map({
      container: id,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: z,
    });
    //Add navigation controls to the mapbox if zoom is lower
    if (z < 11) vgMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    vgMap.on('click', function (e) {
      if (selectedRowIndex) {
        addVgMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      } else {
        toast.error('Please select a trip first by clicking VG button against the record');
      }
    });
  } else if (id == 'mapDayStop') {
    webMap = new mapboxgl.Map({
      container: id,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: z,
    });
    //Add navigation controls to the mapbox if zoom is lower
    if (z < 11) webMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    webMap.on('click', function (e) {
      if (selectedRowIndex) {
        addWebMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      } else {
        addWebMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
        // toast.error('11Please select a trip first by clicking Web button against the record');
      }
    });
  } else {
    if (id == 'mapDay-1') {
      webMapOne = new mapboxgl.Map({
        container: id,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: z,
      });
      webMapOne.on('click', function (e) {
        window.bd.setHotelLocation(
          e.lngLat.lat.toFixed(6),
          e.lngLat.lng.toFixed(6)
        );
        $('#hotelLocation').val(e.lngLat.lng.toFixed(6) + ',' + e.lngLat.lat.toFixed(6));
        mapMarkersOne.forEach((marker) => marker.remove());
        mapMarkersOne = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapOne);
        mapMarkersOne.push(marker);
      });
    } else {
      webMapTwo = new mapboxgl.Map({
        container: id,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: z,
      });

      webMapTwo.on('click', function (e) {
        $('#txtEndLocDay').val(e.lngLat.lng.toFixed(6) + ',' + e.lngLat.lat.toFixed(6));
        mapMarkersTwo.forEach((marker) => marker.remove());
        mapMarkersTwo = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapTwo);
        mapMarkersTwo.push(marker);
      });
    }
  }
};

class MapboxGLMap extends Component {
  constructor(props) {
    super(props);
    // this.id = _uniqueId(props.prefix + "-");
    this.id = props.prefix;
  }
  componentDidMount() {
    initializeMap(-1, this.id);
  }
  render() {
    const id = this.id;
    return (
      <div id={id} className="mapEA">
        {" "}
      </div>
    );
  }
}

class HotelPriceListTable extends Component {



  constructor(props) {
    super(props);
  
    this.state = {
      data: [['Loading Data...']],
      loading: false,
      page: 0,
      rowsPerPage: 10,
      count: 0,
      filterData: [],
      filterBy: '',
      filterValue: '',
      searchTerm: '',
      allTrips: [],
      filterState: false,
      ascendingSort: true,
    };
  }

  async componentDidMount() {
    let data = await this.getData();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.has('id')){
      let id = urlParams.getAll('id');
      this.props.setEditableHotelId(id)
     this.setHotelData(id,data)
    }
  }

  async componentDidUpdate(prevProps, prevState) {

    if (this.state.page !== prevState.page || this.state.rowsPerPage !== prevState.rowsPerPage) {
      let data = await this.getData();
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if(urlParams.has('id')){
       let id = urlParams.getAll('id');
       this.props.setEditableHotelId(id)
       console.log('data', data)
       this.setHotelData(id,data)
      }
    }
    if (this.state.searchTerm !== prevState.searchTerm){
      if(this.state.searchTerm.length === 0){
        if(this.state.filterValue.length !== 0){
          this.getFilteredData()
        }else{
          this.setState({ filterData: [], filterState: false, searchTerm: '' });
          this.props.setHotelSearchData([])
        }
      }
    } 
  }
  

   setHotelData = async (id, data) => {
    let filterId = parseInt(id[0])
    let getHotel = await this.getHotelById(filterId)
    const filterRow = getHotel[0]
    const getIndexRow = data.findIndex(item => item.id === filterId);
    var countryCode;
    var countryname = filterRow.country;
    countriesNames.map(function (item) {
      if (item.name === countryname)
        countryCode = item.code;
    });
    
  
    this.props.setHotel("update", getIndexRow, false, countryCode, filterRow.overnight_city, filterRow.hotel_name, filterRow.site_link, filterRow.hotel_level_basic, filterRow.low_season_price_double, filterRow.low_season_price_single, filterRow.high_season_price_double, filterRow.high_season_price_single);
    openPopup()
    initializeMap(-1, "mapDay-1");
    if(filterRow.latitude && filterRow.longitude){
      this.props.setHotelLocationMap(`${filterRow.latitude},${filterRow.longitude}`)
    }else{
      this.props.setHotelLocationMap(`0,0`)
    }
  }

  getData = () => {
    this.setState({ loading: true });
    let dataRows = this.xhrRequest(ApiData.baseURL+`/hotels-prices-list/personalized/false`).then(async (data) => {
      this.setState({ data: data, loading: false });

      this.props.setHotelTableData(data);
        this.setState({ count: data.length }); 
      return data
    });
    return dataRows
  };



  xhrRequest = (url) => {
    return new Promise(async (resolve, reject) => {
      let data =await getDatafromApi(url)
      if(data){
        data.sort((a,b) => a.id - b.id);
          resolve(data);
      }
    });
  };

  getHotelById = (id) => {
    return new Promise(async (resolve, reject) => {
      let data = await getDatafromApi(ApiData.baseURL +`/hotels-prices-list/${id}`)
      if(data){
        resolve(data);
      }
    });
  };


  render() {
    const columns = [
      {
        name: 'id', options: {
          label: 'ID', filter: true, sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            var editrow = {};
            var rowIndexForUpdate = 0;
            var i = 0;
            this.props.hotelData.forEach((row) => {
              if (row.id == value) {
                editrow = row;
                rowIndexForUpdate = i;
              }
              i++;
            })


            var countryCode;
            var countryname = editrow.country;
            countriesNames.map(function (item) {
              if (item.name === countryname)
                countryCode = item.code;
            });
            
            return (
              <Link href="#" onClick={() => {this.props.setHotel("update", rowIndexForUpdate, false, countryCode, editrow.overnight_city, editrow.hotel_name, editrow.site_link, editrow.hotel_level_basic, editrow.low_season_price_double, editrow.low_season_price_single, editrow.high_season_price_double, editrow.high_season_price_single); openPopup(editrow.id); console.log('editrow clicked', editrow); this.props.setEditableHotelId(editrow.id); this.props.setHotelLocationMap(editrow.latitude ? `${editrow.latitude},${editrow.longitude}` : '0,0'); initializeMap(-1, "mapDay-1"); }}>  {value}</Link>
            );
          },
        }
      },
      { name: 'hotel_name', label: 'Hotel Name', options: { filter: true } },
      { name: 'overnight_city', label: 'Overnight city', options: { filter: true } },
      { name: "country", label: "Country", options: {
        filter: true,
        filterOptions: {
          names: countriesNames.map(country => country.name)
        },
      } 
    },

     {
      name: 'hotel_level_basic', options: {
        label: 'Hotel level basic', filter: false, sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
         
          const HotelLevelBasic = value ? 'basic' : 'upgraded';

          return (
            <p>{HotelLevelBasic}</p>
          );
        },
      }
    },
     { name: 'low_season_price_double', label: 'Low season price double', options: { filter: false } },
     { name: 'low_season_price_single', label: 'Low season price single', options: { filter: false } },
     { name: 'high_season_price_double', label: 'High season price double', options: { filter: false } },
     { name: 'high_season_price_single', label: 'High season price single', options: { filter: false } },
     {
      name: 'site_link', options: {
        label: 'Site Link', filter: true, sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const maxLength = 30; // Define la longitud máxima que deseas mostrar
    
          if (value && value.length > maxLength) {
            // Si el valor es más largo que la longitud máxima, trúnquelo y agregue '...'
            value = value.substring(0, maxLength) + '...';
          }
    
          return (
            <p> {value}</p>
          );
        },
      }
    }
    
    ];

    const onRowsSelect = (curRowSelected, rowsSelected) => {
      selectedRowIndex = [];
     
      for (let i = 0; i < rowsSelected.length; i++) {
        selectedRowIndex.push(rowsSelected[i].index);
      }
      console.log('selecting row', selectedRowIndex)
      activeDeactiveDeleteBtn();
    };




    const { filterData, page, rowsPerPage, count, filterState } = this.state;
    const {hotelData, searchData} = this.props;

    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      download: false,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: 'none',
      rowsSelected: selectedRowIndex,
      rowsPerPage: rowsPerPage,
      page: page,
      count: count,
      pagination: true,
      onRowSelectionChange: onRowsSelect,
    };

    return (
      <div>
        {this.state.loading ? (
          <p style={{ position: 'absolute', top: '80px'}}>Loading Data...</p>
        ) : (
          this.state.filterState ? <p style={{ position: 'absolute', top: '80px'}}>{this.props.searchData.length} trips found</p> : null
        )}
        <MUIDataTable
          title={
            <Typography variant="subtitle2">
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: 'relative', top: 4 }}
                />
              )}
            </Typography>
          }
          data={hotelData}
          columns={columns}
          options={options}
          rowsSelected={onRowsSelect}
        />
      </div>
    );
  }
}


function addWebMarker(coordinates) {

  const id = _uniqueId('webmarker-');

  let marker;
  var el = document.createElement('div');
  el.className = 'marker';
  el.id = id;
  el.addEventListener(
    'click',
    (e) => {
      e.stopPropagation();
      let target_id = e.target.id;
      // let queriedMarkerObj = webmapMarkers.find((marker) => marker.id === query);
      // queriedMarkerObj.marker.remove();
      for (let i = 0; i < webmapMarkers.length; i++) {
        if (webmapMarkers[i].id === target_id) {
          document.getElementById(webmapMarkers[i].id).remove();
          webmapMarkers.splice(i, 1);
        }
      }
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(webMap);
  marker.properties = { id: id };
  webmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}

function addVgMarker(coordinates, type = false) {
  const id = _uniqueId('vgmarker-');
  let marker;
  var el = document.createElement('div');
  el.className = 'marker';
  el.id = id;
  if (type) {
    el.style.backgroundImage = `url('https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/${getSvg(
      type
    )}.svg')`;
  }
  el.addEventListener(
    'click',
    (e) => {
      e.stopPropagation();
      let query = e.target.id;

      let queriedMarkerObj = vgmapMarkers.find((marker) => marker.id === query);
      queriedMarkerObj.marker.remove();
      pointNumbers.splice(
        pointNumbers.findIndex((v) => v.id === query),
        1
      );
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(vgMap);
  vgmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}



function getSvg(type) {
  type = parseInt(type);
  switch (type) {
    case 1: {
      return 'straight';
    }
    case 2: {
      return 'right';
    }
    case 3: {
      return 'left';
    }
    case 4: {
      return 'fork-left';
    }
    case 5: {
      return 'fork-right';
    }
    case 6: {
      return 'poi';
    }
    case 7: {
      return 'info';
    }
    case 8: {
      return 'swimming';
    }
    case 9: {
      return 'rafting';
    }
    case 10: {
      return 'hotel';
    }
    default: {
      return 'poi';
    }
  }
}
function openPopup(id = '') {
  if(id !== ''){
    window.history.pushState(null, null, `?page=HotelPriceList&id=${id}`);
  }
  const modal = document.querySelector(".modal")
  const closeBtn = document.querySelector(".close")
  modal.style.display = "block";
  document.querySelector(".viewdata").style.display = "none";
  closeBtn.addEventListener("click", () => {
    window.history.pushState(null, null, `?page=HotelPriceList`);
    modal.style.display = "none";
    document.querySelector(".viewdata").style.display = "inline-table";
  })
}
function closePopoup() {
  document.querySelector(".modal").style.display = "none";
  document.querySelector(".viewdata").style.display = "inline-table";
}

function activeDeactiveDeleteBtn()
{
  const btn = document.querySelector(".deleteBtn")
  if(selectedRowIndex.length>0) btn.style.backgroundColor='blue';
  else btn.style.backgroundColor='#e0e0e0';
}