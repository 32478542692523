//import references
import React, { Component } from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ReactFlagsSelect from 'react-flags-select';
import { handlePhotoUpload } from "./uploadFiles"
import Link from '@material-ui/core/Link';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import { handleCallApi, getDatafromApi } from './calls'
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import _uniqueId from 'lodash/uniqueId';
import MUIDataTable from 'mui-datatables';
import { CircularProgress, Typography } from '@material-ui/core';



import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import JoditEditor from "jodit-react";
import uploadIcon from './themes/icons/uploadicon.png';
import imageCompression from 'browser-image-compression';
import { ApiData, countriesNames } from "./config"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import MenuItem from '@material-ui/core/MenuItem';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

let pointNumbers = [];
let selectedRowIndex = false;
let selectedRowIds = [];
let selectedDayData = [];
let gpxLoc;
let daysData;

class SelfGuidedPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      photourl: '',
      webphotoLoc: '',
      selfGuideTableData: [['Loading Data...']],
      updateSGRowIndex: '',
      PNCount: 1,
      SNCount: 1,
      selfGuide_tripName: '',
      selfGuide_country: '',
      selfGuide_trip: '',
      selfGuide_subTitle: '',
      selfGuide_desc: '',
      selfGuide_price: '',
      price_description: '',
      selfGuide_price_includes: '',
      selfGuide_price_excluded: '',
      number_travelers: 0,
      selfGuide_photo: '',
      save_type: 'add',
      showModal: false,
      openDeleteCnfrm: false,
      editableSPId: '',
      starting_date: '1970-01-01',
      searchData: [],
      allTrip: [],
      confirmation_policy: ''
    };
  }

  componentDidMount() {
    this.getTrip();
  }

  async getTrip() {
    let data = await getDatafromApi(ApiData.apiUrl + '/trips/simple-trips?personalized=false')
    if(data){
      data.sort((a, b) => a.id - b.id);
      this.setState({ allTrip: data });
    }
  }

  handleSelfGuideValidation() {
    let errors = {};
    let formIsValid = true;

    /*    if (!this.state.selfGuide_tripName) {
         formIsValid = false;
         errors['selfGuide_tripName'] = '*';
       }
   
       if (!this.state.selfGuide_country) {
         formIsValid = false;
         errors['selfGuide_country'] = 'Please Select Country';
       } */

    if (!this.state.selfGuide_trip) {
      formIsValid = false;
      errors['selfGuide_trip'] = 'Please Select Trip';
    }
    if (!this.state.selfGuide_price) {
      formIsValid = false;
      errors['price'] = 'Price Is not empty';
    }
    if (isNaN(parseInt(this.state.selfGuide_price))) {
      formIsValid = false;
      errors['price'] = 'Not a Valid price';
    }
    /* if (!this.state.localHero_type) {
      formIsValid = false;
      errors['localHero_type'] = 'Please Select Type';
    } */
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSaveSelfGuide = async (e) => {
    e.preventDefault();
    toast.dismiss();
    if (this.handleSelfGuideValidation()) {


      let selfGuideData1 = [...this.state.selfGuideTableData];
      var countryCode = this.state.selfGuide_country;
      var countryname = '';
      countriesNames.map(function (item) {
        if (item.code === countryCode)
          countryname = item.name;
      });
      const [year, month, day] = this.state.starting_date.split('-');
      const formattedDate = `${day}/${month}/${year}`;

      let apidata = {
        "trip_id": this.state.selfGuide_trip,
        "photo": this.state.selfGuide_photo,
        "price": Number(this.state.selfGuide_price),
        "price_includes": this.state.selfGuide_price_includes,
        "price_description": this.state.price_description,
        "excluded": this.state.selfGuide_price_excluded,
        "personalized": false,
        "price_description": this.state.price_description,
        "number_travelers": this.state.number_travelers,
        "starting_date": formattedDate,
        "confirmation_policy": this.state.confirmation_policy
      }
      let method = 'POST';
      let success = 'Self Guided Added';
      let url1 = ApiData.apiUrl + `/self-guided-packages/`;
      if (this.state.save_type != 'add') {
        method = 'PUT';
        url1 = ApiData.apiUrl + `/self-guided-packages/${this.state.editableSPId}`;
        success = 'Self Guided Updated';  
        selfGuideData1[this.state.updateSGRowIndex].trip_id = this.state.selfGuide_trip
        selfGuideData1[this.state.updateSGRowIndex].photo = this.state.selfGuide_photo
        selfGuideData1[this.state.updateSGRowIndex].price = Number(this.state.selfGuide_price)
        selfGuideData1[this.state.updateSGRowIndex].price_includes = this.state.selfGuide_price_includes
        selfGuideData1[this.state.updateSGRowIndex].price_description = this.state.price_description
        selfGuideData1[this.state.updateSGRowIndex].number_travelers = this.state.number_travelers
        selfGuideData1[this.state.updateSGRowIndex].excluded = this.state.selfGuide_price_excluded
        selfGuideData1[this.state.updateSGRowIndex].starting_date = formattedDate
        selfGuideData1[this.state.updateSGRowIndex].confirmation_policy = this.state.confirmation_policy
        this.setState({ selfGuideTableData: selfGuideData1 })      
      }
      let data = await handleCallApi(method, url1, JSON.stringify(apidata), success, '')
      if(data){
        if (this.state.save_type === 'add') {
          selfGuideData1.push(data);
          this.setState({ selfGuideTableData: selfGuideData1 })
        }
        else {
            if(this.state.searchData){
              this.state.searchData[this.state.updateSGRowIndex] = data;
              this.setState({ searchData: this.state.searchData })
            }
        }
        this.setState({
          selfGuide_tripName: '',
          selfGuide_country: '',
          selfGuide_trip: '',
          selfGuide_subTitle: '',
          selfGuide_desc: '',
          selfGuide_price: '',
          selfGuide_price_includes: '',
          price_description: '',
          selfGuide_photo: '',
          selfGuide_price_excluded: '',
          number_travelers: 0,
          editableSPId: '',
          starting_date: '1970-01-01',
          confirmation_policy: "",
        });

        closePopoup();
      }
    }
    else {
      toast.error('Missing Information / Fields');
    }
  }

  handleFromDateChange = (event) => {
		const fromDate = event.target.value;
		this.setState({ starting_date: fromDate });
	  }

  setEditableSelfPackageId = (id) => {
    this.setState({ editableSPId: id });
  }
  setSGSearchData = async (data) => {
    this.setState({ searchData: data });
  };

  handlePhoto = async (event) => {
    try {
      const url = await handlePhotoUpload(event, 'planmytrip-assets', 'selfGuidedPackages');
      if (url) {
        this.setState({ selfGuide_photo: url });
      }
    } catch (error) {
      console.log('Error uploading photo:', error);
    }
  };

  setSelfGuideData = async (confirmation_policy, price_description,trip, photo, selfGuide_price, selfGuide_price_includes, selfGuide_price_excluded, starting_date, save_type, updateSGRowIndex, number_travelers) => {
    const dateFormated = this.convertToISODate(starting_date)
    this.setState({
      confirmation_policy,
      showModal: true,
      selfGuide_photo: photo,
      selfGuide_trip: trip,
      selfGuide_price: selfGuide_price,
      selfGuide_price_includes: selfGuide_price_includes,
      price_description: price_description,
      selfGuide_price_excluded: selfGuide_price_excluded,
      starting_date: dateFormated,
      save_type: save_type, updateSGRowIndex: updateSGRowIndex,
      number_travelers
    });

  };

  setSelfGuideTableData = async (data) => {
    this.setState({ selfGuideTableData: data });
  };

  handleClickOpen = () => {
    if (selectedRowIndex.length > 0)
      this.setState({ openDeleteCnfrm: true });
  };

  handleDelete = () => {
    this.setState({ openDeleteCnfrm: false });

    var array = [...this.state.selfGuideTableData]; // make a separate copy of the array
    selectedRowIndex.sort(function (a, b) {
      return b - a;
    });
    selectedRowIndex.forEach(async (i) => {
      let delid = array[i].id;
      array.splice(i, 1);
      await handleCallApi('DELETE', `/self-guided-packages/${delid}/`, {}, '', '')
    })
    this.setSelfGuideTableData(array);
    selectedRowIndex = [];
  };

  handleClose = () => {
    this.setState({ openDeleteCnfrm: false });
    selectedRowIndex = [];
  };

  convertToISODate = (ddmmyyyy) => {
    const parts = ddmmyyyy.split('/');
    if (parts.length === 3) {
        const [day, month, year] = parts;
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }
    return ddmmyyyy; 
}
  




  render() {
    const config = {
      buttons: [
        'source', '|',
        'undo', 'redo', '|',

        'font', 'fontsize', 'bold', 'italic', 'underline', '|',

        'ul',
        'ol', '|',
        'outdent', 'indent', '|',
        'align',
      ],


    }
    return (
      <div>

        <Dialog
          open={this.state.openDeleteCnfrm}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are You Sure You Want to delete this Self Guide Package
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button onClick={this.handleDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <div className="modal">
          <div className="modal_content">
            <span className="close">&times;</span>
            <div className="model_html">
              <h2>ADD A TRIP TO COUNTRY'S PACKAGE</h2>
              {this.state.showModal &&
                <table cellPadding="8" >
                  <tbody>
                    <tr style={{ display: 'none' }}>
                      <td width="35%" style={{ paddingRight: 30 }}>
                        <label>Country</label><br />
                        <ReactFlagsSelect
                          selected={this.state.selfGuide_country}
                          id={'selectCountriesDay'}
                          style={{ width: '70%' }}
                          showSelectedLabel={true}
                          showOptionLabel={true}
                          onSelect={(code) => { this.setState({ selfGuide_country: code }); console.log("countrycode", code) }}
                          placeholder={'Select a Country'}
                          countries={['GR', 'CR', 'NO', 'IS', 'MX', 'ME', 'DK', 'IL', 'PL', 'PT', 'KR', 'EC', 'PE', 'MA', 'IN','ES','RO', 'ZA', 'GE', 'LK', 'CL', 'AR', 'AE', 'TZ', 'BG', 'IT']}

                        />
                        <span className="errorMsg">{this.state.errors['selfGuide_country']}</span>
                      </td>
                      <td style={{ verticalAlign: 'baseline' }}>


                      </td>
                    </tr>
                    <tr>
                      <td width="35%" style={{ paddingRight: 30, verticalAlign: 'baseline' }}>
                        <label>SELECT TRIP</label><br />
                        <FormControl className="dropdownWithborder">
                          <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Type"
                            value={this.state.selfGuide_trip}
                            onChange={(event) => {
                              this.setState({ selfGuide_trip: event.target.value });

                              console.log('event.target.value', event.target.value)
                            }}
                            multiple={false}

                          >



                            {this.state.allTrip.map((item, i) =>
                            (

                              <MenuItem value={item.id}>{item.internal_name}({item.id})</MenuItem>
                            )
                            )}

                          </Select>
                        </FormControl>
                        <span className="errorMsg">{this.state.errors['selfGuide_trip']}</span>
                      </td>
                      <td style={{ verticalAlign: 'baseline' }}>
                        <label>COVER PHOTO</label><br />
                        {this.state.selfGuide_photo ? [
                          <div><span className="labelsmall"> Currently:<span className="colorgreen"> {this.state.selfGuide_photo}</span></span><br /><br />
                          </div>] : null}



                        <div className="divUpload">

                          <img width="20px" src={uploadIcon} />
                          <Button variant="contained" component="label" style={{ float: 'right', fontSize: 9, }} >
                            UPLOAD FILE
                            <input
                              type="file"
                              hidden
                              id="fileSNPhoto"
                              size="small"
                              value=""
                              onChange={this.handlePhoto}
                            />
                          </Button>

                        </div>
                        <span className="errorMsg">{this.state.errors['selfGuide_photo']}</span>
                        <div>
                          {this.state.selfGuide_photo && <img style={{height: '140px', width: '180px'}} src={this.state.selfGuide_photo}></img>}
                        </div>

                      </td>
                    </tr>
                    <tr style={{ display: 'none' }}>
                      <td colSpan="2">
                        <label>Trip Name</label><br />
                        <TextField
                          id="txtSNTitle"
                          style={{ width: '40%' }}

                          variant="outlined"

                          value={this.state.selfGuide_tripName}
                          error={!!this.state.errors['selfGuide_tripName']}
                          onChange={(event) =>
                            this.setState({ selfGuide_tripName: event.target.value })
                          }
                        />
                      </td>
                      <td>

                      </td>
                    </tr>
                    <tr style={{ display: 'none' }}>
                      <td style={{ paddingRight: 50 }}>
                        <label>SUB- TITLE (Trip Type Sescription)</label><br />
                        <TextField
                          id="txtSNTitle"
                          style={{ width: '100%' }}
                          variant="outlined"
                          value={this.state.selfGuide_subTitle}
                          error={!!this.state.errors['selfGuide_subTitle']}
                          onChange={(event) =>
                            this.setState({ selfGuide_subTitle: event.target.value })
                          }
                        />
                      </td>

                    </tr>






                    <tr style={{ display: 'none' }}>
                      <td colspan="2">
                        <label>TRIP ITINERARY (DESCRIPTION)</label><br />

                        <JoditEditor

                          value={this.state.selfGuide_desc}
                          tabIndex={1}
                          style={{ innerHeight: 500 }}
                          onBlur={(value) => {
                            if (value) this.setState({ selfGuide_desc: value })
                          }}
                          config={config}
                        />
                      </td>

                    </tr>



                    <tr>
                      <td >
                        <h3>PRICING</h3>
                      </td>

                    </tr>
                    <tr>
                    <td colSpan="2">
                        <label>Price</label><br />
                        <TextField
                          id="txtSNTitle"
                          style={{ width: '40%' }}

                          variant="outlined"

                          value={this.state.selfGuide_price}
                        error={!!this.state.errors['price']}
                          onChange={(event) =>
                            this.setState({ selfGuide_price: parseInt(event.target.value) })
                          }
                        />
                        <br />
                            <span className="errorMsg">{this.state.errors['price']}</span>

                      </td>

                    </tr>
                    <tr>
                    <td colSpan="2">
                        <label>Starting Date</label><br />
                          <input
                              type="date"
                              id="starting_date"
                              value={this.state.starting_date}
                              onChange={(e) => this.handleFromDateChange(e)}
                          />
                        <br />
                            <span className="errorMsg">{this.state.errors['starting_date']}</span>

                      </td>

                    </tr>
                    <tr>
                    <td colSpan="2">
                        <label>Number Of Travellers</label><br />
                          <input
                              type="number"
                              id="number_travelers"
                              value={this.state.number_travelers}
                              onChange={(event) =>
                                this.setState({ number_travelers: parseInt(event.target.value) })
                              }
                          />
                        <br />
                            <span className="errorMsg">{this.state.errors['number_travelers']}</span>

                      </td>

                    </tr>
                    <tr>
                      <td colspan="2">

                        <label>Price Description</label><br />

                        <JoditEditor
                          value={this.state.price_description}
                          tabIndex={1}
                          onBlur={(value) => { if (value) this.setState({ price_description: value }) }}
                          config={config}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">

                        <label>Price includes</label><br />

                        <JoditEditor
                          value={this.state.selfGuide_price_includes}
                          tabIndex={1}
                          onBlur={(value) => { if (value) this.setState({ selfGuide_price_includes: value }) }}
                          config={config}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">

                        <label>Excluded:</label><br />



                        <JoditEditor
                          value={this.state.selfGuide_price_excluded}
                          tabIndex={1}
                          onBlur={(value) => { if (value) this.setState({ selfGuide_price_excluded: value }) }}
                          config={config}
                        />


                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">

                        <label>Confirmation Policy:</label><br />



                        <JoditEditor
                          value={this.state.confirmation_policy}
                          tabIndex={1}
                          onBlur={(value) => { if (value) this.setState({ confirmation_policy: value }) }}
                          config={config}
                        />


                      </td>
                    </tr>












                    <tr>
                      <td colSpan="4">


                      <label>
                          <strong>
                          To make sure PDF creation succeeds, please install and activate the following plugin for Google Chrome Browser: 
                          </strong>
                          <a href="https://chromewebstore.google.com/detail/cors-unblock/lfhmikememgdcahcdlaciloancbhjino?pli=1" target='_blank'>
                          https://chromewebstore.google.com/detail/cors-unblock/lfhmikememgdcahcdlaciloancbhjino?pli=1
                          </a>
                      </label><br/>


                        {this.state.save_type == 'add' &&
                          <Button
                            id="btnSaveSN"
                            variant="contained"
                            color="primary"
                            onClick={this.handleSaveSelfGuide.bind(this)}
                          >
                            CREATE
                          </Button>
                        }
                        {this.state.save_type != 'add' &&
                          <Button
                            id="btnSaveSN"
                            variant="contained"
                            color="primary"
                            onClick={this.handleSaveSelfGuide.bind(this)}
                          >
                            SAVE CHANGES
                          </Button>
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              }

            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />


        <table cellPadding="10" className="viewdata">
          <tbody>

            <tr>
              <td>
                <h2>Self guided packages</h2>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan="3"  ></td>
              <td>&nbsp;
                <Button
                  id="btnSave"
                  variant="contained"

                  className='deleteBtn'
                  onClick={() => { this.handleClickOpen(); console.log('dvdsg', selectedRowIndex) }}
                  style={{ float: 'right', fontSize: 12, color: '#fff' }}
                >
                  DELETE SELECTED
                </Button>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <SelfGuidesTable setSGSearchData={this.setSGSearchData} setEditableSelfPackageId={this.setEditableSelfPackageId} allTrip={this.state.allTrip} selfGuideData={this.state.selfGuideTableData} setSelfGuideTableData={this.setSelfGuideTableData} setSelfGuideData={this.setSelfGuideData} />
              </td>
            </tr>
            <tr>
              <td colSpan="4">




                <Link href="#" onClick={() => { this.setSelfGuideData(``, `<div>
<strong>Accommodation</strong>
<ul>
<li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;Price is based on 2 adults sharing a double / twin room</li>
<li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;4 nights in Moderate (3 stars) and First Class (4 stars) Hotels</li>
<li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;Adjustments can be made in additional services section</li>
</ul>
</div>

<div>
<strong>Meals</strong>
<ul>
<li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;4 breakfasts in the hotels</li>
</ul>
</div>

<div>
<strong>Car Rental</strong>
<ul>
<li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;Price is based on 2 adults Sharing a car</li>
<li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;Toyota Yaris or similar for 5 days</li>
<li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;A local driver is optional with an additional payment</li>
</ul>
</div>`,`<ul>
 <li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;Price is based on 2 person sharing a double or twin room in one car</span></span></li><li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;Price is based on 1 person in a single&nbsp;room in one car<br></span></span></li>
</ul>`, ``, ``, `<div>
<strong>On-Trip Support</strong><br></br>
Help during your travels by
our Local Hero, </br>service hours 09:00 - 18:00.<br></br>
And:
<ul>
  <li>Navigational Tour Guide</li>
  <li>Seamless Navigation of Your Personalized Trip</li>
  <li>Seamless Navigation of Walking city trails</li>
  <li>Visual & Audio Tips Based on Location</li>
  <li>Curated Hiking Trails</li>
  <li>Content Info About Your Personalized Itinerary</li>
  <li>Flexible adjustments According to Your Personal Preferences</li>
</ul>
</div>

<div>`, `<div>
<strong>Flights</strong>
<ul>
  <li>Airfare - It's your responsibility to arrange your own international and/or domestic flights</li>
  <li>We advise against securing your flight tickets until you get our written confirmation for the Services you have booked</li>
</ul>
</div>

<div>
<strong>Insurance</strong>
<ul>
  <li>When booking one of our Self-Guided Trips, you are required to secure adequate travel insurance cover for the duration of your trip</li>
</ul>
</div>

<div>
<strong>Others</strong>
<ul>
  <li>Peak season supplement in hotels</li>
  <li>Gas for Car rental (car rental is subject to terms & conditions of local rental companies and local laws)</li>
  <li>Local tourist taxes</li>
  <li>All personal expenses & any items not explicitly listed as included</li>
</ul>
</div>`,"", 'add', '', 0); console.log('selfGuide_tripName', this.state.selfGuide_tripName); openPopup(); this.setState({ showModal: true }) }}> + ADD NEW SELF GUIDED PACKAGE  </Link>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <div className="borderDiv" />
              </td>
            </tr>

          </tbody>
        </table>
      </div>


    );
  }
}

export default SelfGuidedPackage;








class SelfGuidesTable extends Component {



  constructor(props) {
    super(props);
    console.log('props', props)
    this.state = {
      data: [['Loading Data...']],
      loading: false,
      localHeroRowsSelected: [],
      rowsPerPage: 10,
      page: 0,
      count: 0,
      filterData: [],
      filterBy: '',
      filterValue: '',
      searchTerm: '',
      filterState: false
    };
  }

  async componentDidMount() {
    await this.getTrip();
    let data = await this.getData();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.has('id')){
     let id = urlParams.getAll('id');
     this.props.setEditableSelfPackageId(id[0])
     this.setSGPackageData(id,data)
    }
  }

  async componentDidUpdate(prevProps, prevState) {

    if (this.state.page !== prevState.page || this.state.rowsPerPage !== prevState.rowsPerPage) {
      let data = await this.getData();
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if(urlParams.has('id')){
       let id = urlParams.getAll('id');
       console.log('id update',id);
       this.props.setEditableSelfPackageId(id[0])
       this.setSGPackageData(id,data)
      }
    }
  }

  setSGPackageData = async (id, data) => {
    let filterId = parseInt(id[0])
    let filterRow = await this.getSGById(filterId)
    let getIndexRow = data.findIndex(row => row.id === filterId)
    this.props.setSelfGuideData(filterRow.confirmation_policy,filterRow.price_description,filterRow.trip_id, filterRow.photo, filterRow.price, filterRow.price_includes, filterRow.excluded, filterRow.starting_date, 'update', getIndexRow, filterRow.number_travelers); 
    openPopup();
  }

  async getTrip() {
    let data1 = await getDatafromApi(ApiData.apiUrl + '/trips/?personalized=false&take=3000')
    let data=data1.results;
    if(data && data.length>2){
      data.sort((a, b) => a.id - b.id);
      this.setState({ allTrip: data });
    }
    else if(data)
    this.setState({ allTrip: data });
    
  }

  getData = () => {
    this.setState({ loading: true });
    let dataRows = this.xhrRequest().then((data) => {
      this.setState({ data, loading: false });
      this.props.setSelfGuideTableData(data);
      return data
    });
    return dataRows
  };


  xhrRequest = async () => {
    try {
        let data = await getDatafromApi(ApiData.baseURL + `/self-guided-packages?take=3000&personalized=false`);
        if (data) {
            data.results.sort((a, b) => a.id - b.id);
            const tripsData = this.state.allTrip;
            let filteredSGP = data.results.filter(sgp => {
                return tripsData && tripsData.some(trip => trip.id === sgp.trip_id);
            });
            console.log('Filtered SGP Data:', filteredSGP);
            console.log('tripsData', tripsData);
            return filteredSGP;
        } else {
            throw new Error("No data received from the API.");
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        throw error;
    }
};

  getFilteredData = () => {
    if(this.state.searchTerm.length || this.state.filterValue.length){
      this.xhrRequestFilterData().then((data) => {
        this.setState({ filterData: data, filterState: true });
        this.props.setSGSearchData(data)
        return data;
      });
    }else{
      this.setState({ filterData: this.props.tripData, filterState: false });
      this.props.setSGSearchData(this.props.tripData)
    }
  };

  xhrRequestFilterData = () => {
    return new Promise(async (resolve, reject) => {
      let data =await getDatafromApi(ApiData.apiUrlLogin +`/trips/findTrips?${this.state.searchTerm ? `internal_name=${this.state.searchTerm.toLocaleUpperCase()}&` : ''}${this.state.filterValue ? `${this.state.filterBy}=${this.state.filterValue}` : ''}`)
      if(data){
        if(data.length){
          data.sort((a, b) => a.id - b.id);
          resolve(data);
        }else{
          this.setState({ filterData: [] }) 
          this.props.setSGSearchData([])
        }
      }
    });
  };

  getSGById = (id) => {
    return new Promise(async (resolve, reject) => {
      let data = await getDatafromApi(ApiData.apiUrl +`/self-guided-packages/${id}`)
      if(data){
        resolve(data);
      }
    });
  };



  render() {
    const columns = [
      {
        name: 'id', options: {
          label: 'ID', filter: false,

          customBodyRender: (value, tableMeta, updateValue) => {
            // const editrow = tableMeta.tableData[tableMeta.rowIndex];
            var editrow = {};
            var rowIndexForUpdate = 0;
            var i = 0;
            tableMeta.tableData.forEach((row) => {
              if (row.id == value) {
                editrow = row;
                rowIndexForUpdate = i;
              }
              i++;
            })





            return (


              <Link href="#" onClick={() => {
                this.props.setSelfGuideData(editrow.confirmation_policy,editrow.price_description,editrow.trip_id, editrow.photo, editrow.price, editrow.price_includes, editrow.excluded, editrow.starting_date, 'update', rowIndexForUpdate, editrow.number_travelers); this.props.setEditableSelfPackageId(value); openPopup(editrow.id);
              }}>  {value}</Link>
            );
          },

        }
      },

       { name: 'trip_id', label: 'Trip Id', options: { filter: false } },
      {
      name: 'trip_id',
      options: {
        label: 'TripName',
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          const { allTrip } = this.props;
          const selectedTrip = allTrip.find(trip => trip.id === value);
          let tripName = '';
          if (selectedTrip) {
            tripName = selectedTrip.internal_name;
          } else {
            tripName = 'Loading...';
          }
          return tripName;
        },
      },
    },
      
    { name: 'starting_date', label: 'Starting Date', options: { filter: false } },
    { name: 'number_travelers', label: 'Number Of Travellers', options: { filter: false } },

    ];

    const onRowsSelect = (curRowSelected, rowsSelected) => {
      selectedRowIndex = [];
      for (let i = 0; i < rowsSelected.length; i++) {
        selectedRowIndex.push(rowsSelected[i].index);
      }
      activeDeactiveDeleteBtn();
    };

    const handleChangePage = (event, newPage) => {
      this.setState({ page: event }); 
    };
  
    const handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event, page: 0 });
    };
    const handleOnFilterChange = (changedColumn, filterList) => {
      let filterBy = changedColumn;
      let filterValue = (filterList[3].join('') || filterList[4].join(''));
      this.setState({filterBy, filterValue}, () => {
        this.getFilteredData()
      });
    };


    const handleOnFilterClose = () => {
      if(this.state.filterBy.length && !this.state.filterValue.length){
        deleteFilterData()
      }
    }
    const deleteFilterData = () => {
      this.setState({ filterData: [], filterState: false, filterBy: '', filterValue: ''});
      this.props.setSGSearchData([])
    }
    const deleteSearchData = () => {
      this.setState({ filterData: [], filterState: false, searchTerm: '' });
      this.props.setSGSearchData([])
    }

    const searchByDayName = (searchTerm) => {
      if(searchTerm){
        if(!isNaN(Number(searchTerm))){
          this.setState({searchTerm}, () => {
            this.getFilteredData()
          });
        }
        if(isNaN(Number(searchTerm)) && searchTerm.length > 3){
          this.setState({searchTerm}, () => {
            this.getFilteredData()
          });
        }
     }else{
        deleteSearchData()
     }
    }

    let searchTimer;
		const searchByUserName = (searchTerm) => {
			if (searchTerm) {
			  if (!isNaN(Number(searchTerm))) {
				clearTimeout(searchTimer); 
				searchTimer = setTimeout(() => {
				  this.setState({ searchTerm }, () => {
					this.getFilteredData();
				  });
				}, 1000); 
			  }
			  if (isNaN(Number(searchTerm)) && searchTerm.length > 3) {
				clearTimeout(searchTimer); 
				searchTimer = setTimeout(() => {
				  this.setState({ searchTerm }, () => {
					this.getFilteredData();
				  });
				}, 1000); 
			  }
			} else {
			  this.setState({ searchTerm: '' });
			  deleteSearchData();
			  selectedRowIndex = [];
			  selectedRowIds = [];
			  activeDeactiveDeleteBtn();
			}
		  };


    const { filterData, page, rowsPerPage, count, filterState } = this.state;
    const {tripData, searchData} = this.props;
    const { data } = this.state;

    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      download: false,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: 'none',
      rowsSelected: selectedRowIndex,
      rowsPerPage: rowsPerPage,
      page: page,
      count: count,
      pagination: filterState ? false : true,
      onRowSelectionChange: onRowsSelect,
      onChangePage: handleChangePage,
      onChangeRowsPerPage: handleChangeRowsPerPage,
      onSearchChange: searchByDayName,
      onFilterChange: handleOnFilterChange,
      onFilterDialogClose: handleOnFilterClose,
      onFilterChipClose: deleteFilterData,
      onSearchClose: deleteSearchData
    };
    return (
      <div>
        <MUIDataTable
          title={
            <Typography variant="subtitle2">
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: 'relative', top: 4 }}
                />
              )}
            </Typography>
          }
          data={filterState ? searchData : this.props.selfGuideData}
          columns={columns}
          options={options}
          rowsSelected={onRowsSelect}
        />
      </div>
    );
  }
}




function openPopup(id='') {
  if(id !== ''){
    window.history.pushState(null, null, `?page=SelfGuidedPackages&id=${id}`);
  }

  const modal = document.querySelector(".modal")
  const closeBtn = document.querySelector(".close")
  modal.style.display = "block";
  document.querySelector(".viewdata").style.display = "none";
  closeBtn.addEventListener("click", () => {
    window.history.pushState(null, null, `?page=SelfGuidedPackages`);
    modal.style.display = "none";
    document.querySelector(".viewdata").style.display = "inline-table";
  })
}

function closePopoup() {
  document.querySelector(".modal").style.display = "none";
  document.querySelector(".viewdata").style.display = "inline-table";
}

function activeDeactiveDeleteBtn() {
  const btn = document.querySelector(".deleteBtn")
  if (selectedRowIndex.length > 0) btn.style.backgroundColor = 'blue';
  else btn.style.backgroundColor = '#e0e0e0';
}
