//import references
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ReactFlagsSelect from 'react-flags-select';
import { handlePhotoUpload } from "./uploadFiles"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { handleCallApi, getDatafromApi } from './calls'
import Link from '@material-ui/core/Link';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';

import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import _uniqueId from 'lodash/uniqueId';
import MUIDataTable from 'mui-datatables';
import { CircularProgress, Typography } from '@material-ui/core';
import { Border } from 'devextreme-react/bar-gauge';

import WebPoint from './components/WebPoint';
import VG from './components/VG';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import uploadIcon from './themes/icons/uploadicon.png';
import imageCompression from 'browser-image-compression';
import { ApiData, MapboxKey, countriesNames } from "./config"

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

let pointNumbers = [];
let selectedRowIndex = false;
let selectedDayData = [];
let gpxLoc;
let daysData;

class LocalHero extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      photourl: '',
      webphotoLoc: '',
      localHeroTableData: [['Loading Data...']],
      updateLocalHeroRowIndex: '',
      PNCount: 1,
      SNCount: 1,
      localHero_name: '',
      localHero_country: '',
      localHero_location: '',
      localHero_type: '',
      localHero_desc: '',
      localHero_photo: '',
      localHero_email: '',
      localHero_phone: '',
      localHero_region: '',
      localHero_availabilityTimeFrom: '',
      localHero_availabilityTimeTo: '',
      localHeroPlanA_Price: '',
      localHeroPlanB_Price: '',
      localHeroPlanC_Price: '',
      vg_title: '',
      vg_desc: '',
      vg_gpx: '',
      selectedTab: 0,
      save_type: 'add',
      openDeleteCnfrm: false,
    };
  }

  handleLocalHeroValidation() {
    let errors = {};
    let formIsValid = true;

    if (!this.state.localHero_name) {
      formIsValid = false;
      errors['localHero_name'] = '*';
    }
    if (!this.state.localHero_email) {
      formIsValid = false;
      errors['localHero_email'] = 'Email is Required';
    }
    if (!this.state.localHero_country) {
      formIsValid = false;
      errors['localHero_country'] = 'Please Select Country';
    }
   /*  if (!this.state.localHero_availabilityTimeFrom) {
      formIsValid = false;
      errors['localHero_availabilityTimeFrom'] = 'Time In  hh:mm:ss Formate';
    }
    if (!this.state.localHero_availabilityTimeTo) {
      formIsValid = false;
      errors['localHero_availabilityTimeTo'] = 'Time In  hh:mm:ss Formate';
    } */
    this.setState({ errors: errors });
    return formIsValid;
  }
  handleSaveLocalHero = async (e) => {
    e.preventDefault();
    toast.dismiss();
    if (this.handleLocalHeroValidation()) {
      let localHeroData1 = [...this.state.localHeroTableData];
      var countryName;
      var countryCode = this.state.localHero_country;
      countriesNames.map(function (item) {
        if (item.code === countryCode)
          countryName = item.name;
      });
      let apidata = {
        "id":0,
        "name": this.state.localHero_name,
        "profile_pic": this.state.localHero_photo,
        "email": this.state.localHero_email,
        "phone": this.state.localHero_phone,
        "country": countryName,
        "region": this.state.localHero_region,
        "description": this.state.localHero_desc,
        "availabel_from": this.state.localHero_availabilityTimeFrom,
        "availabel_to": this.state.localHero_availabilityTimeTo,
        "plan_a_price": this.state.localHeroPlanA_Price,
        "plan_b_price": this.state.localHeroPlanB_Price,
        "plan_c_price": this.state.localHeroPlanC_Price,
      }

      let method = 'POST';
      let url1 = ApiData.apiUrl+`/local-hero`;
      let success ='Local Hero Added';
      if (this.state.save_type != 'add') {
        method = 'PUT';
        success = 'Local Hero Updated';
        url1 = ApiData.apiUrl+`/local-hero/${localHeroData1[this.state.updateLocalHeroRowIndex].id}/`;
      }
      
      let data = await handleCallApi(method, url1, JSON.stringify(apidata), success, '')
      if(data){
        if (this.state.save_type === 'add') {
          localHeroData1.push(data);
          this.setState({ localHeroTableData: localHeroData1 })
        }
        else {
          localHeroData1[this.state.updateLocalHeroRowIndex] = data;
          this.setState({ localHeroTableData: localHeroData1 })
        }
        this.setState({
          localHero_name: '',
          localHero_photo: '',
          localHero_email: '',
          localHero_phone: '',
          localHero_country: '',
          localHero_region: '',
          localHero_desc: '',
          localHero_availabilityTimeFrom: '',
          localHero_availabilityTimeTo: '',
          localHeroPlanA_Price: '',
          localHeroPlanB_Price: '',
          localHeroPlanC_Price: '',

        });

        closePopoup();
      }
        // else{
        //   let errors = {};
        //   if(data.email)
        //   {
        //     errors['localHero_email'] = data.email[0];
        //     this.setState({ errors: errors });
        //   }
        //   if(data.availabel_from)
        //   {
        //     errors['localHero_availabilityTimeFrom'] = data.availabel_from[0];
        //     this.setState({ errors: errors });
        //   }
        //   if(data.availabel_to)
        //   {
        //     errors['localHero_availabilityTimeTo'] = data.availabel_to[0];
        //     this.setState({ errors: errors });
        //   }
        //   if(data.plan_a_price)
        //   {
        //     errors['localHeroPlanA_Price'] = data.plan_a_price[0];
        //     this.setState({ errors: errors });
        //   }
        //   if(data.plan_b_price)
        //   {
        //     errors['localHeroPlanB_Price'] = data.plan_b_price[0];
        //     this.setState({ errors: errors });
        //   }
        //   if(data.plan_c_price)
        //   {
        //     errors['localHeroPlanC_Price'] = data.plan_c_price[0];
        //     this.setState({ errors: errors });
        //   }

        // }
    }
    else {
      toast.error('Missing Information / Fields');
    }
  }

  handlePhoto = async (event) => {
    try {
      const url = await handlePhotoUpload(event, 'planmytrip-assets', 'localHeros');
      if (url) {
        this.setState({ localHero_photo: url });
      }
    } catch (error) {
      console.log('Error uploading photo:', error);
    }
  };

  setLocalHeroData = async (localHero_name, localHero_country, localHero_desc, localHero_photo, localHero_email, localHero_phone, localHero_region, localHero_availabilityTimeFrom, localHero_availabilityTimeTo, localHeroPlanA_Price, localHeroPlanB_Price, localHeroPlanC_Price, save_type, updateLocalHeroRowIndex) => {
    this.setState({ localHero_name: localHero_name, localHero_country: localHero_country, localHero_desc: localHero_desc, localHero_photo: localHero_photo, localHero_email: localHero_email, localHero_phone: localHero_phone, localHero_region: localHero_region, localHero_availabilityTimeFrom: localHero_availabilityTimeFrom, localHero_availabilityTimeTo: localHero_availabilityTimeTo, localHeroPlanA_Price: localHeroPlanA_Price, localHeroPlanB_Price: localHeroPlanB_Price, localHeroPlanC_Price: localHeroPlanC_Price, save_type: save_type, updateLocalHeroRowIndex: updateLocalHeroRowIndex,errors: {} });
  };

  setLocalHeroTableData = async (data) => { this.setState({ localHeroTableData: data }); };

  handleClickOpen = () => {
    if (selectedRowIndex.length > 0)
      this.setState({ openDeleteCnfrm: true });
  };

  handleDelete = () => {
    this.setState({ openDeleteCnfrm: false });

    var array = [...this.state.localHeroTableData]; // make a separate copy of the array
    selectedRowIndex.sort(function (a, b) {
      return b - a;
    });
    selectedRowIndex.forEach(async (i) => {
      let delid = array[i].id;
      array.splice(i, 1);
      await handleCallApi('DELETE', ApiData.apiUrl+`/local-hero/${delid}/`, {}, '', '')
    });
    this.setLocalHeroTableData(array);
    selectedRowIndex = [];
  };

  handleClose = () => {
    this.setState({ openDeleteCnfrm: false });
    selectedRowIndex = [];
  };

  render() {
    return (
      <div>

        <Dialog
          open={this.state.openDeleteCnfrm}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are You Sure You Want to delete this Local Hero
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button onClick={this.handleDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>


        <div className="modal">
          <div className="modal_content">
            <span className="close">&times;</span>
            <div className="model_html">
              <h2>ADD A LOCAL HERO</h2>
              <table cellPadding="8" >
                <tbody>
                  <tr>
                    <td >
                      <label>Name</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        variant="outlined"
                        value={this.state.localHero_name}
                        error={!!this.state.errors['localHero_name']}
                        onChange={(event) =>
                          this.setState({ localHero_name: event.target.value })
                        }
                      />
                    </td>
                    <td>
                      <label>Main Photo url</label><br />
                      {this.state.localHero_photo ? [
                        <div><span className="labelsmall"> Currently:<span className="colorgreen"> {this.state.localHero_photo}</span></span><br /><br />
                        </div>] : null}

                      <div className="divUpload">

                        <img width="20px" src={uploadIcon} />
                        <Button variant="contained" component="label" style={{ float: 'right', fontSize: 9, }} >
                          UPLOAD FILE
                          <input
                            type="file"
                            hidden
                            id="fileSNPhoto"
                            size="small"
                            value=""
                            onChange={this.handlePhoto}
                          />
                        </Button>

                      </div>
                      <span className="errorMsg">{this.state.errors['localHero_photo']}</span>
                      <div>
                        {this.state.localHero_photo && <img style={{height: '140px', width: '180px'}} src={this.state.localHero_photo}></img>}
                      </div>

                    </td>
                  </tr>

                  <tr>
                    <td >
                      <label>Email</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        variant="outlined"
                        value={this.state.localHero_email}
                        error={!!this.state.errors['localHero_email']}
                        onChange={(event) =>
                          this.setState({ localHero_email: event.target.value })
                        }
                      /><br/>
                       <span className="errorMsg">{this.state.errors['localHero_email']}</span>

                    </td>
                    <td >
                      <label>Phone</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        variant="outlined"
                        value={this.state.localHero_phone}
                        error={!!this.state.errors['localHero_phone']}
                        onChange={(event) =>
                          this.setState({ localHero_phone: event.target.value })
                        }
                      />
                    </td>
                  </tr>

                  <tr>
                    <td width="30%">
                      <label>Country</label><br />
                      <ReactFlagsSelect
                        selected={this.state.localHero_country}
                        id={'selectCountriesDay'}
                        showSelectedLabel={true}
                        showOptionLabel={true}
                        onSelect={(code) => { this.setState({ localHero_country: code }); }}
                        placeholder={'Select a Country'}
                        countries={[ 'GR','CR', 'NO','GE', 'MX', 'ME', 'DK', 'PL', 'PT','IS', 'MA', 'IL', 'TR', 'EC','ZA', 'IN','LT','IE','ES' ]}

                      />
                      <span className="errorMsg">{this.state.errors['localHero_country']}</span>
                    </td>
                    <td style={{ verticalAlign: 'baseline' }}>
                      <label>Region</label><br />
                      <FormControl style={{ width: '30%' }} className="dropdownWithborder">
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Type"
                          value={this.state.localHero_region}
                          onChange={(event) => { this.setState({ localHero_region: event.target.value }); }}
                        >
                          <MenuItem value={"r1"}>Region 1</MenuItem>
                          <MenuItem value={"r2"}>Region 2</MenuItem>
                        </Select>
                      </FormControl>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      <label>Description</label><br />
                      <TextField
                        id="txtSNDesc"
                        multiline
                        style={{ width: '80%' }}
                        rows={4}
                        variant="outlined"
                        fullWidth={true}
                        value={this.state.localHero_desc}
                        error={!!this.state.errors['localHero_desc']}
                        onChange={(event) =>
                          this.setState({ localHero_desc: event.target.value })
                        }
                      />
                    </td>
                  </tr>

                  <tr>
                    <td >
                      <label>Availability Times From</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        variant="outlined"
                        value={this.state.localHero_availabilityTimeFrom}
                        error={!!this.state.errors['localHero_availabilityTimeFrom']}
                        onChange={(event) =>
                          this.setState({ localHero_availabilityTimeFrom: event.target.value })
                        }
                      />
                      <br/>
                       <span className="errorMsg">{this.state.errors['localHero_availabilityTimeFrom']}</span>

                    </td>
                    <td >
                      <label>Availability Times To</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        variant="outlined"
                        value={this.state.localHero_availabilityTimeTo}
                        error={!!this.state.errors['localHero_availabilityTimeTo']}
                        onChange={(event) =>
                          this.setState({ localHero_availabilityTimeTo: event.target.value })
                        }
                      />
                       <br/>
                       <span className="errorMsg">{this.state.errors['localHero_availabilityTimeTo']}</span>

                    </td>

                  </tr>
                  <tr>
                    <td colspan="2">
                      <table>
                        <tr>
                          <td >
                            <label>Plan A Price</label><br />
                            <TextField
                              id="txtSNTitle"
                              size="small"

                              variant="outlined"

                              value={this.state.localHeroPlanA_Price}
                              error={!!this.state.errors['localHeroPlanA_Price']}
                              onChange={(event) =>
                                this.setState({ localHeroPlanA_Price: event.target.value })
                              }
                            />
                             <br/>
                       <span className="errorMsg">{this.state.errors['localHeroPlanA_Price']}</span>

                          </td>
                          <td >
                            <label>Plan B Price</label><br />
                            <TextField
                              id="txtSNTitle"
                              size="small"

                              variant="outlined"

                              value={this.state.localHeroPlanB_Price}
                              error={!!this.state.errors['localHeroPlanB_Price']}
                              onChange={(event) =>
                                this.setState({ localHeroPlanB_Price: event.target.value })
                              }
                            />
                             <br/>
                       <span className="errorMsg">{this.state.errors['localHeroPlanB_Price']}</span>

                          </td>
                          <td >
                            <label>Plan C Price</label><br />
                            <TextField
                              id="txtSNTitle"
                              size="small"

                              variant="outlined"

                              value={this.state.localHeroPlanC_Price}
                              error={!!this.state.errors['localHeroPlanC_Price']}
                              onChange={(event) =>
                                this.setState({ localHeroPlanC_Price: event.target.value })
                              }
                            />
                             <br/>
                       <span className="errorMsg">{this.state.errors['localHeroPlanC_Price']}</span>

                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>












                  <tr>
                    <td colSpan="4">
                    {this.state.save_type == 'add' &&
                      <Button
                        id="btnSaveSN"
                        variant="contained"
                        color="primary"
                        onClick={this.handleSaveLocalHero.bind(this)}
                      >
                        CREATE
                      </Button>
  }

                      {this.state.save_type != 'add' &&
                        <Button
                        id="btnSaveSN"
                        variant="contained"
                        color="primary"
                          onClick={this.handleSaveLocalHero.bind(this)}
                        >
                          SAVE CHANGES
                        </Button>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>


            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
        <ToastContainer />
        <table cellPadding="10" className="viewdata">
          <tbody>

            <tr>
              <td>
                <h2>Local Heroes</h2>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan="3"  ></td>
              <td>&nbsp;
                <Button
                  id="btnSave"
                  variant="contained"
                  color="#15cc09"
                  className='deleteBtn'
                  onClick={() => { this.handleClickOpen(); }}
                  style={{ float: 'right', fontSize: 12, color: '#fff' }}
                >
                  DELETE SELECTED
                </Button>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <LocalHeroTable localHeroData={this.state.localHeroTableData} setLocalHeroTableData={this.setLocalHeroTableData} setLocalHeroData={this.setLocalHeroData} />
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <Link href="#" onClick={() => { this.setLocalHeroData('', '', '', '', '', '', '', '', '', '', '', '', 'add', ''); console.log('localHero_name', this.state.localHero_name); openPopup() }}> + ADD NEW LOCAL HERO  </Link>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <div className="borderDiv" />
              </td>
            </tr>

          </tbody>
        </table>

      </div>


    );
  }
}

export default LocalHero;

mapboxgl.accessToken = MapboxKey;
let mapMarkersOne = [];
let mapMarkersTwo = [];
let webmapMarkers = [];
let vgmapMarkers = [];

let webMapOne;
let webMapTwo;
let webMap;
let vgMap;

class LocalHeroTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [['Loading Data...']],
      loading: false,
      localHeroRowsSelected: []
    };
  }

  async componentDidMount() {
    let data = await this.getData();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.has('id')){
     let id = urlParams.getAll('id');
     this.setLocalHeroData(id,data)
    }
  }

  setLocalHeroData = (id, data) => {
    let filterId = parseInt(id[0])
    let filterRow = data.filter(row => row.id === filterId)[0]
    let getIndexRow = data.findIndex(row => row.id === filterId)
    var countryCode;
    var countryName = filterRow.country
    countriesNames.map(function (item) {
      if (item.name === countryName)
        countryCode = item.code;
    });

    this.props.setLocalHeroData(filterRow.name, countryCode, filterRow.description,
      filterRow.profile_pic, filterRow.email, filterRow.phone, filterRow.region, filterRow.availabel_from, filterRow.availabel_to, filterRow.plan_a_price, filterRow.plan_b_price, filterRow.plan_c_price, 'update', getIndexRow);
       openPopup();
  }

  getData = () => {
    this.setState({ loading: true });
    let dataRows = this.xhrRequest().then((data) => {
      this.setState({ data, loading: false });
      this.props.setLocalHeroTableData(data);
      return data
    });
    return dataRows
  };

  xhrRequest = (url) => {
    return new Promise(async (resolve, reject) => {
      let data = await getDatafromApi(ApiData.apiUrl+'/local-hero?take=3000')
      if(data){
        data.results.sort((a,b) => a.id - b.id);
          daysData = data.results;
          resolve(data.results);
      }
    });
  };

  render() {
    const columns = [
      {
        name: 'id', options: {
          label: 'ID', filter: false,

          customBodyRender: (value, tableMeta, updateValue) => {
            // const editrow = tableMeta.tableData[tableMeta.rowIndex];
            var editrow = {};
            var rowIndexForUpdate = 0;
            var i = 0;
            tableMeta.tableData.forEach((row) => {
              if (row.id == value) {
                editrow = row;
                rowIndexForUpdate = i;
              }
              i++;
            })

            var countryCode;
            var countryName = editrow.country
            countriesNames.map(function (item) {
              if (item.name === countryName)
                countryCode = item.code;
            });

            return (
              <Link href="#" onClick={() => {
                this.props.setLocalHeroData(editrow.name, countryCode, editrow.description,
                  editrow.profile_pic, editrow.email, editrow.phone, editrow.region, editrow.availabel_from, editrow.availabel_to, editrow.plan_a_price, editrow.plan_b_price, editrow.plan_c_price, 'update', rowIndexForUpdate); console.log('dvdsg stop_title', editrow); openPopup(editrow.id);
              }}>  {value}</Link>
            );
          },

        }
      },

      { name: 'name', label: 'Name', options: { filter: false } },
      { name: 'description', label: 'Description', options: { filter: false } },
      { name: 'country', label: 'Country', options: { filter: false } },
      { name: 'region', label: 'Region', options: { filter: false } },
      /*  { name: 'profile_pic', label: 'Profile Pic', options: { filter: false } }, */


    ];

    const onRowsSelect = (curRowSelected, rowsSelected) => {
      /*   if (rowsSelected.length > 0) {
          selectedRowIndex = daysData[rowsSelected[0].dataIndex].id;
        } else {
          selectedRowIndex = false;
        } */
      selectedRowIndex = [];
      for (let i = 0; i < rowsSelected.length; i++) {
        selectedRowIndex.push(rowsSelected[i].dataIndex);
      }
      activeDeactiveDeleteBtn()

    };
    const { data } = this.state;

    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      download: false,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: 'none',

      rowsSelected: selectedRowIndex,

      onRowSelectionChange: onRowsSelect,
    };
    return (
      <div>
        <MUIDataTable
          title={
            <Typography variant="subtitle2">
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: 'relative', top: 4 }}
                />
              )}
            </Typography>
          }
          data={this.props.localHeroData}
          columns={columns}
          options={options}
          rowsSelected={onRowsSelect}
        />
      </div>
    );
  }
}

function addWebMarker(coordinates) {
  const id = _uniqueId('webmarker-');
  let marker;
  var el = document.createElement('div');
  el.className = 'marker';
  el.id = id;
  el.addEventListener(
    'click',
    (e) => {
      e.stopPropagation();
      let target_id = e.target.id;
      // let queriedMarkerObj = webmapMarkers.find((marker) => marker.id === query);
      // queriedMarkerObj.marker.remove();
      for (let i = 0; i < webmapMarkers.length; i++) {
        if (webmapMarkers[i].id === target_id) {
          document.getElementById(webmapMarkers[i].id).remove();
          webmapMarkers.splice(i, 1);
        }
      }
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(webMap);
  marker.properties = { id: id };
  webmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}

function addVgMarker(coordinates, type = false) {
  const id = _uniqueId('vgmarker-');
  let marker;
  var el = document.createElement('div');
  el.className = 'marker';
  el.id = id;
  if (type) {
    el.style.backgroundImage = `url('https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/${getSvg(
      type
    )}.svg')`;
  }
  el.addEventListener(
    'click',
    (e) => {
      e.stopPropagation();
      let query = e.target.id;

      let queriedMarkerObj = vgmapMarkers.find((marker) => marker.id === query);
      queriedMarkerObj.marker.remove();
      pointNumbers.splice(
        pointNumbers.findIndex((v) => v.id === query),
        1
      );
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(vgMap);
  vgmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}

function handleGpx(xmlData) {
  const converter = require('@tmcw/togeojson');
  const geojson = converter.gpx(xmlData[2]);
  if (typeof vgMap.getLayer('day-route-line') !== 'undefined') {
    vgMap.removeLayer('day-route-line').removeSource('day-route');
  }
  vgMap.addSource('day-route', {
    type: 'geojson',
    data: geojson,
  });

  vgMap.addLayer({
    id: 'day-route-line',
    type: 'line',
    source: 'day-route',
    paint: {
      'line-color': '#15cc09',
      'line-width': 4,
    },
  });

  var coordinates = geojson.features[0].geometry.coordinates;
  var bounds = coordinates.reduce(function (bounds, coord) {
    return bounds.extend(coord);
  }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

  vgMap.fitBounds(bounds, {
    padding: 20,
  });
}

function getSvg(type) {
  type = parseInt(type);
  switch (type) {
    case 1: {
      return 'straight';
    }
    case 2: {
      return 'right';
    }
    case 3: {
      return 'left';
    }
    case 4: {
      return 'fork-left';
    }
    case 5: {
      return 'fork-right';
    }
    case 6: {
      return 'poi';
    }
    case 7: {
      return 'info';
    }
    case 8: {
      return 'swimming';
    }
    case 9: {
      return 'rafting';
    }
    case 10: {
      return 'hotel';
    }
    default: {
      return 'poi';
    }
  }
}

function openPopup(id='') {
  if(id !== ''){
    window.history.pushState(null, null, `?page=Local Heroes&id=${id}`);
  }
  const modal = document.querySelector(".modal")
  const closeBtn = document.querySelector(".close")
  modal.style.display = "block";
  document.querySelector(".viewdata").style.display = "none";
  closeBtn.addEventListener("click", () => {
    window.history.pushState(null, null, `?page=Local Heroes`);
    modal.style.display = "none";
    document.querySelector(".viewdata").style.display = "inline-table";
  })
}

function closePopoup() {
  document.querySelector(".modal").style.display = "none";
  document.querySelector(".viewdata").style.display = "inline-table";
}

function activeDeactiveDeleteBtn() {
  const btn = document.querySelector(".deleteBtn")
  if (selectedRowIndex.length > 0) btn.style.backgroundColor = 'blue';
  else btn.style.backgroundColor = '#e0e0e0';
}
