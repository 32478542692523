//import references
import { CircularProgress, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import $ from 'jquery';
import _uniqueId from 'lodash/uniqueId';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MUIDataTable from 'mui-datatables';
import React, { Component } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getDatafromApi, handleCallApi } from './calls';
import { ApiData, MapboxKey, countriesNames } from "./config";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


let pointNumbers = [];
let selectedRowIndex = false;
let selectedDayData = [];
let gpxLoc;
let daysData;

class Currencies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      currencyTableData: [['Loading Data...']],
      searchData: [],
      updateCurrencyRowIndex: '',
      countryFilter: '',
      tripName: '',
      trip_photo: '',
      tripInternalName: '',
      createBy: '',
      region: '',
      tripType: 'normal',
      nature_level: '',
      culture_level: '',
      adventure_level: '',
      group_structure: '',
      save_type: 'add',
      openDeleteCnfrm: false,
      selectedDays: [{ "id": '', "name": "Loading...", "country": "" }],
      showSelectedDay: false,
      editableCurrencyId: '',
      personalizedCurrency: false,
      highLights: [""],
      ticketType: [""],
      overnight_pre_night: "",
      overnight_post_night: "",

      // Data hotels 
      country: "",
      currency: "",
      exchange_rate: 0,

    };
  }

  setDayTableData = async (data) => {
    this.setState({ dayTableData: data });
  };

  handleCurrencyValidation() {
    let errors = {};
    let formIsValid = true;
    if (!this.state.country) {
      formIsValid = false;
      errors['country'] = 'Please Select Country';
    }
    if (!this.state.currency) {
      formIsValid = false;
      errors['currency'] = 'Please fill currency';
    }
    if (!this.state.exchange_rate || isNaN(parseFloat(this.state.exchange_rate))) {
      formIsValid = false;
      errors['exchange_rate'] = 'Please fill exchange_rate as a valid number';
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  async handleSaveCurrency(e) {
    e.preventDefault();
    toast.dismiss();
    if (this.handleCurrencyValidation()) {
      let currencyData1 = [...this.state.currencyTableData];
      var countryCode = this.state.country;
      var countryname = '';
      countriesNames.map(function (item) {
        if (item.code === countryCode)
          countryname = item.name;
      });
      let apidata = {
        country: countryname,
        currency: this.state.currency,
        exchange_rate: Number(this.state.exchange_rate),
      }
      let method = 'POST';
      let url1 = ApiData.baseURL + `/currencies/`;
      let success = 'Currency Added';
      if (this.state.save_type != 'add') {
        method = 'PUT';
        url1 = ApiData.baseURL + `/currencies/${this.state.editableCurrencyId}/`;
        success = 'Currency Updated';
      }
      let data = await handleCallApi(method, url1, JSON.stringify(apidata), success, '')
      if (data) {
        if (this.state.save_type === 'add') {
          if (this.state.searchData && this.state.searchData.length) {
            let searchData = [...this.state.searchData];
            if (this.state.countryFilter && this.state.countryFilter.length) {
              if (data.country === this.state.countryFilter) {
                searchData.push(data)
                this.setState({ searchData })
              }
            } else {
              searchData.push(data)
              this.setState({ searchData })
            }
          }
          currencyData1.push(data);
          this.setState({ currencyTableData: currencyData1 })
        }
        else {
          if (this.state.searchData && this.state.searchData.length) {
            this.state.searchData[this.state.updateCurrencyRowIndex] = data;
            this.setState({ searchData: this.state.searchData })
          }
          else {
            currencyData1[this.state.updateCurrencyRowIndex] = data;
            this.setState({ currencyTableData: currencyData1 })
          }
        }

        this.setState({
          updateCurrencyRowIndex: "",
          personalizedCurrency: false,
          country: "",
          currency: "",
          exchange_rate: 0,
        });
        closePopoup();
      }
    } else {
      toast.error('Missing Information / Fields');
    }
  }

  setCurrency = async (save_type, updateCurrencyRowIndex, personalizedCurrency, country, currency, exchange_rate) => {
    this.setState({ save_type, updateCurrencyRowIndex, personalizedCurrency, country, currency, exchange_rate });
    setTimeout(() => {
      this.setState({ showSelectedDay: true });
    }, 500);
  };

  setCurrencyTableData = async (data) => {
    this.setState({ currencyTableData: data });
  };
  setCurrencySearchData = async (data) => {
    this.setState({ searchData: data });
  };

  setSelectedDays = async (data) => {
    this.setState({ selectedDays: data });
  };

  handleClickOpen = () => {
    if (selectedRowIndex.length > 0)
      this.setState({ openDeleteCnfrm: true });
  };

  handleDelete = () => {
    this.setState({ openDeleteCnfrm: false });
    var array = [...this.state.currencyTableData]; // make a separate copy of the array
    selectedRowIndex.sort(function (a, b) {
      return b - a;
    });


    selectedRowIndex.forEach(async (i) => {
      let delid = array[i].id;
      array.splice(i, 1);
      await handleCallApi('DELETE', ApiData.baseURL + `/currencies/${delid}`, {}, '', '')
    })

    this.setCurrencyTableData(array);
    selectedRowIndex = [];
  };

  handleClose = () => {
    this.setState({ openDeleteCnfrm: false });
    selectedRowIndex = [];
  };

  setEditableCurrencyId = (id) => {
    this.setState({ editableCurrencyId: id });
  }
  setCountryFilter = (value) => {
    this.setState({ countryFilter: value });
  }

  handleChangePersonalized = () => {
    this.setState({ personalizedCurrency: !this.state.personalizedCurrency });
  }
  handleCloseTripSelected = () => {
    this.setState({ selectedDays: [] });
  }



  render() {
    return (
      <div>
        <Dialog
          open={this.state.openDeleteCnfrm}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are You Sure You Want to delete this Currency
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button onClick={this.handleDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <div className="modal">
          <div className="modal_content">
            <span className="close" onClick={this.handleCloseTripSelected}>&times;</span>
            <div className="model_html">
              <h2>{this.state.save_type === 'add' ? 'CREATE NEW CURRENCY' : `EDIT CURRENCY ${this.state.editableCurrencyId}`}</h2>
              <table cellPadding="8" >
                <tbody>


                  <tr>
                    <td>
                      <label>Currency</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        style={{ width: '95%' }}
                        variant="outlined"

                        value={this.state.currency}
                        error={!!this.state.errors['currency']}
                        onChange={(event) =>
                          this.setState({ currency: event.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Exchange rate</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        style={{ width: '95%' }}
                        variant="outlined"

                        value={this.state.exchange_rate}
                        error={!!this.state.errors['exchange_rate']}
                        onChange={(event) =>
                          this.setState({ exchange_rate: event.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table>
                        <tr>
                          <td>
                            <label>Country</label><br />
                            <ReactFlagsSelect
                              selected={this.state.country}
                              id={'selectCountriesDay'}
                              showSelectedLabel={true}
                              showOptionLabel={true}
                              onSelect={(code) => { this.setState({ country: code }); }}
                              placeholder={'Select a Country'}
                              countries={['GR', 'CR', 'NO', 'GE', 'MX', 'ME', 'DK', 'PL', 'PT', 'IS', 'MA', 'IL', 'TR', 'EC', 'ZA', 'IN', 'LT', 'IE', 'ES',]}
                            />
                            <span className="errorMsg">{this.state.errors['country']}</span>
                          </td>
                        </tr>
                      </table>


                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      {this.state.save_type == 'add' &&

                        <Button
                          id="btnSaveSN"
                          variant="contained"
                          color="primary"
                          onClick={this.handleSaveCurrency.bind(this)}
                        /*  disabled={
                           this.state.tripInternalName=="" ?  true
                         : this.state.tripName=="" ? true
                         : this.state.tri=="" ? true
                         :false
                         } */
                        >
                          CREATE
                        </Button>
                      }



                      {this.state.save_type != 'add' &&
                        <Button
                          id="btnSaveSN"
                          variant="contained"
                          color="primary"
                          onClick={this.handleSaveCurrency.bind(this)}
                        >
                          SAVE CHANGES
                        </Button>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>


            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
        <ToastContainer />
        <table cellPadding="10" className="viewdata">
          <tbody>

            <tr>
              <td>
                <h2>Currency</h2>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan="3"  ></td>
              <td>&nbsp;
                <Button
                  id="btnSave"
                  variant="contained"
                  className='deleteBtn'
                  color="#15cc09"
                  onClick={() => { this.handleClickOpen(); }}
                  style={{ float: 'right', fontSize: 12, color: '#fff' }}
                >
                  DELETE SELECTED
                </Button>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <CurrenciesTable tripData={this.state.currencyTableData} searchData={this.state.searchData} setCountryFilter={this.setCountryFilter} setCurrencyTableData={this.setCurrencyTableData} setCurrencySearchData={this.setCurrencySearchData} setCurrency={this.setCurrency} setEditableCurrencyId={this.setEditableCurrencyId} />
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <Link href="#" onClick={() => { this.setCurrency('add', false, 0, '', '', 0); openPopup() }}>+ ADD NEW CURRENCY  </Link>



              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <div className="borderDiv" />
              </td>
            </tr>

          </tbody>
        </table>

      </div>

    );
  }
}

export default Currencies;

/* openPopup= () =>
{
  const modal = document.querySelector(".modal")
          const closeBtn = document.querySelector(".close")
          modal.style.display = "block";
  closeBtn.addEventListener("click", () => {
            modal.style.display = "none";
  })
}
          */
mapboxgl.accessToken = MapboxKey;
let mapMarkersOne = [];
let mapMarkersTwo = [];
let webmapMarkers = [];
let vgmapMarkers = [];

let webMapOne;
let webMapTwo;
let webMap;
let vgMap;

const initializeMap = (z, id) => {
  if (id == 'mapDayVG') {
    vgMap = new mapboxgl.Map({
      container: id,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: z,
    });
    //Add navigation controls to the mapbox if zoom is lower
    if (z < 11) vgMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    vgMap.on('click', function (e) {
      if (selectedRowIndex) {
        addVgMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      } else {
        toast.error('Please select a trip first by clicking VG button against the record');
      }
    });
  } else if (id == 'mapDayStop') {
    webMap = new mapboxgl.Map({
      container: id,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: z,
    });
    //Add navigation controls to the mapbox if zoom is lower
    if (z < 11) webMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    webMap.on('click', function (e) {
      if (selectedRowIndex) {
        addWebMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      } else {
        addWebMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
        // toast.error('11Please select a trip first by clicking Web button against the record');
      }
    });
  } else {
    if (id == 'mapDay-1') {
      webMapOne = new mapboxgl.Map({
        container: id,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: z,
      });
      webMapOne.on('click', function (e) {
        $('#txtStartLocDay').val(e.lngLat.lng.toFixed(6) + ',' + e.lngLat.lat.toFixed(6));
        // this.setState({startloc: e.lngLat.lng.toFixed(4) + ',' + e.lngLat.lat.toFixed(4) });
        mapMarkersOne.forEach((marker) => marker.remove());
        mapMarkersOne = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapOne);
        mapMarkersOne.push(marker);
      });
    } else {
      webMapTwo = new mapboxgl.Map({
        container: id,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: z,
      });

      webMapTwo.on('click', function (e) {
        $('#txtEndLocDay').val(e.lngLat.lng.toFixed(6) + ',' + e.lngLat.lat.toFixed(6));
        mapMarkersTwo.forEach((marker) => marker.remove());
        mapMarkersTwo = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapTwo);
        mapMarkersTwo.push(marker);
      });
    }
  }
};

class MapboxGLMap extends Component {
  constructor(props) {
    super(props);
    // this.id = _uniqueId(props.prefix + "-");
    this.id = props.prefix;
  }
  componentDidMount() {
    initializeMap(-1, this.id);
  }
  render() {
    const id = this.id;
    return (
      <div id={id} className="mapEA">
        {' '}
      </div>
    );
  }
}

class CurrenciesTable extends Component {



  constructor(props) {
    super(props);

    this.state = {
      data: [['Loading Data...']],
      loading: false,
      page: 0,
      rowsPerPage: 10,
      count: 0,
      filterData: [],
      filterBy: '',
      filterValue: '',
      searchTerm: '',
      allTrips: [],
      filterState: false,
      ascendingSort: true,
    };
  }

  async componentDidMount() {
    let data = await this.getData();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has('id')) {
      let id = urlParams.getAll('id');
      this.props.setEditableCurrencyId(id)
      this.setCurrencyData(id, data)
    }
  }

  async componentDidUpdate(prevProps, prevState) {

    if (this.state.page !== prevState.page || this.state.rowsPerPage !== prevState.rowsPerPage) {
      let data = await this.getData();
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.has('id')) {
        let id = urlParams.getAll('id');
        this.props.setEditableCurrencyId(id)
        this.setCurrencyData(id, data)
      }
    }
    if (this.state.searchTerm !== prevState.searchTerm) {
      if (this.state.searchTerm.length === 0) {
        if (this.state.filterValue.length !== 0) {
          this.getFilteredData()
        } else {
          this.setState({ filterData: [], filterState: false, searchTerm: '' });
          this.props.setCurrencySearchData([])
        }
      }
    }
  }


  setCurrencyData = async (id, data) => {
    let filterId = parseInt(id[0])
    let getCurrency = await this.getCurrencyById(filterId)
    const filterRow = getCurrency
    const getIndexRow = data.findIndex(item => item.id === filterId);
    var countryCode;
    var countryname = filterRow.country;
    countriesNames.map(function (item) {
      if (item.name === countryname)
        countryCode = item.code;
    });

    this.props.setCurrency("update", getIndexRow, false, countryCode, filterRow.currency, filterRow.exchange_rate);
    openPopup()
  }

  getData = () => {
    this.setState({ loading: true });
    let dataRows = this.xhrRequest(ApiData.baseURL + `/currencies`).then(async (data) => {
      this.setState({ data, loading: false });
      this.props.setCurrencyTableData(data);
      this.setState({ count: data.length });
      return data
    });
    return dataRows
  };



  xhrRequest = (url) => {
    return new Promise(async (resolve, reject) => {
      let data = await getDatafromApi(url)
      if (data) {
        data.sort((a, b) => a.id - b.id);
        resolve(data);
      }
    });
  };


  getCurrencyById = (id) => {
    return new Promise(async (resolve, reject) => {
      let data = await getDatafromApi(ApiData.baseURL + `/currencies/${id}`)
      if (data) {
        resolve(data);
      }
    });
  };


  render() {
    const columns = [
      {
        name: 'id', options: {
          label: 'ID', filter: false, sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            //var editrow = tableMeta.tableData[tableMeta.rowIndex];
            var editrow = {};
            var rowIndexForUpdate = 0;
            var i = 0;
            tableMeta.tableData.forEach((row) => {
              if (row.id == value) {
                editrow = row;
                rowIndexForUpdate = i;
              }
              i++;
            })

            var countryCode;
            var countryname = editrow.country;
            countriesNames.map(function (item) {
              if (item.name === countryname)
                countryCode = item.code;
            });

            return (
              <Link href="#" onClick={() => { this.props.setCurrency("update", rowIndexForUpdate, false, countryCode, editrow.currency, editrow.exchange_rate); openPopup(editrow.id); this.props.setEditableCurrencyId(editrow.id) }}>  {value}</Link>
            );
          },
        }
      },
      { name: 'country', label: 'Country', options: { filter: false } },
      { name: 'currency', label: 'Currency', options: { filter: false } },
      { name: 'exchange_rate', label: 'Exchange rate', options: { filter: false } },
    ];

    const onRowsSelect = (curRowSelected, rowsSelected) => {
      selectedRowIndex = [];

      for (let i = 0; i < rowsSelected.length; i++) {
        selectedRowIndex.push(rowsSelected[i].index);
      }
      activeDeactiveDeleteBtn();
    };

    const handleChangePage = (event, newPage) => {
      this.setState({ page: event });
    };

    const handleChangeRowsPerPage = (event) => {
      this.setState({ rowsPerPage: event, page: 0 });
    };
    const handleOnFilterChange = (changedColumn, filterList) => {
      let filterBy = changedColumn;
      let filterValue = (filterList[3].join('') || filterList[4].join(''));
      this.setState({ filterBy, filterValue }, () => {
        this.props.setCountryFilter(filterValue)
        this.getFilteredData()
      });
    };

    const handleSortChange = () => {
      let isAscending = this.state.ascendingSort
      if (this.state.filterState && this.props.searchData.length) {
        const searchData = this.props.searchData;
        searchData.sort((a, b) => {
          if (a.id < b.id) {
            return isAscending ? -1 : 1;
          }
          if (a.id > b.id) {
            return isAscending ? 1 : -1;
          }
          return 0;
        });
        this.props.setCurrencySearchData(searchData)
      } else {
        const data = this.props.tripData;
        data.sort((a, b) => {
          if (a.id < b.id) {
            return isAscending ? -1 : 1;
          }
          if (a.id > b.id) {
            return isAscending ? 1 : -1;
          }
          return 0;
        });

        this.props.setCurrencyTableData(data);
      }

      this.setState({ ascendingSort: !isAscending });
    };

    const handleOnFilterClose = () => {
      if (this.state.filterBy.length && !this.state.filterValue.length) {
        deleteFilterData()
      }
    }

    const deleteFilterData = () => {
      this.setState({ filterBy: '', filterValue: '' }, () => {
        if (this.state.searchTerm.length > 0) {
          this.getFilteredData()
        } else {
          this.setState({ filterData: [], filterState: false });
          this.props.setCurrencySearchData([])
        }
      })
    }

    const deleteSearchData = () => {
      this.setState({ searchTerm: '' }, () => {
        if (this.state.filterValue.length > 0) {
          this.getFilteredData()
        } else {
          this.setState({ filterData: [], filterState: false, searchTerm: '', loading: false });
          this.props.setCurrencySearchData([])
        }
      });
    }


 
    const { filterData, page, rowsPerPage, count, filterState } = this.state;
    const { tripData, searchData } = this.props;

    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      download: false,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: 'none',
      rowsSelected: selectedRowIndex,
      serverSide: true,
      rowsPerPage: rowsPerPage,
      page: page,
      count: count,
      pagination: true,
      onRowSelectionChange: onRowsSelect,
    };

    return (
      <div>
        {this.state.loading ? (
          <p style={{ position: 'absolute', top: '80px' }}>Loading Data...</p>
        ) : (
          this.state.filterState ? <p style={{ position: 'absolute', top: '80px' }}>{this.props.searchData.length} trips found</p> : null
        )}
        <MUIDataTable
          title={
            <Typography variant="subtitle2">
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: 'relative', top: 4 }}
                />
              )}
            </Typography>
          }
          data={filterState ? searchData : tripData}
          columns={columns}
          options={options}
          rowsSelected={onRowsSelect}
        />
      </div>
    );
  }
}


function addWebMarker(coordinates) {

  const id = _uniqueId('webmarker-');

  let marker;
  var el = document.createElement('div');
  el.className = 'marker';
  el.id = id;
  el.addEventListener(
    'click',
    (e) => {
      e.stopPropagation();
      let target_id = e.target.id;
      // let queriedMarkerObj = webmapMarkers.find((marker) => marker.id === query);
      // queriedMarkerObj.marker.remove();
      for (let i = 0; i < webmapMarkers.length; i++) {
        if (webmapMarkers[i].id === target_id) {
          document.getElementById(webmapMarkers[i].id).remove();
          webmapMarkers.splice(i, 1);
        }
      }
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(webMap);
  marker.properties = { id: id };
  webmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}

function addVgMarker(coordinates, type = false) {
  const id = _uniqueId('vgmarker-');
  let marker;
  var el = document.createElement('div');
  el.className = 'marker';
  el.id = id;
  if (type) {
    el.style.backgroundImage = `url('https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/${getSvg(
      type
    )}.svg')`;
  }
  el.addEventListener(
    'click',
    (e) => {
      e.stopPropagation();
      let query = e.target.id;

      let queriedMarkerObj = vgmapMarkers.find((marker) => marker.id === query);
      queriedMarkerObj.marker.remove();
      pointNumbers.splice(
        pointNumbers.findIndex((v) => v.id === query),
        1
      );
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(vgMap);
  vgmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}



function getSvg(type) {
  type = parseInt(type);
  switch (type) {
    case 1: {
      return 'straight';
    }
    case 2: {
      return 'right';
    }
    case 3: {
      return 'left';
    }
    case 4: {
      return 'fork-left';
    }
    case 5: {
      return 'fork-right';
    }
    case 6: {
      return 'poi';
    }
    case 7: {
      return 'info';
    }
    case 8: {
      return 'swimming';
    }
    case 9: {
      return 'rafting';
    }
    case 10: {
      return 'hotel';
    }
    default: {
      return 'poi';
    }
  }
}
function openPopup(id = '') {
  if (id !== '') {
    window.history.pushState(null, null, `?page=Currencies&id=${id}`);
  }
  const modal = document.querySelector(".modal")
  const closeBtn = document.querySelector(".close")
  modal.style.display = "block";
  document.querySelector(".viewdata").style.display = "none";
  closeBtn.addEventListener("click", () => {
    window.history.pushState(null, null, `?page=Currencies`);
    modal.style.display = "none";
    document.querySelector(".viewdata").style.display = "inline-table";
  })
}
function closePopoup() {
  document.querySelector(".modal").style.display = "none";
  document.querySelector(".viewdata").style.display = "inline-table";
}

function activeDeactiveDeleteBtn() {
  const btn = document.querySelector(".deleteBtn")
  if (selectedRowIndex.length > 0) btn.style.backgroundColor = 'blue';
  else btn.style.backgroundColor = '#e0e0e0';
}