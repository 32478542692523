import React, { Component } from 'react';
import { handleCallApi, getDatafromApi } from '../calls'
import { ApiData, MapboxKey, countriesNames } from "../config"

class BasicAccommodation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: [],
      overnights: [],
      data: [],
      totalLow: 0,
      totalHigh: 0,
      currencyValue: Number(this.props.currency) || 1
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if(this.props.days !== prevState.days || this.state.overnights.length === 0){
      let overnightsArr = []
      this.props.days.forEach(day => {
        overnightsArr.push(day.overnight)
      });
      this.setState({days: this.props.days, overnights: overnightsArr});
      this.getAllData()    
    }
  //   if (this.props.currency !== prevProps.currency){
  //     this.setState(this.props.currency);
  // } 
  }



  getAllData = async () => {
    const { overnights } = this.state;
    const dataForTable = [];
    let lowtotaldouble = 0;
    let hightotaldouble = 0;
    let singleLowtotal = 0;
    let singleHightotal = 0;
    if (overnights) {
      for (const overnight of overnights) {
        try {
          const data = await getDatafromApi(ApiData.baseURL + `/hotels-prices-list/city/${overnight.trim()}/true`);
          let item;
          if (data && data.length > 0) {
            item = { overnight, high_season_price_double: data[0].high_season_price_double, low_season_price_double: data[0].low_season_price_double };
            lowtotaldouble += data[0].low_season_price_double;
            hightotaldouble += data[0].high_season_price_double;
            singleLowtotal += data[0].low_season_price_single;
            singleHightotal += data[0].high_season_price_single;
            console.log('each double low price', data[0].low_season_price_double)
          } else {
            item = { overnight, high_season_price_double: 0, low_season_price_double: 0 };
          }
          dataForTable.push(item);
          this.props.setBasicAccomodationPrice(lowtotaldouble, hightotaldouble);
          this.props.setSingleBasicAccomodationPrice(singleLowtotal, singleHightotal);
        } catch (error) {
          console.error('Error fetching data:', error);
          const item = { overnight: null, high_season_price_double: 0, low_season_price_double: 0 };
          dataForTable.push(item);
        }
      }
  
      this.setState({ data: dataForTable, totalLow: lowtotaldouble, totalHigh: hightotaldouble });
    }
  };
  
  


  render() {
    return (
      <div>
        <h2>Basic Accommodation</h2>
        <table className="basic-accommodation-table">
          <thead>
            <tr>
              <th>O/N City</th>
              <th colSpan="2">Dbl Room</th>
            </tr>
            <tr>          
              <th>-</th>
              <th>Low Season</th>
              <th>Peak Season</th>
            </tr>
          </thead>
          <tbody>
          {this.state.data.map((item, index) => (
              <tr key={index}>             
                <td>{item.overnight}</td>
                <td>€{((item.low_season_price_double)/ Number(this.props.currency)).toFixed(2)}</td>
                <td>€{((item.high_season_price_double)/ Number(this.props.currency)).toFixed(2)}</td>
              </tr>
            ))}
                <tr>             
                <td>Total Trip</td>
                <td>€{((this.state.totalLow)/ Number(this.props.currency)).toFixed(2)}</td>
                <td>€{((this.state.totalHigh)/ Number(this.props.currency)).toFixed(2)}</td>
              </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default BasicAccommodation;
