import React, { Component } from 'react';
import { handleCallApi, getDatafromApi } from '../calls'
import { ApiData, MapboxKey, countriesNames } from "../config"

class UpgradedTransportation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Estados para la tabla
      dayCount: 0,
      lowPrice: 0,
      highPrice: 0,
      data: [],
      country: '',
      currencyValue: Number(this.props.currency) || 1
    };
  }

  async componentDidMount() {
    if(this.props.days){
      console.log('this.props.days.length',this.props.days.length)
      this.setState({country: this.props.countryName, dayCount: this.props.days.length});
      this.getAllData()    
    }
    }

    async componentDidUpdate(prevProps, prevState) {
      if(this.state.dayCount !== prevState.dayCount){
        this.setState({ dayCount: this.props.days.length});
      }
    //   if (this.props.currency !== prevProps.currency){
    //     this.setState(this.props.currency);
    // } 
    }

    
  getAllData = async () => {
    const dataForTable = []
    if (this.props.countryName) {
        try {
          const data = await getDatafromApi(ApiData.baseURL + `/car-rental-prices-list/country/${this.props.countryName}/false`);
          let item = {country: data[0].country, high_season_price: data[0].high_season_price, low_season_price: data[0].low_season_price}
          this.setState({lowPrice: data[0].low_season_price, highPrice: data[0].high_season_price})
          dataForTable.push(item)
          this.props.setUpgradeCarPrice(data[0].low_season_price, data[0].high_season_price)
        } catch (error) {
          console.error('Error fetching data:', error);
        }
        this.props.setNetCostCarPrice(dataForTable)
      this.setState({data:dataForTable})
    }
  };

  render() {
    return (
      <div>
        <h2>Upgraded Car</h2>
        <table className="basic-transportation-table">
          <thead>
            <tr>
              <th></th>
              <th colSpan="4">Low Season</th>
              <th colSpan="4">Peak Season</th>
            </tr>
            <tr>

              <th>Country</th>
              <th>1 in Car</th>
              <th>2 in Car</th>
              <th>3 in Car</th>
              <th>4 in Car</th>
              <th>1 in Car</th>
              <th>2 in Car</th>
              <th>3 in Car</th>
              <th>4 in Car</th>
            </tr>
          </thead>
          <tbody>
  
            {this.state.data.map((item, index) => (
              <tr key={index}>
                 <td>{this.props.countryName}</td>
                  <td>€{((item.low_season_price / Number(this.props.currency))* this.state.dayCount).toFixed(2)}</td>
                  <td>€{(((item.low_season_price / 2)/ Number(this.props.currency))* this.state.dayCount).toFixed(2)}</td>
                  <td>€{(((item.low_season_price / 3)/ Number(this.props.currency))* this.state.dayCount).toFixed(2)}</td>
                  <td>€{(((item.low_season_price / 4)/ Number(this.props.currency))* this.state.dayCount).toFixed(2)}</td>
                  <td>€{((item.high_season_price / Number(this.props.currency))* this.state.dayCount).toFixed(2)}</td>
                  <td>€{(((item.high_season_price / 2) / Number(this.props.currency)) * this.state.dayCount).toFixed(2)}</td>
                  <td>€{(((item.high_season_price / 3) / Number(this.props.currency))* this.state.dayCount).toFixed(2)}</td>
                  <td>€{(((item.high_season_price / 4) / Number(this.props.currency))* this.state.dayCount).toFixed(2)}</td>
              </tr>
            ))}

                <tr>
                 <td>Total Trip</td>
                  <td>€{((this.state.lowPrice * this.state.dayCount) / Number(this.props.currency)).toFixed(2)}</td>
                  <td>€{((this.state.lowPrice * this.state.dayCount) / Number(this.props.currency)).toFixed(2)}</td>
                  <td>€{((this.state.lowPrice * this.state.dayCount) / Number(this.props.currency)).toFixed(2)}</td>
                  <td>€{((this.state.lowPrice * this.state.dayCount) / Number(this.props.currency)).toFixed(2)}</td>
                  <td>€{((this.state.highPrice * this.state.dayCount) / Number(this.props.currency)).toFixed(2)}</td>
                  <td>€{((this.state.highPrice * this.state.dayCount) / Number(this.props.currency)).toFixed(2)}</td>
                  <td>€{((this.state.highPrice * this.state.dayCount) / Number(this.props.currency)).toFixed(2)}</td>
                  <td>€{((this.state.highPrice * this.state.dayCount) / Number(this.props.currency)).toFixed(2)}</td>
              </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default UpgradedTransportation;
