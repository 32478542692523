import React, { Component } from 'react';

class LoadingSpinner extends Component {
  render() {
    return (
      <div className="loading">
        <div className="loading__spinner"></div>
      </div>
    );
  }
}

export default LoadingSpinner;