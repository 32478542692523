import React, { Component } from 'react';
import { handleCallApi } from '../calls';
import { ApiData } from '../config';
import { Grid, Chip, Stack, Link } from '@mui/material';
import styled from 'styled-components';

class RelatedTrips extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trips: [],
      loading: false,
    };
  }

  async loadRelatedTrips(dayId) {
    const url = ApiData.baseURL + '/trips/related-trips';
    const body = { dayId };
    try {
      this.setState({ loading: true });
      const data = await handleCallApi('POST', url, body);
      this.setState({ trips: data, loading: false });
      this.props.handlRelatedTrips(data);
    } catch (error) {
      console.error(error);
      this.setState({ loading: false });
    }
  }

  async componentDidMount() {
    const { selectedDay } = this.props;
    if (selectedDay) {
      this.setState({ loading: true });
      const dayId = typeof selectedDay === 'string' ? selectedDay : +selectedDay;
      const url = ApiData.baseURL + `/trips/related-trips`;
      const body = { dayId };
      const data = await handleCallApi('POST', url, body);
      this.setState({ trips: data, loading: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedDay !== prevProps.selectedDay) {
      if (typeof this.props.selectedDay === 'number') {
        this.loadRelatedTrips(this.props.selectedDay);
      }
    }
  }

  render() {
    const domain = window.location.protocol + '//' + window.location.host;
    const { selectedDay, loadingRelated, relatedDays } = this.props;
    const { trips, loading } = this.state;

    return (
      <>
        {selectedDay && (
          <>
            <h2>Related Trips</h2>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6}>
                <Stack direction="row" spacing={1}>
                  {loading ? (
                    <Chip label="Loading..." color="primary" />
                  ) : trips && trips.length > 0 ? (
                    <ContainerTrips>
                      {trips.map((item) => (
                        <Link key={item.id} to={`${domain}?page=Days&id=${item.id}`}>
                          <Chip key={item.id} label={`${item.id} - ${item.internal_name}`} color="primary" />
                        </Link>
                      ))}
                    </ContainerTrips>
                  ) : (
                    'No trips related'
                  )}
                </Stack>
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  }
}

const ContainerTrips = styled.div`
  width: 100%;
  gap: 1rem;
  display: grid;
  grid-template-columns: repeat(3, auto);
`;

export default RelatedTrips;
