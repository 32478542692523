'use strict';

export const EmailData = {
    emailContent1: `<!DOCTYPE html>
    <html lang="en">
      <head>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
        <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        
        <title>PlanYourTripTemplate</title>
    
        <link href='https://fonts.googleapis.com/css?family=Poppins' rel='stylesheet'>
        <link href='https://fonts.googleapis.com/css?family=Inter' rel='stylesheet'>
        <style>
        body {
            font-family: 'Poppins' !important;
        }
        </style>
     <style type="text/css">
     body {
      margin: 0;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    #root {
      overflow-x: hidden;
      height: fit-content;
      font-family: "Poppins";
    }
    body {
      overflow-x: hidden;
    }
    </style>
    <style type="text/css">
    .daysNewPriceDescription > li{
      font-family: 'Inter' !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      color: #000000;
      line-height: 29px;
    }
    .daysNewPriceDescription > li > span{
      font-family: 'Inter' !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      color: #000000;
      line-height: 29px;
    }
    .daysNewPriceDescription > li > span > span{
      font-family: 'Inter' !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      color: #000000;
      line-height: 29px;
    }
    .daysNewPriceDescription > li > ul{
      font-family: 'Inter' !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      color: #000000;
      line-height: 29px;
    }
    .daysNewPriceDescription > li > ul >li{
      font-family: 'Inter' !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      color: #000000;
      line-height: 29px;
    }
    .daysNewPriceDescription > li > ul > li > span {
      font-family: 'Inter' !important;
      font-weight: 400 !important;
      font-size: 14px !important;
      color: #000000;
      line-height: 29px;
    }</style>
    
    </head>
      <body style="background: #F0F0F0;">
    <div id="root" 
    style="width:100%;background-image:url('https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/email_confirm_orange_vector.png'),url('https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/email_confirm_orange_vector.png'),url('https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/email_confirm_blue_vector.png'),url('https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/email_confirm_blue_vector1.png');background-position:left 527px,left 1220px,right 180px,right 1258px;width:100%;background-size:273px 812px,509px 700px,289px 869px,338px 667px;background-repeat:no-repeat;font-family:'Poppins';">
                <div style="text-align:-webkit-right;margin-right:50px;font-size:12px;font-weight:400;margin-top:30px;">
                    Reference: <br>`,

    emailContent2: `</div>`,


    emailContent3: `
    <div style="text-align:center;width:80%;margin:1rem auto 1rem;">
             <div style="font-size:23px;font-weight:600;font-family:Poppins;color:#613EEA;margin-bottom:10px;"> Hi `,
    emailContent4: `, </div>`,



    emailContent5: `<p style="font-size:22px;font-weight:400;font-family:Poppins;color:#613EEA;width:90%;text-align:center;margin:0 auto;"> 
    This is your personalized `,

    emailContent6: `</p>`,






    emailContent7: `<div style="text-align:-webkit-center;margin-top:30px;">
    <a href="`,

    emailContent8: `" style="text-decoration: none;">
    <div style="background:#613eea;color:#fff;text-align:center;padding:20px 0px;font-size:17px;font-weight:600;width:262px;border-radius:52px;cursor:pointer;">Proceed to Payment</div>
    </div></a>
   </div>`,




    emailContent9: `
    <div style="width:80%;margin-top:2rem;height:100%;display:inherit;-webkit-flex-direction:column;-ms-flex-direction:column;flex-direction:column;justify-content:center;background:white;margin: 0 auto;">
    <div style="display:flex;justify-content:center;align-items:center;margin:0 auto;width:100%;background-image:url('https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/emailConfirmationCountryBackGround.png');background-size:100% 100%;width:100%;">
      <img style="width:305px;height:136px;border-radius:8px;z-index:200;margin:0 auto;object-fit:cover;z-index:1;" src="`,
    emailContent10: `" alt="">
  </div>   `,



    emailContent11: `
    <div style="margin-top:20px;padding:40px 40px 40px 40px">
       `,



    emailContent12: `</div>
    </div>
`,













    emailContent13: `<div style="width:80%;margin:0 auto;padding-top:56px;"> 
    <div style="font-family:Poppins;font-size:28px;font-weight:600;color:#613eea;margin-top:20px;margin-bottom:20px;margin-left:10px;">Price per person: €`,

    emailContent14: `<div>
  </div>
  <div style="width:100%;font-style:normal;margin:0 auto;margin-bottom:30px;">
  <div class="daysNewPriceDescription" style="color: #000000;font-weight: 500;font-size: 16px;">
   `,


    emailContent15: `</div></div>
    <div>
    </div>`,


    emailContent16: `
    <div style="width:90%;border-top:3px solid #613EEA;margin-top:30px;margin-bottom:20px;"></div>
    </div>
    <div style="width:100%;font-style:normal;margin:0 auto;margin-bottom:30px;">
    <div class="daysNewPriceDescription">
      <div style="font-size:16px;">Price includes:</div>`,

                emailContent17: `</div>`,



                emailContent18: `<div class="daysNewPriceDescription">
                <div style="font-size:16px;">Excluded</div>`,


                emailContent19: `
               
              </div>
            </div>`,


            emailContent20: ` <div style="width:80%;margin:0 auto;margin-bottom:50px;">
            <a href="`,
                
                emailContent21: `" style="text-decoration: none;"> 
                <div style="background:#613eea;color:#fff;text-align:center;padding:20px 0px;font-size:17px;font-weight:600;width:262px;border-radius:52px;cursor:pointer;margin:0 auto;">Proceed to Payment</div>
                 </div>
                 </a>
              </div>
            </div>
            </div>
            </body></html>
  `,

    dayContent1: `<div style="font-size:17px;font-weight:700;color:#613EEA;line-height:25px;">Day `,
    dayContent2: `</div>`,
    dayContent3: ` <div style="font-size:17px;font-weight:700;color:#000000;line-height:30px;">`,
    dayContent4: `</div>`,
    dayContent5: `<p style="font-size:17px;font-weight:400;color:#000000;line-height:25px;word-break:break-word;">`,
    dayContent6: `</p>`,
    dayContent7: `<ul style="font-size:17px;font-weight:400;color:#000000;line-height:25px;word-break:break-word;">`,
    dayContent8: ` </ul>`,
    dayContent9: ``,

};
