import React, { Component } from 'react';
import { ApiData } from "./config";
import { FormControl, Autocomplete, Box, Typography, TextField, Select, MenuItem, Container } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link } from '@mui/material';
import { handleCallApi, getDatafromApi } from './calls'
import { ToastContainer, toast } from 'react-toastify';
import LoadingSpinner from './_layout/spinner';
import 'react-toastify/dist/ReactToastify.css';
import { EmailData } from "./emailTemplate";


const BUTTON_OPTIONS = {
	SELF_GUIDED_PACKAGE: 'SelfGuidedPackage',
	FREE_CHOICE: 'FreeChoice'
}

const CURRENCIES = {
	USD: 'USD',
	EUR: 'EUR',
	GBP: 'GBP',
	ILS: 'ILS'
};

class PayByLink extends Component {
	headers = {
		"Content-Type": "application/json",
		Authorization: "Bearer " + localStorage.getItem("token"),
	}

	cancelToken = null;
	constructor(props) {
		super(props);
		this.handleSearchTermChange = this.handleSearchTermChange.bind(this);
		this.handleButtonChange = this.handleButtonChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
		this.handleEmailSelect = this.handleEmailSelect.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);

		this.payByLink = this.payByLink.bind(this);

		this.state = {
			price: 0,
			searchTerm: '',
			emails: [],
			packages: [],
			currencies: Object.keys(CURRENCIES),
			loading: false,
			selectedOption: '',
			selectedValue: '',
			selectedCurrency: 'EUR',
			selectedEmail: '',
			previewURL: undefined,
			userFirstName: '',
			userLastName: '',
		};

	};


	handleSelectChange(event) {
		this.setState({ selectedValue: event.target.value });
	}

	handleCurrencyChange(event) {
		this.setState({ selectedCurrency: event.target.value });
	}

	handleEmailSelect(event) {
		this.setState({ selectedEmail: event.target.value });
	}

	handleButtonChange(event) {
		let apiURL;
		const selectedOption = event.target.value;
		this.setState({ selectedOption });

		switch (selectedOption) {
			case BUTTON_OPTIONS.SELF_GUIDED_PACKAGE:
				this.setState({ loading: true });
				apiURL = `${ApiData.baseURL}/self-guided-packages/detailed?take=3000`;
				break;
			case BUTTON_OPTIONS.FREE_CHOICE:
				console.log('Freechoice called');
				return false;
		}

		fetch(apiURL, { headers: this.headers })
			.then(response => response.json())
			.then(data => this.setState({ packages: data.results, loading: false }))
	}

	handleInputChange(event) {
		this.setState({ price: +event.target.value });
	}

	handleSearchTermChange(event) {
		const searchTerm = event.target.value;
		this.setState({ searchTerm });
		if (searchTerm === '') {
			return;
		}

		if (this.controller) {
			this.controller.abort();
		}
		this.controller = new AbortController();

		fetch(`${ApiData.baseURL}/user/emails?q=${searchTerm}`, {
			signal: this.controller.signal,
			headers: this.headers
		})
			.then(response => response.json())
			.then(data => this.setState({ emails: data }))
			.catch(error => {
				if (error.name !== 'AbortError') {
					console.error(error);
				}
			});
	};

	async payByLink() {
		const { selectedCurrency, selectedValue, selectedOption, selectedEmail, price, userFirstName, userLastName } = this.state;
		this.setState({ loading: true, previewURL: undefined });
		const apiURL = `${ApiData.baseURL}/payment-links`;
		const _package = this.state.packages.find(item => item.id === selectedValue);
		const payload = {
			email: selectedEmail,
			...(selectedOption === BUTTON_OPTIONS.SELF_GUIDED_PACKAGE && { entityId: selectedValue }),
			...(selectedOption === BUTTON_OPTIONS.FREE_CHOICE && { price }),
			type: selectedOption,
			userFirstName: userFirstName,
			currency: selectedCurrency,
			metadata: selectedOption === BUTTON_OPTIONS.SELF_GUIDED_PACKAGE ? _package.trip.internal_name : 'FREE_CHOICE',
			reference: selectedOption === BUTTON_OPTIONS.SELF_GUIDED_PACKAGE ? _package.trip.internal_name + "-" + userFirstName + "-" + userLastName : 'FREE_CHOICE'
		};

		const SUCCESS_MSG = `Payment Link successfully sent to ${selectedEmail}`;
		handleCallApi('POST', apiURL, JSON.stringify(payload), SUCCESS_MSG, 'Error')
			.then(result => {
				if (BUTTON_OPTIONS.SELF_GUIDED_PACKAGE)
					this.sendMailToUser(selectedEmail, selectedValue, result.url, _package, userFirstName, userLastName);
				this.setState({
					loading: false,
					previewURL: result.url,
				});
			})
			.catch(err => {
				this.setState({
					loading: false
				});
			})
		/* this.sendMailToUser(selectedEmail,selectedValue,"https://planyourtrip.com/",_package,userFirstName,userLastName) */
	}

	async sendMailToUser(selectedEmail, selectedValue, paymentURL, _package, userFirstName, userLastName) {

		if (this.state.selectedOption === BUTTON_OPTIONS.SELF_GUIDED_PACKAGE) {
			let selectedPackages = this.state.packages.filter(
				sgp => sgp.id == selectedValue
			);
			let sgpTripId = selectedPackages[0].trip_id;
			let apiURL = `${ApiData.baseURL}/vg/trips/` + sgpTripId;
			getDatafromApi(apiURL)
				.then(trip => {
					let htmlContentforMail = EmailData.emailContent1 + _package.trip.internal_name + "-" + userFirstName + "-" + userLastName + EmailData.emailContent2;

					htmlContentforMail += EmailData.emailContent3 + userFirstName + EmailData.emailContent4;
					htmlContentforMail += EmailData.emailContent5 + trip.days_count + "-days trip to " + trip.country + EmailData.emailContent6;
					htmlContentforMail += EmailData.emailContent7 + paymentURL + EmailData.emailContent8;
					htmlContentforMail += EmailData.emailContent9 + _package.photo + EmailData.emailContent10;
					htmlContentforMail += EmailData.emailContent11;

					for (let i = 0; i < trip.days.length; i++) {
						let daycount = i + 1;
						htmlContentforMail += EmailData.dayContent1 + daycount + EmailData.dayContent2;
						htmlContentforMail += EmailData.dayContent3 + trip.days[i].name + EmailData.dayContent4;
						htmlContentforMail += EmailData.dayContent5 + trip.days[i].description + EmailData.dayContent6;
						htmlContentforMail += EmailData.dayContent7;

						for (let j = 0; j < trip.days[i].web_poi_set.length; j++) {
							htmlContentforMail += ` <li>` + trip.days[i].web_poi_set[j].name + `</li>`
						}
						htmlContentforMail += EmailData.dayContent8;
						htmlContentforMail += EmailData.dayContent9;
					}
					htmlContentforMail += EmailData.emailContent12;
					htmlContentforMail += EmailData.emailContent13 + _package.price + EmailData.emailContent14;

					htmlContentforMail += _package.price_description;
					htmlContentforMail += EmailData.emailContent15;

					htmlContentforMail += EmailData.emailContent16 + _package.price_includes + EmailData.emailContent17;
					htmlContentforMail += EmailData.emailContent18 + _package.excluded + EmailData.emailContent19;

					htmlContentforMail += EmailData.emailContent20 + paymentURL + EmailData.emailContent21;

					let currencyLogo = ''
					if (this.state.selectedCurrency === 'USD') currencyLogo = '$';
					if (this.state.selectedCurrency === 'GBP') currencyLogo = '£';
					if (this.state.selectedCurrency === 'ILS') currencyLogo = '₪';
					if (this.state.selectedCurrency === 'EUR') currencyLogo = '€';
			
					let payload = {
						"email": selectedEmail,
						"subject": "Pay by link",
						// "html": htmlContentforMail,
						"option": this.state.selectedOption === BUTTON_OPTIONS.SELF_GUIDED_PACKAGE ? 'self guide package' : 'free choise',
						"link_payment": this.state.previewURL,
						"firstName": userFirstName,
						"price": this.state.price,
						'currencyForEmail': currencyLogo,
						"package":  this.state.selectedOption === BUTTON_OPTIONS.SELF_GUIDED_PACKAGE ? _package : null,
						"reference": this.state.selectedOption === BUTTON_OPTIONS.SELF_GUIDED_PACKAGE ? _package.trip.internal_name + "-" + userFirstName + "-" + userLastName : 'FREE_CHOICE',
					};

					let apiURL1 = `${ApiData.baseURL}/web/send-email-paybylink`;

					handleCallApi('POST', apiURL1, JSON.stringify(payload), '', 'Error')
						.then(result => {
							console.log('result send Mail', result)
							this.setState({
								loading: false,
								previewURL: paymentURL,
							});
					})

					/* this.setState({
						loading: false,
						previewURL: htmlContentforMail,
					}); */
				})
		} else {
			console.log('Not email (no package selected)');
		}
	}

	/**
	 * Aborting when component id destroyed
	 */
	componentWillUnmount() {
		if (this.controller) {
			this.controller.abort();
		}
	}

	render() {
		const { loading, emails, packages, selectedOption, previewURL, selectedValue, price, selectedEmail, userFirstName, userLastName } = this.state;
		return (
			<>
				{loading ? <LoadingSpinner></LoadingSpinner> : null}
				<Container maxWidth="sm" style={{ 'margin-top': 100 }}>
					<h2>Pay By Link</h2>
					<TableContainer>
						<Table>
							<TableBody>
								<TableRow>
									<TableCell>First Name</TableCell>
									<TableCell>
										<TextField type='text' label="First name" value={this.state.userFirstName} sx={{ width: 400 }} inputProps={{ style: { minHeight: 38 } }} onChange={(event) => this.setState({ userFirstName: event.target.value })} />
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Last Name</TableCell>
									<TableCell>
										<TextField type='text' label="Last name" value={this.state.userLastName} sx={{ width: 400 }} inputProps={{ style: { minHeight: 38 } }} onChange={(event) => this.setState({ userLastName: event.target.value })} />
									</TableCell>
								</TableRow>

								<TableRow>
									<TableCell>Search Email</TableCell>
									<TableCell>
										<Autocomplete
											freeSolo
											options={emails}
											renderInput={(params) => (
												<TextField {...params} label="Please type email" onChange={this.handleSearchTermChange} onSelect={this.handleEmailSelect} value={this.state.searchTerm} sx={{ width: 400 }} />
											)}
										/>
									</TableCell>
								</TableRow>
								<TableRow>
									<TableCell>Type</TableCell>
									<TableCell>
										<FormControl component="fieldset">
											<label>
												<input
													type="radio"
													value={BUTTON_OPTIONS.SELF_GUIDED_PACKAGE}
													checked={selectedOption === BUTTON_OPTIONS.SELF_GUIDED_PACKAGE}
													onChange={this.handleButtonChange}
													style={{ width: 'auto' }}
												/>
												Self Guided Package
											</label>
											<label>
												<input
													type="radio"
													value={BUTTON_OPTIONS.FREE_CHOICE}
													checked={selectedOption === BUTTON_OPTIONS.FREE_CHOICE}
													onChange={this.handleButtonChange}
													style={{ width: 'auto' }}
												/>
												Free Choice
											</label>
										</FormControl>
									</TableCell>
								</TableRow>

								{selectedOption === BUTTON_OPTIONS.FREE_CHOICE ?
									<TableRow>
										<TableCell>Price</TableCell>
										<TableCell><TextField type="number" label="Please put price" value={this.state.price} sx={{ width: 400 }} onChange={this.handleInputChange} /></TableCell>
									</TableRow> : null}


								{selectedOption ?
									<TableRow>
										<TableCell>Currency</TableCell>
										<TableCell>
											<Select
												value={this.state.selectedCurrency}
												onChange={this.handleCurrencyChange}
												sx={{ width: 400 }}
											>
												{this.state.currencies.map((currency, index) => (
													<MenuItem key={currency} value={currency}>
														{currency}
													</MenuItem>
												))}
											</Select>
										</TableCell>
									</TableRow> : null}

								{selectedOption && selectedOption !== BUTTON_OPTIONS.FREE_CHOICE ?
									<TableRow>
										<TableCell>Choose package</TableCell>
										<TableCell>
											<Select
												value={this.state.selectedValue}
												onChange={this.handleSelectChange}
												sx={{ width: 400 }}
											>
												{packages.map((_package, index) => (
													<MenuItem key={_package.id} value= {_package.id}>
														{_package.trip?.internal_name}
													</MenuItem>
												))}
											</Select></TableCell>
									</TableRow> : null}

								{((selectedValue || selectedOption === BUTTON_OPTIONS.FREE_CHOICE) && selectedEmail != "" && userFirstName != "" && userLastName != "") ?
									<TableRow>
										<TableCell colSpan={2}>
											<Box sx={{ display: 'flex', alignItems: 'center' }}>
												<button onClick={this.payByLink}>Send Payment Link</button>
											</Box>
										</TableCell>
									</TableRow> : null}
							</TableBody>
						</Table>
					</TableContainer>
					<div>
						{previewURL ? (<p>Preview URL: <a href={previewURL} target="_blank">{previewURL}</a></p>) : null}
						<div style={{ height: '500px' }}></div>
						<ToastContainer />
					</div>
				</Container>
			</>
		);
	}
}

export default PayByLink;