import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import _uniqueId from 'lodash/uniqueId';
import { ApiData, MapboxKey } from "./config"
import { handleCallApi } from './calls'

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      username: '',
      password: '',
    };
  }

  componentDidMount() {
    console.log(process.env);
    console.log(MapboxKey);
    console.log(ApiData);
  }

  handleLoginValidation() {
    let errors = {};
    let formIsValid = true;

    if (!this.state.username) {
      formIsValid = false;
      errors['username'] = '*';
    }
    if (!this.state.password) {
      formIsValid = false;
      errors['password'] = 'Password is Required';
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  Login = async (e) => {
    e.preventDefault();
    toast.dismiss();
    if (this.handleLoginValidation()) {
      let apidata = {
        "email": this.state.username,
        "password": this.state.password,
      }

      let loginURL = ApiData.baseURL + `/auth/login`;
      let data = await handleCallApi('POST', loginURL, JSON.stringify(apidata), '', '')
      if (data) {
        if (data.token) {
          localStorage.setItem('token', data.token.accessToken);
          localStorage.setItem('userData', JSON.stringify(data.user));
          this.setState({
            username: '',
            password: ''
          });
          window.location = "?page=Stops/POIs";
        }
        else if (data?.error) {
          toast.error(data.message);
        }
      }
    }
    else {
      toast.error('Missing Information / Fields');
    }
  }

  render() {
    return (
      <div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
        <ToastContainer />
        <table cellPadding="10" className="viewdata">
          <tbody>
            <tr>
              <td colSpan="4">
                <div className="model_html_login">
                  <div>
                    <img src="https://planmytrip-assets.s3.amazonaws.com/assets/resources/images/EX_logo.png" alt="" />
                  </div>
                  <table cellPadding="8">
                    <tbody>
                      <tr>
                        <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                          <label>Username</label><br />
                          <TextField
                            id="txtSNTitle"
                            size="small"
                            style={{ width: '300px' }}
                            variant="outlined"
                            value={this.state.username}
                            error={!!this.state.errors['username']}
                            onChange={(event) =>
                              this.setState({ username: event.target.value })
                            }
                          />
                        </td>
                      </tr>

                      <tr>
                        <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                          <label>Password</label><br />
                          <TextField
                            id="txtSNTitle"
                            size="small"
                            type="password"
                            variant="outlined"
                            style={{ width: '300px' }}
                            value={this.state.password}
                            error={!!this.state.errors['password']}
                            onChange={(event) =>
                              this.setState({ password: event.target.value })
                            }
                          /><br />
                          {/*   <span className="errorMsg">{this.state.errors['password']}</span> */}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="4">
                          <Button
                            id="btnSaveSN"
                            variant="contained"
                            color="primary"
                            onClick={this.Login.bind(this)}
                          >
                            Login
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <div className="borderDiv" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default Login;


