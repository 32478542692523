import jsPDF from 'jspdf';
import removeAccents from 'remove-accents';

const OVERNIGHT_NEW_PAGE = true;

const pdfConstants = {
  LEFT_MARGIN: 40,
  RIGHT_MARGIN: 40,
  TOP_MARGIN: 40,
  TITLE_MARGIN: 20,
  LINE_SPACING: 5,
  BOTTOM_MARGIN: 40,
  SECTION_MARGIN: 10,
};

const LOGO_URL = 'https://planmytrip-assets.s3.eu-central-1.amazonaws.com/PlanYourTrip_%20logo%201.png';

export class PdfGenerator {
  pdfDocument;
  parameters;
  currentPositionY;
  sectionImages = [];
  dayImageUrls = [];
  dayConvertedImages = [];
  convertedImages = [];
  checkImage;

  constructor(data) {
    this.parameters = data;
    this.dayImageUrls = this.parameters.days.map(day => day.photo);
    this.sectionImages = [
      this.parameters.package_img,
      'https://upload-111120.s3.amazonaws.com/images/NavigationalTourGuide.jpg',
      this.parameters.local_hero_img,
    ];


    /*     this.pdfDocument = new jsPDF({
          orientation: 'portrait',
          unit: 'pt',
          format: 'letter'
        }); */

    const pageWidth = 11 * 72;
    const pageHeight = 8.5 * 72;

    this.pdfDocument = new jsPDF({
      orientation: 'l',
      unit: 'pt',
      format: [900, 450]
    });

  }

  async initializePdf() {
    await this.convertImages();
    const checkImageUrl = 'https://upload-111120.s3.amazonaws.com/images/Screenshot+from+2024-04-02+17-28-27.jpeg';
    this.checkImage = await this.convertImageToBase64(checkImageUrl);
    this.pdfDocument.addImage(LOGO_URL, 'PNG', pdfConstants.LEFT_MARGIN, 20, 150, 30);
    this.currentPositionY = 20 + 50 + pdfConstants.TITLE_MARGIN;

    this.addText(`Hi, ${this.parameters.username}!`, 16, 'bold');
    const largoTexto = `This is your personalized ${this.parameters.days_count}-days trip to ${this.parameters.country}. Your trip includes:`;
    this.addText(largoTexto, 14);
    const pageWidth = this.pdfDocument.internal.pageSize.getWidth();
    const rightAlignMargin = pdfConstants.RIGHT_MARGIN;
    const textHeight = 20;
    this.pdfDocument.setFont(this.pdfDocument.getFont().fontName, 'bold');
    this.pdfDocument.text('Reference', pageWidth - rightAlignMargin, textHeight + 15, { align: 'right' });
    this.pdfDocument.setFont(this.pdfDocument.getFont().fontName, 'normal');

    this.pdfDocument.text(this.parameters.reference, pageWidth - rightAlignMargin, textHeight + 27, { align: 'right' });

    this.drawSections();
    this.createDetails();
    this.finalDetail();
    //this.pdfDocument.save(`${this.parameters.reference.replace(/ /g, "-")}_planyourtrip.pdf`);
  }

  getPdf(blob = false) {
    if (blob) {
      return this.pdfDocument.output('blob');
    }
    return this.pdfDocument;
  }

  addHtmlToPdf(html) {
    const baseLineSpacing = 10;
    const extraSpacingAfterParagraph = 14;
    const listItemSpacing = 15;

    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const sections = doc.querySelectorAll('div');
    sections.forEach(section => {
      section.childNodes.forEach(node => {
        // Verificar el espacio restante antes de añadir nuevo contenido.
        const pageHeight = this.pdfDocument.internal.pageSize.getHeight();
        if (this.currentPositionY + baseLineSpacing + pdfConstants.BOTTOM_MARGIN > pageHeight) {
          this.pdfDocument.addPage();
          this.currentPositionY = pdfConstants.TOP_MARGIN; // Restablece a margen superior para nueva página.
        }

        if (node.nodeType === Node.ELEMENT_NODE && node.tagName === 'STRONG') {
          this.pdfDocument.setFontSize(14);
          this.pdfDocument.setFont(undefined, 'bold');
          this.pdfDocument.text(node.textContent, pdfConstants.LEFT_MARGIN, this.currentPositionY);
          this.currentPositionY += extraSpacingAfterParagraph; // Espacio después del título.
        } else if (node.nodeType === Node.TEXT_NODE) {
          const textLines = node.textContent.trim().split('\n');
          textLines.forEach((line, index) => {
            if (line.trim().length > 0) { // Asegura que la línea no esté vacía.
              this.pdfDocument.setFontSize(14);
              this.pdfDocument.setFont(undefined, 'normal');
              this.pdfDocument.text(line.trim(), pdfConstants.LEFT_MARGIN, this.currentPositionY);
              this.currentPositionY += baseLineSpacing + 6;
            }
          });
        } else if (node.tagName === 'UL') {
          const items = node.querySelectorAll('li');
          items.forEach(item => {
            this.currentPositionY += listItemSpacing; // Espacio antes de cada elemento de la lista.
            this.pdfDocument.setFontSize(14);
            this.pdfDocument.setFont(undefined, 'normal');
            this.pdfDocument.text(`• ${item.textContent}`, pdfConstants.LEFT_MARGIN, this.currentPositionY);
          });
          this.currentPositionY += baseLineSpacing + 12; // Espacio después del último elemento de la lista.
        }
      });
    });
    // Ajuste importante: Asegurar que haya un espacio adicional después de procesar el HTML
    this.currentPositionY += extraSpacingAfterParagraph;
  }

  convertImageToBase64(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.height = img.naturalHeight;
        canvas.width = img.naturalWidth;
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL('image/jpeg');
        resolve(dataURL);
      };
      img.onerror = () => reject(new Error('No se pudo cargar la imagen'));
      img.src = url;
    });
  }

  async convertImages() {
    this.convertedImages = await Promise.all(this.sectionImages.map(url => this.convertImageToBase64(url)));
    this.dayConvertedImages = await Promise.all(this.dayImageUrls.map(url => this.convertImageToBase64(url)));
  }

  addText(text, fontSize, fontStyle = 'normal', textColor = 'black') {
    text = this.sanitizeText(text);
    this.pdfDocument.setTextColor(textColor); // Establece el color del texto
    this.pdfDocument.setFont(this.pdfDocument.getFont().fontName, fontStyle);
    this.pdfDocument.setFontSize(fontSize);
    const anchoUtil = this.pdfDocument.internal.pageSize.getWidth() - pdfConstants.LEFT_MARGIN - pdfConstants.RIGHT_MARGIN;
    let lines = this.pdfDocument.splitTextToSize(text, anchoUtil);
    this.pdfDocument.text(lines, pdfConstants.LEFT_MARGIN, this.currentPositionY);
    this.currentPositionY += lines.length * (fontSize + pdfConstants.LINE_SPACING);

    if (fontStyle !== 'normal') {
      this.pdfDocument.setFont(this.pdfDocument.getFont().fontName, 'normal');
    }

    this.pdfDocument.setTextColor('black'); // Restablece el color del texto a negro (u otro color predeterminado)
  }

  finalDetail() {
    if (!OVERNIGHT_NEW_PAGE) {
      this.pdfDocument.addPage([900, 900]);
      this.addPostOvernight();
    } else {
      this.addPostOvernight();
      this.pdfDocument.addPage([900, 900]);
    }

    this.currentPositionY = 30;
    this.addText(`Price per person: ${this.parameters.currency}${this.parameters.price}`, 20, 'bold', 'purple');
    this.addHtmlToPdf(this.parameters.price_description);

    this.pdfDocument.setDrawColor(128, 0, 128); // Color morado
    this.pdfDocument.setLineWidth(2);
    this.pdfDocument.line(pdfConstants.LEFT_MARGIN, this.currentPositionY - 20, this.pdfDocument.internal.pageSize.getWidth() - pdfConstants.RIGHT_MARGIN, this.currentPositionY - 20);
    this.currentPositionY += 10;

    this.addHtmlToPdf(this.parameters.price_includes);

    this.addText('Price excludes', 16, 'bold');
    this.addHtmlToPdf(this.parameters.price_excludes);

    this.addText('PlanYourTrip – Cancellation Policy', 15, 'bold');
    this.addText('Up to 45 days before departure - Free of Charge (100% refundable)', 13, 'normal');
    this.addText('Up to 21 days before departure - 20% of the value of the tour (80% refundable)', 13, 'normal');
    this.addText('Up to 48 hours before departure - 50% of the value of the tour (50% refundable)', 13, 'normal');
    this.addText('Less than 48 hours before departure - 100% of the tour (nonrefundable any longer)', 13, 'normal');

    this.currentPositionY += 40;
    const buttonText = 'Proceed to Payment';
    const buttonLink = this.parameters.link_payment;
    const buttonFontSize = 16;
    this.pdfDocument.setFontSize(buttonFontSize);
    const horizontalPadding = 10;
    const verticalPadding = 10;
    const textWidth = this.pdfDocument.getTextWidth(buttonText);
    const buttonWidth = textWidth + horizontalPadding * 2;
    const buttonHeight = buttonFontSize + verticalPadding * 2;
    const buttonX = pdfConstants.LEFT_MARGIN; // Ajusta según la posición deseada
    const buttonY = this.currentPositionY - 40;
    const borderRadius = 5; // Radio de los bordes redondeados

    this.pdfDocument.setFillColor('#613eea'); // Color de relleno azul
    this.pdfDocument.roundedRect(buttonX, buttonY, buttonWidth, buttonHeight, borderRadius, borderRadius, 'F');

    this.pdfDocument.setTextColor(255, 255, 255); // Texto blanco
    this.pdfDocument.textWithLink(buttonText, buttonX + buttonWidth / 2, buttonY + 3 + verticalPadding + buttonFontSize / 2, { url: buttonLink, align: 'center' });
  }

  async createDetails() {
    this.currentPositionY = pdfConstants.TITLE_MARGIN;
    for (let index = 0; index < this.parameters.days.length; index++) {
      const item = this.parameters.days[index];
      const siteLinkHotel = item.siteLinkHotel || `https://www.google.com/search?q=${item.hotelName.replace(/ /g, "+")}`;

      // Add a new page for each day
      this.pdfDocument.addPage([900, 900]);
      const pageWidth = this.pdfDocument.internal.pageSize.getWidth();
      const pageHeight = this.pdfDocument.internal.pageSize.getHeight();

      // Draw the header for each day
      this.pdfDocument.setFillColor('#A7C8EC');
      const rectHeight = 35;
      this.pdfDocument.rect(0, 0, pageWidth, rectHeight, 'F');
      this.currentPositionY = 25;
      this.addText(`Day ${item.dayIndex}`, 14, 'bold', 'white');
      this.currentPositionY = 60;
      this.addText(item.name, 18, 'bold');
      this.addText(item.description, 16);

      // Adjust currentPositionY before adding list items
      this.currentPositionY -= 2;
      item.web_poi_set.forEach(poi => {
        this.addListItem(poi.name, 16);
      });

      this.currentPositionY += 10;

      // Check available space for the image
      const spaceLeft = pageHeight - this.currentPositionY - pdfConstants.BOTTOM_MARGIN;
      if (spaceLeft > 100) { // If there's enough space for the image
        // Display hotel information
        this.pdfDocument.setFont(this.pdfDocument.getFont().fontName, 'bold');
        this.pdfDocument.text(item.overnight, pdfConstants.LEFT_MARGIN, this.currentPositionY);
        const linkStartX = this.pdfDocument.getTextWidth(item.overnight) + 10;
        this.pdfDocument.setTextColor(0, 0, 255); // Set link color
        this.pdfDocument.textWithLink(`, ${item.hotelName}`, linkStartX + pdfConstants.LEFT_MARGIN - 7, this.currentPositionY, { url: siteLinkHotel });
        this.pdfDocument.setTextColor(0, 0, 0); // Reset text color
        this.currentPositionY += 20;

        // Calculate and adjust image size to 75%
        const originalImageHeight = spaceLeft;
        const originalImageWidth = pageWidth - pdfConstants.LEFT_MARGIN - pdfConstants.RIGHT_MARGIN;
        const imageHeight = originalImageHeight * 0.75;
        const imageWidth = originalImageWidth * 0.75;
        const adjustedX = pdfConstants.LEFT_MARGIN + (originalImageWidth - imageWidth) / 2;
        const adjustedY = this.currentPositionY - 10 + (originalImageHeight - imageHeight) / 2;

        // Add the resized and centered image
        this.pdfDocument.addImage(this.dayConvertedImages[index], 'JPEG', adjustedX, adjustedY, imageWidth, imageHeight);
      } else {
        // If not enough space, add a new page for the image
        this.pdfDocument.addPage();
        this.currentPositionY = pdfConstants.TOP_MARGIN;

        // Similar adjustment for the new page
        const imageHeight = (pageHeight - pdfConstants.TOP_MARGIN - pdfConstants.BOTTOM_MARGIN) * 0.75;
        const imageWidth = (pageWidth - pdfConstants.LEFT_MARGIN - pdfConstants.RIGHT_MARGIN) * 0.75;
        const newX = pdfConstants.LEFT_MARGIN + (pageWidth - pdfConstants.LEFT_MARGIN - pdfConstants.RIGHT_MARGIN - imageWidth) / 2;
        const newY = pdfConstants.TOP_MARGIN + (pageHeight - pdfConstants.TOP_MARGIN - pdfConstants.BOTTOM_MARGIN - imageHeight) / 2;

        // Add the resized and centered image on the new page
        this.pdfDocument.addImage(this.dayConvertedImages[index], 'JPEG', newX, newY, imageWidth, imageHeight);
      }

      // Reset currentPositionY for the next iteration
      this.currentPositionY = pdfConstants.TITLE_MARGIN;
    }
  }

  addListItem(text, fontSize) {
    text = this.sanitizeText(text);
    const [leftMargin, rightMargin] = [pdfConstants.LEFT_MARGIN + 30, pdfConstants.RIGHT_MARGIN + 30]
    const bullet = '• '; // Símbolo de bullet
    // Ajusta el texto añadiendo el bullet al inicio.
    const fullText = bullet + text + '.';
    // Calcula el número de líneas que el texto ocupará.
    const anchoUtil = this.pdfDocument.internal.pageSize.getWidth() - leftMargin - rightMargin;
    let lines = this.pdfDocument.splitTextToSize(fullText, anchoUtil);
    // Dibuja el texto en el documento y ajusta la posición Y basada en el número de líneas.
    this.pdfDocument.text(lines, leftMargin, this.currentPositionY);
    // Incrementa currentPositionY adecuadamente, aquí es donde ajustas el espacio entre los elementos.
    // Puedes añadir un valor fijo o usar un multiplicador del tamaño de la fuente para un espacio variable.
    const extraSpacing = fontSize * 0.5; // Ajusta este valor para cambiar el espacio entre los elementos de la lista.
    this.currentPositionY += lines.length * (fontSize + extraSpacing);
  }

  sanitizeText(text) {
    // Reemplazar caracteres no ASCII.
    let normalizedText = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    // Eliminar saltos de línea inesperados y slashes que no están seguidos por espacios
    // Esto asume que un slash seguido de una letra mayúscula es un error de formato.
    normalizedText = normalizedText.replace(/\/\n/g, ' ').replace(/\/([A-Z])/g, ' $1').replace(/°(?!\s)/g, '° ');

    // Reemplazar múltiples espacios con un solo espacio
    let singleSpacedText = normalizedText
      .replace(/\s+/g, ' ')
      .replace('ˢᵗ', 'st')
      .replace('ⁿᵈ', 'nd')
      .replace('ʳᵈ', 'rd')
      .replace('ᵗʰ', 'th');

    return removeAccents(singleSpacedText);
  }

  drawSections() {
    const pageWidth = this.pdfDocument.internal.pageSize.getWidth();
    const globalMargin = 20; // Margen general izquierdo y derecho para todo el conjunto de secciones
    const usableWidth = pageWidth - (globalMargin * 2) - (pdfConstants.SECTION_MARGIN * 2); // Ajusta el ancho usable

    const descriptions = [{
      title: `${this.parameters.days_count}-days Personalized Itinerary`,
      description: 'Once you planned your trip, your personalized itinerary is ready.'
    }, {
      title: 'Navigational Tour Guide',
      description: 'Navigate your entire itinerary on the go and receive relevant tips along your route.',
    }, {
      title: 'Local Hero on the Ground for You',
      description: 'Will be there for you advising, arranging, helping, suggesting.'
    }];

    let sectionX = globalMargin; // Inicio desde el margen global
    let sectionWidths = [
      (usableWidth * 0.5) - (pdfConstants.SECTION_MARGIN / 2),
      (usableWidth * 0.25),
      (usableWidth * 0.25),
    ];

    descriptions.forEach((desc, index) => {
      const imageHeight = 170; // Altura de la imagen
      const sectionHeight = 200; // Altura total de la sección

      // Dibuja el rectángulo de la sección, opcional si quieres visualizar el contenedor de la sección
      //this.pdfDocument.rect(sectionX, this.currentPositionY, sectionWidths[index], sectionHeight, 'S');

      if (this.convertedImages[index]) {
        this.pdfDocument.addImage(this.convertedImages[index], 'JPEG', sectionX, this.currentPositionY, sectionWidths[index], imageHeight);
      }

      let textPositionY = this.currentPositionY + imageHeight + pdfConstants.SECTION_MARGIN;
      this.pdfDocument.setFont(this.pdfDocument.getFont().fontName, 'bold');
      this.pdfDocument.text(desc.title, sectionX, textPositionY + 8, { maxWidth: sectionWidths[index] });

      textPositionY += pdfConstants.LINE_SPACING + (index !== 2 ? 25 : 40); // Espacio entre título y descripción

      this.pdfDocument.setFont(this.pdfDocument.getFont().fontName, 'normal');
      this.pdfDocument.text(desc.description, sectionX, textPositionY, { maxWidth: sectionWidths[index] });

      sectionX += sectionWidths[index] + pdfConstants.SECTION_MARGIN;
      if ((index + 1) % 3 === 0 || index + 1 === descriptions.length) { // Cada 3 secciones o al final de las descripciones
        sectionX = globalMargin; // Reset para una nueva fila con margen global
        this.currentPositionY += sectionHeight + pdfConstants.SECTION_MARGIN; // Mueve hacia abajo para la nueva fila de secciones
      }
    });
    this.currentPositionY += pdfConstants.SECTION_MARGIN;
  }

  addPreOvernight() {
    if (this.parameters.overnightPreCity === '') return;
    if (OVERNIGHT_NEW_PAGE) {
      this.pdfDocument.addPage([30, 900]);
      this.currentPositionY = 20;
      this.pdfDocument.text(`Pre Overnight: ${this.parameters.overnightPreCity}, `, 10, this.currentPositionY);
      const linkStartX = this.pdfDocument.getTextWidth(`Pre Overnight: ${this.parameters.overnightPreCity}, `) + 10; // Calcula el inicio del enlace
      this.pdfDocument.setTextColor(0, 0, 255);
      this.pdfDocument.textWithLink(this.parameters.overnightPreHotel, linkStartX, this.currentPositionY, { url: this.parameters.overnightPreHotelSiteLink });
      this.pdfDocument.setTextColor(0, 0, 0);
    } else {
      this.pdfDocument.text(`Pre Overnight: ${this.parameters.overnightPreCity}, `, 10, this.currentPositionY);
      const linkStartX = this.pdfDocument.getTextWidth(`Pre Overnight: ${this.parameters.overnightPreCity}, `) + 10; // Calcula el inicio del enlace
      this.pdfDocument.setTextColor(0, 0, 255);
      this.pdfDocument.textWithLink(this.parameters.overnightPreHotel, linkStartX, this.currentPositionY, { url: this.parameters.overnightPreHotelSiteLink });
      this.pdfDocument.setTextColor(0, 0, 0);
    }
  }

  addPostOvernight() {
    if (this.parameters.overnightPostHotel === '') return;
    if (OVERNIGHT_NEW_PAGE) {
      this.pdfDocument.addPage([30, 900]);
      this.currentPositionY = 20;
      this.pdfDocument.text(`Post Overnight: ${this.parameters.overnightPostNight}, `, 10, this.currentPositionY);
      const linkStartX = this.pdfDocument.getTextWidth(`Post Overnight: ${this.parameters.overnightPostNight}, `) + 10; // Calcula el inicio del enlace
      this.pdfDocument.setTextColor(0, 0, 255);
      this.pdfDocument.textWithLink(this.parameters.overnightPostHotel, linkStartX, this.currentPositionY, { url: this.parameters.overnightPostHotelSiteLink });
      this.pdfDocument.setTextColor(0, 0, 0);

    } else {
      this.currentPositionY = this.currentPositionY + 25;
      this.pdfDocument.text(`Post Overnight: ${this.parameters.overnightPostNight}, `, 10, this.currentPositionY);
      if (this.parameters.overnightPostHotel) {
        const linkStartX = this.pdfDocument.getTextWidth(`Post Overnight: ${this.parameters.overnightPostNight}, `) + 10; // Calcula el inicio del enlace
        this.pdfDocument.setTextColor(0, 0, 255);
        this.pdfDocument.textWithLink(this.parameters.overnightPostHotel, linkStartX, this.currentPositionY, { url: this.parameters.overnightPostHotelSiteLink });
        this.pdfDocument.setTextColor(0, 0, 0);
        this.currentPositionY = this.currentPositionY + 25;
      }

    }
  }

}
