import React, { Component } from 'react';
import { ApiData } from "./config";
import { handleCallApi } from './calls'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link } from '@mui/material';
import EmailIcon from '@material-ui/icons/Email';
import { Icon, Tooltip } from '@material-ui/core';
import LoadingSpinner from './_layout/spinner';

const PaymentStatus = {
	PENDING: 1,
	CANCELLED: 2,
	SUCCESS: 3,
	FAILURE: 4,
	PAYMENT_REFUNDED: 5
}

class PayByLinkList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			payLinks: [],
			loding: false
		};
	};

	async componentDidMount() {
		const apiURL = `${ApiData.baseURL}/payment-links`;
		try {
			let { results: payLinks } = await handleCallApi('GET', apiURL);
			this.setState({ payLinks });

			console.log(this.state);
		} catch (ex) {
			console.error(ex);
		}
	}

	async resendEmail(row) {
		this.setState({ loading: true });
		const apiURL = `${ApiData.baseURL}/payment-links/resend-email`;
		const payload = { paymentLinkId: row.id };
		try {
			let result = await handleCallApi('POST', apiURL, JSON.stringify(payload));
			setTimeout(() => {
				this.setState({ loading: false });
				toast.success('Email Sent');
			}, 1000);
			console.log(result);
		} catch (ex) {
			console.error(ex);
		}
	}

	render() {
		const { payLinks, loading } = this.state;
		return (
			<>
				{loading === true ? <LoadingSpinner></LoadingSpinner> : null}
				<TableContainer>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>ID</TableCell>
								<TableCell>Trip Name</TableCell>
								<TableCell>Price</TableCell>
								<TableCell>Type</TableCell>
								<TableCell>Status</TableCell>
								<TableCell>Created At</TableCell>
								<TableCell>URL</TableCell>
								<TableCell>User Email</TableCell>
								<TableCell>Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{payLinks.map((row) => (
								<TableRow key={row.id}>
									<TableCell>{row.id}</TableCell>
									<TableCell>{row.metadata}</TableCell>
									<TableCell>{row.price}</TableCell>
									<TableCell>{row.type}</TableCell>
									<TableCell>
										{
											row.status === PaymentStatus.SUCCESS ?
												<span className="success-badge">Success</span> :
												<span className="pending-badge">Pending</span>
										}
									</TableCell>
									<TableCell>{row.createdAt}</TableCell>
									<TableCell>
										{
											row.status === PaymentStatus.SUCCESS ?
												<span>Not available (Payment succeded)</span> :
												<Link href={row.url} target="_blank">{row.url}</Link>
										}

									</TableCell>
									<TableCell>{row.user?.email}</TableCell>
									<TableCell>
										{
											row.status === PaymentStatus.PENDING ?
												<Tooltip title="Resend payment url">
													<a href="#" onClick={() => this.resendEmail(row)}>
														<Icon>
															<EmailIcon />
														</Icon>
													</a>
												</Tooltip> :
												null
										}

									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				<ToastContainer />
			</>);
	}
}

export default PayByLinkList;