import React, { Component } from 'react';
import { handleCallApi, getDatafromApi } from '../calls'
import { ApiData, MapboxKey, countriesNames } from "../config"

class UpgradedAccommodation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: [],
      overnights: [],
      data: [],
      dataBasic: [],
      totalLow: 0,
      totalHigh: 0,
      totalLowBasic: 0,
      totalHighBasic: 0,
      lowtotalPre: 0,
      lowtotalPost: 0,
      hightotalPost: 0,
      hightotalPre: 0,
      preTripData: [],
      postTripData: [],
      currencyValue: Number(this.props.currency) || 1
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if(this.props.days !== prevState.days || this.state.overnights.length === 0){
      let overnightsArr = []
      this.props.days.forEach(day => {
        overnightsArr.push(day.overnight)
      });
      this.setState({days: this.props.days, overnights: overnightsArr, });
      this.getAllData()    
    }
  //   if (this.props.currency !== prevProps.currency){
  //     this.setState(this.props.currency);
  // } 
  }

  getAllData = async () => {
    const { overnights } = this.state;
    const dataForTable = [];
    const dataForTableBasic = [];
    const preTrip = [];
    const postTrip = [];
    // upgraded
    let lowtotalUpgraded = 0;
    let hightotalUpgraded = 0;
    let lowtotaldoubleUpgraded = 0;
    let hightotaldoubleUpgraded = 0;
    // basic
    let lowtotalBasic = 0;
    let hightotalBasic = 0;
    let lowtotaldoubleBasic = 0;
    let hightotaldoubleBasic = 0;
  // pre
    let lowtotalPre = 0;
    let hightotalPre = 0;
  // post
    let lowtotalPost = 0;
    let hightotalPost = 0;
  
    if (overnights) {
      for (const overnight of overnights) {
          if (overnight !== null) {
            let item;
            const data = await getDatafromApi(ApiData.baseURL + `/hotels-prices-list/city/${overnight}/false`);
            if (data && data.length > 0) {
              item = { overnight, high_season_price_single: data[0].high_season_price_single, low_season_price_single: data[0].low_season_price_single };
              lowtotalUpgraded = lowtotalUpgraded + data[0].low_season_price_single;
              hightotalUpgraded = hightotalUpgraded + data[0].high_season_price_single;
              lowtotaldoubleUpgraded = lowtotaldoubleUpgraded + data[0].low_season_price_double;
              hightotaldoubleUpgraded = hightotaldoubleUpgraded + data[0].high_season_price_double;
            } else {
              item = { overnight, high_season_price_single: 0, low_season_price_single: 0 };
            }
            dataForTable.push(item);
  
            let itemBasic;
            const dataBasic = await getDatafromApi(ApiData.baseURL + `/hotels-prices-list/city/${overnight}/true`);
            if (dataBasic.length > 0) {
              itemBasic = { overnight, high_season_price_single: dataBasic[0].high_season_price_single, low_season_price_single: dataBasic[0].low_season_price_single };
              lowtotalBasic = lowtotalBasic + dataBasic[0].low_season_price_single;
              hightotalBasic = hightotalBasic + dataBasic[0].high_season_price_single;
              lowtotaldoubleBasic = lowtotaldoubleBasic + dataBasic[0].low_season_price_double;
              hightotaldoubleBasic = hightotaldoubleBasic + dataBasic[0].high_season_price_double;
              console.log('each single low price', dataBasic[0].low_season_price_single)
            } else {
              itemBasic = { overnight, high_season_price_single: 0, low_season_price_single: 0 };
            }
            dataForTableBasic.push(itemBasic);
          }
        }
          if (this.props.overnight_pre_night) {
            let itemPre;
            const preData = await getDatafromApi(ApiData.baseURL + `/hotels-prices-list/city/${this.props.overnight_pre_night}/true`);
            if (preData.length > 0) {
              itemPre = { overnight: this.props.overnight_pre_night, high_season_price_double: preData[0].high_season_price_double, low_season_price_double: preData[0].low_season_price_double };
              lowtotalPre = lowtotalPre + preData[0].low_season_price_double;
              hightotalPre = hightotalPre + preData[0].high_season_price_double;
            } else {
              itemPre = { overnight: this.props.overnight_pre_night, high_season_price_double: 0, low_season_price_double: 0 };
          }
            preTrip.push(itemPre);
          }
  
          if (this.props.overnight_post_night) {
            let itemPost;
            const postData = await getDatafromApi(ApiData.baseURL + `/hotels-prices-list/city/${this.props.overnight_post_night}/true`);
            console.log('postData', postData);
            if (postData.length > 0) {
              itemPost = { overnight: this.props.overnight_post_night, high_season_price_double: postData[0].high_season_price_double, low_season_price_double: postData[0].low_season_price_double };
              lowtotalPost = lowtotalPost + postData[0].low_season_price_double;
              hightotalPost = hightotalPost + postData[0].high_season_price_double;
            } else {
              itemPost = { overnight: this.props.overnight_post_night, high_season_price_double: 0, low_season_price_double: 0 };
            }
            postTrip.push(itemPost);
          }
       
     
  
      const dataForNetPrices = [{
        single_room_basic_total_low_net_price: lowtotalBasic,
        single_room_basic_total_high_net_price: hightotalBasic,
        double_room_basic_total_low_net_price: lowtotaldoubleBasic,
        double_room_basic_total_high_net_price: hightotaldoubleBasic,
        single_room_upgraded_total_low_net_price: lowtotalUpgraded,
        single_room_upgraded_total_high_net_price: hightotalUpgraded,
        double_room_upgraded_total_low_net_price: lowtotaldoubleUpgraded,
        double_room_upgraded_total_high_net_price: hightotaldoubleUpgraded,
        pre_trip_low_net_price: lowtotalPre,
        pre_trip_high_net_price: hightotalPre,
        post_trip_low_net_price: lowtotalPost,
        post_trip_high_net_price: hightotalPost,
      }];
      
      console.log('lowtotalBasic', lowtotalBasic)
      console.log('this.props.currency', this.props.currency)
      console.log('lowtotaldoubleBasic', lowtotaldoubleBasic)
      console.log('hotelSingleSupplementLow', ((lowtotalBasic / Number(this.props.currency)) - (lowtotaldoubleBasic / Number(this.props.currency)) * 1.1).toFixed(2))
      this.props.setNetAccomodationPrices(dataForNetPrices);
  
      this.setState({
        data: dataForTable,
        dataBasic: dataForTableBasic,
        totalLow: lowtotalUpgraded,
        totalHigh: hightotalUpgraded,
        totalLowBasic: lowtotalBasic,
        totalHighBasic: hightotalBasic,
        preTripData: preTrip,
        postTripData: postTrip,
        lowtotalPre,
        hightotalPre,
        lowtotalPost,
        hightotalPost,
      });
    }
  };
  
  
  

  render() {
    return (
      <div>
        <h2>Upgraded Accommodation</h2>
        <table className="upgraded-accommodation-table">
          <thead>
            <tr>
              <th>O/N City</th>
              <th colSpan="2">Sgl Room</th>
              <th colSpan="2">Upgrade Hotel</th>
              <th colSpan="2">Pre-Trip O/N</th>
              <th colSpan="2">Post-Trip O/N</th>
            </tr>
            <tr>
              <th>-</th>
              <th>Low Season</th>
              <th>Peak Season</th>
              <th>Low Season</th>
              <th>Peak Season</th>
              <th>Low Season</th>
              <th>Peak Season</th>
              <th>Low Season</th>
              <th>Peak Season</th>
            </tr>
          </thead>
          <tbody>
          
            {this.state.data.map((item, index) => {
               const preTripDataItem = this.state.preTripData.find(preItem => preItem.overnight === item.overnight);
               const postTripDataItem = this.state.postTripData.find(post => post.overnight === item.overnight);
               console.log('preTripDataItem', preTripDataItem)
               console.log('postTripDataItem', postTripDataItem)
               return (
              <tr key={index}>
                <td>{this.state.data[index].overnight}</td>
                <td>€{((this.state.dataBasic.length ? this.state.dataBasic[index].low_season_price_single : 0) / Number(this.props.currency)).toFixed(2)}</td>
                <td>€{((this.state.dataBasic.length ? this.state.dataBasic[index].high_season_price_single : 0)/ Number(this.props.currency)).toFixed(2)}</td>
                <td>€{((this.state.data[index].low_season_price_single)/ Number(this.props.currency)).toFixed(2)}</td>
                <td>€{((this.state.data[index].high_season_price_single)/ Number(this.props.currency)).toFixed(2)}</td>
        
                <td>
                  {preTripDataItem ? `€${(preTripDataItem.low_season_price_double / Number(this.props.currency)).toFixed(2)}` : '-'}
                </td>
                <td>
                  {preTripDataItem ? `€${(preTripDataItem.high_season_price_double / Number(this.props.currency)).toFixed(2)}` : '-'}
                </td>

                 <td>
                  {postTripDataItem ? `€${(postTripDataItem.low_season_price_double / Number(this.props.currency)).toFixed(2)}` : '-'}
                </td>
                <td>
                  {postTripDataItem ? `€${(postTripDataItem.high_season_price_double / Number(this.props.currency)).toFixed(2)}` : '-'}
                </td>
                
              </tr>
               )
            })}
             <tr>
                <td>Total Trip</td>
                <td>€{((this.state.totalLowBasic)/ Number(this.props.currency)).toFixed(2)}</td>
                <td>€{((this.state.totalHighBasic)/ Number(this.props.currency)).toFixed(2)}</td>
                <td>€{((this.state.totalLow)/ Number(this.props.currency)).toFixed(2)}</td>
                <td>€{((this.state.totalHigh)/ Number(this.props.currency)).toFixed(2)}</td>
                <td>€{((this.state.lowtotalPre)/ Number(this.props.currency)).toFixed(2)}</td>
                <td>€{((this.state.hightotalPre)/ Number(this.props.currency)).toFixed(2)}</td>
                <td>€{((this.state.lowtotalPost)/ Number(this.props.currency)).toFixed(2)}</td>
                 <td>€{((this.state.hightotalPost)/ Number(this.props.currency)).toFixed(2)}</td>
             </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default UpgradedAccommodation;

