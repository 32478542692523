//import references
import { CircularProgress, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import $ from 'jquery';
import _uniqueId from 'lodash/uniqueId';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MUIDataTable from 'mui-datatables';
import React, { Component } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getDatafromApi, handleCallApi } from './calls';
import SelectDays from './components/SelectDays';
import { ApiData, MapboxKey, countriesNames } from "./config";
import DeleteIcon from './themes/icons/delete.png';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


let pointNumbers = [];
let selectedRowIndex = false;
let selectedRowIds = [];
let selectedDayData = [];
let gpxLoc;
let daysData;

class CustomerTrips extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      tripTableData: [['Loading Data...']],
      searchData: [],
      updateTripRowIndex: '',
      countryFilter: '',
      tripName: '',
      trip_photo: '',
      tripInternalName: '',
      createBy: '',
      country: '',
      region: '',
      tripType: 'normal',
      nature_level: '',
      culture_level: '',
      adventure_level: '',
      group_structure: '',
      save_type: 'add',
      openDeleteCnfrm: false,
      selectedDays: [{ "id": '', "name": "Loading...", "country": "" }],
      showSelectedDay: false,
      createTrip: false,
      editableTripId: '',
      loadingAddTrip: false,
      personalizedTrip: false,
      highLights: [""],
      ticketType: [""],
      overnight_pre_night: "",
      overnight_post_night: ""
    };
  }

  setDayTableData = async (data) => {
    this.setState({ dayTableData: data });
  };

  handleTripValidation() {
    let errors = {};
    let formIsValid = true;

    if (!this.state.tripInternalName) {
      formIsValid = false;
      errors['tripInternalName'] = '* Internal name is required';
    }
    if (!this.state.tripType) {
      formIsValid = false;
      errors['tripType'] = '* Trip type is required';
    }
    if (!this.state.tripName) {
      formIsValid = false;
      errors['tripName'] = '* Trip name is required';
    }

    // if (!this.state.group_structure) {
    //   formIsValid = false;
    //   errors['group_structure'] = 'Please Select Group Structured';
    // }

    if (!this.state.country) {
      formIsValid = false;
      errors['country'] = 'Please Select Country';
    }
    if (!this.state.adventure_level) {
      formIsValid = false;
      errors['AL'] = 'Please Select How Long';
    }
    if (!this.state.culture_level) {
      formIsValid = false;
      errors['CL'] = 'Please Select Culture';
    }
    if (!this.state.nature_level) {
      formIsValid = false;
      errors['NL'] = 'Please Select Nature';
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  async handleSaveTrip(e) {
    e.preventDefault();
    toast.dismiss();
    if (this.handleTripValidation()) {
      let tripData1 = [...this.state.tripTableData];
      var countryCode = this.state.country;
      var countryname = '';
      countriesNames.map(function (item) {
        if (item.code === countryCode)
          countryname = item.name;
      });

      let hl = [];
      let allhighlights = this.state.highLights;
      for (let j = 0; j < allhighlights.length; j++) {
        if (allhighlights[j] != "")
          hl.push(allhighlights[j])
      }

      let daysArray = [];
      this.state.selectedDays.forEach((day) => { daysArray.push(day.id); })
      let apidata = {
        adventure_level: this.state.adventure_level,
        country: countryname,
        created_by: this.state.createBy,
        culture_level: this.state.culture_level,
        group_structure: this.state.group_structure,
        name: this.state.tripName.trim(),
        nature_level: this.state.nature_level,
        photo: this.state.trip_photo,
        region: this.state.region,
        trip_length: this.state.selectedDays.length,
        days: daysArray,
        internal_name: this.state.tripInternalName,
        trip_type: this.state.tripType,
        how_long: this.state.adventure_level,
        highlights: hl,
        personalized: false,
        overnight_pre_night: this.state.overnight_pre_night,
        overnight_post_night: this.state.overnight_post_night,
        custom: true,
      }
      let method = 'POST';
      let url1 = ApiData.apiUrl + `/trips/`;
      let success = 'Trip Added';
      if (this.state.save_type != 'add') {
        method = 'PUT';
        url1 = ApiData.apiUrl + `/trips/${this.state.editableTripId}/`;
        success = 'Trip Updated';
      }
      let data = await handleCallApi(method, url1, JSON.stringify(apidata), success, '')
      if (data) {
        if (this.state.save_type === 'add') {
          if (this.state.searchData && this.state.searchData.length) {
            let searchData = [...this.state.searchData];
            if (this.state.countryFilter && this.state.countryFilter.length) {
              if (data.country === this.state.countryFilter) {
                searchData.push(data)
                this.setState({ searchData })
              }
            } else {
              searchData.push(data)
              this.setState({ searchData })
            }
          }
          tripData1.push(data);
          this.setState({ tripTableData: tripData1 })
        }
        else {
          if (this.state.searchData && this.state.searchData.length) {
            this.state.searchData[this.state.updateTripRowIndex] = data;
            this.setState({ searchData: this.state.searchData })
          }
          else {
            tripData1[this.state.updateTripRowIndex] = data;
            this.setState({ tripTableData: tripData1 })
          }
        }

        this.setState({
          updateTripRowIndex: '',
          tripName: '',
          tripInternalName: '',
          createBy: '',
          country: '',
          region: '',
          nature_level: '',
          culture_level: '',
          adventure_level: '',
          group_structure: '',
          save_type: 'add',
          highLights: [""],
          personalizedTrip: false,
          overnight_pre_night: "",
          overnight_post_night: ""
        });
        closePopoup();
      }
    } else {
      toast.error('Missing Information / Fields');
    }
  }

  setTrip = async (tripName, tripInternalName, createBy, country, region, nature_level1, culture_level, adventure_level, group_structure, trip_type, selectedDay, save_type, updateTripRowIndex, high_Lights, personalized, overnight_pre_night, overnight_post_night) => {
    this.setState({ tripName: tripName, tripInternalName: tripInternalName, createBy: createBy, country: country, region: region, nature_level: nature_level1, culture_level: culture_level, adventure_level: adventure_level, group_structure: group_structure, save_type, save_type: save_type, tripType: trip_type, updateTripRowIndex: updateTripRowIndex, selectedDays: selectedDay, showSelectedDay: false, highLights: high_Lights, personalizedTrip: personalized, overnight_pre_night, overnight_post_night });
    setTimeout(() => {
      this.setState({ showSelectedDay: true });
    }, 500);
  };

  setTripTableData = async (data) => {
    this.setState({ tripTableData: data });
  };
  setTripSearchData = async (data) => {
    this.setState({ searchData: data });
  };

  setSelectedDays = async (data) => {
    this.setState({ selectedDays: data });
  };

  handleClickOpen = () => {
    if (selectedRowIds.length > 0)
      this.setState({ openDeleteCnfrm: true });
  };

  handleDelete = () => {
    this.setState({ openDeleteCnfrm: false });
    var array = [...this.state.tripTableData]; // make a separate copy of the array
    var array1 = [...this.state.searchData];

    selectedRowIndex.sort(function (a, b) {
      return b - a;
    });


    /*  selectedRowIndex.forEach(async (i) => {
       let delid = array[i].id;
      
       array.splice(i, 1);
       console.log('delete id',delid)
       await handleCallApi('DELETE', ApiData.apiUrl + `/trips/${delid}`, {}, '', '')
     })
 
     this.setTripTableData(array);
     selectedRowIndex = []; */

    selectedRowIds.forEach(async (delid) => {

      for (let a1 = 0; a1 < array.length; a1++) {
        if (delid == array[a1].id)
          array.splice(a1, 1);
      }
      for (let a1 = 0; a1 < array1.length; a1++) {
        if (delid == array1[a1].id)
          array1.splice(a1, 1);
      }

      await handleCallApi('DELETE', ApiData.apiUrl + `/trips/${delid}`, {}, '', '')
    })
    this.setState({ searchData: array1 });
    this.setTripTableData(array);
    selectedRowIndex = [];
    selectedRowIds = [];
  };

  handleClose = () => {
    this.setState({ openDeleteCnfrm: false });
    selectedRowIndex = [];
    selectedRowIds = [];
  };

  setEditableTripId = (id) => {
    this.setState({ editableTripId: id });
  }
  setCountryFilter = (value) => {
    this.setState({ countryFilter: value });
  }

  handleChangePersonalized = () => {
    this.setState({ personalizedTrip: !this.state.personalizedTrip });
  }
  handleCloseTripSelected = () => {
    this.setState({ selectedDays: [] });
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.state.openDeleteCnfrm}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are You Sure You Want to delete this Trip
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button onClick={this.handleDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <div className="modal">
          <div className="modal_content">
            <span className="close" onClick={this.handleCloseTripSelected}>&times;</span>
            <div className="model_html">
              <h2>{this.state.save_type === 'add' ? 'CREATE NEW TRIP' : `EDIT TRIP ${this.state.editableTripId}`}</h2>
              <table cellPadding="8" >
                <tbody>
                  <tr>
                    <td width="50%">
                      <label>Catalog \ Internal  name</label><br />
                      <TextField
                        id="tripInternalName"
                        size="small"
                        style={{ width: '92%' }}
                        variant="outlined"

                        value={this.state.tripInternalName}
                        error={!!this.state.errors['tripInternalName']}
                        onChange={(event) =>
                          this.setState({ tripInternalName: event.target.value })
                        }
                      />
                    </td>
                    <td>
                      <label>Created By</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        style={{ width: '50%' }}
                        variant="outlined"
                        editable={false}
                        disabled={true}
                        value={this.state.createBy}
                        error={!!this.state.errors['createBy']}

                        onChange={(event) =>
                          this.setState({ createBy: event.target.value })
                        }
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label>Trip name</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        style={{ width: '95%' }}
                        variant="outlined"

                        value={this.state.tripName}
                        error={!!this.state.errors['tripName']}
                        onChange={(event) =>
                          this.setState({ tripName: event.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Overnight pre night</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        style={{ width: '95%' }}
                        variant="outlined"

                        value={this.state.overnight_pre_night}
                        error={!!this.state.errors['overnight_pre_night']}
                        onChange={(event) =>
                          this.setState({ overnight_pre_night: event.target.value })
                        }
                      />
                    </td>
                    <td>
                      <label>Overnight post night</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        style={{ width: '95%' }}
                        variant="outlined"

                        value={this.state.overnight_post_night}
                        error={!!this.state.errors['overnight_post_night']}
                        onChange={(event) =>
                          this.setState({ overnight_post_night: event.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table>
                        <tr>
                          <td>
                            <label>Country</label><br />
                            <ReactFlagsSelect
                              selected={this.state.country}
                              id={'selectCountriesDay'}
                              showSelectedLabel={true}
                              showOptionLabel={true}
                              onSelect={(code) => { this.setState({ country: code }); }}
                              placeholder={'Select a Country'}
                              countries={['GR', 'CR', 'NO', 'GE', 'MX', 'ME', 'DK', 'PL', 'PT', 'IS', 'MA', 'IL', 'TR', 'EC', 'ZA', 'IN', 'LT', 'IE', 'ES',]}
                            />
                            <span className="errorMsg">{this.state.errors['country']}</span>
                          </td>

                          <td style={{ verticalAlign: 'baseline', paddingLeft: '20px' }}><label>Region</label><br />

                            <FormControl className="dropdownWithborder">
                              <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Type"
                                value={this.state.region}
                                onChange={(event) => { this.setState({ region: event.target.value }); }}
                              >
                                <MenuItem value={"r1"}>Region 1</MenuItem>
                                <MenuItem value={"r2"}>Region 2</MenuItem>
                                <MenuItem value={"South"}>South</MenuItem>
                              </Select>
                            </FormControl>
                          </td>
                        </tr>
                      </table>


                    </td>
                    <td style={{ verticalAlign: 'baseline', paddingTop: '10px' }}>
                      <label>Trip Type</label><br />

                      <FormControl className="dropdownWithborder" style={{ maxWidth: 150 }}>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Type"
                          value={this.state.tripType}
                          onChange={(event) => { this.setState({ tripType: event.target.value }); }}
                        >
                          <MenuItem value={"normal"}>Dynamic</MenuItem>
                          <MenuItem value={"star"}>Relaxed</MenuItem>

                        </Select>
                      </FormControl><br />
                      <span className="errorMsg">{this.state.errors['tripType']}</span>
                    </td>
                  </tr>



                  {/*    <tr>
                    <td colspan="2">
                      <table>
                        <tr>
                            <td>HOW LONG</td>
                          <td>NATURE LEVEL</td>
                          <td>CULTURE LEVEL</td>
                        
                        </tr>
                      </table>
                    </td>
                  </tr> */}
                  <tr>

                    <td colspan="2">
                      <table>

                        <tr>
                          <td style={{ paddingLeft: 15 }}>HOW LONG</td>
                          <td style={{ paddingLeft: 15 }}>NATURE LEVEL</td>
                          <td style={{ paddingLeft: 15 }}>CULTURE LEVEL</td>

                        </tr>
                        <tr>
                          <td >
                            {this.state.tripType == 'normal' &&
                              <label className="boxed">
                                <input
                                  type="radio"
                                  id="oneAL"
                                  value="1"
                                  name="AL"
                                  onChange={(event) => this.setState({ adventure_level: event.target.value })}
                                  checked={this.state.adventure_level === 1 || this.state.adventure_level === '1'}
                                />
                                <span>S</span>
                              </label>
                            }
                            <label className="boxed">
                              <input
                                type="radio"
                                id="twoAL"
                                value="2"
                                name="AL"
                                onChange={(event) => this.setState({ adventure_level: event.target.value })}
                                checked={this.state.adventure_level === 2 || this.state.adventure_level === '2'}
                              />
                              <span>M</span>
                            </label>
                            <label className="boxed">
                              <input
                                type="radio"
                                id="threeAL"
                                value="3"
                                name="AL"
                                onChange={(event) => this.setState({ adventure_level: event.target.value })}
                                checked={this.state.adventure_level === 3 || this.state.adventure_level === '3'}
                              />
                              <span>L</span>
                            </label>
                            <br />
                            <span className="errorMsg">{this.state.errors['AL']}</span>
                          </td>

                          <td>
                            <label className="boxed">
                              <input
                                type="radio"
                                id="oneNL"
                                value="1"
                                name="NL"
                                onChange={(event) => this.setState({ nature_level: event.target.value })}
                                checked={this.state.nature_level === 1 || this.state.nature_level === '1'}
                              />
                              <span>1</span>
                            </label>
                            <label className="boxed">
                              <input
                                type="radio"
                                id="twoNL"
                                value="2"
                                name="NL"
                                onChange={(event) => this.setState({ nature_level: event.target.value })}
                                checked={this.state.nature_level === 2 || this.state.nature_level === '2'}
                              />
                              <span>2</span>
                            </label>
                            {/*   <label className="boxed">
                              <input
                                type="radio"
                                id="threeNL"
                                value="3"
                                name="NL"
                                onChange={(event) => this.setState({ nature_level: event.target.value })}
                                checked={this.state.nature_level === 3 || this.state.nature_level === '3'}
                              />
                              <span>3</span>
                            </label> */}
                            <br />
                            <span className="errorMsg">{this.state.errors['NL']}</span>
                          </td>
                          <td >
                            <label className="boxed">
                              <input
                                type="radio"
                                id="oneCL"
                                value="1"
                                name="CL"
                                onChange={(event) => this.setState({ culture_level: event.target.value })}
                                checked={this.state.culture_level === 1 || this.state.culture_level === '1'}
                              />
                              <span>1</span>
                            </label>
                            <label className="boxed">
                              <input
                                type="radio"
                                id="twoCL"
                                value="2"
                                name="CL"
                                onChange={(event) => this.setState({ culture_level: event.target.value })}
                                checked={this.state.culture_level === 2 || this.state.culture_level === '2'}
                              />
                              <span>2</span>
                            </label>
                            {/*   <label className="boxed">
                              <input
                                type="radio"
                                id="threeCL"
                                value="3"
                                name="CL"
                                onChange={(event) => this.setState({ culture_level: event.target.value })}
                                checked={this.state.culture_level === 3 || this.state.culture_level === '3'}
                              />
                              <span>3</span>
                            </label> */}
                            <br />
                            <span className="errorMsg">{this.state.errors['CL']}</span>
                          </td>



                        </tr>
                      </table>
                    </td>


                  </tr>





                  <tr>
                    <td >



                      <FormControl style={{ width: '30%' }}>
                        <InputLabel id="demo-simple-select-label">Group Structured</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Type"
                          value={this.state.group_structure}
                          onChange={(event) => { this.setState({ group_structure: event.target.value }); }}
                        >

                          <MenuItem value={"WITHOUT KIDS"}>With Kids</MenuItem>
                          <MenuItem value={"WITH KIDS"}>Without Kids</MenuItem>
                        </Select>
                      </FormControl>

                      <br />
                      <span className="errorMsg">{this.state.errors['group_structure']}</span>
                    </td>
                    <td></td>
                  </tr>
                  <tr>
                    <td colSpan={2}>



                      {/* <table>
                        <tr>
                          <td colSpan={3}>
                            <br />

                            <label style={{ fontSize: 20 }}>Ticket Type </label><br /><br />


                          </td>
                        </tr>

                        {this.state.ticketType && this.state.ticketType.map((item, i) =>
                          <tr>
                            <td>
                              <TextField
                                id="txtSNTitle"
                                style={{ width: '95%' }}
                                variant="outlined"
                                value={item}

                                onChange={(event) => {
                                  var a = [...this.state.ticketType];
                                  a[i] = event.target.value;
                                  this.setState({ ticketType: a });
                                }
                                }
                              />

                            </td>

                            {item != "" &&
                              <td>

                                <img style={{ cursor: 'pointer' }} onClick={() => {
                                  var a = [...this.state.ticketType];
                                  a.splice(i, 1);
                                  this.setState({ ticketType: a });
                                }} width="20px" src={DeleteIcon} />
                              </td>
                            }
                          </tr>
                        )}



                        <tr>
                          <td colSpan="3">
                            <span className="errorMsg">{this.state.errors['ticket_types']}</span>
                            <br />
                            <Link onClick={() => {
                              var a = [...this.state.ticketType];
                              a.push("");
                              this.setState({ ticketType: a });
                            }}> + ADD TICKET TYPE </Link>
                          </td>
                        </tr>
                      </table> */}

                      <br />

                    </td>

                  </tr>

                  <tr>
                    <td colSpan={2}>



                      <table>
                        <tr>
                          <td colSpan={3}>
                            <br />

                            <label style={{ fontSize: 20 }}>Highlights</label><br /><br />


                          </td>
                        </tr>

                        {this.state.highLights && this.state.highLights.map((item, i) =>
                          <tr>
                            <td>
                              <TextField
                                id="txtSNTitle"
                                style={{ width: '95%' }}
                                variant="outlined"
                                value={item}

                                onChange={(event) => {
                                  var a = [...this.state.highLights];
                                  a[i] = event.target.value;
                                  this.setState({ highLights: a });
                                }
                                }
                              />

                            </td>

                            {item != '' &&
                              <td>

                                <img style={{ cursor: 'pointer' }} onClick={() => {
                                  var a = [...this.state.highLights];
                                  a.splice(i, 1);
                                  this.setState({ highLights: a });
                                }} width="20px" src={DeleteIcon} />
                              </td>
                            }
                          </tr>
                        )}
                        <tr>
                          <td colSpan="3">
                            <span className="errorMsg">{this.state.errors['highLights']}</span>
                            <br />
                            <Link onClick={() => {
                              var a = [...this.state.highLights];
                              a.push("");
                              this.setState({ highLights: a });
                            }}> + ADD Highlights </Link>
                          </td>
                        </tr>
                        {/* <tr>
                        <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.personalizedTrip}
                                onChange={this.handleChangePersonalized}
                                name="checkedB"
                                color="primary"
                              />
                            }
                            label="Personalized Trip"
                          />
                        </tr> */}
                      </table>

                      <br />

                    </td>

                  </tr>

                  <tr>
                    <td><br /><br /><br /><br />
                      <h5>Select days to trip:</h5>
                    </td>
                  </tr>


                  <tr >
                    <td colspan="2" style={{ borderColor: '#000', border: 'solid 1px #ccc' }}>
                      {this.state.showSelectedDay &&
                        <SelectDays selectedDays={this.state.selectedDays} setSelectedDays={this.setSelectedDays} />
                      }
                    </td>
                  </tr>


                  <tr>
                    <td colSpan="4">
                      {this.state.save_type == 'add' &&

                        <Button
                          id="btnSaveSN"
                          variant="contained"
                          color="primary"
                          onClick={this.handleSaveTrip.bind(this)}
                        /*  disabled={
                           this.state.tripInternalName=="" ?  true
                         : this.state.tripName=="" ? true
                         : this.state.tri=="" ? true
                         :false
                         } */
                        >
                          CREATE
                        </Button>
                      }



                      {this.state.save_type != 'add' &&
                        <Button
                          id="btnSaveSN"
                          variant="contained"
                          color="primary"
                          onClick={this.handleSaveTrip.bind(this)}
                        >
                          SAVE CHANGES
                        </Button>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>


            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
        <ToastContainer />
        <table cellPadding="10" className="viewdata">
          <tbody>

            <tr>
              <td>
                <h2>Customer Trips</h2>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan="3"  ></td>
              <td>&nbsp;
                <Button
                  id="btnSave"
                  variant="contained"
                  className='deleteBtn'
                  color="#15cc09"
                  onClick={() => { this.handleClickOpen(); }}
                  style={{ float: 'right', fontSize: 12, color: '#fff' }}
                >
                  DELETE SELECTED
                </Button>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <TripTable tripData={this.state.tripTableData} searchData={this.state.searchData} setCountryFilter={this.setCountryFilter} setTripTableData={this.setTripTableData} setTripSearchData={this.setTripSearchData} setTrip={this.setTrip} setEditableTripId={this.setEditableTripId} />
              </td>
            </tr>
            <tr>
            </tr>
            <tr>
              <td colSpan="4">
                <div className="borderDiv" />
              </td>
            </tr>

          </tbody>
        </table>

      </div>

    );
  }
}

export default CustomerTrips;

/* openPopup= () =>
{
  const modal = document.querySelector(".modal")
          const closeBtn = document.querySelector(".close")
          modal.style.display = "block";
  closeBtn.addEventListener("click", () => {
            modal.style.display = "none";
  })
}
          */
mapboxgl.accessToken = MapboxKey;
let mapMarkersOne = [];
let mapMarkersTwo = [];
let webmapMarkers = [];
let vgmapMarkers = [];

let webMapOne;
let webMapTwo;
let webMap;
let vgMap;

const initializeMap = (z, id) => {
  if (id == 'mapDayVG') {
    vgMap = new mapboxgl.Map({
      container: id,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: z,
    });
    //Add navigation controls to the mapbox if zoom is lower
    if (z < 11) vgMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    vgMap.on('click', function (e) {
      if (selectedRowIndex) {
        addVgMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      } else {
        toast.error('Please select a trip first by clicking VG button against the record');
      }
    });
  } else if (id == 'mapDayStop') {
    webMap = new mapboxgl.Map({
      container: id,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: z,
    });
    //Add navigation controls to the mapbox if zoom is lower
    if (z < 11) webMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    webMap.on('click', function (e) {
      if (selectedRowIndex) {
        addWebMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      } else {
        addWebMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
        // toast.error('11Please select a trip first by clicking Web button against the record');
      }
    });
  } else {
    if (id == 'mapDay-1') {
      webMapOne = new mapboxgl.Map({
        container: id,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: z,
      });
      webMapOne.on('click', function (e) {
        $('#txtStartLocDay').val(e.lngLat.lng.toFixed(6) + ',' + e.lngLat.lat.toFixed(6));
        // this.setState({startloc: e.lngLat.lng.toFixed(4) + ',' + e.lngLat.lat.toFixed(4) });
        mapMarkersOne.forEach((marker) => marker.remove());
        mapMarkersOne = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapOne);
        mapMarkersOne.push(marker);
      });
    } else {
      webMapTwo = new mapboxgl.Map({
        container: id,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: z,
      });

      webMapTwo.on('click', function (e) {
        $('#txtEndLocDay').val(e.lngLat.lng.toFixed(6) + ',' + e.lngLat.lat.toFixed(6));
        mapMarkersTwo.forEach((marker) => marker.remove());
        mapMarkersTwo = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapTwo);
        mapMarkersTwo.push(marker);
      });
    }
  }
};

class MapboxGLMap extends Component {
  constructor(props) {
    super(props);
    // this.id = _uniqueId(props.prefix + "-");
    this.id = props.prefix;
  }
  componentDidMount() {
    initializeMap(-1, this.id);
  }
  render() {
    const id = this.id;
    return (
      <div id={id} className="mapEA">
        {' '}
      </div>
    );
  }
}

class TripTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: [['Loading Data...']],
      loading: false,
      page: 0,
      rowsPerPage: 10,
      count: 0,
      filterData: [],
      filterBy: '',
      filterValue: '',
      searchTerm: '',
      allTrips: [],
      filterState: false,
      ascendingSort: true,
    };
  }

  async componentDidMount() {
    let data = await this.getData();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has('id')) {
      let id = urlParams.getAll('id');
      this.props.setEditableTripId(id)
      this.setTripData(id, data)
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.page !== prevState.page || this.state.rowsPerPage !== prevState.rowsPerPage) {
      let data = await this.getData();
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.has('id')) {
        let id = urlParams.getAll('id');
        this.props.setEditableTripId(id)
        this.setTripData(id, data)
      }
    }
    if (this.state.searchTerm !== prevState.searchTerm) {
      if (this.state.searchTerm.length === 0) {
        if (this.state.filterValue.length !== 0) {
          this.getFilteredData()
        } else {
          this.setState({ filterData: [], filterState: false, searchTerm: '' });
          this.props.setTripSearchData([])
        }
      }
    }


    /*  selectedRowIndex=[]
     selectedRowIds=[]  */




  }





  setTripData = async (id, data) => {
    let filterId = parseInt(id[0])
    let filterRow = await this.getTripById(filterId)
    let getIndexRow = data.results.findIndex(row => row.id === filterId) || '';
    var countryCode;
    var countryname = filterRow.country;
    let personalized = filterRow.personalized;
    countriesNames.map(function (item) {
      if (item.name === countryname)
        countryCode = item.code;
    });
    let highlights = [""]
    if (filterRow.highlights.length) {
      highlights = filterRow.highlights;
    }
    this.props.setTrip(filterRow.name, filterRow.internal_name, filterRow.created_by, countryCode, filterRow.region, filterRow.nature_level, filterRow.culture_level, filterRow.adventure_level, filterRow.group_structure.toUpperCase(), filterRow.trip_type, filterRow.days, 'update', getIndexRow, highlights, personalized, filterRow.overnight_pre_night, filterRow.overnight_post_night);
    openPopup()
  }

  getData = async () => {
    this.setState({ loading: true });
    let dataRows = await this.xhrRequest(ApiData.apiUrl + `/trips?take=${this.state.rowsPerPage}&skip=${this.state.rowsPerPage * this.state.page}&personalized=false&custom=true`).then(async (data) => {
      data.results.forEach(async (trip) => {
        let tripData = await getDatafromApi(ApiData.baseURL + `/user/${trip.created_by_id}`)
        if (tripData) {
          const fecha = new Date(tripData.createdAt);
          const createdDate = fecha.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
          trip.name = `${tripData.first_name} ${tripData.last_name}`
          trip.email = tripData.email
        } else {
          trip.name = "No name available"
          trip.email = "No email available"
        }
      })
      console.log('TRIPSSSS', data)
      this.setState({ data, loading: false });
      this.props.setTripTableData(data.results);
      if (this.state.count === 0) {
        this.setState({ count: data.count });
      }
      return data
    });
    return dataRows;
  };

  getAllData = () => {
    return new Promise(async (resolve, reject) => {
      let data = await getDatafromApi(ApiData.baseURL + `/trips/all-trips`)
      if (data) {
        data.forEach(async (trip) => {
          const order = trip.days_sort.split(',').map(Number);
          trip.days.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));
          let tripData = await getDatafromApi(ApiData.baseURL + `/user/${trip.created_by_id}`)
          if (tripData) {
            const fecha = new Date(tripData.createdAt);
            const createdDate = fecha.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
            trip.name = `${tripData.first_name} ${tripData.last_name}`
            trip.email = tripData.email
          } else {
            trip.name = "No name available";
            trip.email = "No email available";
          }
        })
        this.setState({ allTrips: data });
      }
      resolve(data);
    });
  };

  xhrRequest = (url) => {
    return new Promise(async (resolve, reject) => {
      let data = await getDatafromApi(url)
      if (data) {
        data.results.sort((a, b) => a.id - b.id);
        daysData = data.results;
        resolve(data);
      }
    });
  };

  getFilteredData = async () => {
    if (this.state.searchTerm.length > 0 || this.state.filterValue.length > 0) {
      this.setState({ loading: true })
      this.xhrRequestFilterData().then((data) => {
        this.setState({ loading: false }, () => {
          this.props.setTripSearchData(data);
          this.setState({ filteredData: data, filterState: true });
        })
      });
    }
  };

  xhrRequestFilterData = () => {
    if (this.state.searchTerm.length > 0 || this.state.filterValue.length > 0) {
      return new Promise(async (resolve, reject) => {
        let data1 = await getDatafromApi(ApiData.baseURL + `/trips/findTrips?${this.state.searchTerm ? `internal_name=${this.state.searchTerm.toLocaleUpperCase()}&` : ''}${this.state.filterValue ? `${this.state.filterBy}=${this.state.filterValue}` : ''}&custom=true`)
        let data = data1.filter(function (item) { return item.personalized == false; });

        if (data) {
          if (data.length) {
            data.forEach(async (trip) => {
              const order = trip.days_sort.split(',').map(Number);
              if (order.length === trip.days.length) {
                const sortedDays = trip.days.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));
                trip.days = sortedDays;

                let tripData = await getDatafromApi(ApiData.baseURL + `/user/${trip.created_by_id}`)
                if (tripData) {
                  const fecha = new Date(tripData.createdAt);
                  const createdDate = fecha.toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
                  trip.name = `${tripData.first_name} ${tripData.last_name}`
                  trip.email = tripData.email
                } else {
                  trip.name = "No name available"
                  trip.email = "No email available"
                }


              } else {
                const filteredDays = trip.days.filter((day) => order.includes(day.id));
                const sortedDays = filteredDays.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));
                trip.days = sortedDays;
              }
            })
            resolve(data);
          } else {
            this.setState({ filterData: [] })
            this.props.setTripSearchData([])
          }
        }
      });
    }

  };

  getTripById = (id) => {
    return new Promise(async (resolve, reject) => {
      let data = await getDatafromApi(ApiData.apiUrl + `/trips/${id}`)
      if (data) {
        resolve(data);
      }
    });
  };

  render() {
    const columns = [
      {
        name: 'id', options: {
          label: 'ID', filter: false, sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            //var editrow = tableMeta.tableData[tableMeta.rowIndex];
            var editrow = {};
            var rowIndexForUpdate = 0;
            var i = 0;
            tableMeta.tableData.forEach((row) => {
              if (row.id == value) {
                editrow = row;
                rowIndexForUpdate = i;
              }
              i++;
            })
            var countryCode;
            var countryname = editrow.country;
            countriesNames.map(function (item) {
              if (item.name === countryname)
                countryCode = item.code;
            });
            let highlights = [""];
            if (editrow.highlights && editrow.highlights.length) {
              highlights = editrow.highlights
            }
            return (
              <Link href="#" onClick={() => { this.props.setTrip(editrow.name, editrow.internal_name, editrow.created_by, countryCode, editrow.region, editrow.nature_level, editrow.culture_level, editrow.adventure_level, editrow.group_structure.toUpperCase(), editrow.trip_type, editrow.days, 'update', rowIndexForUpdate, highlights, editrow.personalized, editrow.overnight_pre_night, editrow.overnight_post_night); openPopup(editrow.id); this.props.setEditableTripId(editrow.id) }}>  {value}</Link>
            );
          },
        }
      },
      { name: 'name', label: 'Trip Name', options: { filter: false } },
      { name: 'internal_name', label: 'Catalog Name', options: { filter: false } },
      {
        name: "country", label: "Country", options: {
          filter: true,
          filterOptions: {
            names: countriesNames.map(country => country.name)
          },
        }
      },
      {
        name: "region", label: "Region", options: {
          filter: true,
          filterOptions: {
            names: ['South', 'North', 'East', 'West']
          },
        }
      },
      { name: 'created_by', label: 'User name', options: { filter: false } },
      { name: 'created_by_id', label: 'User id', options: { filter: false } },

       {
        name: 'id', options: {
          label: 'email', filter: false, sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            //var editrow = tableMeta.tableData[tableMeta.rowIndex];
            var editrow = {};
            var rowIndexForUpdate=0;
            var i=0;
            let email= '';

          tableMeta.tableData.forEach((row)=>{
            if(row.id==value)
            {
              editrow=row;
              rowIndexForUpdate=i;
    
            }
            i++;
          })
          const date = new Date(editrow.createdAt);
          const day = date.toLocaleDateString('es-ES', { day: '2-digit' });
          const month = date.toLocaleDateString('es-ES', { month: '2-digit' });
          const year = date.toLocaleDateString('es-ES', { year: 'numeric' });
          const hour = date.getHours();
          const minute = date.getMinutes();
          const amOrPm = hour < 12 ? 'am' : 'pm';
          const hour12 = hour % 12 || 12; 
          const formattedHour = hour12.toString().padStart(2, '0');
          const formattedMinute = minute.toString().padStart(2, '0');
          const createdDate = `${day}/${month}/${year} - ${formattedHour}:${formattedMinute} ${amOrPm}`;
          
          console.log(createdDate);
          return (
            <p>{createdDate}</p>
          );
        },
      }
    },
     { name: 'overnight_pre_night', label: 'Overnight pre night', options: { filter: false } },
     { name: 'overnight_post_night', label: 'Overnight post night', options: { filter: false } },
    ];

    const onRowsSelect = (curRowSelected, rowsSelected) => {
      selectedRowIndex = [];
      let d1 = filterState ? searchData : tripData;
      for (let j = 0; j < d1.length; j++) {
        selectedRowIds.splice(selectedRowIds.indexOf(d1[j].id), 1)
      }
      for (let i = 0; i < rowsSelected.length; i++) {
        selectedRowIndex.push(rowsSelected[i].index);
        selectedRowIds.push(d1[rowsSelected[i].index].id);
      }
      activeDeactiveDeleteBtn();
      // console.log('selectedRowIds', selectedRowIds)
    };

    const handleChangePage = (event, newPage) => {
      this.setState({ page: event });
      selectedRowIndex = []
      selectedRowIds = []

    };

    const handleChangeRowsPerPage = (event) => {
      this.setState({ rowsPerPage: event, page: 0 });
    };
    const handleOnFilterChange = (changedColumn, filterList) => {
      let filterBy = changedColumn;
      let filterValue = (filterList[3].join('') || filterList[4].join(''));
      this.setState({ filterBy, filterValue }, () => {
        this.props.setCountryFilter(filterValue)
        this.getFilteredData()
      });
      selectedRowIndex = []
      selectedRowIds = []
    };

    const changeSlectedRow = () => {
      selectedRowIndex = [];
      let d1 = filterState ? searchData : tripData;
      for (let i = 0; i < d1.length; i++) {
        for (let j = 0; j < selectedRowIds.length; j++) {
          if (d1[i].id == selectedRowIds[j])
            selectedRowIndex.push(i)
        }
      }

    }

    const handleSortChange = () => {
      let isAscending = this.state.ascendingSort
      if (this.state.filterState && this.props.searchData.length) {
        const searchData = this.props.searchData;
        searchData.sort((a, b) => {
          if (a.id < b.id) {
            return isAscending ? -1 : 1;
          }
          if (a.id > b.id) {
            return isAscending ? 1 : -1;
          }
          return 0;
        });
        this.props.setTripSearchData(searchData)
      } else {
        const data = this.props.tripData;
        data.sort((a, b) => {
          if (a.id < b.id) {
            return isAscending ? -1 : 1;
          }
          if (a.id > b.id) {
            return isAscending ? 1 : -1;
          }
          return 0;
        });

        this.props.setTripTableData(data);
      }

      this.setState({ ascendingSort: !isAscending });
      changeSlectedRow()
    };

    const handleOnFilterClose = () => {
      if (this.state.filterBy && this.state.filterValue) {
        deleteFilterData()
      }
      selectedRowIndex = []
      selectedRowIds = []
    }

    const deleteFilterData = () => {
      this.setState({ filterBy: '', filterValue: '' }, () => {
        if (this.state.searchTerm.length > 0) {
          this.getFilteredData()
        } else {
          this.setState({ filterData: [], filterState: false });
          this.props.setTripSearchData([])
        }
      })
      selectedRowIndex = []
      selectedRowIds = []
    }

    const deleteSearchData = () => {
      this.setState({ searchTerm: '' }, () => {
        if (this.state.filterValue.length > 0) {
          this.getFilteredData()
        } else {
          this.setState({ filterData: [], filterState: false, searchTerm: '', loading: false });
          this.props.setTripSearchData([])
        }
      });
      selectedRowIndex = []
      selectedRowIds = []
    }

    let searchTimer;
		const searchByTripName = (searchTerm) => {
			if (searchTerm) {
			  if (!isNaN(Number(searchTerm))) {
				clearTimeout(searchTimer); 
				searchTimer = setTimeout(() => {
				  this.setState({ searchTerm }, () => {
					this.getFilteredData();
				  });
				}, 1000); 
			  }
			  if (isNaN(Number(searchTerm)) && searchTerm.length > 3) {
				clearTimeout(searchTimer); 
				searchTimer = setTimeout(() => {
				  this.setState({ searchTerm }, () => {
					this.getFilteredData();
				  });
				}, 1000); 
			  }
			} else {
			  this.setState({ searchTerm: '' });
			  deleteSearchData();
			  selectedRowIndex = [];
			  selectedRowIds = [];
			  activeDeactiveDeleteBtn();
			}
		  };


    const { page, rowsPerPage, count, filterState } = this.state;
    const { tripData, searchData } = this.props;

    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      download: false,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: 'none',
      rowsSelected: selectedRowIndex,
      serverSide: true,
      rowsPerPage: rowsPerPage,
      page: page,
      count: count,
      pagination: filterState ? false : true,
      onColumnSortChange: handleSortChange,
      onRowSelectionChange: onRowsSelect,
      onChangePage: handleChangePage,
      onChangeRowsPerPage: handleChangeRowsPerPage,
      onSearchChange: searchByTripName,
      onFilterChange: handleOnFilterChange,
      /* onFilterDialogClose: handleOnFilterClose, */
      onFilterChipClose: deleteFilterData,
      onSearchClose: deleteSearchData
    };
    return (
      <div>
        {this.state.loading ? (
          <p style={{ position: 'absolute', top: '80px' }}>Loading Data...</p>
        ) : (
          this.state.filterState ? <p style={{ position: 'absolute', top: '80px' }}>{this.props.searchData.length} trips found</p> : null
        )}
        <MUIDataTable
          title={
            <Typography variant="subtitle2">
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: 'relative', top: 4 }}
                />
              )}
            </Typography>
          }
          data={this.state.loading ? [] : filterState ? searchData : tripData}
          columns={columns}
          options={options}
          rowsSelected={onRowsSelect}
        />
      </div>
    );
  }
}

function addWebMarker(coordinates) {
  const id = _uniqueId('webmarker-');
  let marker;
  var el = document.createElement('div');
  el.className = 'marker';
  el.id = id;
  el.addEventListener(
    'click',
    (e) => {
      e.stopPropagation();
      let target_id = e.target.id;
      // let queriedMarkerObj = webmapMarkers.find((marker) => marker.id === query);
      // queriedMarkerObj.marker.remove();
      for (let i = 0; i < webmapMarkers.length; i++) {
        if (webmapMarkers[i].id === target_id) {
          document.getElementById(webmapMarkers[i].id).remove();
          webmapMarkers.splice(i, 1);
        }
      }
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(webMap);
  marker.properties = { id: id };
  webmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}

function addVgMarker(coordinates, type = false) {
  const id = _uniqueId('vgmarker-');
  let marker;
  var el = document.createElement('div');
  el.className = 'marker';
  el.id = id;
  if (type) {
    el.style.backgroundImage = `url('https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/${getSvg(
      type
    )}.svg')`;
  }
  el.addEventListener(
    'click',
    (e) => {
      e.stopPropagation();
      let query = e.target.id;

      let queriedMarkerObj = vgmapMarkers.find((marker) => marker.id === query);
      queriedMarkerObj.marker.remove();
      pointNumbers.splice(
        pointNumbers.findIndex((v) => v.id === query),
        1
      );
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(vgMap);
  vgmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}

function getSvg(type) {
  type = parseInt(type);
  switch (type) {
    case 1: {
      return 'straight';
    }
    case 2: {
      return 'right';
    }
    case 3: {
      return 'left';
    }
    case 4: {
      return 'fork-left';
    }
    case 5: {
      return 'fork-right';
    }
    case 6: {
      return 'poi';
    }
    case 7: {
      return 'info';
    }
    case 8: {
      return 'swimming';
    }
    case 9: {
      return 'rafting';
    }
    case 10: {
      return 'hotel';
    }
    default: {
      return 'poi';
    }
  }
}

function openPopup(id = '') {
  if (id !== '') {
    window.history.pushState(null, null, `?page=Trips&id=${id}`);
  }
  const modal = document.querySelector(".modal")
  const closeBtn = document.querySelector(".close")
  modal.style.display = "block";
  document.querySelector(".viewdata").style.display = "none";
  closeBtn.addEventListener("click", () => {
    window.history.pushState(null, null, `?page=Trips`);
    modal.style.display = "none";
    document.querySelector(".viewdata").style.display = "inline-table";
  })
}

function closePopoup() {
  document.querySelector(".modal").style.display = "none";
  document.querySelector(".viewdata").style.display = "inline-table";
}

function activeDeactiveDeleteBtn() {
  const btn = document.querySelector(".deleteBtn")
  if (selectedRowIndex.length > 0) btn.style.backgroundColor = 'blue';
  else btn.style.backgroundColor = '#e0e0e0';
}