import React, { Component } from 'react';
import SelfGuidedPackage from './selfGuidedPackage';
import Purchases from './purchases';
import StopsPois from './stopspois';
import GPX from './gpx';
import Days from './days';
import Trips from './trips';

import StopsPoisPersonalized from './stopspoisPersonalized';
import GPXPersonalized from './gpxPersonalized';
import DaysPersonalized from './daysPersonalized';
import TripsPersonalized from './tripsPersonalized';
import SelfGuidedPackagePersonalized from './selfGuidedPackagePersonalized';
import HotelPriceListPersonalized from './hotelPriceListPersonalized';
import HotelPriceList from './hotelPriceList';
import CarRentPrice from './carRentPriceList';
import Currencies from './currencies';
import CountryHighSeason from './countryHighSeason';
import Quotations from './quotation';
import CustomerTrips from './customerTrips'
import CustomerDays from './CustomerDays'
import ExpentureAttractions from './expentureAttractions';
import LocalHero from './localHero';
import Users from './users';
import Changelog from './changelog';
import PayByLink from './paybylink';
import Login from './login';
import UploadAssets from './uploadAssets';
import Link from '@material-ui/core/Link';
import PayByLinkList from './pay-by-link-list';
import packageJson from '../package.json';

window.Buffer = window.Buffer || require("buffer").Buffer;

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentTab: 1,
      data: [,
        {
          id: '1',
          url: 'ItinerariesBuilder',
          displayName: 'Itineraries Builder',
          info: <StopsPois />,
          active: true,
          issubmenu: false,
          parrentmenuid: 1
        },
        {
          id: '2',
          url: 'StopsPOIs',
          displayName: 'Stops/POIs',
          info: <StopsPois />,
          active: true,
          issubmenu: true,
          parrentmenuid: 1
        },
        {
          id: '3',
          url: 'GPXs',
          displayName: 'GPXs',
          info: <GPX />,
          active: false,
          issubmenu: true,
          parrentmenuid: 1
        },
         {
           id: '4',
           url: 'Days',
           displayName: 'Days',
           info:  <Days />,
           active: false,
           issubmenu : true,
           parrentmenuid : 1
         },
         {
           id: '5',
           url: 'Trips',
           displayName: 'Trips',
           info: <Trips />,
           active: false,
           issubmenu : true,
           parrentmenuid : 1
         }, 

         {
          id: '101',
          url: 'PersonalizedItineraries',
          displayName: 'Personalized Itineraries',
          info: <StopsPoisPersonalized />,
          active: true,
          issubmenu: false,
          parrentmenuid: 101
        },
        {
          id: '102',
          url: 'PersonalizedStopsPOIs',
          displayName: 'Stops/POIs',
          info: <StopsPoisPersonalized />,
          active: true,
          issubmenu: true,
          parrentmenuid: 101
        },
        {
          id: '103',
          url: 'PersonalizedGPXs',
          displayName: 'GPXs',
          info: <GPXPersonalized />,
          active: false,
          issubmenu: true,
          parrentmenuid: 101
        },
         {
           id: '104',
           url: 'PersonalizedDays',
           displayName: 'Days',
           info:  <DaysPersonalized />,
           active: false,
           issubmenu : true,
           parrentmenuid : 101
         },
         {
           id: '105',
           url: 'PersonalizedTrips',
           displayName: 'Trips',
           info: <TripsPersonalized />,
           active: false,
           issubmenu : true,
           parrentmenuid : 101
         }, 
         {
          id: '106',
          url: 'SelfGuidedPackagePersonalized',
          displayName: 'Personalized packages',
          info: <SelfGuidedPackagePersonalized />,
          active: false,
          issubmenu : true,
          parrentmenuid : 101
        }, 
         {
          id: '107',
          url: 'HotelPriceListPersonalized',
          displayName: 'Personalized Hotel Price List',
          info: <HotelPriceListPersonalized />,
          active: false,
          issubmenu : true,
          parrentmenuid : 101
        }, 
         {
          id: '111',
          url: 'CustomerItineraries',
          displayName: 'Customer Itineraries',
          info: <CustomerTrips />,
          active: true,
          issubmenu: false,
          parrentmenuid: 111
        },
        {
          id: '112',
          url: 'CustomerTrips',
          displayName: 'Customer Trips',
          info: <CustomerTrips />,
          active: true,
          issubmenu: true,
          parrentmenuid: 111
        },
        {
          id: '113',
          url: 'CustomerDays',
          displayName: 'Customer Days',
          info: <CustomerDays />,
          active: false,
          issubmenu: true,
          parrentmenuid: 111
        },
         
         {
           id: '6',
           url: 'PlanYourTripAttractions',
           displayName: 'Plan Your Trip Attractions',
           info: <ExpentureAttractions/>,
           active: false,
           issubmenu : false,
           parrentmenuid : 0
         },
         {
           id: '7',
           url: 'LocalHeros',
           displayName: 'Local Heroes',
           info: <LocalHero/>,
           active: false,
           issubmenu : false,
           parrentmenuid : 0
         },
        
         {
           id: '8',
           url: 'SelfGuidedPackages',
           displayName: 'Self Guided Packages',
           info:  <SelfGuidedPackage />,
           active: false,
           issubmenu : false,
           parrentmenuid : 0
         },
         {
           id: '9',
           url: 'Purchases',
           displayName: 'Purchases',
           info:  <Purchases />,
           active: false,
           issubmenu : false,
           parrentmenuid : 0
         },
         {
          id: '10',
          url: 'HotelPriceList',
          displayName: 'Hotel Price List',
          info: <HotelPriceList/>,
          active: false,
          issubmenu : false,
          parrentmenuid : 0
        },
         {
          id: '11',
          url: 'CarRentPriceList',
          displayName: 'Car Rent Price List',
          info: <CarRentPrice/>,
          active: false,
          issubmenu : false,
          parrentmenuid : 0
        },
         {
          id: '12',
          url: 'Currencies',
          displayName: 'Currencies',
          info: <Currencies/>,
          active: false,
          issubmenu : false,
          parrentmenuid : 0
        },
         {
          id: '13',
          url: 'CountryHighSeason',
          displayName: 'Country High Season',
          info: <CountryHighSeason/>,
          active: false,
          issubmenu : false,
          parrentmenuid : 0
        },
        {
          id: '14',
          url: 'packagesPricing',
          displayName: 'Packages Pricing',
          info: <Quotations/>,
          active: false,
          issubmenu : false,
          parrentmenuid : 0
        },
         {
          id: '15',
          url: 'Users',
          displayName: 'Users',
          info: <Users/>,
          active: false,
          issubmenu : false,
          parrentmenuid : 0
        },
         {
          id: '16',
          url: 'Changelog',
          displayName: 'Changelog',
          info: <Changelog/>,
          active: false,
          issubmenu : false,
          parrentmenuid : 0
        },
        {
          id: '17',
          url: 'PayByLink',
          displayName: 'PayByLink',
          info: <PayByLink/>,
          active: false,
          issubmenu : false,
          parrentmenuid : 0
        },
        {
          id: '18',
          url: 'PayByLinkList',
          displayName: 'PayByLinkList',
          info: <PayByLinkList />,
          active: false,
          issubmenu: false,
          parrentmenuid: 0
        },
        // {
        //   id: '19',
        //   url: 'System-Events',
        //   displayName: 'System Events',
        //   info: <PayByLinkList />,
        //   active: false,
        //   issubmenu: false,
        //   parrentmenuid: 0
        // },
        {
          id: '20',
          url: 'Logout',
          displayName: 'Logout',
          info: <Login />,
          active: false,
          issubmenu: false,
          parrentmenuid: 0
        },
        {
          id: '21',
          displayName: `Version ${packageJson.version}`,
          info: packageJson.version,
          active: false,
          issubmenu: false,
          parrentmenuid: 0
        },
      ],
    };
  }

  // Function to handle tab handling and showing respective content
  tabToggle(currentTab, id, parrentid) {
    let tabs = this.state.data;
    tabs.map((tab, index) => {
      if (tab.id == id || tab.id == parrentid || id == tab.parrentmenuid) {
        tabs[index].active = true;
        if (id == 1 && tab.id != 2 && tab.id != 1) tabs[index].active = false;
      }
      else {
        tabs[index].active = false;
      }
      return null;
    });
    if (id != 1)
      this.setState({ currentTab, });
    else
      this.setState({ currentTab: this.state.currentTab });
  }

  logout(p) {
    if (p == 'Logout') {
      localStorage.clear();
      window.location = "?page=login";
    }
    else
      window.location = "?page=" + p
  }

  // Render main component of the app
  render() {
    let queryParams = new URLSearchParams(window.location.search);
    let page = queryParams.get("page");
    let token = localStorage.getItem('token');
    let userData = localStorage.getItem('userData');
    if ((!token || !userData) && page != 'Login') {
      window.location = "?page=Login";
      page = "Login";
    }
    let tabs = this.state.data;
    let dataTabs;
    if (userData){
      dataTabs = JSON.parse(userData).role === "SUPERADMIN" ? this.state.data : tabs.filter(item => item.id !== '10' && item.id !== '11');
    }
    return (
      <div>
        {page != "Login" &&
          <div className="tab">
            {dataTabs.map((tab, i) => (
            (!tab.issubmenu || 
              ((page == "ItinerariesBuilder" || page == "StopsPOIs" || page == "GPXs" || page == "Days" || page == "Trips" || page == null) && tab.parrentmenuid==1) || 
              ((page == "CustomerItineraries" || page == "CustomerTrips" || page == "CustomerDays" || page == null) && tab.parrentmenuid==111) || 
              ((page == "PersonalizedItineraries" || page == "PersonalizedStopsPOIs" || page == "PersonalizedGPXs" || page == "PersonalizedDays" || page == "PersonalizedTrips" || page == "SelfGuidedPackagePersonalized" || page == "HotelPriceListPersonalized") && tab.parrentmenuid==101) ) &&
            (
              
            /*  <Link  onClick={this.logout(tab.id)}> */
            <Link onClick={() => this.logout(tab.url)}>
                <div
                  id={tab.id}
                  key={i}
                  className={[page == tab.url ? 'tablinks active' : 'tablinks'] + [tab.issubmenu ? ' submenu' : ''] + [((page == "ItinerariesBuilder" || page == null) && (tab.url == "ItinerariesBuilder" || tab.url == "StopsPOIs")) ? ' active' : ''] + [((page == "GPXs" || page == "Days" || page == "Trips") && tab.url == "ItinerariesBuilder") ? ' active' : '']}
                  onClick={() => {/* this.tabToggle(i,tab.id,tab.parrentmenuid)*/ }}
                >
                  {tab.displayName}
                </div>
              </Link>
            )
            ))}
          </div>
        
        }
        {page == "Days" &&
          <div className="tabcontent"><Days /></div>
        }
        {(page == "StopsPOIs" || page == "ItinerariesBuilder" || page == null) &&
          <div className="tabcontent"><StopsPois /></div>
        }
        {page == "GPXs" &&
          <div className="tabcontent"><GPX /></div>
        }
        {page == "Trips" &&
          <div className="tabcontent"><Trips /></div>
        }
        {page == "PersonalizedDays" &&
          <div className="tabcontent"><DaysPersonalized /></div>
        }
        {(page == "CustomerTrips" || page == "CustomerItineraries") &&
          <div className="tabcontent"><CustomerTrips /></div>
        }
        {page == "CustomerDays" &&
          <div className="tabcontent"><CustomerDays /></div>
        }
        {(page == "PersonalizedStopsPOIs" || page == "PersonalizedItineraries") &&
          <div className="tabcontent"><StopsPoisPersonalized /></div>
        }
        {page == "PersonalizedGPXs" &&
          <div className="tabcontent"><GPXPersonalized /></div>
        }
        {page == "PersonalizedTrips" &&
          <div className="tabcontent"><TripsPersonalized /></div>
        }
         {page == "SelfGuidedPackagePersonalized" &&
          <div className="tabcontent"><SelfGuidedPackagePersonalized /></div>
        }
         {page == "HotelPriceListPersonalized" &&
          <div className="tabcontent"><HotelPriceListPersonalized /></div>
        }
  
        {page == "PersonalizedGPXs" &&
          <div className="tabcontent"><GPXPersonalized /></div>
        }
        {(page == "Expenture Attractions" || page == "PlanYourTripAttractions")&&
          <div className="tabcontent"><ExpentureAttractions /></div>
        }
        {page == "LocalHeros" &&
          <div className="tabcontent"><LocalHero /></div>
        }
        {page == "Login" &&
          <div className="tabcontent1" style={{ textAlign: 'center' }}><Login /></div>
        }
        {page == "SelfGuidedPackages" &&
          <div className="tabcontent"><SelfGuidedPackage /></div>
        }
        {page == "Purchases" &&
          <div className="tabcontent"><Purchases /></div>
        }
        {page == "HotelPriceList" && 
          <div className="tabcontent"><HotelPriceList /></div>
        }
        {page == "CarRentPriceList" &&
          <div className="tabcontent"><CarRentPrice /></div>
        }
        {page == "Currencies" &&
          <div className="tabcontent"><Currencies /></div>
        }
        {page == "CountryHighSeason" &&
          <div className="tabcontent"><CountryHighSeason /></div>
        }
        {page == "packagesPricing" &&
          <div className="tabcontent"><Quotations /></div>
        }
        {page == "Users" &&
          <div className="tabcontent"><Users /></div>
        }
        {page == "Changelog" &&
          <div className="tabcontent"><Changelog /></div>
        }
        {page == "PayByLink" &&
          <div className="tabcontent"><PayByLink /></div>
        }
         {page == "PayByLinkList" &&
          <div className="tabcontent"><PayByLinkList /></div>
        }
        {page == "UploadAssets" &&
          <div className="tabcontent"><UploadAssets /></div>
        }
        {/*  {page!="Days" &&         
        <div className="tabcontent">{this.state.data[this.state.currentTab].info}</div>
      } */}
      </div>
    );
  }
}

export default App;