//import references
import React, { Component } from "react";
import FormControl from '@material-ui/core/FormControl';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { handlePhotoUpload } from "./uploadFiles"
import ReactFlagsSelect from "react-flags-select";
import Link from "@material-ui/core/Link";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import _uniqueId from "lodash/uniqueId";
import MUIDataTable from "mui-datatables";
import { CircularProgress, Typography } from "@material-ui/core";
import uploadIcon from "./themes/icons/uploadicon.png";
import MapboxAutocomplete from "react-mapbox-autocomplete";
import WebPoint from "./components/WebPoint";
import VG from "./components/VG";
import { handleCallApi, getDatafromApi } from './calls'
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import RelatedTrips from './components/RelatedTrips'
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { ApiData, countriesNames } from "./config";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

let pointNumbers = [];
let selectedRowIndex = false;
let selectedRowIds = [];
let selectedDayData = [];
let gpxLoc;
let daysData;

class Days extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      photourl: "",
      dayTableData: [["Loading Data..."]],
      searchData: [],
      updateDayRowIndex: "",
      selectedTab: 2,
      dayTitle: "",
      dayCountryCode: "",
      dayRegion: "",
      dayLengthHours: "",
      overnight: "",
      dayCoverPhoto: "",
      dayDescription: "",
      dayStartLocation: "",
      dayEndLocation: "",
      save_type: "add",
      openDeleteCnfrm: false,
      globalPoiSet: [],
      selectedWebPOIsKeys: [],
      selectedVgPOIsKeys: [],
      globalPoiSetWeb: [],
      globalPoiSetVg: [],
      overnight: "",
      selectedDayId: '',
      selectedWebPOI: [{ id: "", name: "Loading...", country: "" }],
      selectedVG: [{ id: "", name: "Loading...", country: "" }],
      showVg: true,
      openModalDay: false,
      Vgpoiall: [],
      relatedDays: [],
      loadingRelated: true,
      
    };
    window.bd = this;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.openModalDay !== prevState.openModalDay) {
       if(!this.state.openModalDay){
         this.setState({
           relatedDays: [],
           loadingRelated: true
         });
       }
    }
  }

  setglobalPoiSet = async (data) => {
    this.setState({ globalPoiSet: data });
  };
  setglobalPoiSetWeb = async (data) => {
    this.setState({ globalPoiSetWeb: data });
  };
  setglobalPoiSetVg = async (data) => {
    this.setState({ globalPoiSetVg: data });
  };
  setVgpoiall = async (data) => {
    this.setState({ setVgpoiall: data });
  };

  setSelectedWebPoi = async (data) => {
    this.setState({ selectedWebPOI: data });
  };

  setSelectedWebPOIsKeys = async (data) => {
    this.setState({ selectedWebPOIsKeys: data });
  };
  setSelectedVgPOIsKeys = async (data) => {
    this.setState({ selectedVgPOIsKeys: data });
  };

  setSelectedVG = async (data) => {
    this.setState({ selectedVG: data });
  };

  setSelectedVgByWeb = async (data) => {
    let id = data.id;
    data.type = "POI";
    data.originalType = "POI"; 
    let vgdata = [...this.state.selectedVG];
    let globalpoivg = [...this.state.globalPoiSetVg];
    let allreadyInArray = false;
    vgdata.forEach((vg) => {
      if (vg.id == id) allreadyInArray = true;
    });
    if (!allreadyInArray) {
      vgdata.push(data);
      globalpoivg.push(data.id);
    }

    this.setState({ globalPoiSetVg: globalpoivg });
    this.setState({ selectedVG: vgdata });

    this.setState({ showVg: false });

    setTimeout(
      function () {
        //Start the timer

        this.setState({ showVg: true });
      }.bind(this),
      200
    );
  };

  setStartLocationMap(poiLocation) {
    var poiCoordinates = poiLocation.split(",");
    if (poiCoordinates.length >= 2) {
      if (poiCoordinates[0] > 90) poiCoordinates[0] = 90;
      if (poiCoordinates[0] < -90) poiCoordinates[0] = -90;

      mapMarkersOne.forEach((marker) => marker.remove());
      mapMarkersOne = [];
      const marker = new mapboxgl.Marker()
        .setLngLat([poiCoordinates[1], poiCoordinates[0]])
        .addTo(webMapOne);
      mapMarkersOne.push(marker);
    }
  }
  setEndLocationMap(poiLocation) {
    var poiCoordinates = poiLocation.split(",");

    if (poiCoordinates.length >= 2) {
      if (poiCoordinates[0] > 90) poiCoordinates[0] = 90;
      if (poiCoordinates[0] < -90) poiCoordinates[0] = -90;

      mapMarkersTwo.forEach((marker) => marker.remove());
      mapMarkersTwo = [];
      const marker = new mapboxgl.Marker()
        .setLngLat([poiCoordinates[1], poiCoordinates[0]])
        .addTo(webMapTwo);
      mapMarkersTwo.push(marker);
    }
  }

  _suggestionSelected(result, lat, lng, text) {
    window.bd.setPoiLocation(lat, lng);
    window.bd.setStartLocationMap(lat + "," + lng);
  }
  _suggestionSelected1(result, lat, lng, text) {
    window.bd.setDayEndLocation(lat, lng);
    window.bd.setEndLocationMap(lat + "," + lng);
  }

  setPoiLocation(latitude, longitude) {
    this.setState({ dayStartLocation: latitude + "," + longitude });
    $(".react-mapbox-ac-input").val("");
  }

  setDayEndLocation(latitude, longitude) {
    this.setState({ dayEndLocation: latitude + "," + longitude });
    $(".react-mapbox-ac-input").val("");
  }

  handleDayValidation() {
    let errors = {};
    let formIsValid = true;
    if (!this.state.dayTitle) {
      formIsValid = false;
      errors["dayTitle"] = "*";
    }
    if (!this.state.dayCoverPhoto) {
      formIsValid = false;
      errors["dayCoverPhoto"] = "Please Select Cover Photo";
    }
    if (!this.state.dayStartLocation) {
      formIsValid = false;
      errors["dayStartLocation"] = "Please Select Starting Point";
      toast.error("Please Select Starting Point");
    }
    if (!this.state.dayEndLocation) {
      formIsValid = false;
      errors["dayEndLocation"] = "Please Select Ending Point";
      toast.error("Please Select Ending Point");
    }
    if (!this.state.dayCountryCode) {
      formIsValid = false;
      errors["dayCountryCode"] = "Please Select Country";
      toast.error("Please Select Country");
    }
    if (!this.state.overnight) {
      formIsValid = false;
      errors["overNight"] = "Overnight is required";
      toast.error("Overnight is required");
    }
    /* if (!this.state.dayDescription) {
       formIsValid = false;
       errors['dayDescription'] = '*';
     }
      if (!this.state.dayRegion) {
       formIsValid = false;
       errors['dayRegion'] = 'Please Select Region';
     } */
    /*   else{
        var loc = this.state.dayStartLocation.split(",");
        let pattern = new RegExp('^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}');
        if(pattern.test(loc[0]) && pattern.test(loc[1]))
        console.log('valid')
        else
        {
            formIsValid = false;
            errors['dayStartLocation'] = 'Not a Valid Location';
          }
        } */

    /*   if(this.state.dayEndLocation)
      {
       var loc = this.state.dayEndLocation.split(",");
       let pattern = new RegExp('^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}');
       if(pattern.test(loc[0]) && pattern.test(loc[1]))
       console.log('valid')
       else
       {
           formIsValid = false;
           errors['dayEndLocation'] = 'Not a Valid Location';
         }
      }  */

    this.setState({ errors: errors });
    return formIsValid;
  }

  async handleSaveDay(e) {
    e.preventDefault();
    toast.dismiss();
    if (this.handleDayValidation()) {
      if (!this.state.globalPoiSetWeb) {
        // formIsValid = false;
        toast.error("Please Select Web Poi From list");
        return;
      }
      if (this.state.globalPoiSetVg.length < 0) {
        // formIsValid = false;
        toast.error("Please Select VG Poi From list");
        return;
      }

      let dayData1 = [...this.state.dayTableData];
      var countryCode = this.state.dayCountryCode;
      var countryname = "";
      countriesNames.map(function (item) {
        if (item.code === countryCode) countryname = item.name;
      });

      var dayStartLocation = this.state.dayStartLocation.split(",");
      var dayEndLocation = this.state.dayEndLocation.split(",");
      var startLat = "";
      var startLng = "";
      var endLat = "";
      var endLng = "";
      var starting_point = null;
      var ending_point = null;
      if (dayStartLocation.length > 1) {
        startLat = parseFloat(dayStartLocation[0]);
        startLng = parseFloat(dayStartLocation[1]);
        starting_point = { latitude: startLat, longitude: startLng };
      }
      if (dayEndLocation.length > 1) {
        endLat = parseFloat(dayEndLocation[0]);
        endLng = parseFloat(dayEndLocation[1]);
        ending_point = { latitude: endLat, longitude: endLng };
      }

      let web_poi_set = [];
      this.state.selectedWebPOI.forEach((webpoi) => {
        web_poi_set.push(webpoi.id);
      });
      let vg_poi_set = [];
      let vg_gpx_set = [];
      let vg_pkg_set = [];
      this.state.selectedVG.forEach((vgpoi) => {
        if (vgpoi.type == "POI") vg_poi_set.push(vgpoi.id);
        if (vgpoi.type == "GPX") vg_gpx_set.push(vgpoi.id);
        if (vgpoi.type == "TRIALPACKAGE") vg_pkg_set.push(vgpoi.id);
      });
      let internal_name = _uniqueId("");
      if (this.state.save_type != "add" && this.state.updateDayRowIndex){
        if(this.state.searchData.length > 0){
          let searchData1 = [...this.state.searchData];
          const rowSearched = searchData1[this.state.updateDayRowIndex];
          internal_name = rowSearched.internal_name;
        }else{
          const row = dayData1[this.state.updateDayRowIndex];
          internal_name = row.internal_name;
        }
      }
      let webpoiSet = [];
      if (this.state.globalPoiSetWeb != "")
        webpoiSet = this.state.globalPoiSetWeb;
      let vgpoiSet = [];
      if (this.state.globalPoiSetVg != "") vgpoiSet = this.state.globalPoiSetVg;

      let photoUrl = "";
      let a = this.state.dayCoverPhoto.split(" ");

      for (let i = 0; i < a.length; i++) {
        if (i != 0) photoUrl = photoUrl + "%20";
        photoUrl = photoUrl + a[i];
      }

      let webpoiSetAsNum = webpoiSet.map(str => Number(str));
      let vgpoiSetAsNum = vgpoiSet.map(str => Number(str));

      let apidata = {
        country: countryname,
        description: this.state.dayDescription,
        internal_name: internal_name.trim(),
        name: this.state.dayTitle.trim(),
        photo: photoUrl,
        region: this.state.dayRegion,
        starting_point: starting_point,
        ending_point: ending_point,
        web_poi_set: webpoiSetAsNum,
        vg_poi_set: vgpoiSetAsNum,
        overnight: this.state.overnight,
        personalized:false,
        custom: false
        //wayoint: this.state.selectedWebPOI,
        /* web_poi_set: web_poi_set,
        vg_poi_set: vg_poi_set,
        vg_gpx_set: vg_gpx_set,
        vg_package_set: vg_pkg_set, */
      };

      let method
      let url1;
      if (this.state.save_type != "add") {
        method = "PUT";
        url1 = ApiData.apiUrl + `/days/${this.state.selectedDayId}?admin=true`;
      } else {
        method = "POST";
        url1 = ApiData.apiUrl + `/days/`;
      }

      let success = method === 'POST' ? 'Day Added' : 'Day Updated';
      let error = 'Missing Information / Fields'
      let data = await handleCallApi(method, url1, JSON.stringify(apidata), success, error)
      if (data) {
        if (this.state.save_type === "add") {
          dayData1.push(data);
          if(this.state.searchData && this.state.searchData.length){
            let searchData1 = [...this.state.searchData];
            searchData1.push(data)
            this.setState({ searchData: searchData1 })
          }
          this.setState({ dayTableData: dayData1 })
        } else {
          if (this.state.searchData && this.state.searchData.length > 0) {
            this.state.searchData[this.state.updateDayRowIndex] = data;
            this.setState({ searchData: this.state.searchData })
          }
          else {
            let table = this.state.dayTableData
            let index = this.state.updateDayRowIndex
            table[index] = data;
            this.setState({ dayTableData: table })
          }
        }

        this.setState({
          dayTitle: "",
          dayCountryCode: "",
          dayRegion: "",
          dayLengthHours: "",
          dayCoverPhoto: "",
          dayDescription: "",
          dayStartLocation: "",
          dayEndLocation: "",
          overnight: "",
          selectedDayId: ""
        });

        closePopoup();
      }

    };
  }

  handlePhoto = async (event) => {
    try {
      const url = await handlePhotoUpload(event, 'planmytrip-assets', 'days');
      if (url) {
        this.setState({ dayCoverPhoto: url });
      }
    } catch (error) {
      console.log('Error uploading photo:', error);
    }
  };

  setDaysSearchData = async (data) => {
    this.setState({ searchData: data });
  };

  setDayData = async (
    webPoiData,
    vgPoiData,
    globalPoiSetWeb,
    globalPoiSetVg,
    dayTitle,
    dayCountryCode,
    dayRegion,
    dayLengthHours,
    dayCoverPhoto,
    dayDescription,
    dayStartLocation,
    dayEndLocation,
    save_type,
    updateDayRowIndex,
    overNight,
    id
  ) => {
    let index;
    if(!updateDayRowIndex){
      if(this.state.searchData.length){
        index = this.state.searchData.findIndex(obj => obj.id === this.state.selectedDayId);
      }else{
        index = this.state.dayTableData.findIndex(obj => obj.id === this.state.selectedDayId);
      }
    }else{
      index = updateDayRowIndex
    }
    this.setState({
      selectedWebPOIsKeys: webPoiData,
      selectedVgPOIsKeys: vgPoiData,
      globalPoiSetWeb: globalPoiSetWeb,
      globalPoiSetVg: globalPoiSetVg,
      dayTitle: dayTitle,
      dayCountryCode: dayCountryCode,
      dayRegion: dayRegion,
      dayLengthHours: dayLengthHours,
      dayCoverPhoto: dayCoverPhoto,
      dayDescription: dayDescription,
      dayStartLocation: dayStartLocation,
      dayEndLocation: dayEndLocation,
      save_type: save_type,
      updateDayRowIndex: index === -1 ? 0 : index,
      selectedTab: 2,
      overnight: overNight
    });
    this.setSelectedWebPoi([]);
    // this.setSelectedVG([]);
    this.setState({ selectedTab: 0 });
  };

  handleClickOpen = () => {
    if (selectedRowIds.length > 0) this.setState({ openDeleteCnfrm: true });
  };

  handleDelete = () => {
    this.setState({ openDeleteCnfrm: false });
    var array = [...this.state.dayTableData]; // make a separate copy of the array
    var array1= [...this.state.searchData];

    /* selectedRowIndex.sort(function (a, b) {
      return b - a;
    });
    selectedRowIndex.forEach(async (i) => {
      let delid = array[i].id;
      array.splice(i, 1);
      await handleCallApi('DELETE', ApiData.apiUrl + `/days/${delid}/`, {}, '', '')
    });
    this.setDayTableData(array);
    selectedRowIndex = []; */


    selectedRowIds.forEach(async (delid) => {
  
      for(let a1=0;a1<array.length;a1++)
      {
        if(delid==array[a1].id)
           array.splice(a1, 1);
      }
      for(let a1=0;a1<array1.length;a1++)
      {
        if(delid==array1[a1].id)
           array1.splice(a1, 1);
      }
     
      await handleCallApi('DELETE', ApiData.apiUrl + `/days/${delid}`, {}, '', '')
    })
    this.setState({ searchData: array1 });
    this.setDayTableData(array);
    selectedRowIndex = []; 
    selectedRowIds = [];

  };









  handleDeleteOld = () => {
    this.setState({ openDeleteCnfrm: false });
    var array = [...this.state.dayTableData]; // make a separate copy of the array
    selectedRowIndex.sort(function (a, b) {
      return b - a;
    });
    selectedRowIndex.forEach(async (i) => {
      let delid = array[i].id;
      array.splice(i, 1);
      await handleCallApi('DELETE', ApiData.apiUrl + `/days/${delid}/`, {}, '', '')
    });
    this.setDayTableData(array);
    selectedRowIndex = [];
  };

  handleClose = () => {
    this.setState({ openDeleteCnfrm: false });
    selectedRowIndex = [];
    selectedRowIds = [];
  };

  setDayTableData = async (data) => {
    this.setState({ dayTableData: data });
  };

  handleDaySelecteId = (id) => {
    this.setState({ selectedDayId: id, openModalDay: true})
  }

  handlRelatedTrips = (data) => {
    this.setState({ relatedDays: data, loadingRelated: false })
  }

  closeModalState = () => {
    this.setState({openModalDay: false, globalPoiSetWeb: [], globalPoiSetVg: [], selectedDayId: '', selectedWebPOIsKeys: [],  setSelectedVgByWeb: [], selectedVG: [], setVgpoiall: []})
  }

  render() {
    return (
      <div>
        <Dialog
          open={this.state.openDeleteCnfrm}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are You Sure You Want to delete this Day
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button onClick={this.handleDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <div className="modal">
          <div className="modal_content">
            <div onClick={this.closeModalState}>
              <span className="close">&times;</span>
            </div>
            <div className="model_html">
              <h2>{this.state.save_type === 'add' ? 'Add New Day' : `Update Day ${this.state.selectedDayId}`}</h2>
			  <RelatedTrips loadingRelated={this.state.loadingRelated} handlRelatedTrips={this.handlRelatedTrips} relatedDays={this.state.relatedDays} openModalDay={this.state.openModalDay} selectedDay={this.state.selectedDayId}></RelatedTrips>
              <table cellPadding="8">
                <tbody>
                  <tr>
                    <td width="50%">
                      <label>Day Title</label>
                      <br />
                      <TextField
                        id="dayTitle"
                        size="medium"
                        variant="outlined"
                        style={{ width: "90%" }}
                        value={this.state.dayTitle}
                        error={!!this.state.errors["dayTitle"]}
                        onChange={(event) =>
                          this.setState({ dayTitle: event.target.value })
                        }
                      />
                    </td>

                    <td>
                      {this.state.dayCoverPhoto
                        ? [
                          <div>
                            <label>Cover Photo</label>
                            <br />
                            <span className="labelsmall">
                              {" "}
                              Currently:
                              <span className="colorgreen">
                                {" "}
                                {this.state.dayCoverPhoto}
                              </span>
                            </span>
                            <br />
                            <br />
                          </div>,
                        ]
                        : null}
                      <div className="divUpload">
                        <img width="20px" src={uploadIcon} />
                        <Button
                          variant="contained"
                          component="label"
                          style={{ float: "right", fontSize: 9 }}
                        >
                          UPLOAD FILE
                          <input
                            type="file"
                            hidden
                            id="fileSNPhoto"
                            size="small"
                            value=""
                            onChange={this.handlePhoto}
                          />
                        </Button>
                      </div>
                      <span className="errorMsg">
                        {this.state.errors["dayCoverPhoto"]}
                      </span>
                      <div>
                        {this.state.dayCoverPhoto && <img style={{ height: '140px', width: '180px' }} src={this.state.dayCoverPhoto}></img>}
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label>Country</label>
                      <br />

                      <ReactFlagsSelect
                        selected={this.state.dayCountryCode}
                        id={"selectCountriesDay"}
                        showSelectedLabel={true}
                        style={{ width: "200px" }}
                        showOptionLabel={true}
                        onSelect={(code) => {
                          this.setState({ dayCountryCode: code });
                        }}
                        placeholder={"Select a Country"}
                        countries={[
                          "GR",
                          "CR",
                          "NO",
                          "GE",
                          "MX",
                          "ME",
                          "DK",
                          "PL",
                          "PT",
                          "IS",
                          "MA",
                          "IL",
                          "TR",
                          "EC",
                          "ZA",
                          "IN",
                          "LT",
                          "IE",
                          "ES",
                        ]}
                      />

                      {/* 'KR',   'PE','RO',     'LK','CL','AR','AE','TZ','BG','IT' */}
                      {/* 'TR', 'LT','IE' */}
                      <span className="errorMsg">
                        {this.state.errors["dayCountryCode"]}
                      </span>

                      {/* <TextField
                        id="txtSNCountry"
                        size="small"
                        variant="outlined"
                        value={this.state.stop_country}
                        onChange={(event) =>
                          this.setState({ stop_country: event.target.value })
                        } 
                      />*/}
                    </td>
                    <td style={{ verticalAlign: "baseline" }}>
                      <label>Region</label>
                      <br />
                      <FormControl
                        style={{ maxWidth: "200px" }}
                        className="dropdownWithborder"
                      >
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Type"
                          value={this.state.dayRegion}
                          onChange={(event) => {
                            this.setState({ dayRegion: event.target.value });
                          }}
                        >
                          <MenuItem value={"Northern"}>Northern</MenuItem>
                          <MenuItem value={"South"}>South</MenuItem>
                        </Select>
                      </FormControl>
                      <span className="errorMsg">
                        {this.state.errors["dayRegion"]}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td style={{ alignItems: "center" }}>
                      <label>Length( in Hours)</label>&nbsp;&nbsp;&nbsp;
                      <TextField
                        id="txtLengthHours"
                        size="small"
                        variant="outlined"
                        style={{ width: 50 }}
                        value={this.state.dayLengthHours}
                        onChange={(event) =>
                          this.setState({ dayLengthHours: event.target.value })
                        }
                      />
                    </td>
                    <td style={{ alignItems: "center" }}>
                      <label>Overnight</label>&nbsp;&nbsp;&nbsp;
                      <TextField
                        id="txtLengthHours"
                        size="small"
                        variant="outlined"
                        style={{ width: '50%' }}
                        value={this.state.overnight}
                        onChange={(event) =>
                          this.setState({ overnight: event.target.value })
                        }
                      /><br/>
                      <span className="errorMsg">&nbsp;&nbsp;
                        {this.state.errors["overNight"]}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      <label>Description</label>
                      <br />
                      <TextField
                        id="txtDescription"
                        multiline
                        style={{ width: "80%" }}
                        rows={4}
                        variant="outlined"
                        fullWidth={true}
                        value={this.state.dayDescription}
                        error={!!this.state.errors["dayDescription"]}
                        onChange={(event) =>
                          this.setState({ dayDescription: event.target.value })
                        }
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label>Day Start Location</label>
                      <br />
                      <TextField
                        id="txtStartLocation"
                        className="location"
                        variant="outlined"
                        fullWidth={true}
                        value={this.state.dayStartLocation}
                        onChange={(event) => {
                          this.setState({
                            dayStartLocation: event.target.value,
                          });
                          this.setStartLocationMap(event.target.value);
                        }}
                      />
                      <br />{" "}
                      <span className="errorMsg">
                        {this.state.errors["dayStartLocation"]}
                      </span>
                      <br />
                      OR
                      <MapboxAutocomplete
                        publicKey={mapboxgl.accessToken}
                        inputclassName="form-control search"
                        onSuggestionSelect={this._suggestionSelected}
                        onChange={(e) =>
                          console.log("newlocation1", e.target.value)
                        }
                        resetSearch={false}
                      />
                    </td>
                    <td>
                      <label>Day End Location</label>
                      <br />
                      <TextField
                        id="txtEndLocation"
                        className="location"
                        variant="outlined"
                        fullWidth={true}
                        value={this.state.dayEndLocation}
                        onChange={(event) => {
                          this.setState({ dayEndLocation: event.target.value });
                          this.setEndLocationMap(event.target.value);
                        }}
                      />
                      <br />
                      <span className="errorMsg">
                        {this.state.errors["dayEndLocation"]}
                      </span>{" "}
                      <br />
                      OR
                      <MapboxAutocomplete
                        publicKey={mapboxgl.accessToken}
                        inputclassName="form-control search"
                        onSuggestionSelect={this._suggestionSelected1}
                        onChange={(e) =>
                          console.log("newlocation1", e.target.value)
                        }
                        resetSearch={false}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <MapboxGLMap prefix={"mapDay-1"} />
                    </td>
                    <td>
                      <MapboxGLMap prefix={"mapDay-2"} />
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      <Tabs
                        value={0}
                        onChange={(event, newvalue) => {
                          this.setState({ selectedTab: newvalue });
                        }}
                        aria-label="basic tabs example"
                      >
                        <Tab label="WEB" value={0} />
                        {/*  <Tab label="VG" value={1} /> */}
                      </Tabs>
                    </td>
                  </tr>
                  {this.state.selectedTab == 0 && (
                    <tr>
                      <td
                        colspan="2"
                        style={{
                          borderColor: "#000",
                          border: "solid 1px #ccc",
                        }}
                      >
                        <WebPoint
                          saveType={this.state.save_type}
                          openModalDay={this.state.openModalDay}
                          setglobalPoiSet={this.setglobalPoiSetWeb}
                          globalPoiSet={this.state.globalPoiSetWeb}
                          selectedWebPOIsKeys={this.state.selectedWebPOIsKeys}
                          setSelectedWebPOIsKeys={this.setSelectedWebPOIsKeys}
                          setSelectedWebPoi={this.setSelectedWebPoi}
                          setSelectedVgByWeb={this.setSelectedVgByWeb}
                        />
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colspan="2">
                      <Tabs
                        value={0}
                        onChange={(event, newvalue) => {
                          this.setState({ selectedTab: newvalue });
                        }}
                        aria-label="basic tabs example"
                      >
                        {/*  <Tab label="WEB" value={0} /> */}
                        <Tab label="VG" value={0} />
                      </Tabs>
                    </td>
                  </tr>

                  {this.state.selectedTab == 0 && this.state.showVg && (
                    <tr>
                      <td
                        colspan="2"
                        style={{
                          borderColor: "#000",
                          border: "solid 1px #ccc",
                        }}
                      >
                        <VG
                          openModalDay={this.state.openModalDay}
                          setglobalPoiSet={this.setglobalPoiSetVg}
                          globalPoiSet={this.state.globalPoiSetVg}
                          selectedVG={this.state.selectedVG}
                          setSelectedVG={this.setSelectedVG}
                          setVgpoiall={this.setVgpoiall}
                          Vgpoiall={this.state.Vgpoiall}
                        />
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td colSpan="4">
                      {this.state.save_type == "add" && (
                        <Button
                          id="btnSaveSN"
                          variant="contained"
                          color="primary"
                          onClick={this.handleSaveDay.bind(this)} 
                        >
                          CREATE
                        </Button>
                      )}
                      {this.state.save_type != "add" && (
                        <Button
                          id="btnSaveSN"
                          variant="contained"
                          color="primary"
                          onClick={this.handleSaveDay.bind(this)}
                        >
                          SAVE CHANGES
                        </Button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
        <ToastContainer />
        <table cellPadding="10" className="viewdata">
          <tbody>
            <tr>
              <td>
                <h2>Days Module</h2>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan="3"></td>
              <td>
                &nbsp;
                <Button
                  id="btnSave"
                  variant="contained"
                  color="#15cc09"
                  className="deleteBtn"
                  onClick={() => {
                    this.handleClickOpen();
                  }}
                  style={{ float: "right", fontSize: 12, color: "#fff" }}
                >
                  DELETE SELECTED
                </Button>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <DaysTable
                  dayTableData={this.state.dayTableData}
                  searchData={this.state.searchData}
                  setDaysSearchData={this.setDaysSearchData}
                  setDayTableData={this.setDayTableData}
                  setDayData={this.setDayData}
                  setStartLocationMap={this.setStartLocationMap}
                  setEndLocationMap={this.setEndLocationMap}
                  handleDaySelecteId={this.handleDaySelecteId}
                />
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <Link
                  href="#"
                  onClick={() => {
                    this.setDayData(
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "add",
                      "",
                      "",
                      ""
                    );
                    this.setState({openModalDay: true})
                    openPopup();
                    initializeMap(-1, "mapDay-1");
                    initializeMap(-1, "mapDay-2");
                  }}
                >
                  + ADD NEW DAY{" "}
                </Link>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <div className="borderDiv" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default Days;

/* openPopup= () =>
{
  const modal = document.querySelector(".modal")
  const closeBtn = document.querySelector(".close")
  modal.style.display = "block";
  closeBtn.addEventListener("click", () => {
    modal.style.display = "none";
  })
}
 */
//Mapbox Key
mapboxgl.accessToken =
  "pk.eyJ1IjoidXJpYWJyaWRnaWZ5IiwiYSI6ImNrb3IybjRtYjEwd2wydnBpdGxvNWVoYzIifQ.jUaSkJhni2GW103YouzXOQ";
let mapMarkersOne = [];
let mapMarkersTwo = [];
let webmapMarkers = [];
let vgmapMarkers = [];

let webMapOne;
let webMapTwo;
let webMap;
let vgMap;

const initializeMap = (z, id) => {
  if (id == "mapDayVG") {
    vgMap = new mapboxgl.Map({
      container: id,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [0, 0],
      zoom: z,
    });
    //Add navigation controls to the mapbox if zoom is lower
    if (z < 11)
      vgMap.addControl(new mapboxgl.NavigationControl(), "bottom-right");
    vgMap.on("click", function (e) {
      if (selectedRowIndex) {
        addVgMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      } else {
        toast.error(
          "Please select a trip first by clicking VG button against the record"
        );
      }
    });
  } else if (id == "mapDayStop") {
    webMap = new mapboxgl.Map({
      container: id,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [0, 0],
      zoom: z,
    });
    //Add navigation controls to the mapbox if zoom is lower
    if (z < 11)
      webMap.addControl(new mapboxgl.NavigationControl(), "bottom-right");
    webMap.on("click", function (e) {
      if (selectedRowIndex) {
        addWebMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      } else {
        addWebMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
        // toast.error('11Please select a trip first by clicking Web button against the record');
      }
    });
  } else {
    if (id == "mapDay-1") {
      webMapOne = new mapboxgl.Map({
        container: id,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [0, 0],
        zoom: z,
      });
      webMapOne.on("click", function (e) {
        window.bd.setPoiLocation(
          e.lngLat.lat.toFixed(6),
          e.lngLat.lng.toFixed(6)
        );

        $("#txtStartLocDay").val(
          e.lngLat.lng.toFixed(6) + "," + e.lngLat.lat.toFixed(6)
        );
        // this.setState({ startloc: e.lngLat.lng.toFixed(4) + ',' + e.lngLat.lat.toFixed(4) });
        mapMarkersOne.forEach((marker) => marker.remove());
        mapMarkersOne = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapOne);
        mapMarkersOne.push(marker);
      });
    } else {
      webMapTwo = new mapboxgl.Map({
        container: id,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [0, 0],
        zoom: z,
      });

      webMapTwo.on("click", function (e) {
        //window.bd.setPoiLocation(e.lngLat.lat.toFixed(6), e.lngLat.lng.toFixed(6));
        window.bd.setState({
          dayEndLocation:
            e.lngLat.lat.toFixed(6) + "," + e.lngLat.lng.toFixed(6),
        });
        $("#txtEndLocDay").val(
          e.lngLat.lng.toFixed(6) + "," + e.lngLat.lat.toFixed(6)
        );
        mapMarkersTwo.forEach((marker) => marker.remove());
        mapMarkersTwo = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapTwo);
        mapMarkersTwo.push(marker);
      });
    }
  }
};

class MapboxGLMap extends Component {
  constructor(props) {
    super(props);
    // this.id = _uniqueId(props.prefix + "-");
    this.id = props.prefix;
  }
  componentDidMount() {
    initializeMap(-1, this.id);
  }
  render() {
    const id = this.id;
    return (
      <div id={id} className="mapEA">
        {" "}
      </div>
    );
  }
}

class DaysTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [["Loading Data..."]],
      loading: false,
      page: 0,
      rowsPerPage: 10,
      count: 0,
      filterData: [],
      filterBy: '',
      filterValue: '',
      searchTerm: '',
      allDays: [],
      filterState: false,
      ascendingSort: true,
    };
  }

  async componentDidMount() {
    let data = await this.getData();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has("id")) {
      let id = urlParams.getAll("id");
      this.setDaysData(id, data);
      const parseId = parseInt(id[0]);
      this.props.handleDaySelecteId(parseId);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.page !== prevState.page || this.state.rowsPerPage !== prevState.rowsPerPage) {
      this.getData();
    }
  }

  setDaysData = async (id, data) => {

    let filterId = parseInt(id[0]);
    let filterRow = await this.getDayById(filterId)
    let getIndexRow = data.results.findIndex((row) => row.id === filterId) || '';
    let globalPoiSetVg = filterRow.vg_poi_set_sort.split(',');
    let globalPoiSetWeb = filterRow.web_poi_set_sort.split(',');
    let globalPoiSetWebNum = globalPoiSetWeb.map(Number)
    let globalPoiSetVgNum = globalPoiSetVg.map(Number)
    let vgPoiData = filterRow.vg_poi_set;
    let WebPoiData = filterRow.web_poi_set;
    let overNight = filterRow.overnight;
    var countryCode;
    var countryname = filterRow.country;
    countriesNames.map(function (item) {
      if (item.name === countryname) countryCode = item.code;
    });
    let startLoc = "";
    if (filterRow.starting_point)
      startLoc =
        filterRow.starting_point?.latitude +
        "," +
        filterRow.starting_point?.longitude;
    let endLoc = "";
    if (filterRow.ending_point)
      endLoc =
        filterRow.ending_point?.latitude + "," + filterRow.ending_point?.longitude;

    this.props.setDayData(
      vgPoiData,
      WebPoiData,
      globalPoiSetWebNum,
      globalPoiSetVgNum,
      filterRow.name,
      countryCode,
      filterRow.region,
      5,
      filterRow.photo,
      filterRow.description,
      startLoc,
      endLoc,
      "update",
      getIndexRow,
      overNight,
      id
    );
    openPopup()
    initializeMap(-1, "mapDay-1");
    initializeMap(-1, "mapDay-2");
    if (filterRow.starting_point)
      this.props.setStartLocationMap(startLoc);
    if (filterRow.ending_point)
      this.props.setEndLocationMap(endLoc);
  };

  getData = () => {
    this.setState({ loading: true });
    let dataRows = this.xhrRequest().then(async (data) => {
      // if(this.state.allDays.length === 0) {
      //   await this.getAllData();
      // }
      this.setState({ data, loading: false, count: data.count });
      this.props.setDayTableData(data.results);
      return data;
    });
    return dataRows;
  };

  getAllData = () => {
    return new Promise(async (resolve, reject) => {
      let data =await getDatafromApi(ApiData.baseURL + `/days/all-days`)
      if(data){
        this.setState({ allDays: data});
      }
      resolve(data)
    });
  };

  xhrRequest = (url) => {
    return new Promise(async (resolve, reject) => {
      let data = await getDatafromApi(ApiData.apiUrl + `/days?take=${this.state.rowsPerPage}&skip=${this.state.rowsPerPage * this.state.page}&personalized=false&custom=false`)
      if (data) {
        data.results.sort((a, b) => a.id - b.id);
        daysData = data.results;
        resolve(data);
      }
    });
  };

  getFilteredData = async () => {
    if(this.state.searchTerm.length > 0 || this.state.filterValue.length > 0){
      this.setState({  loading: true })
      this.xhrRequestFilterData().then((data) => {
        this.setState({ loading: false }, () => {
          this.props.setDaysSearchData(data);
          this.setState({ filteredData: data, filterState: true });
        })
      });
    }
  };
  

  xhrRequestFilterData = () => {
    if(this.state.searchTerm.length > 0 || this.state.filterValue.length > 0){
      return new Promise(async (resolve, reject) => {
        let data = await getDatafromApi(ApiData.baseURL + `/vg/days/findDays?personalized=false&custom=false&${this.state.searchTerm ? `name=${this.state.searchTerm}&` : ''}${this.state.filterValue ? `${this.state.filterBy}=${this.state.filterValue}` : ''}`)
        if (data) {
          data.sort((a, b) => a.id - b.id);
          let data1 = data.filter(function (item) { return item.personalized == false; });
          resolve(data1);
        }
      });
    }
  };

  getDayById = (id) => {
    return new Promise(async (resolve, reject) => {
      let data = await getDatafromApi(ApiData.apiUrl + `/days/${id}`)
      if (data) {
        resolve(data);
      }
    });
  };

  render() {
    const columns = [
      {
        name: "id",
        label: "ID",
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            var editrow = {};
            var rowIndexForUpdate = 0;
            var i = 0;
   
            if(this.props.searchData && this.props.searchData.length){
              this.props.searchData.forEach((row) => {
                if (row.id == value) {
                  editrow = row;
                  rowIndexForUpdate = i;
                }
                i++;
              });
            }else{
              this.props.dayTableData.forEach((row) => {
                if (row.id == value) {
                  editrow = row;
                  rowIndexForUpdate = i;
                }
                i++;
              });
            }
            let overNight = "";
            if (editrow.overnight)
              overNight = editrow.overnight;
            var countryCode;
            var countryname = editrow.country;
            countriesNames.map(function (item) {
              if (item.name === countryname) countryCode = item.code;
            });
            let startLoc = "";
            if (editrow.starting_point){
              startLoc =
                editrow.starting_point?.latitude +
                "," +
                editrow.starting_point?.longitude;
            }else{
              startLoc =
              editrow.starting_point_latitude +
              "," +
              editrow.starting_point_longitude;
            }
            let endLoc = "";
            if (editrow.ending_point){
              endLoc =
                editrow.ending_point?.latitude +
                "," +
                editrow.ending_point?.longitude;
            }else{
              endLoc =
              editrow.ending_point_latitude +
              "," +
              editrow.ending_point_longitude;
            }
            let id = '';
            if(editrow.id){
              id = editrow.id
            }else{
              id= value
            }

            return (
              // dayTitle, dayCountryCode, dayRegion, dayLengthHours, dayCoverPhoto, dayDescription,dayStartLocation,dayEndLocation
              <Link
                href="#"
                onClick={() => {
                  console.log('editrow',editrow)
                  this.props.handleDaySelecteId(editrow.id);
                  let globalPoiSetVgIds = editrow.vg_poi_set_sort.split(',');
                  let globalPoiSetWebIds = editrow.web_poi_set_sort.split(',');
                  let vgPoiData = editrow.vg_poi_set;
                  let WebPoiData = editrow.web_poi_set;
                  this.props.setDayData(
                    vgPoiData,
                    WebPoiData,
                    globalPoiSetWebIds,
                    globalPoiSetVgIds,
                    editrow.name,
                    countryCode,
                    editrow.region,
                    5,
                    editrow.photo,
                    editrow.description,
                    startLoc,
                    endLoc,
                    "update",
                    rowIndexForUpdate,
                    overNight,
                    value
                  );
                  window.history.pushState(null, null, `?page=Days&id=${editrow.id}`);
                  openPopup()
                  initializeMap(-1, "mapDay-1");
                  initializeMap(-1, "mapDay-2");
                  if (startLoc)
                    this.props.setStartLocationMap(startLoc);
                  if (endLoc)
                    this.props.setEndLocationMap(endLoc);
                }}
              >
                {value}
              </Link>
            );
          },
        },
      },

      {
        name: "internal_name",
        label: "Internal Name",
        options: { filter: false },
      },
      { name: "name", label: "Name", options: { filter: false } },
      {
        name: "country", label: "Country", options: {
          filter: true,
          filterOptions: {
            names: countriesNames.map(country => country.name)
          },
        }
      },
      {
        name: "region", label: "Region", options: {
          filter: true,
          filterOptions: {
            names: ['South', 'North', 'East', 'West']
          },
        }
      },
    ];

    const onRowsSelect = (curRowSelected, rowsSelected) => {
      selectedRowIndex = [];
      let d1 = filterState ? searchData : dayTableData;
      for (let j = 0; j < d1.length; j++) {
       selectedRowIds.splice(selectedRowIds.indexOf(d1[j].id), 1 )
      }




      for (let i = 0; i < rowsSelected.length; i++) {
        selectedRowIndex.push(rowsSelected[i].index);
        selectedRowIds.push(d1[rowsSelected[i].index].id);
        // console.log('aaaa',d1[rowsSelected[i].index].id)
      }


      activeDeactiveDeleteBtn();
     // console.log('selectedRowIds', selectedRowIds)
    };
    const onRowsSelectOld = (curRowSelected, rowsSelected) => {
      selectedRowIndex = [];
      for (let i = 0; i < rowsSelected.length; i++) {
        selectedRowIndex.push(rowsSelected[i].dataIndex);
      }
      activeDeactiveDeleteBtn();
    };

    const handleChangePage = (event, newPage) => {
      this.setState({ page: event });
      selectedRowIndex=[]
      selectedRowIds=[]
      activeDeactiveDeleteBtn();
    };

    const handleChangeRowsPerPage = (event) => {
      this.setState({ rowsPerPage: event, page: 0 });
    };
    const changeSlectedRow = () => {
      selectedRowIndex = [];
      let d1 = filterState ? searchData : dayTableData;
      for (let i = 0; i < d1.length; i++) {
        for (let j = 0; j < selectedRowIds.length; j++) {
          if (d1[i].id == selectedRowIds[j])
            selectedRowIndex.push(i)
        }
      }

    }
    const handleSortChange = () => {
      let isAscending = this.state.ascendingSort
      if(this.state.filterState && this.props.searchData.length){
        const searchData = this.props.searchData;
        searchData.sort((a, b) => {
          if (a.id < b.id) {
            return isAscending ? -1 : 1;
          }
          if (a.id > b.id) {
            return isAscending ? 1 : -1;
          }
          return 0;
        });
        this.props.setDaysSearchData(searchData)
      }else{
        const data = this.props.dayTableData;
        data.sort((a, b) => {
          if (a.id < b.id) {
            return isAscending ? -1 : 1;
          }
          if (a.id > b.id) {
            return isAscending ? 1 : -1;
          }
          return 0;
        });

        this.props.setDayTableData(data);
        changeSlectedRow()
      }
  
      this.setState({ascendingSort: !isAscending});
    };

    const handleOnFilterChange = (changedColumn, filterList) => {
      let filterBy = changedColumn;
      let filterValue = (filterList[3].join('') || filterList[4].join(''));
      this.setState({ filterBy, filterValue }, () => {
        this.getFilteredData()
      });
      selectedRowIndex=[]
      selectedRowIds=[]
      activeDeactiveDeleteBtn();
    };

    const handleOnFilterClose = () => {
      if (this.state.filterBy && this.state.filterValue) {
        deleteFilterData()
      }
      selectedRowIndex=[]
      selectedRowIds=[]
      activeDeactiveDeleteBtn();
    }
    const deleteFilterData = () => {
      this.setState({ filterBy: '', filterValue: ''}, () => {
        if(this.state.searchTerm.length > 0){
          this.getFilteredData()
        }else{
          this.setState({ filterData: [], filterState: false});
          this.props.setDaysSearchData([])
        }
      })
      selectedRowIndex=[]
      selectedRowIds=[]
      activeDeactiveDeleteBtn();
    }

    const deleteSearchData = () => {
      this.setState({ searchTerm: '' }, () => {
        if(this.state.filterValue.length > 0){
          this.getFilteredData()
        }else{
          this.setState({ filterData: [], filterState: false, searchTerm: '' , loading: false});
          this.props.setDaysSearchData([])
        }
      });
      selectedRowIndex=[]
      selectedRowIds=[]
      activeDeactiveDeleteBtn();
    }


    const searchByDayName = (searchTerm) => {
      if (searchTerm) {
        if (!isNaN(Number(searchTerm))) {
          this.setState({ searchTerm }, () => {
            this.getFilteredData()
          });
        }
        if (isNaN(Number(searchTerm)) && searchTerm.length > 3) {
          this.setState({ searchTerm }, () => {
            this.getFilteredData()
          });
        }
     }
     if(searchTerm === null){
      this.setState({ searchTerm: '' });
      deleteSearchData()
     }
     selectedRowIndex=[]
     selectedRowIds=[]
     activeDeactiveDeleteBtn();
    }

    const { filterData, page, rowsPerPage, count, filterState } = this.state;
    const { dayTableData, searchData } = this.props;

    const options = {
      filter: true,
      filterType: "dropdown",
      responsive: "standard",
      download: false,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: "none",
      rowsSelected: selectedRowIndex,
      serverSide: true,
      rowsPerPage: rowsPerPage,
      page: page,
      count: count,
      onColumnSortChange: handleSortChange,
      pagination: filterState ? false : true,
      onRowSelectionChange: onRowsSelect,
      onChangePage: handleChangePage,
      onChangeRowsPerPage: handleChangeRowsPerPage,
      onSearchChange: searchByDayName,
      onFilterChange: handleOnFilterChange,
      /* onFilterDialogClose: handleOnFilterClose, */
      onFilterChipClose: deleteFilterData,
      onSearchClose: deleteSearchData
    };
    return (
      <div>
        {this.state.loading ? (
          <p style={{ position: 'absolute', top: '80px'}}>Loading Data...</p>
        ) : (
          this.state.filterState ? <p style={{ position: 'absolute', top: '80px'}}>{this.props.searchData.length} days found</p> : null
        )}

        <MUIDataTable
          title={
            <Typography variant="subtitle2">

              {this.state.loading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              )}
            </Typography>
          }
          data={filterState ? searchData : dayTableData}
          columns={columns}
          options={options}
          rowsSelected={onRowsSelect}
        />
      </div>
    );
  }
}

function addWebMarker(coordinates) {
  const id = _uniqueId("webmarker-");
  let marker;
  var el = document.createElement("div");
  el.className = "marker";
  el.id = id;
  el.addEventListener(
    "click",
    (e) => {
      e.stopPropagation();
      let target_id = e.target.id;
      // let queriedMarkerObj = webmapMarkers.find((marker) => marker.id === query);
      // queriedMarkerObj.marker.remove();
      for (let i = 0; i < webmapMarkers.length; i++) {
        if (webmapMarkers[i].id === target_id) {
          document.getElementById(webmapMarkers[i].id).remove();
          webmapMarkers.splice(i, 1);
        }
      }
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(webMap);
  marker.properties = { id: id };
  webmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}

function addVgMarker(coordinates, type = false) {
  const id = _uniqueId("vgmarker-");
  let marker;
  var el = document.createElement("div");
  el.className = "marker";
  el.id = id;
  if (type) {
    el.style.backgroundImage = `url('https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/${getSvg(
      type
    )}.svg')`;
  }
  el.addEventListener(
    "click",
    (e) => {
      e.stopPropagation();
      let query = e.target.id;

      let queriedMarkerObj = vgmapMarkers.find((marker) => marker.id === query);
      queriedMarkerObj.marker.remove();
      pointNumbers.splice(
        pointNumbers.findIndex((v) => v.id === query),
        1
      );
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(vgMap);
  vgmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}

function handleGpx(xmlData) {
  const converter = require("@tmcw/togeojson");
  const geojson = converter.gpx(xmlData[2]);
  if (typeof vgMap.getLayer("day-route-line") !== "undefined") {
    vgMap.removeLayer("day-route-line").removeSource("day-route");
  }
  vgMap.addSource("day-route", {
    type: "geojson",
    data: geojson,
  });

  vgMap.addLayer({
    id: "day-route-line",
    type: "line",
    source: "day-route",
    paint: {
      "line-color": "#15cc09",
      "line-width": 4,
    },
  });

  var coordinates = geojson.features[0].geometry.coordinates;
  var bounds = coordinates.reduce(function (bounds, coord) {
    return bounds.extend(coord);
  }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

  vgMap.fitBounds(bounds, {
    padding: 20,
  });
}

function getSvg(type) {
  type = parseInt(type);
  switch (type) {
    case 1: {
      return "straight";
    }
    case 2: {
      return "right";
    }
    case 3: {
      return "left";
    }
    case 4: {
      return "fork-left";
    }
    case 5: {
      return "fork-right";
    }
    case 6: {
      return "poi";
    }
    case 7: {
      return "info";
    }
    case 8: {
      return "swimming";
    }
    case 9: {
      return "rafting";
    }
    case 10: {
      return "hotel";
    }
    default: {
      return "poi";
    }
  }
}
function openPopup() {
  const modal = document.querySelector(".modal");
  const closeBtn = document.querySelector(".close");
  modal.style.display = "block";
  document.querySelector(".viewdata").style.display = "none";
  closeBtn.addEventListener("click", () => {
    window.history.pushState(null, null, `?page=Days`);
    modal.style.display = "none";
    document.querySelector(".viewdata").style.display = "inline-table";
  });
}
function closePopoup() {
  document.querySelector(".modal").style.display = "none";
  document.querySelector(".viewdata").style.display = "inline-table";
}

function activeDeactiveDeleteBtn() {
  const btn = document.querySelector(".deleteBtn");
  if (selectedRowIndex.length > 0) btn.style.backgroundColor = "blue";
  else btn.style.backgroundColor = "#e0e0e0";
}
