//import references
import { CircularProgress, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import $ from 'jquery';
import _uniqueId from 'lodash/uniqueId';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MUIDataTable from 'mui-datatables';
import React, { Component } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getDatafromApi, handleCallApi } from './calls';
import { ApiData, MapboxKey, countriesNames } from "./config";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;


let pointNumbers = [];
let selectedRowIndex = false;

class CarRentPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      carRentTableData: [['Loading Data...']],
      searchData: [],
      updateCarRentRowIndex: '',
      countryFilter: '',
      tripName: '',
      trip_photo: '',
      tripInternalName: '',
      createBy: '',
      region: '',
      tripType: 'normal',
      nature_level: '',
      culture_level: '',
      adventure_level: '',
      group_structure: '',
      save_type: 'add',
      openDeleteCnfrm: false,
      selectedDays: [{ "id": '', "name": "Loading...", "country": "" }],
      showSelectedDay: false,
      editableCarId: '',
      personalizedCar: false,
      highLights: [""],
      ticketType: [""],
      overnight_pre_night: "",
      overnight_post_night: "",

      // Data hotels 
      country: "",
      car_name: "",
      car_level_basic: true,
      low_season_price: 0,
      high_season_price: 0,

    };
  }

  setDayTableData = async (data) => {
    this.setState({ dayTableData: data });
  };

  handleCarRentValidation() {
    let errors = {};
    let formIsValid = true;
    if (!this.state.country) {
      formIsValid = false;
      errors['country'] = 'Please Select Country';
    }
    if (!this.state.country) {
      formIsValid = false;
      errors['car_name'] = 'Please fill car name';
    }
    if (!this.state.low_season_price || isNaN(this.state.low_season_price)) {
      formIsValid = false;
      errors['low_season_price'] = 'Please fill low season price and it should be a number';
    }
    if (!this.state.high_season_price || isNaN(this.state.high_season_price)) {
      formIsValid = false;
      errors['high_season_price'] = 'Please fill high season price and it should be a number';
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  async handleSaveCarRent(e) {
    e.preventDefault();
    toast.dismiss();
    if (this.handleCarRentValidation()) {
      let carRentData1 = [...this.state.carRentTableData];
      var countryCode = this.state.country;
      var countryname = '';
      countriesNames.map(function (item) {
        if (item.code === countryCode)
          countryname = item.name;
      });
      let apidata = {
        country: countryname.trim(),
        car_name: this.state.car_name.trim(),
        car_level_basic: this.state.car_level_basic,
        low_season_price: Number(this.state.low_season_price),
        high_season_price: Number(this.state.high_season_price),
      }
      let method = 'POST';
      let url1 = ApiData.baseURL + `/car-rental-prices-list/`;
      let success = 'Car Rent prices list Added';
      if (this.state.save_type != 'add') {
        method = 'PUT';
        url1 = ApiData.baseURL + `/car-rental-prices-list/${this.state.editableCarId}/`;
        success = 'Car Rent prices list Updated';
      }
      let data = await handleCallApi(method, url1, JSON.stringify(apidata), success, '')
      if (data) {
        if (this.state.save_type === 'add') {
          if (this.state.searchData && this.state.searchData.length) {
            let searchData = [...this.state.searchData];
            if (this.state.countryFilter && this.state.countryFilter.length) {
              if (data.country === this.state.countryFilter) {
                searchData.push(data)
                this.setState({ searchData })
              }
            } else {
              searchData.push(data)
              this.setState({ searchData })
            }
          }
          carRentData1.push(data);
          this.setState({ carRentTableData: carRentData1 })
        }
        else {
          if (this.state.searchData && this.state.searchData.length) {
            this.state.searchData[this.state.updateCarRentRowIndex] = data;
            this.setState({ searchData: this.state.searchData })
          }
          else {
            carRentData1[this.state.updateCarRentRowIndex] = data[0];
            this.setState({ carRentTableData: carRentData1 })
          }
        }

        this.setState({
          updateCarRentRowIndex: "",
          personalizedCar: false,
          country: "",
          car_name: "",
          car_level_basic: false,
          low_season_price: 0,
          high_season_price: 0,
        });
        closePopoup();
      }
    } else {
      toast.error('Missing Information / Fields');
    }
  }

  setCarRent = async (save_type, updateCarRentRowIndex, personalizedCar, country, car_name, car_level_basic, low_season_price, high_season_price) => {
    this.setState({ save_type, updateCarRentRowIndex, personalizedCar, country, car_name, car_level_basic, low_season_price, high_season_price });
    setTimeout(() => {
      this.setState({ showSelectedDay: true });
    }, 500);
  };

  setCarRentTableData = async (data) => {
    this.setState({ carRentTableData: data });
  };
  setCarRentSearchData = async (data) => {
    this.setState({ searchData: data });
  };

  setSelectedDays = async (data) => {
    this.setState({ selectedDays: data });
  };

  handleClickOpen = () => {
    if (selectedRowIndex.length > 0)
      this.setState({ openDeleteCnfrm: true });
  };

  handleDelete = () => {
    this.setState({ openDeleteCnfrm: false });
    var array = [...this.state.carRentTableData]; // make a separate copy of the array
    selectedRowIndex.sort(function (a, b) {
      return b - a;
    });


    selectedRowIndex.forEach(async (i) => {
      let delid = array[i].id;
      array.splice(i, 1);
      await handleCallApi('DELETE', ApiData.baseURL + `/car-rental-prices-list/${delid}`, {}, '', '')
    })

    this.setCarRentTableData(array);
    selectedRowIndex = [];
  };

  handleClose = () => {
    this.setState({ openDeleteCnfrm: false });
    selectedRowIndex = [];
  };

  setEditableCarId = (id) => {
    this.setState({ editableCarId: id });
  }
  setCountryFilter = (value) => {
    this.setState({ countryFilter: value });
  }

  handleChangePersonalized = () => {
    this.setState({ personalizedCar: !this.state.personalizedCar });
  }
  handleCloseTripSelected = () => {
    this.setState({ selectedDays: [] });
  }



  render() {
    return (
      <div>
        <Dialog
          open={this.state.openDeleteCnfrm}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are You Sure You Want to delete this cars?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button onClick={this.handleDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <div className="modal">
          <div className="modal_content">
            <span className="close" onClick={this.handleCloseTripSelected}>&times;</span>
            <div className="model_html">
              <h2>{this.state.save_type === 'add' ? 'CREATE NEW CAR RENT' : `EDIT CAR RENT ${this.state.editableCarId}`}</h2>
              <table cellPadding="8" >
                <tbody>


                  <tr>
                    <td>
                      <label>Car Name</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        style={{ width: '95%' }}
                        variant="outlined"

                        value={this.state.car_name}
                        error={!!this.state.errors['car_name']}
                        onChange={(event) =>
                          this.setState({ car_name: event.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Country</label><br />
                      <ReactFlagsSelect
                        selected={this.state.country}
                        id={'selectCountriesDay'}
                        showSelectedLabel={true}
                        showOptionLabel={true}
                        onSelect={(code) => { this.setState({ country: code }); }}
                        placeholder={'Select a Country'}
                        countries={['GR', 'CR', 'NO', 'GE', 'MX', 'ME', 'DK', 'PL', 'PT', 'IS', 'MA', 'IL', 'TR', 'EC', 'ZA', 'IN', 'LT', 'IE', 'ES',]}
                      />
                      <span className="errorMsg">{this.state.errors['country']}</span>
                    </td>
                  </tr>
                  <tr>

                    <td style={{ verticalAlign: 'baseline', paddingTop: '10px' }}>
                      <label>Car level basic</label><br />

                      <FormControl className="dropdownWithborder" style={{ maxWidth: 150 }}>
                        <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Type"
                          value={this.state.save_type === 'add' ? true : this.state.car_level_basic}
                          onChange={(event) => { this.setState({ car_level_basic: event.target.value }); }}
                        >
                          <MenuItem value={true}>Basic</MenuItem>
                          <MenuItem value={false}>Upgraded</MenuItem>

                        </Select>
                      </FormControl><br />
                      <span className="errorMsg">{this.state.errors['car_level_basic']}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Low season price</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        style={{ width: '95%' }}
                        variant="outlined"

                        value={this.state.low_season_price}
                        error={!!this.state.errors['low_season_price']}
                        onChange={(event) =>
                          this.setState({ low_season_price: event.target.value })
                        }
                      />
                    </td>
                    <td>
                      <label>High season price</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        style={{ width: '95%' }}
                        variant="outlined"

                        value={this.state.high_season_price}
                        error={!!this.state.errors['high_season_price']}
                        onChange={(event) =>
                          this.setState({ high_season_price: event.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="4">
                      {this.state.save_type == 'add' &&

                        <Button
                          id="btnSaveSN"
                          variant="contained"
                          color="primary"
                          onClick={this.handleSaveCarRent.bind(this)}
                        /*  disabled={
                           this.state.tripInternalName=="" ?  true
                         : this.state.tripName=="" ? true
                         : this.state.tri=="" ? true
                         :false
                         } */
                        >
                          CREATE
                        </Button>
                      }



                      {this.state.save_type != 'add' &&
                        <Button
                          id="btnSaveSN"
                          variant="contained"
                          color="primary"
                          onClick={this.handleSaveCarRent.bind(this)}
                        >
                          SAVE CHANGES
                        </Button>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>


            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
        <ToastContainer />
        <table cellPadding="10" className="viewdata">
          <tbody>

            <tr>
              <td>
                <h2>Car Rent List</h2>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan="3"  ></td>
              <td>&nbsp;
                <Button
                  id="btnSave"
                  variant="contained"
                  className='deleteBtn'
                  color="#15cc09"
                  onClick={() => { this.handleClickOpen(); }}
                  style={{ float: 'right', fontSize: 12, color: '#fff' }}
                >
                  DELETE SELECTED
                </Button>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <CarRentPriceTable tripData={this.state.carRentTableData} searchData={this.state.searchData} setCountryFilter={this.setCountryFilter} setCarRentTableData={this.setCarRentTableData} setCarRentSearchData={this.setCarRentSearchData} setCarRent={this.setCarRent} setEditableCarId={this.setEditableCarId} />
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <Link href="#" onClick={() => { this.setCarRent('add', 0, false, '', '', false, 0, 0); openPopup() }}>+ ADD NEW CAR RENT  </Link>



              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <div className="borderDiv" />
              </td>
            </tr>

          </tbody>
        </table>

      </div>

    );
  }
}

export default CarRentPrice;

/* openPopup= () =>
{
  const modal = document.querySelector(".modal")
          const closeBtn = document.querySelector(".close")
          modal.style.display = "block";
  closeBtn.addEventListener("click", () => {
            modal.style.display = "none";
  })
}
          */
mapboxgl.accessToken = MapboxKey;
let mapMarkersOne = [];
let mapMarkersTwo = [];
let webmapMarkers = [];
let vgmapMarkers = [];

let webMapOne;
let webMapTwo;
let webMap;
let vgMap;

const initializeMap = (z, id) => {
  if (id == 'mapDayVG') {
    vgMap = new mapboxgl.Map({
      container: id,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: z,
    });
    //Add navigation controls to the mapbox if zoom is lower
    if (z < 11) vgMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    vgMap.on('click', function (e) {
      if (selectedRowIndex) {
        addVgMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      } else {
        toast.error('Please select a trip first by clicking VG button against the record');
      }
    });
  } else if (id == 'mapDayStop') {
    webMap = new mapboxgl.Map({
      container: id,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: z,
    });
    //Add navigation controls to the mapbox if zoom is lower
    if (z < 11) webMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    webMap.on('click', function (e) {
      if (selectedRowIndex) {
        addWebMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      } else {
        addWebMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
        // toast.error('11Please select a trip first by clicking Web button against the record');
      }
    });
  } else {
    if (id == 'mapDay-1') {
      webMapOne = new mapboxgl.Map({
        container: id,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: z,
      });
      webMapOne.on('click', function (e) {
        $('#txtStartLocDay').val(e.lngLat.lng.toFixed(6) + ',' + e.lngLat.lat.toFixed(6));
        // this.setState({startloc: e.lngLat.lng.toFixed(4) + ',' + e.lngLat.lat.toFixed(4) });
        mapMarkersOne.forEach((marker) => marker.remove());
        mapMarkersOne = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapOne);
        mapMarkersOne.push(marker);
      });
    } else {
      webMapTwo = new mapboxgl.Map({
        container: id,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: z,
      });

      webMapTwo.on('click', function (e) {
        $('#txtEndLocDay').val(e.lngLat.lng.toFixed(6) + ',' + e.lngLat.lat.toFixed(6));
        mapMarkersTwo.forEach((marker) => marker.remove());
        mapMarkersTwo = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapTwo);
        mapMarkersTwo.push(marker);
      });
    }
  }
};

class MapboxGLMap extends Component {
  constructor(props) {
    super(props);
    // this.id = _uniqueId(props.prefix + "-");
    this.id = props.prefix;
  }
  componentDidMount() {
    initializeMap(-1, this.id);
  }
  render() {
    const id = this.id;
    return (
      <div id={id} className="mapEA">
        {' '}
      </div>
    );
  }
}

class CarRentPriceTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [['Loading Data...']],
      loading: false,
      page: 0,
      rowsPerPage: 10,
      count: 0,
      filterData: [],
      filterBy: '',
      filterValue: '',
      searchTerm: '',
      allTrips: [],
      filterState: false,
      ascendingSort: true,
    };
  }

  async componentDidMount() {
    let data = await this.getData();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has('id')) {
      let id = urlParams.getAll('id');
      this.props.setEditableCarId(id)
      this.setCarRentData(id, data)
    }
  }

  async componentDidUpdate(prevProps, prevState) {

    if (this.state.page !== prevState.page || this.state.rowsPerPage !== prevState.rowsPerPage) {
      let data = await this.getData();
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.has('id')) {
        let id = urlParams.getAll('id');
        this.props.setEditableCarId(id)
        this.setCarRentData(id, data)
      }
    }
    if (this.state.searchTerm !== prevState.searchTerm) {
      if (this.state.searchTerm.length === 0) {
        if (this.state.filterValue.length !== 0) {
          this.getFilteredData()
        } else {
          this.setState({ filterData: [], filterState: false, searchTerm: '' });
          this.props.setCarRentSearchData([])
        }
      }
    }
  }


  setCarRentData = async (id, data) => {
    let filterId = parseInt(id[0])
    let getHotel = await this.getCarById(filterId)
    const filterRow = getHotel[0]
    const getIndexRow = data.findIndex(item => item.id === filterId);
    var countryCode;
    var countryname = filterRow.country;
    countriesNames.map(function (item) {
      if (item.name === countryname)
        countryCode = item.code;
    });

    this.props.setCarRent("update", getIndexRow, false, countryCode, filterRow.car_name, filterRow.car_level_basic, filterRow.low_season_price, filterRow.high_season_price)
    openPopup()
  }

  getData = () => {
    this.setState({ loading: true });
    let dataRows = this.xhrRequest(ApiData.baseURL + `/car-rental-prices-list`).then(async (data) => {
      this.setState({ data, loading: false });
      this.props.setCarRentTableData(data);
      this.setState({ count: data.length });
      return data
    });
    return dataRows
  };

  xhrRequest = (url) => {
    return new Promise(async (resolve, reject) => {
      let data = await getDatafromApi(url)
      if (data) {
        data.sort((a, b) => a.id - b.id);
        resolve(data);
      }
    });
  };

  getCarById = (id) => {
    return new Promise(async (resolve, reject) => {
      let data = await getDatafromApi(ApiData.baseURL + `/car-rental-prices-list/${id}`)
      if (data) {
        resolve(data);
      }
    });
  };

  render() {
    const columns = [
      {
        name: 'id', options: {
          label: 'ID', filter: false, sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            var editrow = {};
            var rowIndexForUpdate = 0;
            var i = 0;
            tableMeta.tableData.forEach((row) => {
              if (row.id == value) {
                editrow = row;
                rowIndexForUpdate = i;
              }
              i++;
            })

            var countryCode;
            var countryname = editrow.country;
            countriesNames.map(function (item) {
              if (item.name === countryname)
                countryCode = item.code;
            });

            return (
              <Link href="#" onClick={() => { this.props.setCarRent("update", rowIndexForUpdate, false, countryCode, editrow.car_name, editrow.car_level_basic, editrow.low_season_price, editrow.high_season_price); openPopup(editrow.id); this.props.setEditableCarId(editrow.id) }}>  {value}</Link>
            );
          },
        }
      },
      { name: 'car_name', label: 'Car Name', options: { filter: false } },
      {
        name: 'id', options: {
          label: 'Car Level Basic', filter: false, sort: true,
          customBodyRender: (value, tableMeta, updateValue) => {
            var editrow = {};
            var rowIndexForUpdate = 0;
            var i = 0;
            tableMeta.tableData.forEach((row) => {
              if (row.id == value) {
                editrow = row;
                rowIndexForUpdate = i;
              }
              i++;
            })
            let carLevelBasic = editrow.car_level_basic === true ? 'basic' : 'upgraded';
            return (
              <p>{carLevelBasic}</p>
            );
          },
        }
      },
      {
        name: "country", label: "Country", options: {
          filter: true,
          filterOptions: {
            names: countriesNames.map(country => country.name)
          },
        }
      },
      { name: 'low_season_price', label: 'Low season price', options: { filter: false } },
      { name: 'high_season_price', label: 'High season price', options: { filter: false } },
    ];

    const onRowsSelect = (curRowSelected, rowsSelected) => {
      selectedRowIndex = [];
      for (let i = 0; i < rowsSelected.length; i++) {
        selectedRowIndex.push(rowsSelected[i].index);
      }
      activeDeactiveDeleteBtn();
    };

    const { page, rowsPerPage, count } = this.state;
    const { tripData } = this.props;
    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      download: false,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: 'none',
      rowsSelected: selectedRowIndex,
      rowsPerPage: rowsPerPage,
      page: page,
      count: count,
      pagination: true,
      onRowSelectionChange: onRowsSelect,

    };

    return (
      <div>
        {this.state.loading ? (
          <p style={{ position: 'absolute', top: '80px' }}>Loading Data...</p>
        ) : (
          this.state.filterState ? <p style={{ position: 'absolute', top: '80px' }}>{this.props.searchData.length} trips found</p> : null
        )}
        <MUIDataTable
          title={
            <Typography variant="subtitle2">
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: 'relative', top: 4 }}
                />
              )}
            </Typography>
          }
          data={tripData}
          columns={columns}
          options={options}
          rowsSelected={onRowsSelect}
        />
      </div>
    );
  }
}


function addWebMarker(coordinates) {

  const id = _uniqueId('webmarker-');

  let marker;
  var el = document.createElement('div');
  el.className = 'marker';
  el.id = id;
  el.addEventListener(
    'click',
    (e) => {
      e.stopPropagation();
      let target_id = e.target.id;
      // let queriedMarkerObj = webmapMarkers.find((marker) => marker.id === query);
      // queriedMarkerObj.marker.remove();
      for (let i = 0; i < webmapMarkers.length; i++) {
        if (webmapMarkers[i].id === target_id) {
          document.getElementById(webmapMarkers[i].id).remove();
          webmapMarkers.splice(i, 1);
        }
      }
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(webMap);
  marker.properties = { id: id };
  webmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}

function addVgMarker(coordinates, type = false) {
  const id = _uniqueId('vgmarker-');
  let marker;
  var el = document.createElement('div');
  el.className = 'marker';
  el.id = id;
  if (type) {
    el.style.backgroundImage = `url('https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/${getSvg(
      type
    )}.svg')`;
  }
  el.addEventListener(
    'click',
    (e) => {
      e.stopPropagation();
      let query = e.target.id;

      let queriedMarkerObj = vgmapMarkers.find((marker) => marker.id === query);
      queriedMarkerObj.marker.remove();
      pointNumbers.splice(
        pointNumbers.findIndex((v) => v.id === query),
        1
      );
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(vgMap);
  vgmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}



function getSvg(type) {
  type = parseInt(type);
  switch (type) {
    case 1: {
      return 'straight';
    }
    case 2: {
      return 'right';
    }
    case 3: {
      return 'left';
    }
    case 4: {
      return 'fork-left';
    }
    case 5: {
      return 'fork-right';
    }
    case 6: {
      return 'poi';
    }
    case 7: {
      return 'info';
    }
    case 8: {
      return 'swimming';
    }
    case 9: {
      return 'rafting';
    }
    case 10: {
      return 'hotel';
    }
    default: {
      return 'poi';
    }
  }
}
function openPopup(id = '') {
  if (id !== '') {
    window.history.pushState(null, null, `?page=CarRentPriceList&id=${id}`);
  }
  const modal = document.querySelector(".modal")
  const closeBtn = document.querySelector(".close")
  modal.style.display = "block";
  document.querySelector(".viewdata").style.display = "none";
  closeBtn.addEventListener("click", () => {
    window.history.pushState(null, null, `?page=CarRentPriceList`);
    modal.style.display = "none";
    document.querySelector(".viewdata").style.display = "inline-table";
  })
}
function closePopoup() {
  document.querySelector(".modal").style.display = "none";
  document.querySelector(".viewdata").style.display = "inline-table";
}

function activeDeactiveDeleteBtn() {
  const btn = document.querySelector(".deleteBtn")
  if (selectedRowIndex.length > 0) btn.style.backgroundColor = 'blue';
  else btn.style.backgroundColor = '#e0e0e0';
}