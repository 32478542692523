'use strict';

const mainURL = process.env.REACT_APP_SERVER_API;

export const ApiData = {
    apiUrl: `${mainURL}/vg`,
    baseURL: mainURL
};

export const MapboxKey = process.env.REACT_APP_MAPBOX_KEY

export const countriesNames = [
    { name: "Israel", code: "IL", centroid: [34.8516, 32.0] },
    { name: "Greece", code: "GR", centroid: [21.8243, 39.0742] },
    { name: "Montenegro", code: "ME", centroid: [19.2912337, 42.6988483] },
    { name: "Iceland", code: "IS", centroid: [41.1145237, 42.2989208] },
    { name: "Norway", code: "NO", centroid: [41.1145237, 42.2989208] },
    { name: "South Africa", code: "ZA", centroid: [41.1145237, 42.2989208] },
    { name: "Costa Rica", code: "CR", centroid: [41.1145237, 42.2989208] },
    { name: "India", code: "IN", centroid: [41.1145237, 42.2989208] },
    { name: "Georgia", code: "GE", centroid: [41.1145237, 42.2989208] },
    { name: "Ireland", code: "IE", centroid: [41.1145237, 42.2989208] },
    { name: "Lithuania", code: "LT", centroid: [41.1145237, 42.2989208] },
    { name: "Turkey", code: "TR", centroid: [41.1145237, 42.2989208] },
    { name: "Portugal", code: "PT", centroid: [24.9668, 45.9432] },
    { name: "South Korea", code: "KR", centroid: [24.9668, 45.9432] },
    { name: "Sri Lanka", code: "LK", centroid: [24.9668, 45.9432] },
    { name: "Chile", code: "CL", centroid: [24.9668, 45.9432] },
    { name: "Argentina", code: "AR", centroid: [24.9668, 45.9432] },
    { name: "UAE", code: "AE", centroid: [24.9668, 45.9432] },
    { name: "Bulgaria", code: "BG", centroid: [24.9668, 45.9432] },
    { name: "Ecuador", code: "EC", centroid: [24.9668, 45.9432] },
    { name: "Italy", code: "IT", centroid: [24.9668, 45.9432] },
    { name: "Romania", code: "RO", centroid: [24.9668, 45.9432] },
    { name: "Spain", code: "ES", centroid: [24.9668, 45.9432] },
    { name: "Albania", code: "AL", centroid: [20.1683, 41.1533] },
    { name: "Armenia", code: "AM", centroid: [45.0382, 40.0691] },
    { name: "Tanzania", code: "TZ", centroid: [34.8888, -6.369] },
    { name: "Slovakia", code: "SK", centroid: [19.699, 48.669] },
    { name: "Czech Republic", code: "CZ", centroid: [15.473, 49.8175] },
    { name: "Mexico", code: "MX", centroid: [15.473, 49.8175] },
    { name: "Denmark", code: "DK", centroid: [15.473, 49.8175] },
    { name: "Poland", code: "PL", centroid: [15.473, 49.8175] },
    { name: "Peru", code: "PE", centroid: [15.473, 49.8175] },
    { name: "Morocco", code: "MA", centroid: [15.473, 49.8175] },
];