import { Box, Chip, CircularProgress, FormControl, MenuItem, Modal, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { Stack } from '@mui/material';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MUIDataTable from 'mui-datatables';
import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ApiData } from "./config";
import { handlePhotoUpload } from "./uploadFiles";
import { handleCallApi, getDatafromApi } from './calls'
import ChangeLogSelectedTable from './changelogTables'

/* import Moment from 'react-moment';  */
const SPACED_DATE_FORMAT = "DD MMM YYYY";
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

let selectedRowIndex = false;

class ChangeLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      changeLogTableData: [['Loading Data...']],
      updateRowIndex: '',
      device: [],
      currentTrip: '',
      save_type: 'add',
      openDeleteCnfrm: false,
      allTripEvent: [],
      allData: [],
      selectedUpdated: [],
      selectedBeforeUpdated: [],
      differentKeys: [],
      editState: false,
      loading: false,
    };
  }
  
  setEventTripTableData = (tableData, allData) => {
    if (tableData) {
        this.setState({changeLogTableData: tableData})
    }
    if(allData){
        this.setState({allData: allData})
    }
  }
  
  setEventTripData = async (id) => {
    console.log('id', id)
    let row = this.state.allData.filter(event => event.id === id)
    console.log('row', row)
    let tripBeforeUpdateSelected = row[0].metadata.tripBeforeUpdate;
    let updatedTripSelected = row[0].metadata.tripUpdated;
    let daysArrUpdated = [];
    let daysArrBeforeUpdated = [];
    console.log('updatedTripSelected',updatedTripSelected)
    if(row[0].event === 'TRIP_UPDATE'){
      if (!Array.isArray(updatedTripSelected.days)) {
        let dataDaysUpdated = updatedTripSelected.days.split(", ").map(day => parseInt(day));
        updatedTripSelected.days = dataDaysUpdated.join(', ');
      }else{
        updatedTripSelected.days.map(day => {
          daysArrUpdated.push(day.id)
        })
    updatedTripSelected.days = daysArrUpdated.join(', ');
    }

      if (!Array.isArray(tripBeforeUpdateSelected.days)) {
        let dataDays = tripBeforeUpdateSelected.days.split(", ").map(day => parseInt(day)); 
        tripBeforeUpdateSelected.days = dataDays.join(', ');
      }else{
        tripBeforeUpdateSelected.days.map(day => {
          daysArrBeforeUpdated.push(day)
        })
    tripBeforeUpdateSelected.days = daysArrBeforeUpdated.join(', ');
    }
    }else{
      delete updatedTripSelected.web_poi_set;
      delete updatedTripSelected.vg_poi_set;
      delete updatedTripSelected.starting_point_latitude;
      delete updatedTripSelected.starting_point_longitude;
      delete updatedTripSelected.ending_point_latitude;
      delete updatedTripSelected.ending_point_longitude;
      updatedTripSelected.starting_point = `lat: ${updatedTripSelected.starting_point.latitude}, lon: ${updatedTripSelected.starting_point.longitude}`;
      updatedTripSelected.ending_point = `lat: ${updatedTripSelected.ending_point.latitude}, lon: ${updatedTripSelected.ending_point.longitude}`;

      delete tripBeforeUpdateSelected.web_poi_set;
      delete tripBeforeUpdateSelected.vg_poi_set;
      delete tripBeforeUpdateSelected.starting_point_latitude;
      delete tripBeforeUpdateSelected.starting_point_longitude;
      delete tripBeforeUpdateSelected.ending_point_latitude;
      delete tripBeforeUpdateSelected.ending_point_longitude;
      tripBeforeUpdateSelected.starting_point = `lat: ${tripBeforeUpdateSelected.starting_point.latitude}, lon: ${tripBeforeUpdateSelected.starting_point.longitude}`;;
      tripBeforeUpdateSelected.ending_point = `lat: ${tripBeforeUpdateSelected.ending_point.latitude}, lon: ${tripBeforeUpdateSelected.ending_point.longitude}`;;
    }



    const beforeTrip = Object.keys(tripBeforeUpdateSelected)
    .filter(key => Object.keys(updatedTripSelected).includes(key))
    .reduce((obj, key) => {
      obj[key] = tripBeforeUpdateSelected[key];
      return obj;
    }, {});

    let diffKeys = [];
    for (let key in beforeTrip) {
      if (Array.isArray(beforeTrip[key])) {
        if (JSON.stringify(beforeTrip[key]) !== JSON.stringify(updatedTripSelected[key])) {
          diffKeys.push(key);
        }
      } else {
        if (beforeTrip[key] !== updatedTripSelected[key]) {
          diffKeys.push(key);
        }
      }
    }
    this.setState({ selectedBeforeUpdated: beforeTrip, selectedUpdated: updatedTripSelected, differentKeys: diffKeys});
  };

 
  handleClickOpen = () => {
    if (selectedRowIndex.length > 0)
      this.setState({ openDeleteCnfrm: true });
  };

  handleClose = () => {
    this.setState({ openDeleteCnfrm: false });
    selectedRowIndex = [];
  };


  render() {
    return (
      <div>

        <Dialog
          open={this.state.openDeleteCnfrm}
          onClose={this.handleClose}user_first_name
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are You Sure You Want to delete this User
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button onClick={this.handleDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>


        <div className="modal">
          <div className="modal_content">
            <span className="close">&times;</span>
            <div className="model_html" style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '10px'}}>
            {this.state.selectedBeforeUpdated && <div>
            <h2>After Update</h2>
            <ChangeLogSelectedTable data={this.state.selectedBeforeUpdated} differentKeys={this.state.differentKeys}/>
            </div>}
            {this.state.selectedUpdated && <div>
            <h2>Before Update</h2>
            <ChangeLogSelectedTable data={this.state.selectedUpdated} differentKeys={this.state.differentKeys}/>
            </div>}
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
        <ToastContainer />
        <table cellPadding="10" className="viewdata">
          <tbody>

            <tr>
              <td>
                <h2>Change Log</h2>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan="3"  ></td>
              <td>&nbsp;
                <Button
                  id="btnSave"
                  variant="contained"
                  color="#15cc09"
                  className='deleteBtn'
                  onClick={() => { this.handleClickOpen() }}
                  style={{ float: 'right', fontSize: 12, color: '#fff' }}
                >
                  DELETE SELECTED
                </Button>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <ChangeLogTable eventsData={this.state.changeLogTableData} setEventTripTableData={this.setEventTripTableData} setEventTripData={this.setEventTripData} setEventTrip={this.setEventTrip}/>
              </td>
            </tr>
            {/*   <tr>
           
              <td colSpan="4">
                <Link href="#" onClick={() => { this.setEventTripData(  '', '', '', '','','', 'add', ''); console.log('username', this.state.user_name); openPopup() }}> + ADD NEW User  </Link>
              </td>
            </tr> */}
            <tr>
              <td colSpan="4">
                <div className="borderDiv" />
              </td>
            </tr>

          </tbody>
        </table>
        

      </div>


    );
  }
}

export default ChangeLog;


class ChangeLogTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [['Loading Data...']],
      loading: false,
      openDeleteModal: false,
      indexRowForDelete: '',
      allData: [],
    };
  }

  componentDidMount() {
    this.getData();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    // if(urlParams.has('id')){
    //  let id = urlParams.getAll('id');
    //  this.props.setEditableTripId(id)
    //  this.setEventTripData(id,'update')
    //  openPopup()
    // }

  }



  getData = () => {
    this.setState({ loading: true });
    this.xhrRequest().then((data) => {       
      this.setState({ data, loading: false });
    });
  };
  
  xhrRequest = (url) => {
    return new Promise(async (resolve, reject) => {
      let trips = await getDatafromApi(ApiData.baseURL + '/internal-events?eventType=TRIP_UPDATE')
      let days = await getDatafromApi(ApiData.baseURL + '/internal-events?eventType=DAY_UPDATE')
      let data = [];
      if (trips.results && trips.results.length > 0) {
        trips.results.forEach((trip) => {
          trip.event = 'TRIP_UPDATE';
          data.push(trip);
        })
      }

      if (days.results && days.results.length > 0) {
        days.results.forEach((day) => {
          day.event = 'DAY_UPDATE';
          data.push(day);
        })
      }
      data.sort((a, b) => b.id - a.id);
      this.setState({allData: data})
      let dataForTable = [];
      data.map((item) =>{
        let trip = item.metadata.tripBeforeUpdate;
        trip.eventId = item.id;
        trip.event = item.event
        trip.userId = item.metadata.user.id;
        trip.userName = `${item.metadata.user.first_name} ${item.metadata.user.last_name}`
        trip.updateDate = item.metadata.updateDate;
        dataForTable.push(trip)
      })  
      this.props.setEventTripTableData(dataForTable, data)
      resolve(dataForTable);
    });
  };


  
  render() {
    const columns = [
      {
        name: 'eventId', options: {
          label: 'Event Id', filter: false,

          customBodyRender: (value, tableMeta, updateValue) => {
            // const editroww = tableMeta.tableData[tableMeta.rowIndex];
            var editrow = {};
            var rowIndexForUpdate = 0;
            var i = 0;
            tableMeta.tableData.forEach((row) => {
              if (row[0] == value) {
                editrow = row;
                rowIndexForUpdate = i;
              }
              i++;
            })

            var trip_id = 0;
            if (editrow.currentTrip?.id) trip_id = editrow.currentTrip.id;
            
            return (
              <Link href="#" onClick={async () => {
               await this.props.setEventTripData(value);
                openPopup();
              }}> {value}</Link>
            );
          },

        }
      },

      { name: 'id', label: 'Element Id', options: { filter: false } },
      { name: 'name', label: 'Event Name', options: { filter: false } },
      { name: 'userName', label: 'User Name', options: { filter: false } },
      { name: 'userId', label: 'User Id', options: { filter: false } },
      { name: 'event', label: 'Event Type', options: { filter: false } },
      { name: 'updateDate', label: 'Event Date', options: { filter: false } },

    ];

    const onRowsSelect = (curRowSelected, rowsSelected) => {
      selectedRowIndex = [];
      for (let i = 0; i < rowsSelected.length; i++) {
        selectedRowIndex.push(rowsSelected[i].dataIndex);
      }
      activeDeactiveDeleteBtn()

    };
    const { data } = this.state;

    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      download: false,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: 'none',

      rowsSelected: selectedRowIndex,

      onRowSelectionChange: onRowsSelect,

      customSort: (data, colIndex, order, meta) => {
         
        return data.sort((a, b) => {
          
         if(colIndex==5)
         {
       
          let adate=a.data[colIndex].split('-');
          let bdate=b.data[colIndex].split('-');
          let anewdate="";
          let bnewdate="";
          if(adate.length==3)
          anewdate =new Date(adate[1]+'-'+adate[0]+'-'+adate[2]).getTime();
          if(bdate.length==3)
           bnewdate=new Date(bdate[1]+'-'+bdate[0]+'-'+bdate[2]).getTime();
       
          return ( anewdate < bnewdate ? -1: 1 ) * (order === 'desc' ? 1 : -1);
         }
          else
          return (a.data[colIndex] < b.data[colIndex] ? -1: 1 ) * (order === 'desc' ? 1 : -1);
        });
      }

    };
    return (
      <div>
        <MUIDataTable
          title={
            <Typography variant="subtitle2">
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: 'relative', top: 4 }}
                />
              )}
            </Typography>
          }
          data={this.props.eventsData}
          columns={columns}
          options={options}
          rowsSelected={onRowsSelect}
        />
      </div>
    );
  }
}

function openPopup() {
  try{
    const modal = document.querySelector(".modal")
    const closeBtn = document.querySelector(".close")
    if (modal) {
      modal.style.display = "block";
      document.querySelector(".viewdata").style.display = "none";
    }
    if(closeBtn){
      closeBtn.addEventListener("click", () => {
        modal.style.display = "none";
        document.querySelector(".viewdata").style.display = "inline-table";
      })
    }
  }catch(err){
    console.log(err)
  }
}

function closePopoup() {
  document.querySelector(".modal").style.display = "none";
  document.querySelector(".viewdata").style.display = "inline-table";
}

function activeDeactiveDeleteBtn() {
  const btn = document.querySelector(".deleteBtn")
  if (selectedRowIndex.length > 0) btn.style.backgroundColor = 'blue';
  else btn.style.backgroundColor = '#e0e0e0';
}

