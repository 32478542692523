//import references
import React, { Component } from "react";
import FormControl from '@material-ui/core/FormControl';
import Button from "@material-ui/core/Button";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TextField from "@material-ui/core/TextField";
import ReactFlagsSelect from "react-flags-select";
import _uniqueId from "lodash/uniqueId";
import { CircularProgress, Typography } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { handlePhotoUpload } from "./uploadFiles"
import $ from "jquery";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import MUIDataTable from "mui-datatables";
import MapboxAutocomplete from "react-mapbox-autocomplete";
import { ToastContainer, toast } from "react-toastify";
import VG from "./components/VGPersonalized";
import WebPoint from "./components/WebPointPersonalized";
import uploadIcon from "./themes/icons/uploadicon.png";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { ApiData } from "./config";
import { getDatafromApi } from './calls';
import SelectPersonalizedDays from './components/SelectPersonalizedDays';
import { countriesNames } from "./config";


// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

let pointNumbers = [];
let selectedRowIndex = false;
let daysData;

class DaysPersonalized extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      photourl: "",
      dayTableData: [["Loading Data..."]],
      updateDayRowIndex: "",
      selectedTab: 2,
      dayTitle: "",
      dayCountryCode: "",
      dayRegion: "",
      dayLengthHours: "",
      overnight: "",
      dayCoverPhoto: "",
      dayDescription: "",
      dayStartLocation: "",
      dayEndLocation: "",
      save_type: "add",
      openDeleteCnfrm: false,
      globalPoiSet: [],
      globalPoiSetWeb: [],
      globalPoiSetVg: [],
      selectedWebPOI: [{ id: "", name: "Loading...", country: "" }],
      selectedVG: [{ id: "", name: "Loading...", country: "" }],
      showVg: true,
      Vgpoiall: [],
      selectedDays: [{ "id": '', "name": "Loading...", "country": "" }],
      allDay: [],
      isDuplicate: false,
    };
    window.bd = this;
  }


  setDuplicateDayData = (day) => {
    return new Promise((resolve, reject) => {
        if (!day.web_poi_set || !day.vg_poi_set) {
            reject('web_poi_set or vg_poi_set is undefined');
        }

        var countryCode;
        var countryname = day.country;
        countriesNames.map(function (item) {
            if (item.name === countryname) countryCode = item.code;
        });

        let startLoc = "";
        if (day.starting_point)
            startLoc =
            day.starting_point?.latitude +
            "," +
            day.starting_point?.longitude;
        let endLoc = "";
        if (day.ending_point)
            endLoc =
            day.ending_point?.latitude +
            "," +
            day.ending_point?.longitude;

        let globalPoiSetVg = [];
        let globalPoiSetWeb = [];
        day.web_poi_set.forEach((poi) => {
            globalPoiSetWeb.push(poi.id);
        });
        day.vg_poi_set.forEach((poi) => {
            globalPoiSetVg.push(poi.id);
        });

        this.setDayData(
            globalPoiSetWeb,
            globalPoiSetVg,
            day.name,
            countryCode,
            day.region,
            5,
            day.photo,
            day.description,
            startLoc,
            endLoc,
            "add",
            '',
            day.overnight
        );
        this.setState({ isDuplicate: false });

        setTimeout(() => {
            initializeMap(-1, "mapDay-1", startLoc);
            initializeMap(-1, "mapDay-2", endLoc);
            if (day.starting_point)
                this.setStartLocationMap(startLoc);
            if (day.ending_point)
                this.setEndLocationMap(endLoc);
            resolve(); // Resuelve la promesa después de completar el proceso
        }, 1000);
    });
}





  async getDay() {
    let data1 = await getDatafromApi(ApiData.apiUrl + '/days?take=3000&personalized=false')
    if (data1) {
      let data = data1.results;
      data.sort((a, b) => a.id - b.id);
      this.setState({ allDay: data });
    }
  }

  componentDidMount() {
    this.getDay();
  }

  setglobalPoiSet = async (data) => {
    this.setState({ globalPoiSet: data });
  };
  setglobalPoiSetWeb = async (data) => {
    this.setState({ globalPoiSetWeb: data });
  };
  setglobalPoiSetVg = async (data) => {
    this.setState({ globalPoiSetVg: data });
  };
  setVgpoiall = async (data) => {
    this.setState({ setVgpoiall: data });
  };

  setSelectedWebPoi = async (data) => {
    this.setState({ selectedWebPOI: data });
  };

  setSelectedVG = async (data) => {
    this.setState({ selectedVG: data });
  };

  
  setSelectedDays = async (data) => {
    this.setState({ selectedDays: data });
  };

  setSelectedVgByWeb = async (data) => {
    let id = data.id;
    data.type = "POI";
    data.originalType = "POI";
    let vgdata = [...this.state.selectedVG];
    let globalpoivg = [...this.state.globalPoiSetVg];
    let allreadyInArray = false;
    vgdata.forEach((vg) => {
      if (vg.id == id) allreadyInArray = true;
    });
    if (!allreadyInArray) {
      vgdata.push(data);
      globalpoivg.push(data.id);
    }

    this.setState({ globalPoiSetVg: globalpoivg });
    this.setState({ selectedVG: vgdata });

    this.setState({ showVg: false });

    setTimeout(
      function () {
        //Start the timer
        this.setState({ showVg: true });
      }.bind(this),
      200
    );
  };

  setStartLocationMap(poiLocation) {
    var poiCoordinates = poiLocation.split(",");
    if (poiCoordinates.length >= 2) {
      if (poiCoordinates[0] > 90) poiCoordinates[0] = 90;
      if (poiCoordinates[0] < -90) poiCoordinates[0] = -90;

      mapMarkersOne.forEach((marker) => marker.remove());
      mapMarkersOne = [];
      const marker = new mapboxgl.Marker()
        .setLngLat([poiCoordinates[1], poiCoordinates[0]])
        .addTo(webMapOne);
      mapMarkersOne.push(marker);
    }
  }
  setEndLocationMap(poiLocation) {
    var poiCoordinates = poiLocation.split(",");
    if (poiCoordinates.length >= 2) {
      if (poiCoordinates[0] > 90) poiCoordinates[0] = 90;
      if (poiCoordinates[0] < -90) poiCoordinates[0] = -90;

      mapMarkersTwo.forEach((marker) => marker.remove());
      mapMarkersTwo = [];
      const marker = new mapboxgl.Marker()
        .setLngLat([poiCoordinates[1], poiCoordinates[0]])
        .addTo(webMapTwo);
      mapMarkersTwo.push(marker);
    }
  }

  _suggestionSelected(result, lat, lng, text) {
    window.bd.setPoiLocation(lat, lng);
    window.bd.setStartLocationMap(lat + "," + lng);
  }
  _suggestionSelected1(result, lat, lng, text) {
    window.bd.setDayEndLocation(lat, lng);
    window.bd.setEndLocationMap(lat + "," + lng);
  }

  setPoiLocation(latitude, longitude) {
    this.setState({ dayStartLocation: latitude + "," + longitude });
    $(".react-mapbox-ac-input").val("");
  }

  setDayEndLocation(latitude, longitude) {
    this.setState({ dayEndLocation: latitude + "," + longitude });
    $(".react-mapbox-ac-input").val("");
  }

  handleDayValidation() {
    let errors = {};
    let formIsValid = true;

    if (!this.state.dayTitle) {
      formIsValid = false;
      errors["dayTitle"] = "*";
    }
    if (!this.state.dayCoverPhoto) {
      formIsValid = false;
      errors["dayCoverPhoto"] = "Please Select Cover Photo";
    }
    if (!this.state.dayStartLocation) {
      formIsValid = false;
      errors["dayStartLocation"] = "Please Select Starting Point";
    }
    if (!this.state.dayEndLocation) {
      formIsValid = false;
      errors["dayEndLocation"] = "Please Select Ending Point";
    }
    if (!this.state.dayCountryCode) {
      formIsValid = false;
      errors["dayCountryCode"] = "Please Select Country";
    }
    /* if (!this.state.dayDescription) {
       formIsValid = false;
       errors['dayDescription'] = '*';
     }
      if (!this.state.dayRegion) {
       formIsValid = false;
       errors['dayRegion'] = 'Please Select Region';
     } */
    /*   else{
        var loc = this.state.dayStartLocation.split(",");
        let pattern = new RegExp('^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}');
        if(pattern.test(loc[0]) && pattern.test(loc[1]))
        console.log('valid')
        else
        {
            formIsValid = false;
            errors['dayStartLocation'] = 'Not a Valid Location';
          }
        } */

    /*   if(this.state.dayEndLocation)
      {
       var loc = this.state.dayEndLocation.split(",");
       let pattern = new RegExp('^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}');
       if(pattern.test(loc[0]) && pattern.test(loc[1]))
       console.log('valid')
       else
       {
           formIsValid = false;
           errors['dayEndLocation'] = 'Not a Valid Location';
         }
      }  */

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSaveDay(e) {
    e.preventDefault();
    toast.dismiss();
    if (this.handleDayValidation()) {
      if (!this.state.globalPoiSetWeb) {
        // formIsValid = false;
        toast.error("Please Select Web Poi From list");
        return;
      }
      if (this.state.globalPoiSetVg.length < 0) {
        // formIsValid = false;
        toast.error("Please Select VG Poi From list");
        return;
      }

      let dayData1 = [...this.state.dayTableData];
      var countryCode = this.state.dayCountryCode;
      var countryname = "";
      countriesNames.map(function (item) {
        if (item.code === countryCode) countryname = item.name;
      });

      var dayStartLocation = this.state.dayStartLocation.split(",");
      var dayEndLocation = this.state.dayEndLocation.split(",");
      var startLat = "";
      var startLng = "";
      var endLat = "";
      var endLng = "";
      var starting_point = null;
      var ending_point = null;
      if (dayStartLocation.length > 1) {
        startLat = parseFloat(dayStartLocation[0]);
        startLng = parseFloat(dayStartLocation[1]);
        starting_point = { latitude: startLat, longitude: startLng };
      }
      if (dayEndLocation.length > 1) {
        endLat = parseFloat(dayEndLocation[0]);
        endLng = parseFloat(dayEndLocation[1]);
        ending_point = { latitude: endLat, longitude: endLng };
      }

      let web_poi_set = [];
      this.state.selectedWebPOI.forEach((webpoi) => {
        web_poi_set.push(webpoi.id);
      });
      let vg_poi_set = [];
      let vg_gpx_set = [];
      let vg_pkg_set = [];
      this.state.selectedVG.forEach((vgpoi) => {
        if (vgpoi.type == "POI") vg_poi_set.push(vgpoi.id);
        if (vgpoi.type == "GPX") vg_gpx_set.push(vgpoi.id);
        if (vgpoi.type == "TRIALPACKAGE") vg_pkg_set.push(vgpoi.id);
      });
      let internal_name = _uniqueId("");
      if (this.state.save_type != "add" && this.state.updateDayRowIndex) {
        const row = dayData1[this.state.updateDayRowIndex]
        internal_name = row.internal_name;
      }
      let webpoiSet = [];
      if (this.state.globalPoiSetWeb != "")
        webpoiSet = this.state.globalPoiSetWeb;
      let vgpoiSet = [];
      if (this.state.globalPoiSetVg != "") vgpoiSet = this.state.globalPoiSetVg;

      let photoUrl = "";
      let a = this.state.dayCoverPhoto.split(" ");

      for (let i = 0; i < a.length; i++) {
        if (i != 0) photoUrl = photoUrl + "%20";
        photoUrl = photoUrl + a[i];
      }

      let apidata = {
        country: countryname,
        description: this.state.dayDescription,
        internal_name: internal_name,
        name: this.state.dayTitle,
        photo: photoUrl,
        region: this.state.dayRegion,
        starting_point: starting_point,
        ending_point: ending_point,
        web_poi_set: webpoiSet,
        vg_poi_set: vgpoiSet,
        overnight: this.state.overnight,
        personalized: true,
        custom: false
        //wayoint: this.state.selectedWebPOI,
        /* web_poi_set: web_poi_set,
        vg_poi_set: vg_poi_set,
        vg_gpx_set: vg_gpx_set,
        vg_package_set: vg_pkg_set, */
      };

      console.log("api data", apidata);
      console.log("this.state.save_type", this.state.save_type);

      let method = "POST";
      let url1 = ApiData.apiUrl + `/days/`;
      if (this.state.save_type != "add") {
        method = "PUT";
        url1 =
          ApiData.apiUrl +
          `/days/${dayData1[this.state.updateDayRowIndex].id}/`;
      }
      let requestOptions = {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(apidata),
      };
      //medraft comment

      fetch(url1, requestOptions)
        .then((response) => response.json())
        .then((data) => {
          if (this.state.save_type === "add") {
            // dayData1.unshift(data);
            dayData1.push(data);
            toast.success("Day Added");
          } else {
            dayData1[this.state.updateDayRowIndex] = data;
            toast.success("Day Updated");
          }

          this.setState({ dayTableData: dayData1 });

          this.setState({
            dayTitle: "",
            dayCountryCode: "",
            dayRegion: "",
            dayLengthHours: "",
            dayCoverPhoto: "",
            dayDescription: "",
            dayStartLocation: "",
            dayEndLocation: "",
            overnight: ""
          });

          closePopoup();
        });

      /*  this.setState({
         dayTitle: '',
         dayCountryCode: '',
         dayRegion: '',
         dayLengthHours: '',
         dayCoverPhoto: '',
         dayDescription: '',
         dayStartLocation: '',
         dayEndLocation: '',
       });
       closePopoup(); */

      //end medraft comment
    } else {
      toast.error("Missing Information / Fields");
    }
  }

  setDayData = async (
    globalPoiSetWeb,
    globalPoiSetVg,
    dayTitle,
    dayCountryCode,
    dayRegion,
    dayLengthHours,
    dayCoverPhoto,
    dayDescription,
    dayStartLocation,
    dayEndLocation,
    save_type,
    updateDayRowIndex,
    overNight
  ) => {
    this.setState({
      globalPoiSetWeb: globalPoiSetWeb,
      globalPoiSetVg: globalPoiSetVg,
      dayTitle: dayTitle,
      dayCountryCode: dayCountryCode,
      dayRegion: dayRegion,
      dayLengthHours: dayLengthHours,
      dayCoverPhoto: dayCoverPhoto,
      dayDescription: dayDescription,
      dayStartLocation: dayStartLocation,
      dayEndLocation: dayEndLocation,
      save_type: save_type,
      updateDayRowIndex: updateDayRowIndex,
      selectedTab: 2,
      overnight: overNight
    });
    this.setSelectedWebPoi([]);
    this.setSelectedVG([]);

    setTimeout(
      function () {
        //Start the timer
        this.setState({ selectedTab: 0 });
      }.bind(this),
      200
    );
  };

  handleClickOpen = () => {
    if (selectedRowIndex.length > 0) this.setState({ openDeleteCnfrm: true });
  };

  handleDelete = () => {
    this.setState({ openDeleteCnfrm: false });

    var array = [...this.state.dayTableData]; // make a separate copy of the array
    selectedRowIndex.sort(function (a, b) {
      return b - a;
    });

    selectedRowIndex.forEach((i) => {
      let requestOptions = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };

      let delid = array[i].id;
      array.splice(i, 1);

      fetch(ApiData.apiUrl + `/days/${delid}/`, requestOptions)
        .then((response) => response)
        .then((data) => { });
    });

    this.setDayTableData(array);
    selectedRowIndex = [];
  };

  handleClose = () => {
    this.setState({ openDeleteCnfrm: false });
    selectedRowIndex = [];
  };

  setDayTableData = async (data) => {
    this.setState({ dayTableData: data });
  };

  handlePhoto = async (event) => {
    try {
      const url = await handlePhotoUpload(event, 'planmytrip-assets', 'days');
      if (url) {
        this.setState({ dayCoverPhoto: url });
      }
    } catch (error) {
      console.log('Error uploading photo:', error);
    }
  };

  render() {
    return (
      <div>
        <Dialog
          open={this.state.openDeleteCnfrm}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are You Sure You Want to delete this Day
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button onClick={this.handleDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <div class="modal">
          <div class="modal_content">
            <span class="close">&times;</span>
            {this.state.isDuplicate &&
              <div class="model_html">
                <h2>Select Existing DAY</h2>
                <table cellPadding="8">
                  <tbody>

                    {
                    /* <tr>
                      <td>
                        <lable>Select DAY</lable>
                        <br />

                        <FormControl className="dropdownWithborder">
                          <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Type"
                            value={""}
                            onChange={(event) => {
                              //this.setState({ selfGuide_trip: event.target.value });
                              this.setDuplicateDayData(event.target.value);
                              // console.log('event.target.value', event.target.value)
                            }}
                            multiple={false}

                          >




                            {this.state.allDay.length > 0 && this.state.allDay.map((item, i) =>
                            (
                              <MenuItem value={item}>{item.name}({item.id})</MenuItem>
                            )
                            )}

                          </Select>
                        </FormControl>
                      </td>
                    </tr> */
                    }
                    <SelectPersonalizedDays selectedDays={this.state.selectedDays} setDuplicateDayData={this.setDuplicateDayData} />
                  </tbody>
                </table>
              </div>
            }
            {!this.state.isDuplicate &&
              <div class="model_html">
                <h2>Add new day</h2>
                <table cellPadding="8">
                  <tbody>
                    <tr>
                      <td width="50%">
                        <lable>Day Title</lable>
                        <br />
                        <TextField
                          id="dayTitle"
                          size="medium"
                          variant="outlined"
                          value={this.state.dayTitle}
                          error={!!this.state.errors["dayTitle"]}
                          onChange={(event) =>
                            this.setState({ dayTitle: event.target.value })
                          }
                        />
                      </td>

                      <td>
                        {this.state.dayCoverPhoto
                          ? [
                            <div>
                              <lable>Cover Photo</lable>
                              <br />
                              <span class="lablesmall">
                                {" "}
                                Currently:
                                <span class="colorgreen">
                                  {" "}
                                  {this.state.dayCoverPhoto}
                                </span>
                              </span>
                              <br />
                              <br />
                            </div>,
                          ]
                          : null}
                        <div class="divUpload">
                          <img width="20px" src={uploadIcon} />
                          <Button
                            variant="contained"
                            component="label"
                            style={{ float: "right", fontSize: 9 }}
                          >
                            UPLOAD FILE
                            <input
                              type="file"
                              hidden
                              id="fileSNPhoto"
                              size="Small"
                              value=""
                              onChange={this.handlePhoto}
                            />
                          </Button>
                        </div>
                        <span className="errorMsg">
                          {this.state.errors["dayCoverPhoto"]}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <lable>Country</lable>
                        <br />

                        <ReactFlagsSelect
                          selected={this.state.dayCountryCode}
                          id={"selectCountriesDay"}
                          showSelectedLabel={true}
                          style={{ width: "200px" }}
                          showOptionLabel={true}
                          onSelect={(code) => {
                            this.setState({ dayCountryCode: code });
                          }}
                          placeholder={"Select a Country"}
                          countries={[
                            "GR",
                            "CR",
                            "NO",
                            "GE",
                            "MX",
                            "ME",
                            "DK",
                            "PL",
                            "PT",
                            "IS",
                            "MA",
                            "IL",
                            "TR",
                            "EC",
                            "ZA",
                            "IN",
                            "LT",
                            "IE",
                            "ES",
                          ]}
                        />

                        {/* 'KR',   'PE','RO',     'LK','CL','AR','AE','TZ','BG','IT' */}
                        {/* 'TR', 'LT','IE' */}
                        <span className="errorMsg">
                          {this.state.errors["dayCountryCode"]}
                        </span>

                        {/* <TextField
                        id="txtSNCountry"
                        size="Small"
                        variant="outlined"
                        value={this.state.stop_country}
                        onChange={(event) =>
                          this.setState({ stop_country: event.target.value })
                        } 
                      />*/}
                      </td>
                      <td style={{ verticalAlign: "baseline" }}>
                        <lable>Region</lable>
                        <br />
                        <FormControl
                          style={{ maxWidth: "200px" }}
                          class="dropdownWithborder"
                        >
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Type"
                            value={this.state.dayRegion}
                            onChange={(event) => {
                              this.setState({ dayRegion: event.target.value });
                            }}
                          >
                            <MenuItem value={"Northern"}>Northern</MenuItem>
                            <MenuItem value={"South"}>South</MenuItem>
                          </Select>
                        </FormControl>
                        <span className="errorMsg">
                          {this.state.errors["dayRegion"]}
                        </span>
                      </td>
                    </tr>

                    <tr>
                      <td style={{ alignItems: "center" }}>
                        <lable>Length( in Hours)</lable>&nbsp;&nbsp;&nbsp;
                        <TextField
                          id="txtLengthHours"
                          size="Small"
                          variant="outlined"
                          style={{ width: 50 }}
                          value={this.state.dayLengthHours}
                          onChange={(event) =>
                            this.setState({ dayLengthHours: event.target.value })
                          }
                        />
                      </td>
                      <td style={{ alignItems: "center" }}>
                        <lable>Overnight</lable>&nbsp;&nbsp;&nbsp;
                        <TextField
                          id="txtLengthHours"
                          size="Small"
                          variant="outlined"
                          style={{ width: '50%' }}
                          value={this.state.overnight}
                          onChange={(event) =>
                            this.setState({ overnight: event.target.value })
                          }
                        />
                      </td>
                    </tr>

                    <tr>
                      <td colspan="2">
                        <lable>Description</lable>
                        <br />
                        <TextField
                          id="txtDescription"
                          multiline
                          style={{ width: "80%" }}
                          rows={4}
                          variant="outlined"
                          fullWidth={true}
                          value={this.state.dayDescription}
                          error={!!this.state.errors["dayDescription"]}
                          onChange={(event) =>
                            this.setState({ dayDescription: event.target.value })
                          }
                        />
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <lable>Day Start Location</lable>
                        <br />
                        <TextField
                          id="txtStartLocation"
                          className="location"
                          variant="outlined"
                          fullWidth={true}
                          value={this.state.dayStartLocation}
                          onChange={(event) => {
                            this.setState({
                              dayStartLocation: event.target.value,
                            });
                            this.setStartLocationMap(event.target.value);
                          }}
                        />
                        <br />{" "}
                        <span className="errorMsg">
                          {this.state.errors["dayStartLocation"]}
                        </span>
                        <br />
                        OR
                        <MapboxAutocomplete
                          publicKey={mapboxgl.accessToken}
                          inputClass="form-control search"
                          onSuggestionSelect={this._suggestionSelected}
                          onChange={(e) =>
                            console.log("newlocation1", e.target.value)
                          }
                          resetSearch={false}
                        />
                      </td>
                      <td>
                        <lable>Day End Location</lable>
                        <br />
                        <TextField
                          id="txtEndLocation"
                          className="location"
                          variant="outlined"
                          fullWidth={true}
                          value={this.state.dayEndLocation}
                          onChange={(event) => {
                            this.setState({ dayEndLocation: event.target.value });
                            this.setEndLocationMap(event.target.value);
                          }}
                        />
                        <br />
                        <span className="errorMsg">
                          {this.state.errors["dayEndLocation"]}
                        </span>{" "}
                        <br />
                        OR
                        <MapboxAutocomplete
                          publicKey={mapboxgl.accessToken}
                          inputClass="form-control search"
                          onSuggestionSelect={this._suggestionSelected1}
                          onChange={(e) =>
                            console.log("newlocation1", e.target.value)
                          }
                          resetSearch={false}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <MapboxGLMap prefix={"mapDay-1"} />
                      </td>
                      <td>
                        <MapboxGLMap prefix={"mapDay-2"} />
                      </td>
                    </tr>

                    <tr>
                      <td colspan="2">
                        <Tabs
                          value={0}
                          onChange={(event, newvalue) => {
                            this.setState({ selectedTab: newvalue });
                          }}
                          aria-label="basic tabs example"
                        >
                          <Tab label="WEB" value={0} />
                          {/*  <Tab label="VG" value={1} /> */}
                        </Tabs>
                      </td>
                    </tr>
                    {this.state.selectedTab == 0 && (
                      <tr>
                        <td
                          colspan="2"
                          style={{
                            borderColor: "#000",
                            border: "solid 1px #ccc",
                          }}
                        >
                          <WebPoint
                            setglobalPoiSet={this.setglobalPoiSetWeb}
                            globalPoiSet={this.state.globalPoiSetWeb}
                            selectedWebPOIsKeys={this.state.selectedWebPOI}
                            setSelectedWebPoi={this.setSelectedWebPoi}
                            setSelectedVgByWeb={this.setSelectedVgByWeb}
                          />
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td colspan="2">
                        <Tabs
                          value={0}
                          onChange={(event, newvalue) => {
                            this.setState({ selectedTab: newvalue });
                          }}
                          aria-label="basic tabs example"
                        >
                          {/*  <Tab label="WEB" value={0} /> */}
                          <Tab label="VG" value={0} />
                        </Tabs>
                      </td>
                    </tr>

                    {this.state.selectedTab == 0 && this.state.showVg && (
                      <tr>
                        <td
                          colspan="2"
                          style={{
                            borderColor: "#000",
                            border: "solid 1px #ccc",
                          }}
                        >
                          <VG
                            setglobalPoiSet={this.setglobalPoiSetVg}
                            globalPoiSet={this.state.globalPoiSetVg}
                            selectedVG={this.state.selectedVG}
                            setSelectedVG={this.setSelectedVG}
                            setVgpoiall={this.setVgpoiall}
                            Vgpoiall={this.state.Vgpoiall}
                          />
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td colSpan="4">
                        {this.state.save_type == "add" && (
                          <Button
                            id="btnSaveSN"
                            variant="contained"
                            color="primary"
                            onClick={this.handleSaveDay.bind(this)}
                          >
                            CREATE
                          </Button>
                        )}
                        {this.state.save_type != "add" && (
                          <Button
                            id="btnSaveSN"
                            variant="contained"
                            color="primary"
                            onClick={this.handleSaveDay.bind(this)}
                          >
                            SAVE CHANGES
                          </Button>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            }
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
        <ToastContainer />
        <table cellPadding="10" class="viewdata">
          <tbody>
            <tr>
              <td>
                <h2>Days Module</h2>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan="3"></td>
              <td>
                &nbsp;
                <Button
                  id="btnSave"
                  variant="contained"
                  color="#15cc09"
                  className="deleteBtn"
                  onClick={() => {
                    this.handleClickOpen();
                  }}
                  style={{ float: "right", fontSize: 12, color: "#fff" }}
                >
                  DELETE SELECTED
                </Button>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <DaysTable
                  dayData={this.state.dayTableData}
                  setDayTableData={this.setDayTableData}
                  setDayData={this.setDayData}
                  setStartLocationMap={this.setStartLocationMap}
                  setEndLocationMap={this.setEndLocationMap}
                />
              </td>
            </tr>
            <tr>
              <td width={170}>
                <Link
                  href="#"
                  onClick={() => {
                    this.setDayData(
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "add",
                      "",
                      ""
                    );
                    this.setState({ isDuplicate: false });
                    openPopup();
                    initializeMap(-1, "mapDay-1",[0,0]);
                    initializeMap(-1, "mapDay-2",[0,0]);
                  }}
                >
                  + ADD NEW DAY{" "}
                </Link>
              </td>


              <td colSpan="1">


                <Link
                  href="#"
                  onClick={() => {
                    this.setDayData(
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "",
                      "add",
                      "",
                      ""
                    );
                    this.setState({ isDuplicate: true });
                    openPopup();
                  
                  }}
                  style={{ textTransform: "uppercase" }}
                >
                  + Duplicate from existing day{" "}
                </Link>

              </td>
              <td colSpan="10">&nbsp;</td>

            </tr>
            <tr>
              <td colSpan="4">
                <div className="borderDiv" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default DaysPersonalized;

/* openPopup= () =>
{
  const modal = document.querySelector(".modal")
  const closeBtn = document.querySelector(".close")
  modal.style.display = "block";
  closeBtn.addEventListener("click", () => {
    modal.style.display = "none";
  })
}
 */
//Mapbox Key
mapboxgl.accessToken =
  "pk.eyJ1IjoidXJpYWJyaWRnaWZ5IiwiYSI6ImNrb3IybjRtYjEwd2wydnBpdGxvNWVoYzIifQ.jUaSkJhni2GW103YouzXOQ";
let mapMarkersOne = [];
let mapMarkersTwo = [];
let webmapMarkers = [];
let vgmapMarkers = [];

let webMapOne;
let webMapTwo;
let webMap;
let vgMap;

const initializeMap = (z, id,centerCordinate) => {
  if (id == "mapDayVG") {
    vgMap = new mapboxgl.Map({
      container: id,
      style: "mapbox://styles/mapbox/streets-v11",
      center: centerCordinate,
      zoom: z,
    });
    //Add navigation controls to the mapbox if zoom is lower
    if (z < 11)
      vgMap.addControl(new mapboxgl.NavigationControl(), "bottom-right");
    vgMap.on("click", function (e) {
      if (selectedRowIndex) {
        addVgMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      } else {
        toast.error(
          "Please select a trip first by clicking VG button against the record"
        );
      }
    });
  } else if (id == "mapDayStop") {
    webMap = new mapboxgl.Map({
      container: id,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [0, 0],
      zoom: z,
    });
    //Add navigation controls to the mapbox if zoom is lower
    if (z < 11)
      webMap.addControl(new mapboxgl.NavigationControl(), "bottom-right");
    webMap.on("click", function (e) {
      if (selectedRowIndex) {
        addWebMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      } else {
        addWebMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
        // toast.error('11Please select a trip first by clicking Web button against the record');
      }
    });
  } else {
    if (id == "mapDay-1") {
      webMapOne = new mapboxgl.Map({
        container: id,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [0, 0],
        zoom: z,
      });
      webMapOne.on("click", function (e) {
        window.bd.setPoiLocation(
          e.lngLat.lat.toFixed(6),
          e.lngLat.lng.toFixed(6)
        );

        $("#txtStartLocDay").val(
          e.lngLat.lng.toFixed(6) + "," + e.lngLat.lat.toFixed(6)
        );
        // this.setState({ startloc: e.lngLat.lng.toFixed(4) + ',' + e.lngLat.lat.toFixed(4) });
        mapMarkersOne.forEach((marker) => marker.remove());
        mapMarkersOne = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapOne);
        mapMarkersOne.push(marker);
      });
    } else {
      webMapTwo = new mapboxgl.Map({
        container: id,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [0, 0],
        zoom: z,
      });

      webMapTwo.on("click", function (e) {
        //window.bd.setPoiLocation(e.lngLat.lat.toFixed(6), e.lngLat.lng.toFixed(6));
        window.bd.setState({
          dayEndLocation:
            e.lngLat.lat.toFixed(6) + "," + e.lngLat.lng.toFixed(6),
        });
        $("#txtEndLocDay").val(
          e.lngLat.lng.toFixed(6) + "," + e.lngLat.lat.toFixed(6)
        );
        mapMarkersTwo.forEach((marker) => marker.remove());
        mapMarkersTwo = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapTwo);
        mapMarkersTwo.push(marker);
      });
    }
  }
};

class MapboxGLMap extends Component {
  constructor(props) {
    super(props);
    // this.id = _uniqueId(props.prefix + "-");
    this.id = props.prefix;
  }
  componentDidMount() {
    initializeMap(-1, this.id,[0,0]);
  }
  render() {
    const id = this.id;
    return (
      <div id={id} className="mapEA">
        {" "}
      </div>
    );
  }
}

class DaysTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [["Loading Data..."]],
      loading: false,
    };
  }

  async componentDidMount() {
    let data = await this.getData();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has("id")) {
      let id = urlParams.getAll("id");
      this.setDaysData(id, data);
    }
  }

  setDaysData = (id, data) => {
    let filterId = parseInt(id[0]);
    let filterRow = data.filter((row) => row.id === filterId)[0];
    let getIndexRow = data.findIndex((row) => row.id === filterId);

    let globalPoiSetVg = [];
    let globalPoiSetWeb = [];
    filterRow.web_poi_set.forEach((poi) => {
      globalPoiSetWeb.push(poi.id);
    });
    filterRow.vg_poi_set.forEach((poi) => {
      globalPoiSetVg.push(poi.id);
    });
    var countryCode;
    var countryname = filterRow.country;
    countriesNames.map(function (item) {
      if (item.name === countryname) countryCode = item.code;
    });

    let startLoc = "";
    if (filterRow.starting_point)
      startLoc =
        filterRow.starting_point?.latitude +
        "," +
        filterRow.starting_point?.longitude;
    let endLoc = "";
    if (filterRow.ending_point)
      endLoc =
        filterRow.ending_point?.latitude + "," + filterRow.ending_point?.longitude;

    this.props.setDayData(
      globalPoiSetWeb,
      globalPoiSetVg,
      filterRow.name,
      countryCode,
      filterRow.region,
      5,
      filterRow.photo,
      filterRow.description,
      startLoc,
      endLoc,
      "update",
      getIndexRow,
      ""
    );
    openPopup()
    initializeMap(-1, "mapDay-1",[0,0]);
    initializeMap(-1, "mapDay-2",[0,0]);
    if (filterRow.starting_point)
      this.props.setStartLocationMap(startLoc);
    if (filterRow.ending_point)
      this.props.setEndLocationMap(endLoc);
  };

  getData = () => {
    this.setState({ loading: true });
    let dataRows = this.xhrRequest().then((data) => {
      this.setState({ data, loading: false });
      this.props.setDayTableData(data);
      return data;
    });
    return dataRows;
  };

  xhrRequest = (url) => {
    return new Promise((resolve, reject) => {
      const requestOptions = {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      };
      fetch(ApiData.apiUrl + "/days?take=3000&personalized=true&custom=false", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          data.results.sort((a, b) => a.id - b.id);
          daysData = data.results;
          resolve(data.results);
        });

    });
  };

  render() {
    const columns = [
      {
        name: "id",
        options: {
          label: "ID",
          filter: false,

          customBodyRender: (value, tableMeta, updateValue) => {
            //  const editrow = tableMeta.tableData[tableMeta.rowIndex];
            var editrow = {};
            var rowIndexForUpdate = 0;
            var i = 0;
            tableMeta.tableData.forEach((row) => {
              if (row.id == value) {
                editrow = row;
                rowIndexForUpdate = i;
              }
              i++;
            });
            let overNight = "";
            if (editrow.overnight)
              overNight = editrow.overnight;
            var countryCode;
            var countryname = editrow.country;
            countriesNames.map(function (item) {
              if (item.name === countryname) countryCode = item.code;
            });
            let startLoc = "";
            if (editrow.starting_point)
              startLoc =
                editrow.starting_point?.latitude +
                "," +
                editrow.starting_point?.longitude;
            let endLoc = "";
            if (editrow.ending_point)
              endLoc =
                editrow.ending_point?.latitude +
                "," +
                editrow.ending_point?.longitude;

            return (
              // dayTitle, dayCountryCode, dayRegion, dayLengthHours, dayCoverPhoto, dayDescription,dayStartLocation,dayEndLocation
              <Link
                href="#"
                onClick={() => {
                  let globalPoiSetVg = [];
                  let globalPoiSetWeb = [];
                  editrow.web_poi_set.forEach((poi) => {
                    globalPoiSetWeb.push(poi.id);
                  });
                  editrow.vg_poi_set.forEach((poi) => {
                    globalPoiSetVg.push(poi.id);
                  });

                  this.props.setDayData(
                    globalPoiSetWeb,
                    globalPoiSetVg,
                    editrow.name,
                    countryCode,
                    editrow.region,
                    5,
                    editrow.photo,
                    editrow.description,
                    startLoc,
                    endLoc,
                    "update",
                    rowIndexForUpdate,
                    overNight
                  );
                  window.history.pushState(null, null, `?page=PersonalizedDays&id=${editrow.id}`);
                  openPopup()
                  initializeMap(-1, "mapDay-1",[0,0]);
                  initializeMap(-1, "mapDay-2",[0,0]);
                  if (editrow.starting_point)
                    this.props.setStartLocationMap(startLoc);
                  if (editrow.ending_point)
                    this.props.setEndLocationMap(endLoc);
                }}
              >
                {" "}
                {editrow.id}
              </Link>
            );
          },
        },
      },

      {
        name: "internal_name",
        label: "Internal Name",
        options: { filter: false },
      },
      { name: "name", label: "Name", options: { filter: false } },
      /*   { name: 'description', label: 'Description', options: { filter: false } }, */
      /*   { name: 'location', label: 'Location', options: { filter: false } },
        { name: 'type', label: 'Type', options: { filter: false } },
        { name: 'photo', options: { display: false, filter: false } }, */
      { name: "country", label: "Country" },
      { name: "region", label: "Region" },
    ];

    const onRowsSelect = (curRowSelected, rowsSelected) => {
      selectedRowIndex = [];

      for (let i = 0; i < rowsSelected.length; i++) {
        selectedRowIndex.push(rowsSelected[i].dataIndex);
      }
      activeDeactiveDeleteBtn();
    };
    const { data } = this.state;

    const options = {
      filter: true,
      filterType: "dropdown",
      responsive: "standard",
      download: false,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: "none",
      rowsSelected: selectedRowIndex,
      onRowSelectionChange: onRowsSelect,
    };
    return (
      <div>
        <MUIDataTable
          title={
            <Typography variant="subtitle2">
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: "relative", top: 4 }}
                />
              )}
            </Typography>
          }
          data={this.props.dayData}
          columns={columns}
          options={options}
          rowsSelected={onRowsSelect}
        />
      </div>
    );
  }
}

function addWebMarker(coordinates) {
  const id = _uniqueId("webmarker-");

  let marker;
  var el = document.createElement("div");
  el.className = "marker";
  el.id = id;
  el.addEventListener(
    "click",
    (e) => {
      e.stopPropagation();
      let target_id = e.target.id;
      // let queriedMarkerObj = webmapMarkers.find((marker) => marker.id === query);
      // queriedMarkerObj.marker.remove();
      for (let i = 0; i < webmapMarkers.length; i++) {
        if (webmapMarkers[i].id === target_id) {
          document.getElementById(webmapMarkers[i].id).remove();
          webmapMarkers.splice(i, 1);
        }
      }
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(webMap);
  marker.properties = { id: id };
  webmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}

function addVgMarker(coordinates, type = false) {
  const id = _uniqueId("vgmarker-");
  let marker;
  var el = document.createElement("div");
  el.className = "marker";
  el.id = id;
  if (type) {
    el.style.backgroundImage = `url('https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/${getSvg(
      type
    )}.svg')`;
  }
  el.addEventListener(
    "click",
    (e) => {
      e.stopPropagation();
      let query = e.target.id;

      let queriedMarkerObj = vgmapMarkers.find((marker) => marker.id === query);
      queriedMarkerObj.marker.remove();
      pointNumbers.splice(
        pointNumbers.findIndex((v) => v.id === query),
        1
      );
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(vgMap);
  vgmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}

function handleGpx(xmlData) {
  const converter = require("@tmcw/togeojson");
  const geojson = converter.gpx(xmlData[2]);
  if (typeof vgMap.getLayer("day-route-line") !== "undefined") {
    vgMap.removeLayer("day-route-line").removeSource("day-route");
  }
  vgMap.addSource("day-route", {
    type: "geojson",
    data: geojson,
  });

  vgMap.addLayer({
    id: "day-route-line",
    type: "line",
    source: "day-route",
    paint: {
      "line-color": "#15cc09",
      "line-width": 4,
    },
  });

  var coordinates = geojson.features[0].geometry.coordinates;
  var bounds = coordinates.reduce(function (bounds, coord) {
    return bounds.extend(coord);
  }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

  vgMap.fitBounds(bounds, {
    padding: 20,
  });
}

function getSvg(type) {
  type = parseInt(type);
  switch (type) {
    case 1: {
      return "straight";
    }
    case 2: {
      return "right";
    }
    case 3: {
      return "left";
    }
    case 4: {
      return "fork-left";
    }
    case 5: {
      return "fork-right";
    }
    case 6: {
      return "poi";
    }
    case 7: {
      return "info";
    }
    case 8: {
      return "swimming";
    }
    case 9: {
      return "rafting";
    }
    case 10: {
      return "hotel";
    }
    default: {
      return "poi";
    }
  }
}

function openPopup() {
  const modal = document.querySelector(".modal");
  const closeBtn = document.querySelector(".close");
  modal.style.display = "block";
  document.querySelector(".viewdata").style.display = "none";
  closeBtn.addEventListener("click", () => {
    window.history.pushState(null, null, `?page=PersonalizedDays`);
    modal.style.display = "none";
    document.querySelector(".viewdata").style.display = "inline-table";
  });
}
function closePopoup() {
  document.querySelector(".modal").style.display = "none";
  document.querySelector(".viewdata").style.display = "inline-table";
}

function activeDeactiveDeleteBtn() {
  const btn = document.querySelector(".deleteBtn");
  if (selectedRowIndex.length > 0) btn.style.backgroundColor = "blue";
  else btn.style.backgroundColor = "#e0e0e0";
}