export const paybylinkTemplate = `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8">
    <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>PlanYourTripTemplate</title>
    <link href='https://fonts.googleapis.com/css?family=Poppins' rel='stylesheet'>
    <link href='https://fonts.googleapis.com/css?family=Inter' rel='stylesheet'>
    <style>
      body {
        font-family: 'Poppins' !important;
        direction: ltr !important;
      }
    </style>
    <style type="text/css">
      body {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        direction: ltr !important;
      }

      #root {
        overflow-x: hidden;
        height: fit-content;
        font-family: "Poppins";
        display: block;
        width: 100%;
        background-image: url('https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/email_confirm_orange_vector.png'), url('https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/email_confirm_orange_vector.png'), url('https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/email_confirm_blue_vector.png'), url('https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/email_confirm_blue_vector1.png');
        background-position: left 527px, left 1220px, right 180px, right 1258px;
        width: 100%;
        height: 100vh;
        background-size: 273px 812px, 509px 700px, 289px 869px, 338px 667px;
        background-repeat: no-repeat;
        font-family: 'Poppins';
      }

      body {
        overflow-x: hidden;
      }

      .card-principal,
      .card1,
      .card2 {
        height: 178px;
        display: inline-block;
        vertical-align: top;
        background-color: white;
      }

      .card-principal {
        width: calc(48% - 10px);
        position: relative;
        margin-right: 20px;
      }

      .card1 {
        width: calc(25% - 10px);
        position: relative;
        margin-right: 20px;
      }

      .card2 {
        width: calc(25% - 10px);
        position: relative;
      }

      .text_card_principal {
        display: inline-block;
        width: calc(48% - 10px);
        margin-right: 20px;
        vertical-align: top;
        background-color: white;
      }

      .text_card1 {
        display: inline-block;
        width: calc(25% - 10px);
        margin-right: 20px;
        vertical-align: top;
        background-color: white;
      }

      .text_card2 {
        display: inline-block;
        width: calc(25% - 10px);
        vertical-align: top;
        background-color: white;
      }

      .price_index{
        display: none;
      }

      @media (max-width: 800px) {
        #root {
          background-image: none !important;
        }

        .data_div {
          width: 100% !important;
          margin: 0 auto !important;
        }

        .data_background {
          background: white !important;
          padding-bottom: 1rem !important;
          height: 100% !important;
          width: 100% !important;
        }

        .reference {
          display: table !important;
          width: 80% !important;
        }

        .table_row {
          display: table-row !important;
          padding-top: 1rem !important;
        }

        .table-cell1 {
          display: table-cell !important;
          margin-right: 10px !important;
        }

        .table-cell2 {
          display: table-cell !important;
          text-align: right !important;
          margin-top: 10px !important;
          margin-right: 20px !important;
        }

        .titles {
          text-align: center !important;
          width: 85% !important;
          margin: 0 auto !important;
        }

        .username {
          font-family: 'Poppins' !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 16px !important;
          text-align: left !important;
          color: #00141A !important;
        }

        .title_text {
          font-family: 'Poppins' !important;
          font-style: normal !important;
          font-size: 14px !important;
          text-align: left !important;
          width: 80% !important;
          color: #00141A !important;
          margin: 0 !important;
        }

        .cards {
          display: none !important;
        }

        .card-principal {
          height: 178px !important;
          display: inline-block !important;
          width: calc(48% - 10px) !important;
          position: relative !important;
          margin-right: 20px !important;
          vertical-align: top !important;
          background-color: white !important;
        }

        .card_img {
          width: 100% !important;
          height: 178px !important;
          object-fit: cover !important;
        }

        .check {
          position: absolute !important;
          bottom: -10px !important;
          left: 5px !important;
        }

        .card1 {
          height: 178px !important;
          display: inline-block !important;
          width: calc(25% - 10px) !important;
          position: relative !important;
          margin-right: 20px !important;
          vertical-align: top !important;
          background-color: white !important;
        }

        .card2 {
          height: 178px !important;
          display: inline-block !important;
          width: calc(25% - 10px) !important;
          position: relative !important;
          vertical-align: top !important;
          background-color: white !important;
        }

        .text_cards {
          text-align: justify !important;
          white-space: nowrap !important;
          display: flex !important;
          flex-wrap: wrap !important;
          margin-top: 0 !important;
        }

        .text_card_principal,
        .text_card1,
        .text_card2 {
          display: inline-block !important;
          width: 100% !important;
          margin-right: 0 !important;
          height: fit-content !important;
        }

        .div_card_text {
          display: table !important;
          position: relative !important;
          top: 0px !important;
          width: 100vw !important;
          height: 5rem !important;
          padding-left: 1rem !important;
          padding-top: 0 !important;
          padding-bottom: 0 !important;
        }

        .title_card_text {
          display: block !important;
          padding-left: 10px !important;
          width: 300px !important;
          white-space: normal !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          margin: 0 !important;
          font-size: 14px !important;
        }

        .text_card_text {
          display: block !important;
          padding-left: 10px !important;
          width: 300px !important;
          white-space: normal !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          margin: 0 !important;
          font-size: 14px !important;
        }

        .table_desktop{
          display: none !important;
        }
        .space {
          display: none !important;
        }

        .day_trip {
          margin-bottom: 1rem !important;
        }

        .day_index {
          width: 100% !important;
          height: 40px !important;
          margin: 0 auto !important;
          margin-top: 10px !important;
          text-align: start !important;
          background: #A7C8EC !important;
          display: flex !important;
          align-items: center !important;
        }

        .day_index_title {
          font-family: 'Poppins' !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 16px !important;
          line-height: 30px !important;
          color: #FFFFFF !important;
          padding-left: 35px !important;
          margin: 0 !important;
        }

        .day {
          width: 100% !important;
          background: white !important;
          padding-top: 30px !important;
        }

        .day_name {
          margin-top: 0px !important;
          margin-bottom: 2rem !important;
          font-size: 14px !important;
          padding-left: 35px !important;
          font-family: 'Poppins' !important;
        }

        .day_description {
          margin-top: 0 !important;
          margin-bottom: 10px !important;
          font-size: 14px !important;
          padding-left: 35px !important;
          font-family: 'Poppins' !important;
          padding-right: 6rem !important;
        }

        .day_ul {
          margin-top: 0 !important;
          margin-bottom: 2rem !important;
          font-size: 14px !important;
          padding-left: 4rem !important;
          padding-right: 6rem !important;
        }

        .day_img_div {
          width: 100% !important;
          max-width: 692px !important;
          margin: 0 auto !important;
          text-align: center !important;
          padding-bottom: 3rem !important;
        }

        .price_div {
          width: 100% !important;
          margin-top: 2rem !important;
          margin: 0 auto !important;
          background: white !important;
        }

        .price_per_person-title {
          display: none !important;
        }

        .price_index {
          width: 100% !important;
          height: 40px !important;
          margin: 0 auto !important;
          margin-top: 10px !important;
          text-align: start !important;
          background: #A7C8EC !important;
          display: flex !important;
          align-items: center !important;
        }

        .price_index_title {
          font-family: 'Poppins' !important;
          font-style: normal !important;
          font-weight: 600 !important;
          font-size: 20px !important;
          line-height: 30px !important;
          color: #FFFFFF !important;
          padding-left: 35px !important;
          margin: 0 !important;
        }

        .price_description {
          margin-top: 0 !important;
          margin-bottom: 10px !important;
          font-size: 14px !important;
          padding-left: 0px !important;
        }


        .price_inc_exc {
          margin-top: 0 !important;
          margin-bottom: 10px !important;
          font-size: 14px !important;
          padding-left: 0px !important;
        }

        .div_payment {
          margin-top: 30px !important;
          margin: 0 auto !important;
          margin-top: 2rem !important;
        }

        .card_img_mobile {
          height: 100% !important;
          object-fit: cover !important;
        }

        .card_img_mobile:first-child {
          width: 50% !important;
        }

        .card_img_mobile:not(:first-child) {
          width: 25% !important;
        }
      }
    </style>
  </head>
  <body style="direction: ltr !important; background: #F0F0F0; font-family: 'Poppins'">
    <div id="root">
      <div class='data_div' style=" width: 60%; margin-top: 2rem; display: block; margin: 0 auto;">
        <div class='data_background' style="background: white; padding-bottom: 1rem;
        height: 100%;">
          <div class='reference' style="display: table; width: 92%;">
            <div class="table_row" style="display: table-row; padding-top: 1rem;">
              <div class="table-cell1" style="display: table-cell; margin-right: 10px;">
                <img src="https://planmytrip-assets.s3.eu-central-1.amazonaws.com/PlanYourTrip_%20logo%201.png" alt="" style="margin-top: 10px; margin-left: 20px;">
              </div>
              <div class="table-cell2" style="display: table-cell; text-align: right; margin-top: 10px; margin-right: 20px;">
                <p>Reference: <br>{{reference}}
                </p>
              </div>
            </div>
          </div>
          <div class='titles' style="text-align: center; width: 80%; margin: 1rem auto 1rem;">
            <div class="username" style="font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            text-align: left;
            color: #00141A;"> Hi {{username}}, </div>
            <p class="title_text" style="font-family: 'Poppins';
            font-style: normal;
            font-size: 16px;
            text-align: left;
            color: #00141A;"> This is your personalized {{days_count}}-days trip to {{country}}. Your trip includes: </p>
          </div>
        </div>

        <div class="table_desktop" style="width: 100%;">
          <table cellpadding="0" cellspacing="0" style="width: 100%;">
            <tr>
              <td style="width: 48%; margin-right: 10px;">
                <div style="position: relative; background-color: white;">
                  <img src="{{package_img}}" alt="" style="width: 98%; height: 178px; object-fit: cover;">
                </div>
              </td>
              <td style="width: 25%; margin-right: 10px;">
                <div style="position: relative; background-color: white;">
                  <img src="https://planmytrip-assets.s3.eu-central-1.amazonaws.com/NavigationalTourGuide.png" alt="" style="width: 98%; height: 178px; object-fit: cover;">
                </div>
              </td>
              <td style="width: 25%;">
                <div style="position: relative; background-color: white;">
                  <img src="{{local_hero_img}}" alt="" style="width: 100%; height: 178px; object-fit: cover;">
                </div>
              </td>
            </tr>
          </table>
        </div>

       <table class="table_desktop" cellpadding="0" cellspacing="0" style="width: 100%;">
  <tr>
    <td style="width: 48%; margin-right: 10px;">
      <div class="text_card_principal" style="width: 98%; height: 140px; margin-right: 20px; background-color: white;">
        <div class="div_card_text" style="width: 80%; height: 140px; display: table-cell; vertical-align: top; padding: 10px;">
          <div style="display: flex; align-items: center;">
            <img src="https://planmytrip-assets.s3.eu-central-1.amazonaws.com/check.png" alt="" style="height: 20px; width: 20px;">
            <div style="display: flex; flex-direction: column; margin-left: 5px;">
              <h4 class="title_card_text" style="margin-top: 0; margin-bottom: 0; font-size: 13px; text-align: left;">{{days_count}}-days Personalized Itinerary</h4>
            </div>
          </div>
          <p class="text_card_text" style="font-size: 14px;">Once you planned your trip, your personalized itinerary is ready.</p>
        </div>
      </div>
    </td>
    <td style="width: 25%; margin-right: 10px;">
      <div class="text_card1" style="width: 98%; height: 140px; margin-right: 20px; background-color: white;">
        <div class="div_card_text" style="width: 80%; height: 140px; display: table-cell; vertical-align: top; padding: 10px;">
          <div style="display: flex; align-items: center;">
            <img src="https://planmytrip-assets.s3.eu-central-1.amazonaws.com/check.png" alt="" style="height: 20px; width: 20px;">
            <div style="display: flex; flex-direction: column; margin-left: 5px;">
              <h4 class="title_card_text" style="margin-top: 0; margin-bottom: 0; font-size: 13px; text-align: left;">Navigational Tour Guide</h4>
            </div>
          </div>
          <p class="text_card_text" style="font-size: 14px;">Navigate your entire itinerary on the go and receive relevant tips along your route.</p>
        </div>
      </div>
    </td>
    <td style="width: 25%;">
      <div class="text_card2" style="width: 100%; height: 140px; background-color: white;">
        <div class="div_card_text" style="width: 80%; height: 140px; display: table-cell; vertical-align: top; padding: 10px;">
          <div style="display: flex; align-items: center;">
            <img src="https://planmytrip-assets.s3.eu-central-1.amazonaws.com/check.png" alt="" style="height: 20px; width: 20px;">
            <div style="display: flex; flex-direction: column; margin-left: 5px;">
              <h4 class="title_card_text" style="margin-top: 0; margin-bottom: 0; font-size: 13px; text-align: left;">Local Hero on the Ground for You</h4>
            </div>
          </div>
          <p class="text_card_text" style="font-size: 14px;">Will be there for you advising, arranging, helping, suggesting.</p>
        </div>
      </div>
    </td>
  </tr>
</table>

    {{#if overnightPreCity}}
      <div class="day_index" style="width: 100%;
                      height: 40px;
                      margin-top: 10px;
                      margin-bottom: 30px;
                      text-align: start;
                      background: white;
                      display: flex;
                      align-items: center;">
                      
                  <div style="padding-left: 35px; padding-right: 36px; font-family: 'Poppins'; margin: auto 0;">
                      <span style="color: black;">Pre Overnight: {{overnightPreCity}}{{#if overnightPreHotel}}, {{/if}}</span>
                      {{#if overnightPreHotel}}
                        <a class="link_payment" href="{{overnightPreHotelSiteLink}}" style="cursor: pointer; text-decoration: none; color: blue;" target="_blank">
                            {{overnightPreHotel}}
                        </a>
                      {{/if}}
                  </div>
        </div>
    {{/if}}

        {{#each days}}
          <div class="day_trip" style="margin-bottom: 1rem; padding-bottom: 1rem">
            <div class="day_index" style="width: 100%;
              height: 40px;
              margin: 0 auto;
              margin-top: 10px;
              text-align: start;
              background: #A7C8EC;
              display: flex;
              align-items: center;">
              <h4 class="day_index_title" style="font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 30px;
                color: #FFFFFF;
                padding-left: 35px;
                margin: 0;">Day {{dayIndex}}</h4>
            </div>
            <div class="day" style="width: 100%;
              background: white;
              padding-top: 30px;">
              
              <h4 class="day_name" style="margin-top: 0px;
                margin-bottom: 2rem;
                font-size: 14px;
                padding-left: 35px;
                font-family: 'Poppins';">{{name}}</h4>
              <p class="day_description" style="margin-top: 0;
                margin-bottom: 10px;
                font-size: 14px;
                padding-left: 35px;
                font-family: 'Poppins';
                padding-right: 4rem;">{{description}}</p>
              <ul class="day_ul" style="margin-top: 0;
                margin-bottom: 2rem;
                font-size: 14px;
                padding-left: 4.5rem;
                padding-right: 4rem;">
                {{#each web_poi_set}}
                  <li class="day_li" style="font-family: 'Poppins';">{{name}}</li>
                {{/each}}
              </ul>

              {{#if lastDay}}
              <!-- Handle last day case if needed -->
            {{else}}
              <div style="padding-left: 35px; font-family: 'Poppins'; margin-bottom: 20px;">
              {{#if overnight}}
                {{#if hotelName}}
                  <span style="color: black;">{{overnight}}, </span>
                  <a class="link_payment" href="{{siteLinkHotel}}" style="cursor: pointer; text-decoration: none; color: blue;" target="_blank">
                    {{hotelName}}
                  </a>
                {{else}}
                  <span style="color: black;">{{overnight}}</span>
                {{/if}}
              {{/if}}
            </div>
            
            {{/if}}

              <div class="day_img_div" style="width: 100%;
                max-width: 
                margin: 0 auto;
                text-align: center;
                padding-bottom: 3rem;">
                <img class="day_img" style="width: 100%; height: auto;" src="{{photo}}" alt="">
                </div>
                <div class="day_img_div" style="width: 100%; margin-bottom: 10px">
                </div>
            </div>
              </div>
            {{/each}}

            {{#if overnightPostNight}}
            <div class="day_index" style="width: 100%;
                          height: 40px;
                          margin-top: 10px;
                          margin-bottom: 30px;
                          text-align: start;
                          background: white;
                          display: flex;
                          align-items: center;">
                          
                      <div style="padding-left: 35px; padding-right: 36px; font-family: 'Poppins'; margin: auto 0;">
                          <span style="color: black;"> Post Overnight: {{overnightPostNight}}{{#if overnightPostHotel}}, {{/if}}</span>
                          {{#if overnightPostHotel}}
                            <a class="link_payment" href="{{overnightPostHotelSiteLink}}" style="cursor: pointer; text-decoration: none; color: blue;" target="_blank">
                                {{overnightPostHotel}}
                            </a>
                          {{/if}}
                      </div>
                </div>
          {{/if}}
          
          {{#if contentEmail}}
          <div class="day_index" style="width: 100%;
                    height: 40px;
                    margin-top: 10px;
                    margin-bottom: 30px;
                    text-align: start;
                    background: white;
                    display: flex;
                    align-items: center;">
                    
                <div style="padding-left: 35px; padding-right: 35px; font-family: 'Poppins'; margin: auto 0;">
                   {{{contentEmail}}}
                </div>
          </div>
        {{/if}}
  

        <div class="price" style="margin-bottom: 1rem; margin-top: 2rem;">
          <div class="price_index">
            <h4 class="price_index_title">Price per person: {{currency}} {{price}}</h4>
          </div>
          <div class="price_div" style="width: 100%;
          margin-top: 2rem;
          margin: 0 auto;
          background: white;">
            <div class="price_per_person" style="padding-left: 35px;
            padding-bottom: 1rem;
            padding-top: 1rem;">
              <h1 class="price_per_person-title" style="color: #613EEA">Price per person: {{currency}} {{price}}</h1>
              <h3 style="font-size: 16px">Price includes</h3>
              <div class="price_description" style="margin-top: 0;
              margin-bottom: 10px;
              font-size: 14px;">
                {{{price_description}}}
              </div>
            </div>
            <div class="barra" style="width: 90%;
            margin-bottom: 1rem;
            height: 2px;
            background-color: #613EEA;
            margin: 0 auto;"></div>
            <div style="padding-left: 35px;">
              <h3 style="font-size: 16px">Price includes</h3>
              <div class="price_inc_exc" style="margin-top: 0;
              margin-bottom: 10px;
              font-size: 14px;">
                {{{price_includes}}}
              </div>
            </div>
            <div style="padding-bottom: 15px">
              <h3 style="font-size: 16px">Price excludes</h3>
              <div class="price_inc_exc" style="margin-top: 0;
              margin-bottom: 10px;
              font-size: 14px;">
                {{{price_excludes}}}
              </div>
              <h4>PlanYourTrip – Cancellation Policy</h4>
              <p>Up to 45 days before departure - Free of Charge (100% refundable)</p>
              <p>Up to 21 days before departure - 20% of the value of the tour (80% refundable)</p>
              <p>Up to 48 hours before departure - 50% of the value of the tour (50% refundable)</p>
              <p>Less than 48 hours before departure - 100% of the tour (nonrefundable any longer)</p>
            </div>

            <div class="div_payment" style="text-align: left;
            margin-top: 30px;
            padding-left: 35px;
            padding-bottom: 2rem">
              <a class="link_payment" href="{{link_payment}}" style="text-decoration: none;">
                <div class="btn_payment" style="  background: #613eea;
                color: #fff;
                text-align: center;
                padding: 20px 0px;
                font-size: 17px;
                font-weight: 600;
                width: 262px;
                border-radius: 52px;
                cursor: pointer;"> Proceed to Payment </div>
              </a>
            </div>

          </div>
        </div>
      </div>
  </body>
</html> 
`;

export default paybylinkTemplate