//import references
import React, { Component, useState } from 'react';
import { getDatafromApi } from '../calls'
import 'react-toastify/dist/ReactToastify.css';
import Link from '@material-ui/core/Link';
import MUIDataTable from 'mui-datatables';
import { CircularProgress, Typography } from '@material-ui/core';
import DataGrid, {
  Column,
  Selection,
  RowDragging,
  Scrolling,
  Paging,
  Sorting,
} from 'devextreme-react/data-grid';
import { ApiData } from "../config"

let daysData;
let selectedRowIndex = false;


class SelectPersonalizedDays extends Component {

  constructor(props) {
    super(props);
    this.onReorder = this.onReorder.bind(this);

    this.state = {
      data: [['Loading Data...']],
      loading: false,
      dragWebPOIsKeys: [],
      selectedDays: [{ "id": '', "name": "Loading...", "country": "" },],
      defaultSelectedRow: [0, 1, 3]
    };
  }

  onReorder(e) {
    const visibleRows = e.component.getVisibleRows();
    const newDays = [...this.props.selectedDays];
    const toIndex = newDays.indexOf(visibleRows[e.toIndex].data);
    const fromIndex = newDays.indexOf(e.itemData);

    newDays.splice(fromIndex, 1);
    newDays.splice(toIndex, 0, e.itemData);
    this.props.setDuplicateDayData(newDays);
    /*  this.setState({
       selectedDays: newDays,
     }); */
    let selectedWebPOI = newDays.filter((day) => this.state.dragWebPOIsKeys.includes(day.id));
    const selectedRowKeys = selectedWebPOI.map(({ id }) => id);
  }


  onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    this.setState({
      dragWebPOIsKeys: selectedRowKeys,
    });
  };
  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps, prevState) {

  }

  getData = () => {
    this.setState({ loading: true });
    this.xhrRequest().then((data) => {
      this.setState({ data, loading: false });
    });
  };
  xhrRequest = (url) => {
    return new Promise(async (resolve, reject) => {
      let data = await getDatafromApi(ApiData.apiUrl+'/days?take=3000&personalized=false');
      if(data){
        data.results.sort((a,b) => a.id - b.id);
          daysData = data.results;
          for (let i = 0; i < daysData.length; i++) {
            for (let j = 0; j < this.props.selectedDays.length; j++) {
              if (this.props.selectedDays[j].id == daysData[i].id) {
                daysData[i].selected1 = true;
              }
            }
          }
          resolve(data.results);
          this.setSelectedRecord();
      }
    });
  };

  setSelectedRecord() {
    let selectedDays1 = [...this.props.selectedDays];
    let defaultSelectedRow1 = [];
    for (let i = 0; i < daysData.length; i++) {
      if (daysData[i].selected1) {
        let flg = 1
        for (let j = 0; j < this.props.selectedDays.length; j++) {
          if (this.props.selectedDays[j].id == daysData[i].id) {
            flg = 0
          }
        }
        defaultSelectedRow1.push(i);


        if (flg == 1) {
          //this.props.selectedDays.push(daysData[i])
          selectedDays1.push(daysData[i])
        }
      }

    }

    let selectedDaysNew = []

    for (let i = 0; i < selectedDays1.length; i++) {
      for (let j = 0; j < daysData.length; j++) {

        if ((daysData[j].id == selectedDays1[i].id) && daysData[j].selected1) {
          selectedDaysNew.push(daysData[j])

        }
      }


    }
    // this.props.setDuplicateDayData(selectedDaysNew);
    this.setState({ defaultSelectedRow: defaultSelectedRow1 });
  }




  render() {
    const columns = [
      { name: 'id', options: { label: 'ID', filter: false, } },
      { name: 'internal_name', label: 'Internal Name', options: { filter: false } },
      { name: 'name', label: 'Name', options: { filter: false } },
      { name: 'country', label: 'Country' },

    ];

    const onRowsSelect = (curRowSelected, rowsSelected, rowMeta) => {
        const selectedIndex = curRowSelected[0].dataIndex;
        const selectedDay = daysData[selectedIndex];
      
        // Desmarcar todos los días excepto el seleccionado
        const newDaysData = daysData.map((day, index) => {
          day.selected1 = index === selectedIndex;
          return day;
        });
      
        this.setSelectedRecord(newDaysData);
      
        // Llamar a setDuplicateDayData con el día seleccionado
        this.props.setDuplicateDayData(selectedDay);
      };
      
      
    const { data } = this.state;

    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      download: false,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: 'none',
      rowsSelected: this.state.defaultSelectedRow,
      onRowSelectionChange: onRowsSelect,
    };

    return (
      <table>

        <tr>
          <td style={{ width: '40%' }}>
            
          </td>
        </tr>

        <tr>
          <td style={{ width: '40%' }}>

            <div>
              <MUIDataTable
                title={
                  <Typography variant="subtitle2">
                    {this.state.loading && (
                      <CircularProgress
                        size={24}
                        style={{ marginLeft: 15, position: 'relative', top: 4 }}
                      />
                    )}
                  </Typography>
                }
                data={data}
                columns={columns}
                options={options}
                rowsSelected={onRowsSelect}
                rowMeta={[0, 1]}
              />
            </div>
          </td>
          {/* <td style={{ width: '10%' }}>

          </td> */}
          {/* <td style={{ width: '45%', WebkitPaddingEnd: 100, verticalAlign: 'baseline' }} className="mytable">

            <DataGrid
              // height={440}
              dataSource={this.props.selectedDays}
              keyExpr="id"
              showBorders={true}
              ref={(ref) => (this.dataGrid = ref)}
              selectableRows={false}
              expandableRowsHeader={false}
              onSelectionChanged={this.onSelectionChanged}
              selectedRowKeys={this.state.dragWebPOIsKeys}
            >
              <RowDragging
                allowReordering={true}
                onReorder={this.onReorder}
                showDragIcons={true}
              />

              <Scrolling mode="virtual" />
              <Sorting mode="none" />

              <Column dataField="id" width={60} />
              <Column dataField="name" />
              <Column dataField="country" />

              <Paging defaultPageSize={10} />
            </DataGrid>

          </td> */}
        </tr>
      </table >
    );
  }
}
export default SelectPersonalizedDays;