//import references
import React, { Component } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { handleCallApi, getDatafromApi } from './calls'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import MapboxAutocomplete from 'react-mapbox-autocomplete';
import Link from '@material-ui/core/Link';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import { handlePhotoUpload, handleUploadFile, handleTrailPhotoUpload } from "./uploadFiles"
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import _uniqueId from 'lodash/uniqueId';
import MUIDataTable from 'mui-datatables';
import { CircularProgress, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import uploadIcon from './themes/icons/uploadicon.png';
import { ApiData, MapboxKey, countriesNames } from "./config"
import WebPoint from './components/WebPoint';
import Autocomplete from '@mui/material/Autocomplete';

import DataGrid, {
  Column,
  RowDragging,
  Scrolling,
  Paging,
  Sorting,
  Selection,
  CheckBox
} from 'devextreme-react/data-grid';


// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

//for chip set
/* const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
})); */


// end for chip


const top100Films = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
  { label: '12 Angry Men', year: 1957 },
  { label: "Schindler's List", year: 1993 },
  { label: 'Pulp Fiction', year: 1994 },
  {
    label: 'The Lord of the Rings: The Return of the King',
    year: 2003,
  },
  { label: 'The Good, the Bad and the Ugly', year: 1966 },
  { label: 'Fight Club', year: 1999 },
  {
    label: 'The Lord of the Rings: The Fellowship of the Ring',
    year: 2001,
  },
  {
    label: 'Star Wars: Episode V - The Empire Strikes Back',
    year: 1980,
  },
  { label: 'Forrest Gump', year: 1994 },
  { label: 'Inception', year: 2010 },
  {
    label: 'The Lord of the Rings: The Two Towers',
    year: 2002,
  },
  { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { label: 'Goodfellas', year: 1990 },
  { label: 'The Matrix', year: 1999 },
  { label: 'Seven Samurai', year: 1954 },
  {
    label: 'Star Wars: Episode IV - A New Hope',
    year: 1977,
  },
  { label: 'City of God', year: 2002 },
  { label: 'Se7en', year: 1995 },
  { label: 'The Silence of the Lambs', year: 1991 },
  { label: "It's a Wonderful Life", year: 1946 },
  { label: 'Life Is Beautiful', year: 1997 },
  { label: 'The Usual Suspects', year: 1995 },
  { label: 'Léon: The Professional', year: 1994 },
  { label: 'Spirited Away', year: 2001 },
  { label: 'Saving Private Ryan', year: 1998 },
  { label: 'Once Upon a Time in the West', year: 1968 },
  { label: 'American History X', year: 1998 },
  { label: 'Interstellar', year: 2014 },
  { label: 'Casablanca', year: 1942 },
  { label: 'City Lights', year: 1931 },
  { label: 'Psycho', year: 1960 },
  { label: 'The Green Mile', year: 1999 },
  { label: 'The Intouchables', year: 2011 },
  { label: 'Modern Times', year: 1936 },
  { label: 'Raiders of the Lost Ark', year: 1981 },
  { label: 'Rear Window', year: 1954 },
  { label: 'The Pianist', year: 2002 },
  { label: 'The Departed', year: 2006 },
  { label: 'Terminator 2: Judgment Day', year: 1991 },
  { label: 'Back to the Future', year: 1985 },
  { label: 'Whiplash', year: 2014 },
  { label: 'Gladiator', year: 2000 },
  { label: 'Memento', year: 2000 },
  { label: 'The Prestige', year: 2006 },
  { label: 'The Lion King', year: 1994 },
  { label: 'Apocalypse Now', year: 1979 },
  { label: 'Alien', year: 1979 },
  { label: 'Sunset Boulevard', year: 1950 },
  {
    label: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
    year: 1964,
  },
  { label: 'The Great Dictator', year: 1940 },
  { label: 'Cinema Paradiso', year: 1988 },
  { label: 'The Lives of Others', year: 2006 },
  { label: 'Grave of the Fireflies', year: 1988 },
  { label: 'Paths of Glory', year: 1957 },
  { label: 'Django Unchained', year: 2012 },
  { label: 'The Shining', year: 1980 },
  { label: 'WALL·E', year: 2008 },
  { label: 'American Beauty', year: 1999 },
  { label: 'The Dark Knight Rises', year: 2012 },
  { label: 'Princess Mononoke', year: 1997 },
  { label: 'Aliens', year: 1986 },
  { label: 'Oldboy', year: 2003 },
  { label: 'Once Upon a Time in America', year: 1984 },
  { label: 'Witness for the Prosecution', year: 1957 },
  { label: 'Das Boot', year: 1981 },
  { label: 'Citizen Kane', year: 1941 },
  { label: 'North by Northwest', year: 1959 },
  { label: 'Vertigo', year: 1958 },
  {
    label: 'Star Wars: Episode VI - Return of the Jedi',
    year: 1983,
  },
  { label: 'Reservoir Dogs', year: 1992 },
  { label: 'Braveheart', year: 1995 },
  { label: 'M', year: 1931 },
  { label: 'Requiem for a Dream', year: 2000 },
  { label: 'Amélie', year: 2001 },
  { label: 'A Clockwork Orange', year: 1971 },
  { label: 'Like Stars on Earth', year: 2007 },
  { label: 'Taxi Driver', year: 1976 },
  { label: 'Lawrence of Arabia', year: 1962 },
  { label: 'Double Indemnity', year: 1944 },
  {
    label: 'Eternal Sunshine of the Spotless Mind',
    year: 2004,
  },
  { label: 'Amadeus', year: 1984 },
  { label: 'To Kill a Mockingbird', year: 1962 },
  { label: 'Toy Story 3', year: 2010 },
  { label: 'Logan', year: 2017 },
  { label: 'Full Metal Jacket', year: 1987 },
  { label: 'Dangal', year: 2016 },
  { label: 'The Sting', year: 1973 },
  { label: '2001: A Space Odyssey', year: 1968 },
  { label: "Singin' in the Rain", year: 1952 },
  { label: 'Toy Story', year: 1995 },
  { label: 'Bicycle Thieves', year: 1948 },
  { label: 'The Kid', year: 1921 },
  { label: 'Inglourious Basterds', year: 2009 },
  { label: 'Snatch', year: 2000 },
  { label: '3 Idiots', year: 2009 },
  { label: 'Monty Python and the Holy Grail', year: 1975 },
];

const localPoiTypes = [
  { name: 'LOCAL HERO TIP', id: 0 },
  { name: 'RIGHT', id: 1 },
  { name: 'LEFT', id: 2 },
  { name: 'Branching / fork to the right', id: 3 },
  { name: 'Branching / fork to the left', id: 4 },
  { name: 'Point of interest', id: 5 },
  { name: 'Information Icon', id: 6 },
  { name: 'Icon for swimming', id: 7 },
  { name: 'Rafting / kayaking icon', id: 8 }
];

let pointNumbers = [];
let selectedRowIndex = false;
let selectedRowIDs = [];
let selectedLocalPoiRowIndex = false;
let selectedTrialRowIndex = [];



let poiRowsSelected = [];
let gpxLoc;
let daysData;
let newrowId = 101;

class GPX extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      photourl: '',
      gpxTableData: [['Loading Data...']],
      selectedKeysPois: [],
      localPOITableData: [['Loading Data...']],
      trialPkgTableData: [['Loading Data...']],
      updateGPXRowIndex: '',
      updateTrialPkgRowIndex: '',
      gpxDataForTrailpackage: [],
      gpx_title: '',
      gpx: '',
      gpx_type: '',
      gpx_country: '',
      gpx_level: -1,
      gpx_location: '',
      gpx_photo: '',
      gpx_starting_point: null,
      gpx_ending_point: null,
      gpx_ascent: '',
      gpx_duration: '',
      gpx_distance: '',
      description: '',
      updateLocalPoiRowIndex: '',
      local_Poi_Multiple: [],
      local_poi_type: '',
      local_poi_photo: '',
      global_poi_type: '',
      local_poi_title: '',
      local_poi_desc: '',
      local_poi_location: '',
      trial_package_name: '',
      trial_package_type: '',
      trial_package_gpx: '',
      selectedTab: 2,
      trailType: [],
      selectedGpxId: '',
      save_type: 'add',
      local_poi_save_type: 'add',
      trialPkg_save_type: 'add',
      openDeleteCnfrm: false,
      openDeleteCnfrm1: false,
      openDeleteCnfrm2: false,
      openDeleteMarker: false,
      deleteMarkerId: 0,
      chipset: [],
      allGlobalPOI: [],
      globalPoiSetWeb: [],
      selectedWebPOI: [{ "id": '', "name": "Loading...", "country": "" }],

    };
    window.bd = this;
  }


  componentDidMount() {
    this.getGlobalPOI();
  }


  async getGlobalPOI() {
    let data = await getDatafromApi(ApiData.apiUrl + '/global-poi/')
    if(data){
      data.results.sort((a, b) => a.id - b.id);
      for (let i = 0; i < data.results.length; i++) {
        data.results[i].label = data.results[i].id + " (" + data.results[i].name + ")"
      }
      this.setState({ allGlobalPOI: data.results });
    }

  }

  set_local_poi_location(lat, lng) {
    this.setState({ local_poi_location: lat + ',' + lng });
  }


  setglobalPoiSetWeb = async (data) => {

    this.setState({ globalPoiSetWeb: data });
  };

  setSelectedWebPoi = async (data) => {
    this.setState({ selectedWebPOI: data });
  };

  setSelectedVgByWeb = async (data) => {
    let id = data.id;
    data.type = 'POI';
    data.originalType = 'POI';
  }

  //end for global webpoi
  setStartLocationMap(poiLocation) {

    var poiCoordinates = poiLocation.split(",");
    if (poiCoordinates.length >= 2) {
      if (poiCoordinates[0] > 90)
        poiCoordinates[0] = 90
      if (poiCoordinates[0] < -90)
        poiCoordinates[0] = -90

      mapMarkersOne.forEach((marker) => marker.remove());
      mapMarkersOne = [];
      const marker = new mapboxgl.Marker()
        .setLngLat([poiCoordinates[1], poiCoordinates[0]])
        .addTo(webMapOne);
      mapMarkersOne.push(marker);

    }
  }
  setEndLocationMap(poiLocation) {

    var poiCoordinates = poiLocation.split(",");
    if (poiCoordinates.length >= 2) {
      if (poiCoordinates[0] > 90)
        poiCoordinates[0] = 90
      if (poiCoordinates[0] < -90)
        poiCoordinates[0] = -90

      mapMarkersTwo.forEach((marker) => marker.remove());
      mapMarkersTwo = [];
      const marker = new mapboxgl.Marker()
        .setLngLat([poiCoordinates[1], poiCoordinates[0]])
        .addTo(webMapTwo);
      mapMarkersTwo.push(marker);

    }
  }
  setLocalPOILocationMap(poiLocation) {

    var poiCoordinates = poiLocation.split(",");

    if (poiCoordinates.length >= 2) {
      if (poiCoordinates[0] > 90)
        poiCoordinates[0] = 90
      if (poiCoordinates[0] < -90)
        poiCoordinates[0] = -90

      mapMarkersThree.forEach((marker) => marker.remove());
      mapMarkersThree = [];
      const marker = new mapboxgl.Marker()
        .setLngLat([poiCoordinates[1], poiCoordinates[0]])
        .addTo(webMapTwo);
      mapMarkersThree.push(marker);

    }
  }

  _suggestionSelected(result, lat, lng, text) {
    window.bd.setDayStartLocation(lat, lng)
    window.bd.setStartLocationMap(lat + ',' + lng)

  }
  _suggestionSelected1(result, lat, lng, text) {
    window.bd.setDayEndLocation(lat, lng)
    window.bd.setEndLocationMap(lat + ',' + lng)
  }
  _suggestionSelected2(result, lat, lng, text) {
    window.bd.seLocalPoiLocation(lat, lng)
    window.bd.setLocalPOILocationMap(lat + ',' + lng)
  }


  setDayStartLocation(latitude, longitude) {

    this.setState({ gpx_starting_point: latitude + ',' + longitude })
    $('.react-mapbox-ac-input').val('');
  }

  setDayEndLocation(latitude, longitude) {
    this.setState({ gpx_ending_point: latitude + ',' + longitude })
    $('.react-mapbox-ac-input').val('');
  }
  seLocalPoiLocation(latitude, longitude) {
    this.setState({ local_poi_location: latitude + ',' + longitude })
    $('.react-mapbox-ac-input').val('');
  }

  handleChipDelete(chipToDelete) {
    var gpxSet = [...this.state.chipset];

    var gpxSet = this.state.chipset.filter((chip) => chip.key !== chipToDelete);
    this.setState({ chipset: gpxSet });
    var gpxDataForTrailpackage1 = [...this.state.gpxDataForTrailpackage];
    var newGpxData = this.state.gpxTableData.filter((gpx2) => gpx2.id == chipToDelete);
    gpxDataForTrailpackage1.push(newGpxData[0]);
    this.setState({ gpxDataForTrailpackage: gpxDataForTrailpackage1 });
  }

  setGpxData = async (gpx_photo, gpx_title, gpx, gpx_type, gpx_country, gpx_level, gpx_location, gpx_starting_point, gpx_ending_point, save_type, wayPoint, trail_types, gpx_ascent, gpx_duration, gpx_distance, description, updateGPXRowIndex) => {

    gpxLoc = gpx;
    if (gpxLoc) {
      fetch(gpxLoc).then((r) => {
        r.text().then((d) => {
          setTimeout(function () { //Start the timer
            handleGpx($(d));//After 1 second, set render to true
          }.bind(this), 1000)
        });
      });
    }
    if (wayPoint && wayPoint.length > 0) {
      setTimeout(function () {

        wayPoint.forEach((p) => {
          if (p.location.latitude && p.location.longitude)
            console.log('poi', p.location.latitude + ',' + p.location.longitude)
          addVgMarker({ lng: p.location.longitude, lat: p.location.latitude }, p.id);

        })
      }.bind(this), 3000)

    }
console.log('trail_types', trail_types)
    this.setLocalPOITableData(wayPoint);
    this.setState({
      gpx_photo: gpx_photo,
      gpx_title: gpx_title, gpx: gpx, gpx_type: gpx_type.toString(), gpx_country: gpx_country, gpx_level: gpx_level, gpx_location: gpx_location, gpx_starting_point: gpx_starting_point, gpx_ending_point: gpx_ending_point, save_type: save_type, updateGPXRowIndex: updateGPXRowIndex, trailType: trail_types, selectedTab: 2,
      gpx_ascent: gpx_ascent, gpx_duration: gpx_duration, gpx_distance: gpx_distance, description: description
    });

    this.setSelectedWebPoi([]);
    this.setState({ globalPoiSetWeb: [] })

    setTimeout(function () { //Start the timer

      this.setState({ selectedTab: 2 })

    }.bind(this), 200)

  };

  setTrialData = async (trial_type, trial_name, trial_gpx, save_type, updateTrialRowIndex) => {
    console.log('trial_gpx', trial_gpx)

    var chipdata = [];
    var gpxsForTrailpackage = [];
    if (trial_gpx.length > 0) {
      var trailGpxCountry = this.state.gpxTableData.filter((data) => data.id == trial_gpx[0]);
      console.log('country', trailGpxCountry[0].country)
      gpxsForTrailpackage = this.state.gpxTableData.filter((data) => data.country == trailGpxCountry[0].country);
    }
    trial_gpx.forEach((id) => {

      this.state.gpxTableData.forEach((element) => {
        if (id == element.id)
          chipdata.push({ key: id, label: element.name })
      })

      gpxsForTrailpackage = gpxsForTrailpackage.filter((data) => data.id != id);
    })

    if (trial_gpx.length > 0)
      this.setState({ gpxDataForTrailpackage: gpxsForTrailpackage })
    else
      this.setState({ gpxDataForTrailpackage: this.state.gpxTableData })

    this.setState({ chipset: chipdata })
    console.log('chipset', chipdata)


    this.setState({ trial_package_type: trial_type, trial_package_name: trial_name, trial_package_gpx: trial_gpx, trialPkg_save_type: save_type, updateTrialPkgRowIndex: updateTrialRowIndex });
  };
  setLocalPoiData = async (local_poi_photo,local_poi_type, local_poi_title, local_poi_desc, local_poi_location, save_type, updateLocalPoiRowIndex) => {
    this.setState({local_poi_photo:local_poi_photo, local_poi_type: local_poi_type, local_poi_title: local_poi_title, local_poi_desc: local_poi_desc, local_poi_location: local_poi_location, local_poi_save_type: save_type, updateLocalPoiRowIndex: updateLocalPoiRowIndex });
  };
  setTrialPackagePoiData = async (trial_package_name, trial_package_type, trial_package_gpx) => {
    this.setState({ trial_package_name: trial_package_name, trial_package_type: trial_package_type, trial_package_gpx: trial_package_gpx });
  };

  handlePNValidation() {
    let errors = {};
    let formIsValid = true;
    if (this.state.selectedPN === '') {
      formIsValid = false;
      errors['pnselect'] = '*';
    }
    this.setState({ errors: errors });
    return formIsValid;
  }
  validateWaypointsSave() {
    if (!gpxLoc) {
      toast.error('No Map Marker found. Please draw at least one');
      return false;
    }
    if (!vgmapMarkers.length === 0) {
      toast.error('No Map Marker found. Please draw at least one');
      return false;
    }
    return true;
  }

  addSavedPN(e) {
    e.preventDefault();
    toast.dismiss();
    if (this.handlePNValidation()) {
      if (vgmapMarkers.length === 0) {
        toast.error('No Map Marker found. Please draw at least one');
        return;
      }
      let location = {
        longitude: vgmapMarkers[vgmapMarkers.length - 1].coordinates['lng'],
        latitude: vgmapMarkers[vgmapMarkers.length - 1].coordinates['lat'],
      };
      vgmapMarkers[vgmapMarkers.length - 1].name = this.state.vg_title;
      vgmapMarkers[vgmapMarkers.length - 1].description = this.state.vg_desc;
      vgmapMarkers[vgmapMarkers.length - 1].type = this.state.selectedPN;
      vgmapMarkers[vgmapMarkers.length - 1].location = location;

      document.getElementById(
        vgmapMarkers[vgmapMarkers.length - 1].id
      ).style.backgroundImage = `url('https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/${getSvg(
        vgmapMarkers[vgmapMarkers.length - 1].type
      )}.svg')`;

      this.setState({ vg_title: '', vg_desc: '', selectedPN: '' });
      toast.info('Point Number Added');
    } else {
      toast.error('Missing Information / Fields');
    }
  }



  handleSaveGpxValidation() {
    let errors = {};
    let formIsValid = true;

    if (!gpxLoc) {
      formIsValid = false;
      errors['gpxLoc'] = 'Please Select GPX File';
    }
    if (!this.state.gpx_title) {
      formIsValid = false;
      errors['gpx_title'] = '*';
    }
    if (!this.state.gpx_country) {
      formIsValid = false;
      errors['gpx_country'] = 'Please Select Country';
    }
    if (this.state.gpx_type == '' && this.state.gpx_type != 0) {
      formIsValid = false;
      errors['gpx_type'] = 'Please Select Type';
    }
    if (!this.state.gpx_starting_point) {
      formIsValid = false;
      errors['gpx_starting_point'] = 'Start Location is Required';
    }
    if (!this.state.gpx_photo && this.state.gpx_type == '1') {
      formIsValid = false;
      errors['gpx_photo'] = 'Photo is Required';
    }
    /*  else{
       var loc = this.state.gpx_starting_point.split(",");
       let pattern = new RegExp('^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}');
       if(pattern.test(loc[0]) && pattern.test(loc[1]))
       console.log('valid')
       else
       {
           formIsValid = false;
           errors['gpx_starting_point'] = 'Not a Valid Location';
         }
       } */


    if (!this.state.gpx_ending_point) {
      formIsValid = false;
      errors['gpx_ending_point'] = 'End Location is Required';
    }
    /*  else{
       var loc = this.state.gpx_ending_point.split(",");
       let pattern = new RegExp('^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}');
       if(pattern.test(loc[0]) && pattern.test(loc[1]))
       console.log('valid')
       else
       {
           formIsValid = false;
           errors['gpx_ending_point'] = 'Not a Valid Location';
         }
       } */
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSaveSN(e) {
  }

  validateIdsArray (data) {
    const updatedData = data.map(item => {
      if (!item.hasOwnProperty('id')) {
        return { ...item, id: 'newId' };
      }
      return item;
    });
    return updatedData;
  }
  

async handleSaveGpx(e) {
    e.preventDefault();
    toast.dismiss();
    if (this.handleSaveGpxValidation()) {
      var countryCode = this.state.gpx_country;
      var countryname = '';
      countriesNames.map(function (item) {
        if (item.code === countryCode)
          countryname = item.name;
      });

      var startloc = this.state.gpx_starting_point.split(",");
      var endloc = this.state.gpx_ending_point.split(",");

      this.state.localPOITableData.forEach((poi) => {
        if (poi.isNew) {
          delete poi['id'];
          delete poi['isNew'];
        }
      })

      var dayStartLocation = this.state.gpx_starting_point.split(',');
      var dayEndLocation = this.state.gpx_ending_point.split(',');
      var startLat = '';
      var startLng = '';
      var endLat = '';
      var endLng = '';
      var starting_point = null
      var ending_point = null
      if (dayStartLocation.length > 1) {
        startLat = parseFloat(dayStartLocation[0]);
        startLng = parseFloat(dayStartLocation[1]);
        starting_point = { latitude: startLat, longitude: startLng }
      }
      if (dayEndLocation.length > 1) {
        endLat = parseFloat(dayEndLocation[0]);
        endLng = parseFloat(dayEndLocation[1]);
        ending_point = { latitude: endLat, longitude: endLng }
      }

      var countryName;
      var countryCode = this.state.gpx_country;
      countriesNames.map(function (item) {
        if (item.code === countryCode)
          countryName = item.name;
      });

      let gpxfileUrl = '';
      let a = gpxLoc.split(' ');

      for (let i = 0; i < a.length; i++) {
        if (i != 0)
          gpxfileUrl = gpxfileUrl + '%20';
        gpxfileUrl = gpxfileUrl + a[i];
      }
      const trailTypesString = this.state.trailType.length > 0 ? 
  (this.state.trailType.length > 1 ? this.state.trailType.join(', ') : this.state.trailType[0]) : 
  'null';

      if (this.state.gpx_level == null)
        this.state.gpx_level = -1;

      let apidata = {
        "poi": 0,
        "starting_point": starting_point,
        "ending_point": ending_point,
        "waypoints": this.state.localPOITableData,
        "waypoints_sort": this.state.localPOITableData.map(waypoint => waypoint.id),
        "url": gpxfileUrl,
        "name": this.state.gpx_title,
        "type": parseInt(this.state.gpx_type),
        "country": countryName,
        "package": null,
        "photo": this.state.gpx_photo,
        "level": this.state.gpx_level,
        "trail_types": trailTypesString,
        "poi_set": [],
        "ascent": this.state.gpx_ascent.toString(),
        "duration": this.state.gpx_duration.toString(),
        "distance": this.state.gpx_distance.toString(),
        "description": this.state.description.toString(),
        "personalized" : true
      }
      let gpxTableData1 = [...this.state.gpxTableData];
      

      let method = 'POST';
      let url1 = ApiData.apiUrl + `/gpxs/`;
      if (this.state.save_type != 'add') {
        method = 'PUT';
        url1 = ApiData.apiUrl + `/gpxs/${gpxTableData1[this.state.updateGPXRowIndex].id}/`;
      }
      let success = this.state.save_type === 'add' ? 'GPX Added' : 'GPX Updated';
      let data = await handleCallApi(method, url1, JSON.stringify(apidata), success, '')
      if(data){
       // data.waypoints = this.state.localPOITableData;
     
        if (this.state.save_type === 'add') {
          gpxTableData1.push(data);
        }
        else {
          let validateIds = this.validateWaypointsIds(data.waypoints)
          data.waypoints = validateIds
          gpxTableData1[this.state.updateGPXRowIndex] = data;
        }
        this.setState({ gpxTableData: gpxTableData1 })
        this.setState({
          gpx_title: '',
          gpx: '',
          gpx_type: '',
          gpx_country: '',
          gpx_level: '',
          gpx_location: '',
          gpx_photo: ''
        });
        gpxLoc = '';
      }
      closePopoup();
    } else {
      toast.error('Missing Information / Fields');
    }
  }

  handlePhoto = async (event) => {
    try {
      const url = await handleTrailPhotoUpload(event, 'planmytrip-assets', 'gpxImg');
   
      if (url) {
        this.setState({ gpx_photo: url });
      }
    } catch (error) {
      console.log('Error uploading photo:', error);
    }
  };

  handleUpload = async (event) => {
    try {
      var file = event.target.files[0];
      var reader = new FileReader();
      reader.readAsText(file);
      reader.onloadend = function () {
        handleGpx($(reader.result));
      };
      const url = await handleUploadFile(event, 'planmytrip-assets', 'gpxImg');

      if (url) {
        gpxLoc= url
        this.setState({ gpx: url});
        
      }
    } catch (error) {
      console.log('Error uploading file:', error);
    }
  };

  handleLocalPoiValidation() {
    let errors = {};
    let formIsValid = true;

    if (!this.state.local_poi_title) {
      formIsValid = false;
      errors['local_poi_title'] = '*';
    }
    if (!this.state.local_poi_location) {
      formIsValid = false;
      errors['local_poi_location'] = '*';
    }

    if (!this.state.local_poi_type && this.state.local_poi_type != 0) {
      formIsValid = false;
      errors['local_poi_type'] = 'Type Is Required';
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSaveLocalPoi(e) {
    e.preventDefault();
    toast.dismiss();
    if (this.handleLocalPoiValidation()) {

      var loc = this.state.local_poi_location.split(",");
      if (this.state.local_poi_type != 5 || this.state.local_Poi_Multiple.length < 2) {
        let newRow = {
          "id": 'new' + newrowId,
          "name": this.state.local_poi_title,
          "description": this.state.local_poi_desc,
          "location": { latitude: parseFloat(loc[0]), longitude: parseFloat(loc[1]) },
          "type": this.state.local_poi_type,
          "photo" : this.state.local_poi_photo
        }
        let localPoiData1 = [...this.state.localPOITableData];
        newrowId = newrowId + 1;

        if (this.state.local_poi_save_type === 'add') {
          newRow.isNew = true;
          localPoiData1.push(newRow);
          console.log('localPoiData1', localPoiData1)
          this.setState({ localPOITableData: localPoiData1 })
          toast.success('Local Poi Added');
          addVgMarker({ lng: parseFloat(loc[1]), lat: parseFloat(loc[0]) }, newRow.id);
        }
        else {
          newRow.id = localPoiData1[this.state.updateLocalPoiRowIndex].id;
          localPoiData1[this.state.updateLocalPoiRowIndex] = newRow;
          this.setState({ localPOITableData: localPoiData1 })
          toast.success('Local Poi Updated');
        }

      }
      else {
        let localPoiData1 = [...this.state.localPOITableData];

        for (let i = 0; i < this.state.local_Poi_Multiple.length; i++) {

          let newRow = {
            "id": 'new' + newrowId,
            "name": this.state.local_Poi_Multiple[i].name,
            "description": this.state.local_Poi_Multiple[i].description,
            "location": { latitude: parseFloat(this.state.local_Poi_Multiple[i].location.latitude), longitude: parseFloat(this.state.local_Poi_Multiple[i].location.longitude) },
            "type": this.state.local_poi_type,
            "photo" : this.state.local_Poi_Multiple[i].photo
          }

          newrowId = newrowId + 1;

          if (this.state.local_poi_save_type === 'add') {
            newRow.isNew = true;
            localPoiData1.push(newRow);
            addVgMarker({ lng: parseFloat(this.state.local_Poi_Multiple[i].location.longitude), lat: parseFloat(this.state.local_Poi_Multiple[i].location.latitude) }, newRow.id);
          }
          else {
            newRow.id = localPoiData1[this.state.updateLocalPoiRowIndex].id;
            localPoiData1[this.state.updateLocalPoiRowIndex] = newRow;
          }
        }

        if (this.state.local_poi_save_type === 'add') {
          this.setState({ localPOITableData: localPoiData1 })
          toast.success('Local Poi Added');
        }
        else {
          this.setState({ localPOITableData: localPoiData1 })
          toast.success('Local Poi Updated');
        }
        this.setState({ local_Poi_Multiple: [] });

      }

      this.setState({
        local_poi_title: '',
        local_poi_type: '',
        local_poi_desc: '',
        local_poi_location: '',
        local_poi_photo : ''
      });

      closePopoup1();
      /// end call api and after success
    } else {
      toast.error('Missing Information / Fields');
    }
  }

  handleClickOpen = () => {
    if (selectedRowIndex.length > 0)
      this.setState({ openDeleteCnfrm: true });
  };

  handleClickOpen2 = () => {
    if (selectedTrialRowIndex.length > 0)
      this.setState({ openDeleteCnfrm2: true });
  };


  handleClickOpen1 = () => {
    if (selectedLocalPoiRowIndex)
      this.setState({ openDeleteCnfrm1: true });
  };

  handleClose = () => {
    this.setState({ openDeleteCnfrm: false });
    this.setState({ openDeleteCnfrm1: false });
    this.setState({ openDeleteCnfrm2: false });
    this.setState({ openDeleteMarker: false });
  };

  handleDelete = () => {
    this.setState({ openDeleteCnfrm: false });
    var array = [...this.state.gpxTableData]; // make a separate copy of the array
    selectedRowIndex.sort(function (a, b) {
      return b - a;
    });

    selectedRowIndex.forEach(async (i) => {
      let delid = array[i].id;
      array.splice(i, 1);
      await handleCallApi('DELETE', ApiData.apiUrl + `/gpxs/${delid}/`, {}, '', '')
    })
    this.setGpxTableData(array);
  };

  handleDeleteLocalPoi = () => {
    var array = [...this.state.localPOITableData]; // make a separate copy of the array
    selectedLocalPoiRowIndex.sort(function (a, b) {
      return b - a;
    });

    selectedLocalPoiRowIndex.forEach((i) => {
      removeVGmarker(array[i].id);
      array.splice(i, 1);
    })
    // this.setLocalPOITableData(array);
  };

  handleDeleteMarker = () => {
    this.setState({ openDeleteMarker: false });
    var array = [...this.state.localPOITableData]; // make a separate copy of the array
    for (let i = 0; i < array.length; i++) {
      if (array[i].id == this.state.deleteMarkerId) {
        removeVGmarker(array[i].id);
        array.splice(i, 1);
      }
    }
    this.setLocalPOITableData(array);
  };

  setGpxTableData = async (data) => {
    this.setState({ gpxTableData: data });
  };

  validateWaypointsIds = (data) => {
    const waypoints = data.map(item => {
      if (!item.hasOwnProperty('id')) {
        return { ...item, id: 'newId' };
      }
      return item;
    });
    return waypoints;
  }

  validateWaypoints = (data) => {
    const validatedWaypoints = [];

  for (let i = 0; i < data.length; i++) {
    const waypoint = data[i];

    if (waypoint.location.latitude !== null && waypoint.location.longitude !== null) {
      validatedWaypoints.push(waypoint);
    }
  }
  return validatedWaypoints;
  }

  setLocalPOITableData = (data) => {
    const dataWypoints =  this.validateWaypoints(data)
    this.setState({ localPOITableData: dataWypoints });
  };

  setTrialPkgTableData = async (data) => {
    this.setState({ trialPkgTableData: data });
  };

  setGpxSelectedId = (id) => {
    this.setState({ gpxSelectedId: id })
  }
  
  handleDeletePoiSelected = async() => {
    const array = [...this.state.localPOITableData];
    const selectedKeysPois = [...this.state.selectedKeysPois];
    const gpxData = [...this.state.gpxTableData];

    const index = gpxData.findIndex((item) => item.id == this.state.gpxSelectedId);
    const filteredWaypoints = array.filter((item) => !selectedKeysPois.includes(item.id));

    let rowData = gpxData[index]
    let waypoint_sort = rowData.waypoint_sort
    let arrayWaypointsSort = (waypoint_sort.includes(",")) ? waypoint_sort.split(",").map(Number) : [Number(waypoint_sort)]; // [101]

    let filteredWaypointsSort = arrayWaypointsSort.filter(waypoint => !selectedKeysPois.includes(waypoint)); // [102]
    waypoint_sort = filteredWaypointsSort.join(","); 

    gpxData[index].waypoints = filteredWaypoints;
    gpxData[index].waypoint_sort = !filteredWaypoints.length ? '' : waypoint_sort;

    this.setState({ localPOITableData: filteredWaypoints, gpxTableData: gpxData});
        let data = await handleCallApi('POST', ApiData.apiUrl + `/gpxs/delete-waypoints/${this.state.gpxSelectedId}`, JSON.stringify({ selectedKeysPois }), '', '')
    // selectedLocalPoiRowIndex.forEach((i) => {
    //   console.log('i', i);
    //   removeVGmarker(array[i].id);
    //   array.splice(i, 1);
    // })
};

  handleSetSelectedKeysPois= async (data) => {
    this.setState({ selectedKeysPois: data });
  };

  render() {
    return (
      <div>
        {/* conform box */}
        <div>

          <Dialog
            open={this.state.openDeleteCnfrm}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Delete"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are You Sure You Want to delete this GPX Point
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose}>Cancel</Button>
              <Button onClick={this.handleDelete} autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.openDeleteCnfrm1}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Delete"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are You Sure You Want to delete this Local POI
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose}>Cancel</Button>
              <Button onClick={this.handleDeleteLocalPoi} autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.openDeleteMarker}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Delete"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are You Sure You Want to delete this Local POI
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose}>Cancel</Button>
              <Button onClick={this.handleDeleteMarker} autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>

        </div>
        {/* conform box */}



        <div className="modal">
          <div className="modal_content">
            <span className="close">&times;</span>
            <div className="model_html">
            <h2>{this.state.save_type === 'add' ? 'Add New GPX' : `Update Gpx ${this.state.gpxSelectedId}`}</h2>
              <table cellPadding="8" >
                <tbody>
                  <tr>
                    <td width="60%">
                      <Button variant="contained" component="label" >
                        UPLOAD GPX FILE
                        <input
                          type="file"
                          hidden
                          id="fileGPXPN"
                          value=""
                          onChange={this.handleUpload}
                          accept=".gpx"
                        />
                      </Button><br />
                      <span className="errorMsg">{this.state.errors['gpxLoc']}</span>
                    </td>
                    <td>

                     {this.state.gpx ? [
                        <div>
                          <span className="labelsmall"> Currently:<span className="colorgreen"> {this.state.gpx}</span></span><br /><br />
                        </div>] : null}
                      <span className="errorMsg">{this.state.errors['gpx']}</span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ verticalAlign: 'baseline' }}>
                      <label>Title</label><br />
                      <TextField
                        id="txtSNTitle"
                        size="small"
                        variant="outlined"
                        value={this.state.gpx_title}
                        error={!!this.state.errors['gpx_title']}
                        onChange={(event) =>
                          this.setState({ gpx_title: event.target.value })
                        }
                      />
                    </td>
                    <td>
                      <label>Country</label><br />
                      <ReactFlagsSelect
                        selected={this.state.gpx_country}
                        id={'selectCountriesDay'}
                        showSelectedLabel={true}
                        showOptionLabel={true}
                        onSelect={(code) => { this.setState({ gpx_country: code }); console.log("countrycode", code) }}
                        placeholder={'Select a Country'}
                        countries={[ 'GR','CR', 'NO','GE',  'MX', 'ME', 'DK',  'PL', 'PT','IS', 'MA',  'IL',  'TR',   'EC','ZA', 'IN','LT','IE','ES',     ]}

                      />
                      <span className="errorMsg">{this.state.errors['gpx_country']}</span>
                    </td>

                  </tr>

                  <tr>
                    <td>
                      <label>Type</label><br />
                      <RadioGroup row aria-label="TYPE" value={this.state.gpx_type.toString()} name="row-radio-buttons-group"
                        onChange={(event) => { console.log('test1'); this.setState({ gpx_type: parseInt(event.target.value), gpx_level: -1 }) }} >
                        <FormControlLabel value="0" control={<Radio />} label="Embeded In Day" />
                        <FormControlLabel value="1" control={<Radio />} label="Trail" />
                      </RadioGroup>
                      <span className="errorMsg">{this.state.errors['gpx_type']}</span>
                    </td>

                  </tr>
                  {
                    (this.state.gpx_type == '1' || this.state.gpx_type == 1) &&
                    <tr>
                      <td>Level</td>
                      <td> <label>Photo</label></td>
                    </tr>
                  }
                  {
                    (this.state.gpx_type == '1' || this.state.gpx_type == 1) &&
                    <>
                      <tr>
                        <td>
                          <label className="boxed">
                            <input
                              disabled={this.state.gpx_type == '0' || this.state.gpx_type === ''}
                              type="radio"
                              id="oneNL"
                              value="1"
                              name="NL"
                              style={{ color: '#ccc' }}
                              onChange={(event) => this.setState({ gpx_level: parseInt(event.target.value) })}
                              checked={(this.state.gpx_level === 1 || this.state.gpx_level === '1')}
                            />
                            <span>EASY</span>
                          </label>
                          <label className="boxed">
                            <input
                              disabled={this.state.gpx_type === '0' || this.state.gpx_type === ''}
                              type="radio"
                              id="twoNL"
                              value="2"
                              name="NL"
                              onChange={(event) => this.setState({ gpx_level: parseInt(event.target.value) })}
                              checked={(this.state.gpx_level === 2 || this.state.gpx_level === '2')}
                            />
                            <span>MODERATE</span>
                          </label>
                          <label className="boxed">
                            <input
                              disabled={this.state.gpx_type === '0' || this.state.gpx_type === ''}
                              type="radio"
                              id="twoNL"
                              value="2"
                              name="NL"
                              onChange={(event) => this.setState({ gpx_level: parseInt(event.target.value) })}
                              checked={(this.state.gpx_level === 3 || this.state.gpx_level === '3')}
                            />
                            <span>HARD</span>
                          </label>
                          <br />
                          <span className="errorMsg">{this.state.errors['gpx_level']}</span>
                        </td>
                        <td>
                          <div className="divUpload">

                            <img width="20px" src={uploadIcon} />
                            <Button variant="contained" component="label" style={{ float: 'right', fontSize: 9, }} >
                              UPLOAD FILE
                              <input
                                type="file"
                                hidden
                                id="fileSNPhoto"
                                size="small"
                                onChange={this.handlePhoto}
                              />
                            </Button>
                          </div>
                          {this.state.gpx_photo && [
                            <div>

                              <span className="labelsmall"> Currently:<span className="colorgreen"> {this.state.gpx_photo}</span></span><br /><br />
                            </div>]}
                          <span className="errorMsg">{this.state.errors['gpx_photo']}</span>
                          <div>
                        {this.state.gpx_photo && <img style={{ height: '140px', width: '180px' }} src={this.state.gpx_photo}></img>}
                      </div>
                        </td>
                      </tr>
                      <tr >
                        <td colSpan={2}>
                          <table><tr>
                            <td width={200} style={{ verticalAlign: 'baseline' }}>
                              <label>Distance</label><br />
                              <TextField
                                id="txtSNTitle"
                                size="small"
                                variant="outlined"
                                value={this.state.gpx_distance}

                                onChange={(event) =>
                                  this.setState({ gpx_distance: event.target.value })
                                }
                              />
                            </td>
                            <td width={200} style={{ verticalAlign: 'baseline' }}>
                              <label>Duration</label><br />
                              <TextField
                                id="txtSNTitle"
                                size="small"
                                variant="outlined"
                                value={this.state.gpx_duration}

                                onChange={(event) =>
                                  this.setState({ gpx_duration: event.target.value })
                                }
                              />
                            </td>
                            <td width={200} style={{ verticalAlign: 'baseline' }}>
                              <label>Ascent</label><br />
                              <TextField
                                id="txtSNTitle"
                                size="small"
                                variant="outlined"
                                value={this.state.gpx_ascent}

                                onChange={(event) =>
                                  this.setState({ gpx_ascent: event.target.value })
                                }
                              />
                            </td>
                            <td width={200} style={{ verticalAlign: 'baseline' }}>
                              <label>Type</label><br />
                              <FormControl className="dropdownWithborder">
                                <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Type"
                                  value={this.state.trailType}
                                  onChange={(event) => {
                                    this.setState({ trailType: event.target.value });

                                    console.log('event.target.value', event.target.value)
                                  }}
                                  multiple={true}
                                >
                                  <MenuItem value={'Hiking'}>HIKING</MenuItem>
                                  <MenuItem value={'Bicycle'}>BICYCLE</MenuItem>
                                  <MenuItem value={'4x4'}>4X4</MenuItem>
                                  <MenuItem value={'Bike'}>BIKE</MenuItem>
                                </Select>
                              </FormControl>
                            </td>
                          </tr></table>
                        </td>
                      </tr>
                      <tr><td><br></br></td></tr>
                      <br></br>
                    </>
                  }

                  <tr>
                    <td colspan={2}>
                      <label>Description</label><br />
                      <TextField
                        id="txtDescription"
                        multiline
                        style={{ width: '80%' }}
                        rows={4}
                        variant="outlined"
                        fullWidth={true}
                        value={this.state.description}
                        error={!!this.state.errors['description']}
                        onChange={(event) =>
                          this.setState({ description: event.target.value })
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <div>
                        <table>
                          <tr>
                            <td>
                              <label>Start Location</label><br />
                              <TextField
                                id="txtStartLocation"
                                className="location"
                                variant="outlined"
                                fullWidth={false}
                                error={!!this.state.errors['gpx_starting_point']}
                                value={this.state.gpx_starting_point}
                                onChange={(event) => { this.setState({ gpx_starting_point: event.target.value }); this.setStartLocationMap(event.target.value) }}
                              /><br />
                              <span className="errorMsg">{this.state.errors['gpx_starting_point']}</span>
                              <br />
                              OR
                              <MapboxAutocomplete publicKey={mapboxgl.accessToken}
                                inputclassName='form-control search'
                                onSuggestionSelect={this._suggestionSelected}
                                onChange={(e) => console.log("newlocation1", e.target.value)}
                                resetSearch={false} />

                            </td>
                            <td>
                              <label>End Location</label><br />
                              <TextField
                                id="txtEndLocation"
                                className="location"
                                variant="outlined"
                                fullWidth={false}
                                error={!!this.state.errors['gpx_ending_point']}
                                value={this.state.gpx_ending_point}
                                onChange={(event) => { this.setState({ gpx_ending_point: event.target.value }); this.setEndLocationMap(event.target.value) }}
                              /> <br />
                              <span className="errorMsg">{this.state.errors['gpx_ending_point']}</span>
                              <br />
                              OR
                              <MapboxAutocomplete publicKey={mapboxgl.accessToken}
                                inputclassName='form-control search'
                                onSuggestionSelect={this._suggestionSelected1}
                                onChange={(e) => console.log("newlocation1", e.target.value)}
                                resetSearch={false} />
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <MapboxGLMap prefix={'mapDay-1'} />
                            </td>
                            <td>
                              <MapboxGLMap prefix={'mapDay-2'} />
                            </td>
                          </tr>
                        </table>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <MapboxGLMap prefix={'mapDayVG'} />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan="2" style={{ paddingTop: 50 }}>
                      <h2>Local POI</h2>
                      <LocalPOITable localPoiData={this.state.localPOITableData} validateWaypoints={this.validateWaypoints} setLocalPOITableData={this.setLocalPOITableData} title={this.state.local_poi_title} setLocalPoiData={this.setLocalPoiData} handleSetSelectedKeysPois={this.handleSetSelectedKeysPois}/>

                    </td>

                  </tr>

                  <tr>
                    <td>
                      <Link href="#" onClick={() => { this.setLocalPoiData('','', '', '', '', 'add', ''); console.log('gpx_title', this.state.gpx_title); openPopup1(); initializeMap(-1, 'mapDay-3'); }}> + ADD LOCAL POI \ WAYPOINT</Link>
                    </td>
                    <td> <Button
                      id="btnSave"
                      variant="contained"
                      color="#15cc09"
                      onClick={() => {this.handleDeletePoiSelected()}}
                      style={{ float: 'right', marginLeft: 10, fontSize: 12, color: '#fff' }}
                      className='deleteBtnLocalPoi'
                    >
                      DELETE SELECTED
                    </Button></td>
                  </tr>
                  {this.state.selectedTab == 0 &&
                    <tr>
                      <td> <h2>Global POI</h2></td>
                    </tr>
                  }{this.state.selectedTab == 0 &&
                    <tr >
                      <td colspan="2" style={{ borderColor: '#000', border: 'solid 1px #ccc' }}>
                        <WebPoint setglobalPoiSet={this.setglobalPoiSetWeb} globalPoiSet={this.state.globalPoiSetWeb} selectedWebPOIsKeys={this.state.selectedWebPOI} setSelectedWebPoi={this.setSelectedWebPoi} setSelectedVgByWeb={this.setSelectedVgByWeb} />
                      </td>
                    </tr>

                  }
                  <tr>
                    <td colSpan="2">
                      {this.state.save_type == 'add' &&
                        <Button
                          id="btnSaveSN"
                          variant="contained"
                          color="primary"

                          onClick={this.handleSaveGpx.bind(this)}
                        >
                          CREATE
                        </Button>
                      }
                      {this.state.save_type != 'add' &&
                        <Button
                          id="btnSaveSN"
                          variant="contained"
                          color="primary"
                          onClick={this.handleSaveGpx.bind(this)}
                        >
                          SAVE CHANGES
                        </Button>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="modal1">
          <div className="modal_content">
            <span className="close1">&times;</span>
            <div className="model_html">
              <h2>ADD LOCAL POI\WAYPOINT</h2>
              <table cellPadding="8" >
                <tbody>
                  <tr>
                    <td >
                      <label>Types</label><br />
                      <FormControl style={{ width: '30%' }}>
                        <InputLabel id="demo-simple-select-label">Type</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Type"
                          value={this.state.local_poi_type}
                          onChange={(event) => {
                            this.setState({ local_poi_type: event.target.value });
                            this.setState({ global_poi_type: '' });
                            console.log("group_structure", event.target.value)
                          }}
                        >
                          <MenuItem value={0}>LOCAL HERO TIP</MenuItem>
                          <MenuItem value={1}>RIGHT</MenuItem>
                          <MenuItem value={2}>LEFT</MenuItem>
                          <MenuItem value={3}>Branching / fork to the right</MenuItem>
                          <MenuItem value={4}>Branching / fork to the left</MenuItem>
                          <MenuItem value={5}>point of interest</MenuItem>
                          <MenuItem value={6}>Information Icon</MenuItem>
                          <MenuItem value={7}>Icon for swimming</MenuItem>
                          <MenuItem value={8}>Rafting / kayaking icon</MenuItem>
                        </Select>
                      </FormControl>
                    </td>


                  </tr>
                  {this.state.local_poi_type == 5 && this.state.local_poi_save_type == 'add' &&
                    <tr>
                      <td >
                        <label>Global POI</label><br />
                        <Autocomplete
                          disablePortal
                          id="combo-box-demo"
                          options={this.state.allGlobalPOI}
                          sx={{ width: 300 }}
                          multiple={true}

                          onChange={(event, value) => {
                            console.log('value', value);
                            this.setState({ local_Poi_Multiple: value });
                            if (value.length == 1) {
                              const poi = value[0];
                              this.setState({ global_poi_type: poi.id });
                              this.setState({
                                local_poi_title: poi.name,
                                local_poi_desc: poi.description,
                                local_poi_location: poi.location.latitude + ',' + poi.location.longitude,
                                local_poi_photo : poi.photo
                              });
                              this.setLocalPOILocationMap(poi.location.latitude + ',' + poi.location.longitude)
                            }

                          }

                          }

                          renderInput={(params) => <TextField  {...params} label="Select Global POI" />}
                        />
                        <FormControl style={{ width: '30%', display: 'none' }}>
                          <InputLabel id="demo-simple-select-label">Select Global POI</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Type"
                            value={this.state.global_poi_type}
                            onChange={(event) => {
                              this.setState({ global_poi_type: event.target.value });
                              let gpoi = this.state.allGlobalPOI[event.target.value];
                              this.setState({
                                local_poi_title: gpoi.name,
                                local_poi_desc: gpoi.description,
                                local_poi_location: gpoi.location.latitude + ',' + gpoi.location.longitude
                              });

                              this.setLocalPOILocationMap(gpoi.location.latitude + ',' + gpoi.location.longitude)
                            
                              console.log("group_structure", this.state.allGlobalPOI[event.target.value])
                            }}
                          >
                            {this.state.allGlobalPOI.map((item, i) =>
                            (
                              <MenuItem value={i}>{item.id}</MenuItem>
                            )
                            )}
                          </Select>
                        </FormControl>
                      </td>
                    </tr>
                  }
                  {(this.state.local_Poi_Multiple.length < 2 || this.state.local_poi_type != 5) &&
                    <>
                      <tr>
                        <td >
                          <label>Title</label><br />
                          <TextField
                            id="txtSNTitle"
                            size="small"
                            variant="outlined"
                            value={this.state.local_poi_title}
                            error={!!this.state.errors['local_poi_title']}
                            onChange={(event) =>
                              this.setState({ local_poi_title: event.target.value })
                            }
                          />
                        </td>
                      </tr>
                      <tr>
                        <td >
                          <label>Location</label><br />
                          <TextField
                            id="txtStartLocDay"
                            size="small"
                            variant="outlined"
                            value={this.state.local_poi_location}
                            onChange={(event) => {
                              this.setState({ local_poi_location: event.target.value });
                              this.setLocalPOILocationMap(event.target.value)
                            }
                            }
                          />
                          <br /> <span className="errorMsg">{this.state.errors['local_poi_location1']}</span> <br />
                          OR
                          <MapboxAutocomplete publicKey={mapboxgl.accessToken}
                            inputclassName='form-control search'
                            onSuggestionSelect={this._suggestionSelected2}
                            onChange={(e) => console.log("newlocation1", e.target.value)}
                            resetSearch={false} />
                        </td>
                      </tr>
                      <tr>
                        <td> <MapboxGLMap prefix={'mapDay-3'} /></td>
                      </tr>
                      <tr>
                        <td>
                          <label>Description</label><br />
                          <TextField
                            id="txtSNDesc"
                            multiline
                            rows={4}
                            variant="outlined"
                            fullWidth={true}
                            value={this.state.local_poi_desc}
                            onChange={(event) =>
                              this.setState({ local_poi_desc: event.target.value })
                            }
                          />
                        </td>
                        <td style={{ width: '30%' }}>&nbsp;</td>
                      </tr>
                    </>
                  }
                  <tr>
                    <td colSpan="2">
                      {this.state.local_poi_save_type == 'add' &&
                        <Button
                          id="btnSaveSN"
                          variant="contained"
                          color="primary"
                          className='submitBtn'
                          onClick={this.handleSaveLocalPoi.bind(this)}
                        >
                          SUBMIT
                        </Button>
                      }
                      {this.state.local_poi_save_type != 'add' &&
                        <Button
                          id="btnSaveSN"
                          variant="contained"
                          color="primary"
                          className='submitBtn'
                          onClick={this.handleSaveLocalPoi.bind(this)}
                        >
                          SAVE
                        </Button>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
        <ToastContainer />
        <table cellPadding="10" className="viewdata" >
          <tbody>
            <tr>
              <td><h2>GPX Module</h2> </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan="3"  ></td>
              <td>&nbsp;
                <Button
                  id="btnSave"
                  variant="contained"
                  className='deleteBtn'
                  color="#15cc09"
                  onClick={() => { this.handleClickOpen(); }}
                  style={{ float: 'right', marginLeft: 10, fontSize: 12, color: '#fff' }}
                >
                  DELETE SELECTED
                </Button>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <GPXTable gpxData={this.state.gpxTableData} setGpxSelectedId={this.setGpxSelectedId} setGpxTableData={this.setGpxTableData} title={this.state.gpx_title} setGpxData={this.setGpxData} />
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <Link href="#" onClick={() => { this.setGpxData('', '', '', '', '', '', '', '', '', 'add', [], [], '', '', '', '', ''); console.log('gpx_title1', this.state.gpx_title); openPopup(); initializeMap(-1, 'mapDayVG'); initializeMap(-1, 'mapDay-1'); initializeMap(-1, 'mapDay-2'); }}> + ADD NEW GPX</Link>

              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <div className="borderDiv" />
              </td>
            </tr>

          </tbody>
        </table>

      </div>
    );
  }
}

export default GPX;


mapboxgl.accessToken = MapboxKey;
let mapMarkersOne = [];
let mapMarkersTwo = [];
let mapMarkersThree = [];
let webmapMarkers = [];
let vgmapMarkers = [];

let webMapOne;
let webMapTwo;
let webMap;
let vgMap;


const initializeMap = (z, id) => {
  if (id == 'mapDayVG') {
    vgMap = new mapboxgl.Map({
      container: id,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: z,
    });
    //Add navigation controls to the mapbox if zoom is lower
    if (z < 11) vgMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    vgMap.on('click', function (e) {
      console.log('loc', e.lngLat.lat + ',' + e.lngLat.lng)
      //  this.setLocalPoiData('', '', '', '', 'add', '');
      openPopup1();
      window.bd.set_local_poi_location(e.lngLat.lat, e.lngLat.lng);
      window.bd.setLocalPOILocationMap(e.lngLat.lat + ',' + e.lngLat.lng)
      $('#txtStartLocDay').val(e.lngLat.lat + ',' + e.lngLat.lng)
      if (selectedRowIndex) {
        // addVgMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      } else {
        //addVgMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
        //  toast.error('Please select a trip first by clicking VG button against the record');
      }
    });
  } else if (id == 'mapDayStop') {
    webMap = new mapboxgl.Map({
      container: id,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: z,
    });
    //Add navigation controls to the mapbox if zoom is lower
    if (z < 11) webMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    webMap.on('click', function (e) {
      if (selectedRowIndex) {
        // addWebMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      } else {
        // addWebMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
        // toast.error('11Please select a trip first by clicking Web button against the record');
      }
    });
  } else {
    if (id == 'mapDay-1') {
      webMapOne = new mapboxgl.Map({
        container: id,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: z,
      });
      webMapOne.on('click', function (e) {
        window.bd.setDayStartLocation(e.lngLat.lat.toFixed(6), e.lngLat.lng.toFixed(6));

        $('#txtStartLocDay').val(e.lngLat.lng.toFixed(6) + ',' + e.lngLat.lat.toFixed(6));
        // this.setState({ startloc: e.lngLat.lng.toFixed(4) + ',' + e.lngLat.lat.toFixed(4) });
        mapMarkersOne.forEach((marker) => marker.remove());
        mapMarkersOne = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapOne);
        mapMarkersOne.push(marker);
      });
    }
    else if (id == 'mapDay-2') {
      webMapTwo = new mapboxgl.Map({
        container: id,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: z,
      });

      webMapTwo.on('click', function (e) {
        window.bd.setDayEndLocation(e.lngLat.lat.toFixed(6), e.lngLat.lng.toFixed(6));
        $('#txtEndLocDay').val(e.lngLat.lng.toFixed(6) + ',' + e.lngLat.lat.toFixed(6));
        mapMarkersTwo.forEach((marker) => marker.remove());
        mapMarkersTwo = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapTwo);
        mapMarkersTwo.push(marker);
      });
    }
    else {
      webMapTwo = new mapboxgl.Map({
        container: id,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: z,
      });

      webMapTwo.on('click', function (e) {

        window.bd.seLocalPoiLocation(e.lngLat.lat.toFixed(6), e.lngLat.lng.toFixed(6));
        $('#txtEndLocDay').val(e.lngLat.lng.toFixed(6) + ',' + e.lngLat.lat.toFixed(6));
        mapMarkersThree.forEach((marker) => marker.remove());
        mapMarkersThree = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapTwo);
        mapMarkersThree.push(marker);
      });
    }
  }
};



class MapboxGLMap extends Component {
  constructor(props) {
    super(props);
    // this.id = _uniqueId(props.prefix + "-");
    this.id = props.prefix;
  }
  componentDidMount() {
    initializeMap(-1, this.id);
  }
  render() {
    const id = this.id;
    return (
      <div id={id} className="mapEA">
        {' '}
      </div>
    );
  }
}


class GPXTable extends Component {



  constructor(props) {
    super(props);
    console.log('props', props)
    this.state = {
      data: [['Loading Data...']],
      loading: false,
      gpxRowsSelected: []
    };
  }



  async componentDidMount() {
    let data = await this.getData();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.has('id')){
      let id = urlParams.getAll('id');
      this.props.setGpxSelectedId(id[0])
     this.setGpxData(id,data)
    }
  }

  setGpxData = (id, data) => {
    let filterId = parseInt(id[0])
    let filterRow = data.filter(row => row.id === filterId)[0]
    let getIndexRow = data.findIndex(row => row.id === filterId)
    var countryCode = '';
    var countryname = filterRow.country;
    countriesNames.map(function (item) {
      if (item.name === countryname)
        countryCode = item.code;
    });
    let startLoc = '';
    let startLocForMap = '';
    let gpx_ascent = '';
    let gpx_duration = '';
    let gpx_distance = '';
    if (filterRow?.ascent) gpx_ascent = filterRow.ascent;
    if (filterRow?.duration) gpx_duration = filterRow.duration;
    if (filterRow?.distance) gpx_distance = filterRow.distance;
    if (filterRow.starting_point) {
      startLoc = filterRow.starting_point?.latitude + ',' + filterRow.starting_point?.longitude;
      startLocForMap = filterRow.starting_point?.longitude + ',' + filterRow.starting_point?.latitude;
    }
    let endLoc = '';
    let endLocForMap = '';
    if (filterRow.ending_point) {
      endLoc = filterRow.ending_point?.latitude + ',' + filterRow.ending_point?.longitude;
      endLocForMap = filterRow.ending_point?.longitude + ',' + filterRow.ending_point?.latitude;
    }
    var trail_types = [];
    console.log('filterRow', filterRow)
    if (filterRow?.trail_types) trail_types = filterRow.trail_types
    this.props.setGpxData(filterRow.photo, filterRow.name, filterRow.url, filterRow.type, countryCode, filterRow.level, filterRow.starting_point, startLoc, endLoc, 'update', filterRow.waypoints, trail_types, gpx_ascent, gpx_duration, gpx_distance, filterRow.description, getIndexRow);

    openPopup();
    initializeMap(-1, 'mapDayVG');
    initializeMap(-1, 'mapDay-1');
    initializeMap(-1, 'mapDay-2');
    if (startLocForMap) window.bd.setStartLocationMap(startLoc)
    if (endLocForMap) window.bd.setEndLocationMap(startLoc)
  }

  getData = () => {
    this.setState({ loading: true });
    let dataRows = this.xhrRequest().then((data) => {
      this.setState({ data, loading: false });
      this.props.setGpxTableData(data);
      return data
    });
    return dataRows
  };

  xhrRequest = (url) => {
    return new Promise(async (resolve, reject) => {
        let data = await getDatafromApi(ApiData.apiUrl + '/gpxs?personalized=true&take=3000')
        if(data){
          data.results.sort((a, b) => a.id - b.id);
          daysData = data.results;
          data.results.forEach((item) => {
            if (item.trail_types === "null" || (item.trail_types === null)) {
              item.trail_types = [];
            } else if (item.trail_types.includes(",")) {
              item.trail_types = item.trail_types.split(",").map((type) => type.trim());
            } else {
              item.trail_types = [item.trail_types];
            }
          });
          resolve(data.results);
         return data.results
        }

    });
  };

  render() {
    const columns = [
      {
        name: 'id', options: {
          label: 'ID', filter: false,

          customBodyRender: (value, tableMeta, updateValue) => {
            // const editrow = tableMeta.tableData[tableMeta.rowIndex];
            var editrow = {};
            var rowIndexForUpdate = 0;
            var i = 0;

            tableMeta.tableData.forEach((row) => {
              if (row.id == value) {
                editrow = row;
                rowIndexForUpdate = i;
              }
              i++;
            })
            var countryCode = '';
            var countryname = editrow.country;
            countriesNames.map(function (item) {
              if (item.name === countryname)
                countryCode = item.code;
            });
            let startLoc = '';
            let startLocForMap = '';
            let gpx_ascent = '';
            let gpx_duration = '';
            let gpx_distance = '';
            if (editrow?.ascent) gpx_ascent = editrow.ascent;
            if (editrow?.duration) gpx_duration = editrow.duration;
            if (editrow?.distance) gpx_distance = editrow.distance;
            if (editrow.starting_point) {
              startLoc = editrow.starting_point?.latitude + ',' + editrow.starting_point?.longitude;
              startLocForMap = editrow.starting_point?.longitude + ',' + editrow.starting_point?.latitude;
            }
            let endLoc = '';
            let endLocForMap = '';
            if (editrow.ending_point) {
              endLoc = editrow.ending_point?.latitude + ',' + editrow.ending_point?.longitude;
              endLocForMap = editrow.ending_point?.longitude + ',' + editrow.ending_point?.latitude;
            }
            var trail_types = [];
            if (typeof editrow?.trail_types === 'string') {
              if (editrow.trail_types.length > 0) {
                trail_types = editrow.trail_types.split(", ");
              } else {
                trail_types.push("null");
              }
            } 
            if(editrow?.trail_types === null){
              trail_types.push("null");

            } else if (Array.isArray(editrow?.trail_types)) {
              trail_types = editrow.trail_types;
            }
            return (
              <Link href="#" onClick={() => {
                this.props.setGpxData(editrow.photo, editrow.name, editrow.url, editrow.type, countryCode, editrow.level, editrow.starting_point, startLoc, endLoc, 'update', editrow.waypoints, trail_types, gpx_ascent, gpx_duration, gpx_distance, editrow.description, rowIndexForUpdate);
                this.props.setGpxSelectedId(editrow.id);
                openPopup(editrow.id);
                initializeMap(-1, 'mapDayVG');
                initializeMap(-1, 'mapDay-1');

                initializeMap(-1, 'mapDay-2');
                if (startLocForMap) window.bd.setStartLocationMap(startLoc)
                if (endLocForMap) window.bd.setEndLocationMap(startLoc)
              }}>  {value}</Link>
            );
          },

        }
      },
      { name: 'name', label: 'Name', options: { filter: false } },
      {
        name: 'starting_point', options: {
          filter: false, label: 'Start Location',
          customBodyRender: (value, tableMeta, updateValue) => {
            return (parseFloat(value?.latitude).toFixed(4) + ',' + parseFloat(value?.longitude).toFixed(4));
          }
        }
      },
      { name: 'type', options: { label: 'Type', filter: false, customBodyRender: (value, tableMeta, updateValue) => { let type1 = 'Embeded'; if (value == 1) type1 = 'Trail'; return (type1); }, } },

      {
        name: 'level',
        options: {
          label: 'Level',
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value === 1) {
              return 'EASY';
            } else if (value === 2) {
              return 'MODERATE';
            } else if (value === 3) {
              return 'HARD';
            } else {
              return '';
            }
          },
        },
      },
      { name: 'country', label: 'Country' },
      { 
        name: 'trail_types',     
        options: {
          label: 'Trail Types',
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            let trailTypes = [];
      
            if (Array.isArray(value)) {
              trailTypes = value;
            } else if (typeof value === 'string') {
              trailTypes = value.split(',').map(item => item.trim());
            }
      
            return trailTypes.join(', ');
          },
        },
      },
    ];

    const onRowsSelect = (curRowSelected, rowsSelected) => {
      selectedRowIDs = [];
      selectedRowIndex = [];

      for (let i = 0; i < rowsSelected.length; i++) {
        //    console.log('rowsSelected', data[rowsSelected[i].index].id)
        selectedRowIDs.push(this.props.gpxData[rowsSelected[i].dataIndex].id);
        selectedRowIndex.push(rowsSelected[i].dataIndex);

      }
      activeDeactiveDeleteBtn();

    };
    const { data } = this.state;

    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      download: true,
      print: true,
      selectableRowsOnClick: false,
      selectToolbarPlacement: 'none',
      rowsSelected: this.state.gpxRowsSelected,
      /*  selectableRows: 'single', */
      onRowSelectionChange: onRowsSelect,
    };
    return (
      <div>
        <MUIDataTable
          title={
            <Typography variant="subtitle2">
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: 'relative', top: 4 }}
                />
              )}
            </Typography>
          }
          data={this.props.gpxData}
          columns={columns}
          options={options}
          rowsSelected={onRowsSelect}
        />
      </div>
    );
  }
}


class LocalPOITable extends Component {

  constructor(props) {
    super(props);
    console.log('props', props)
    this.state = {
      data: [['Loading Data...']],
      loading: false,
      selectedRowKeys: [],
      indexData: ''
    };

    this.onReorder = this.onReorder.bind(this);

  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.selectedRowKeys !== prevState.selectedRowKeys){
      this.props.handleSetSelectedKeysPois(this.state.selectedRowKeys)
        activeDeleteBtn(this.state.selectedRowKeys)
    }
  }

  onReorder(e) {
    const visibleRows = e.component.getVisibleRows();
  const newLocalPoiData = [...this.props.localPoiData];
    const toIndex = newLocalPoiData.indexOf(visibleRows[e.toIndex].data);
    const fromIndex = newLocalPoiData.indexOf(e.itemData);

    newLocalPoiData.splice(fromIndex, 1);
    newLocalPoiData.splice(toIndex, 0, e.itemData);
    this.props.setLocalPOITableData(newLocalPoiData);
  }





  render() {
    const columns = [
      {
        name: 'id', options: {
          label: 'INTERNAL ID', filter: false,

          customBodyRender: (value, tableMeta, updateValue) => {
            // const editrow = tableMeta.tableData[tableMeta.rowIndex];
            var editrow = {};
            var rowIndexForUpdate = 0;
            var i = 0;
            var localpoipic="";
            tableMeta.tableData.forEach((row) => {
              if (row.id == value) {
                editrow = row;
                rowIndexForUpdate = i;
              }
              i++;
            })
      if(editrow?.photo)
      {
        localpoipic =editrow.photo
      }
            return (
              //  local_poi_type, local_poi_title, local_poi_desc, local_poi_location
              <Link href="#" onClick={() => {
                this.props.setLocalPoiData(localpoipic,editrow.type, editrow.name, editrow.description, editrow.location?.latitude + ',' + editrow.location?.longitude, 'update', rowIndexForUpdate); console.log('dvdsg gpx_title', editrow);
                openPopup1();
                initializeMap(-1, 'mapDay-3');
                if (editrow.location != '') {
                  setTimeout(function () { //Start the timer
                    window.bd.setLocalPOILocationMap(editrow.location?.latitude + ',' + editrow.location?.longitude)
                  }.bind(this), 200)


                }
              }}>  {value ? value : 'newPoi'}</Link>
            );
          },

        }
      },


      { name: 'name', label: 'Title', options: { filter: false } },
      {
        name: 'description', options: {
          label: 'Description', filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (value)
              return (value.length > 150 ? value.substring(0, 150) + '...' : value);
            else return value;
          }
        }
      },
      {
        name: 'location', options: {
          label: 'Location', filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {

            return (value?.latitude + ',' + value?.longitude);
          }
        }
      },
      {
        name: 'type', options: {
          filter: false, label: 'Type',
          customBodyRender: (value, tableMeta, updateValue) => {
            var poiType = '';
            localPoiTypes.map(function (item) {

              if (item.id === value)
                poiType = item.name;
            });
            //localPoiTypes.ma
            return (poiType);
          }
        }
      },
    ];

    const onRowsSelect = (curRowSelected, rowsSelected) => {      //local poi
      selectedLocalPoiRowIndex = [];
      for (let i = 0; i < rowsSelected.length; i++) {
        console.log('rowsSelected', this.props.localPoiData[rowsSelected[i].dataIndex].id)
        selectedLocalPoiRowIndex.push(rowsSelected[i].dataIndex);
      }
      activeDeactiveDeleteBtnLocalPoi();
    };
    const { data } = this.state;

    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      download: false,
      print: false,
      selectableRowsOnClick: true,
      selectToolbarPlacement: 'none',
      selectableRows: 'single',
      onRowSelectionChange: onRowsSelect,
    };


const LocationCell =(cellData) =>{

if(cellData && cellData.value?.latitude)
{
return (
  <div>{cellData.value.latitude}, {cellData.value.longitude}</div>
)
}
else
return null;
}

const PoiTypeCell =(cellData) =>{
var poiType = '';
  localPoiTypes.map(function (item) {
    if (item.id === cellData.value)
      poiType = item.name;
  });
  return poiType;
}

 
const IdCell =(cellData) =>{
   var editrow = cellData.data;
   var localpoipic="";
   if(editrow.photo)
   localpoipic=editrow.photo;
  return (
    <Link href="#" onClick={() => {
      this.props.setLocalPoiData(localpoipic,editrow.type, editrow.name, editrow.description, editrow.location?.latitude + ',' + editrow.location?.longitude, 'update', cellData.rowIndex); console.log('dvdsg gpx_title', editrow);
      openPopup1();
      initializeMap(-1, 'mapDay-3');
      if (editrow.location != '') {
        setTimeout(function () { //Start the timer
          window.bd.setLocalPOILocationMap(editrow.location?.latitude + ',' + editrow.location?.longitude)
        }.bind(this), 200)
      }
    }}>  {cellData.value}</Link>
  );

}

const localPoiData = this.props.localPoiData.map(poi => {
  if (!poi.id || poi.id === '') {
    return {
      ...poi,
      id: 'newPoi'
    };
  }
  return poi;
});

    return (
      <div>
            <div>
        <DataGrid
          dataSource={localPoiData}
          keyExpr="id"
          showBorders={true}
          ref={(ref) => (this.dataGrid = ref)}
          allowColumnReordering={true}
          onSelectionChanged={(e)=> this.setState({selectedRowKeys: e.selectedRowKeys, indexData: e})}
        >
          <Selection
            mode="multiple"
            selectAllMode={'allPages'}
            showCheckBoxesMode={'onClick'}
          />

            <RowDragging
                allowReordering={true}
                onReorder={this.onReorder}
                showDragIcons={false}
                caption="INTERNAL ID"
            />
            <Scrolling mode="virtual" />
            <Column dataField="id" cellRender={IdCell} width={120} alignment="center" caption="INTERNAL ID" />
            <Column dataField="name" caption="Title" />
            <Column dataField="description" />
            <Column dataField="location" width={320}  cellRender={LocationCell} />
            <Column dataField="type" cellRender={PoiTypeCell}/>
          <Paging defaultPageSize={10} />
        </DataGrid>
      </div>
      </div>
    );
  }
}


class TrialTable extends Component {



  constructor(props) {
    super(props);
    this.state = {
      data: [['Loading Data...']],
      loading: false,

    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.setState({ loading: true });
    this.xhrRequest().then((data) => {
      this.setState({ data, loading: false });
      this.props.setTrialPkgTableData(data);
    });
  };

  xhrRequest = (url) => {
    return new Promise(async (resolve, reject) => {
      let data = await getDatafromApi(ApiData.apiUrl + '/vg/trail-packages/')
      if(data){
        daysData = data.results;
        resolve(data.results);
      }
    });
  };

  render() {
    const columns = [

      {
        name: 'id', options: {
          label: 'ID', filter: false,

          customBodyRender: (value, tableMeta, updateValue) => {
            //const editrow = tableMeta.tableData[tableMeta.rowIndex];
            var editrow = {};
            var rowIndexForUpdate = 0;
            var i = 0;
            tableMeta.tableData.forEach((row) => {
              if (row.id == value) {
                editrow = row;
                rowIndexForUpdate = i;
              }
              i++;
            })

            return (
              //  local_poi_type, local_poi_title, local_poi_desc, local_poi_location
              <Link href="#" onClick={() => { this.props.setTrialData(editrow.type, editrow.name, editrow.gpx_set, 'update', rowIndexForUpdate); openPopup2() }}>  {value}</Link>
            );
          },

        }
      },



      { name: 'name', label: 'Name', options: { filter: false } },
      /*   { name: 'description', label: 'Description', options: { filter: false,display: false  } },  */
      { name: 'type', label: 'Type', options: { filter: true } },
      /* {
        name: 'type', options: {
          label: 'Type', filter: false, customBodyRender: (value, tableMeta, updateValue) => {
            let type1 = '';
            if (value == 1) type1 = 'HIKING';
            if (value == 2) type1 = 'BICYCLE';
            if (value == 3) type1 = '4X4';
            return (type1);
          },
        }
      }, */

      { name: 'country', label: 'Country' },

    ];

    const onRowsSelect = (curRowSelected, rowsSelected) => {
      if (rowsSelected.length > 0) {
        selectedRowIndex = this.props.trialPkgData[rowsSelected[0].dataIndex].id;
      } else {
        selectedRowIndex = false;
      }
      selectedTrialRowIndex = [];
      for (let i = 0; i < rowsSelected.length; i++) {
        selectedTrialRowIndex.push(rowsSelected[i].dataIndex);
      }
      console.log('selectedTrialRowIndex', selectedTrialRowIndex);
      activeDeactiveDeleteBtnTrialPkg();
    };
    const { data } = this.state;

    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      download: false,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: 'none',

      onRowSelectionChange: onRowsSelect,
    };
    return (
      <div>
        <MUIDataTable
          title={
            <Typography variant="subtitle2">
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: 'relative', top: 4 }}
                />
              )}
            </Typography>
          }
          data={this.props.trialPkgData}
          columns={columns}
          options={options}
          rowsSelected={onRowsSelect}
        />
      </div>
    );
  }
}


function getDayData(day_id, handlerType) {
  return new Promise(async (resolve, reject) => {
    let data = await getDatafromApi(ApiData.apiUrl + `/${handlerType}/days/${day_id}`)
    if(data){
      resolve(data);
    }
  });
}

function addWebMarker(coordinates) {

  const id = _uniqueId('webmarker-');

  let marker;
  var el = document.createElement('div');
  el.className = 'marker';
  el.id = id;
  el.addEventListener(
    'click',
    (e) => {
      e.stopPropagation();
      let target_id = e.target.id;
      // let queriedMarkerObj = webmapMarkers.find((marker) => marker.id === query);
      // queriedMarkerObj.marker.remove();
      for (let i = 0; i < webmapMarkers.length; i++) {
        if (webmapMarkers[i].id === target_id) {
          document.getElementById(webmapMarkers[i].id).remove();
          webmapMarkers.splice(i, 1);
        }
      }
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(webMap);
  marker.properties = { id: id };
  webmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}

function addVgMarker(coordinates, id1, type = false) {
  //const id = _uniqueId('vgmarker-');
  const id = 'vgmarker-' + id1;
  let marker;
  var el = document.createElement('div');
  el.className = 'marker';
  el.id = id;
  el.poiid = id1;
  if (type) {
    el.style.backgroundImage = `url('https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/${getSvg(
      type
    )}.svg')`;
  }
  el.addEventListener(
    'click',
    (e) => {
      e.stopPropagation();
      let query = e.target.id;
      console.log('markerid', e.target.poiid)
      window.bd.setState({ openDeleteMarker: true });
      window.bd.setState({ deleteMarkerId: e.target.poiid });
      //removeVGmarker(query);
      /* let queriedMarkerObj = vgmapMarkers.find((marker) => marker.id === query);
      queriedMarkerObj.marker.remove();
      pointNumbers.splice(
        pointNumbers.findIndex((v) => v.id === query),
        1
      ); */
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(vgMap);
  vgmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}


function removeVGmarker(query) {
  let queriedMarkerObj = vgmapMarkers.find((marker) => marker.id === 'vgmarker-' + query);
  queriedMarkerObj.marker.remove();
  pointNumbers.splice(
    pointNumbers.findIndex((v) => v.id === query),
    1
  );
}


function handleGpx(xmlData) {
  const converter = require('@tmcw/togeojson');
  const geojson = converter.gpx(xmlData[2]);
  if (typeof vgMap.getLayer('day-route-line') !== 'undefined') {
    vgMap.removeLayer('day-route-line').removeSource('day-route');
  }
  vgMap.addSource('day-route', {
    type: 'geojson',
    data: geojson,
  });

  vgMap.addLayer({
    id: 'day-route-line',
    type: 'line',
    source: 'day-route',
    paint: {
      'line-color': '#15cc09',
      'line-width': 4,
    },
  });

  var coordinates = geojson.features[0].geometry.coordinates;
  var bounds = coordinates.reduce(function (bounds, coord) {
    return bounds.extend(coord);
  }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

  vgMap.fitBounds(bounds, {
    padding: 20,
  });
}

function getSvg(type) {
  type = parseInt(type);
  switch (type) {
    case 1: {
      return 'straight';
    }
    case 2: {
      return 'right';
    }
    case 3: {
      return 'left';
    }
    case 4: {
      return 'fork-left';
    }
    case 5: {
      return 'fork-right';
    }
    case 6: {
      return 'poi';
    }
    case 7: {
      return 'info';
    }
    case 8: {
      return 'swimming';
    }
    case 9: {
      return 'rafting';
    }
    case 10: {
      return 'hotel';
    }
    default: {
      return 'poi';
    }
  }
}

function openPopup(id='') {
  if(id !== ''){
    window.history.pushState(null, null, `?page=GPXs&id=${id}`);
  }
  const closeBtn = document.querySelector(".close")
  document.querySelector(".viewdata").style.display = "none";
  document.querySelector(".modal").style.display = "block";
  document.querySelector(".modal1").style.display = "none";


  closeBtn.addEventListener("click", () => {
    window.history.pushState(null, null, `?page=GPXs`);
    document.querySelector(".modal").style.display = "none";
    document.querySelector(".viewdata").style.display = "inline-table";
  })
}

function closePopoup() {
  document.querySelector(".modal").style.display = "none";
  document.querySelector(".viewdata").style.display = "inline-table";
}

function openPopup1() {
  const closeBtn = document.querySelector(".close1")
  document.querySelector(".viewdata").style.display = "none";
  document.querySelector(".modal1").style.display = "block";
  document.querySelector(".modal").style.display = "none";


  closeBtn.addEventListener("click", () => {
    document.querySelector(".modal1").style.display = "none";
    document.querySelector(".modal").style.display = "block";
  })
}

function closePopoup1() {
  document.querySelector(".modal1").style.display = "none";
  document.querySelector(".modal").style.display = "block";
}

function openPopup2() {
  const closeBtn = document.querySelector(".close2")
  document.querySelector(".viewdata").style.display = "none";
  document.querySelector(".modal").style.display = "none";
  document.querySelector(".modal1").style.display = "none";
  closeBtn.addEventListener("click", () => {
    document.querySelector(".viewdata").style.display = "inline-table";
  })
}

function activeDeactiveDeleteBtn() {
  // const btn = document.querySelector(".createPKGBtn")
  const btn = document.querySelector(".deleteBtn")
  const btn1 = document.querySelector(".deleteBtn")
  if (selectedRowIDs.length > 0) { btn.style.backgroundColor = 'blue'; btn1.style.backgroundColor = 'blue'; }
  else { btn.style.backgroundColor = '#e0e0e0'; btn1.style.backgroundColor = '#e0e0e0'; }
}

function activeDeactiveDeleteBtnTrialPkg() {
  const btn = document.querySelector(".deleteBtnTrialPkg")
  if (selectedTrialRowIndex.length > 0) btn.style.backgroundColor = 'blue';
  else btn.style.backgroundColor = '#e0e0e0';
}

function activeDeactiveDeleteBtnLocalPoi() {
  const btn = document.querySelector(".deleteBtnLocalPoi")
  if (selectedLocalPoiRowIndex.length > 0) btn.style.backgroundColor = 'blue';
  else btn.style.backgroundColor = '#e0e0e0';
}

function activeDeleteBtn(selectedLocalPoiRowIndex) {
  const btn = document.querySelector(".deleteBtnLocalPoi")
  if (selectedLocalPoiRowIndex.length > 0) btn.style.backgroundColor = 'blue';
  else btn.style.backgroundColor = '#e0e0e0';
}
