import { CircularProgress, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@mui/material';
import Handlebars from 'handlebars';
import JoditEditor from "jodit-react";
import $ from 'jquery';
import _uniqueId from 'lodash/uniqueId';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { PdfGenerator } from './@common/helpers/pdf-generator';
import MUIDataTable from 'mui-datatables';
import React, { Component } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getDatafromApi, handleCallApi } from './calls';
import { ApiData, MapboxKey, countriesNames } from "./config";
import paybylinkTemplate from './template_email';
import uploadIcon from './themes/icons/uploadicon.png';
import { handleBase64Upload, handlePhotoUpload } from "./uploadFiles";
import LoadingSpinner from './_layout/spinner';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

let pointNumbers = [];
let selectedRowIndex = false;
let selectedRowIds = [];

const CURRENCIES = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  ILS: '₪'
};

class SelfGuidedPackagePersonalized extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      errors: {},
      photourl: '',
      webphotoLoc: '',
      selfGuideTableData: [['Loading Data...']],
      updateSGRowIndex: '',
      PNCount: 1,
      SNCount: 1,
      selfGuide_tripName: '',
      selfGuide_country: '',
      selfGuide_trip: '',
      selfGuide_subTitle: '',
      selfGuide_desc: '',
      selfGuide_car_model: '',
      selfGuide_price: '',
      price_description: '',
      selfGuide_price_includes: '',
      selfGuide_price_excluded: '',
      selfGuide_photo: '',
      save_type: 'add',
      number_travelers: 0,
      showModal: false,
      openDeleteCnfrm: false,
      editableSPId: '',
      starting_date: '1970-01-01',
      searchData: [],
      allTrip: [],
      confirmation_policy: '',
      showEmailField: false,
      searchTerm: '',
      emails: [],
      selectedEmail: '',
      userFirstName: '',
      userLastName: '',
      selectedCurrency: '€',
      currencies: Object.keys(CURRENCIES),
      overnightPreCity: '',
      overnightPostNight: '',
      overnightPreHotel: '',
      overnightPostHotel: '',
      overnightPreHotelSiteLink: '',
      overnightPostHotelSiteLink: '',
      hotelNames: [],
      hotelSites: [],
      trip: {},
      car_name: '',
      priceIncludes: '',
      mapPhotoUrl: '',
      tripPhotoUrl: '',
      hotelOptions: [],
      searchTermHotel: '',
      searchTermOvernightHotel: '',
      contentEmail: '',
      days_data: [],
      user_data: []
    };
  }

  componentDidMount() {
    this.getTrip();
  }

  componentDidUpdate(prevProps, prevState) {

  }

  async getTrip() {
    let data1 = await getDatafromApi(ApiData.apiUrl + '/trips/?personalized=true&take=3000');
    let data = data1.results;
    if (data && data.length > 2) {
      data.sort((a, b) => a.id - b.id);
      this.setState({ allTrip: data });
    }
    else if (data)
      this.setState({ allTrip: data });
  }

  handleSelfGuideValidation() {
    let errors = {};
    let formIsValid = true;
    let errorMessage = ''
    if (!this.state.selfGuide_trip) {
      formIsValid = false;
      errors['selfGuide_trip'] = 'Please Select Trip';
      errorMessage = 'Please Select Trip';
    }
    if (!this.state.selfGuide_photo) {
      formIsValid = false;
      errors['selfGuide_photo'] = 'You must select a photo';
      errorMessage = 'You must select a photo';
    }
    if (!this.state.selfGuide_price) {
      formIsValid = false;
      errors['price'] = 'Price Is not empty';
      errorMessage = 'Price Is not empty';
    }
    if (isNaN(parseInt(this.state.selfGuide_price))) {
      formIsValid = false;
      errors['price'] = 'Not a Valid price';
      errorMessage = 'Not a Valid price';
    }
    if (!formIsValid) {
      toast.error(errorMessage);
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  handleSaveSelfGuide = async (e) => {
    e.preventDefault();
    toast.dismiss();
    if (this.handleSelfGuideValidation()) {
      this.checkHotelDaysSelected();
      let selfGuideData1 = [...this.state.selfGuideTableData];
      var countryCode = this.state.selfGuide_country;
      var countryname = '';
      countriesNames.map(function (item) {
        if (item.code === countryCode)
          countryname = item.name;
      });
      const [year, month, day] = this.state.starting_date.split('-');
      const formattedDate = `${day}/${month}/${year}`;
      const daysData = [];
      this.state.hotelNames.forEach((hotelName, i) => {
        let data = {};
        let hotelSite = this.state.hotelSites[i];
        data.hotelName = hotelName;
        data.hotelSite = hotelSite;
        data.overnightPreCity = this.state.overnightPreCity;
        data.overnightPostNight = this.state.overnightPostNight;
        data.overnightPreHotel = this.state.overnightPreHotel;
        data.overnightPostHotel = this.state.overnightPostHotel;
        data.overnightPreHotelSiteLink = this.state.overnightPreHotelSiteLink;
        data.overnightPostHotelSiteLink = this.state.overnightPostHotelSiteLink;
        data.contentEmail = this.state.contentEmail;
        daysData.push(data)
      })
      const userData = [{
        firstName: this.state.userFirstName,
        lastName: this.state.userLastName,
        selectedEmail: this.state.selectedEmail,
        selectedCurrency: this.state.selectedCurrency
      }];

      let apidata = {
        "trip_id": this.state.selfGuide_trip,
        "photo": this.state.selfGuide_photo,
        "price": Number(this.state.selfGuide_price),
        "price_includes": this.state.selfGuide_price_includes,
        "price_description": this.state.price_description,
        "excluded": this.state.selfGuide_price_excluded,
        "personalized": true,
        "price_description": this.state.price_description,
        "starting_date": formattedDate,
        'number_travelers': this.state.number_travelers,
        'confirmation_policy': this.state.confirmation_policy,
        'days_data': daysData,
        'user_data': userData,
      }
      let method = 'POST';
      let success = '';
      let url1 = ApiData.apiUrl + `/self-guided-packages/`;
      if (this.state.save_type != 'add') {
        method = 'PUT';
        url1 = ApiData.apiUrl + `/self-guided-packages/${this.state.editableSPId}`;
        success = 'Self Guided Updated';
        selfGuideData1[this.state.updateSGRowIndex].trip_id = this.state.selfGuide_trip
        selfGuideData1[this.state.updateSGRowIndex].photo = this.state.selfGuide_photo
        selfGuideData1[this.state.updateSGRowIndex].price = Number(this.state.selfGuide_price)
        selfGuideData1[this.state.updateSGRowIndex].price_includes = this.state.selfGuide_price_includes
        selfGuideData1[this.state.updateSGRowIndex].price_description = this.state.price_description
        selfGuideData1[this.state.updateSGRowIndex].excluded = this.state.selfGuide_price_excluded
        selfGuideData1[this.state.updateSGRowIndex].number_travelers = this.state.number_travelers
        selfGuideData1[this.state.updateSGRowIndex].confirmation_policy = this.state.confirmation_policy
        selfGuideData1[this.state.updateSGRowIndex].starting_date = formattedDate
        selfGuideData1[this.state.updateSGRowIndex].days_data = daysData
        selfGuideData1[this.state.updateSGRowIndex].user_data = userData
        this.setState({ selfGuideTableData: selfGuideData1 })
      }
      let data = await handleCallApi(method, url1, JSON.stringify(apidata), success, '')
      if (data) {
        if (this.state.save_type === 'add') {
          selfGuideData1.unshift(data);
          this.setState({ selfGuideTableData: selfGuideData1 })
          toast.success(`SGP ${data.id} created`);
        }
        else {
          if (this.state.searchData) {
            this.state.searchData[this.state.updateSGRowIndex] = data;
            this.setState({ searchData: this.state.searchData })
          }
        }
        this.setState({
          selfGuide_tripName: '',
          selfGuide_country: '',
          selfGuide_trip: '',
          selfGuide_subTitle: '',
          selfGuide_desc: '',
          selfGuide_price: '',
          selfGuide_price_includes: '',
          price_description: '',
          selfGuide_photo: '',
          selfGuide_price_excluded: '',
          editableSPId: '',
          number_travelers: 0,
          starting_date: '1970-01-01',
        });

        closePopoup();
      }
    }
    else {
      toast.error('Missing Information / Fields');
    }
  }

  async checkHotelDaysSelected() {
    const hotelNameMismatches = [];

    await Promise.all(this.state.trip.days.map(async (day, index) => {
      // Verificar si el índice existe en ambos arreglos
      if (this.state.hotelNames[index] && day.hotel && day.hotel.length > 0) {
        if (this.state.hotelNames[index] != this.state.trip.days[index].hotel[0].hotel_name) {
          // Guardar el objeto en el array en caso de que no coincida
          hotelNameMismatches.push({
            dayId: this.state.trip.days[index].id,
            hotelName: this.state.hotelNames[index],
          });
        }
        return this.state.hotelNames[index] === day.hotel[0].hotel_name;
      }
      return false;
    }));

    if (hotelNameMismatches.length === 0) {
      console.log('The names coincide in the same indexes.');
    } else {
      const method = "PUT";
      const success = 'Day Updated';
      const error = 'Missing Information / Fields';
      await Promise.all(hotelNameMismatches.map(async (data) => {
        const hotelData = await getDatafromApi(ApiData.baseURL + `/hotels-prices-list/name/${data.hotelName}/${this.state.trip.country}/true`);
        const apidata = {
          ending_point: { latitude: parseFloat(hotelData[0].latitude), longitude: parseFloat(hotelData[0].longitude) },
          overnight: hotelData[0].overnight_city
        };
        await handleCallApi(method, ApiData.apiUrl + `/days/overnight/${data.dayId}?admin=true`, JSON.stringify(apidata), success, error);
      }));
    }
  }

  handlePhotoForEmail = async (event) => {
    try {
      const url = await handleBase64Upload(event, 'planmytrip-assets', 'paybylink');
      if (url) {
        return url;
      }
    } catch (error) {
      console.log('Error uploading photo:', error);
    }
  };

  async generateMapImage(coordinates, width = 1500, height = 900) {
    // Verifica si hay al menos una coordenada válida
    if (coordinates.length === 0 || isNaN(coordinates[0].longitude) || isNaN(coordinates[0].latitude)) {
      return null;
    }
    // Crear un nuevo mapa en un contenedor con el tamaño especificado
    const mapContainer = document.createElement('div');
    mapContainer.style.width = `${width}px`;
    mapContainer.style.height = `${height}px`;

    const map = new mapboxgl.Map({
      container: mapContainer,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [coordinates[0].longitude, coordinates[0].latitude],
      zoom: 5,
    });

    // Esperar a que el estilo del mapa se cargue completamente
    await new Promise((resolve) => {
      map.on('style.load', () => {
        resolve();
      });
    });

    // Agregar la fuente y capa para los marcadores y las líneas
    map.addSource('markersAndLines', {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: coordinates.map((markerData) => ({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [markerData.longitude, markerData.latitude],
          },
          properties: {},
        })),
      },
    });

    map.addLayer({
      id: 'markers',
      type: 'circle',
      source: 'markersAndLines',
      paint: {
        'circle-color': '#8a2be2', // Violeta
        'circle-radius': 6,
      },
    });

    // Agregar líneas entre los marcadores
    map.addLayer({
      id: 'lines',
      type: 'line',
      source: 'markersAndLines',
      paint: {
        'line-color': '#8a2be2', // Violeta
        'line-width': 2,
      },
    });

    // Esperar a que el mapa esté completamente cargado
    await new Promise((resolve) => {
      map.once('idle', () => {
        resolve();
      });
    });

    // Obtener la imagen del mapa como base64
    const mapImage = await map.getCanvas().toDataURL('image/png');

    // Limpiar el mapa
    map.remove();
    return mapImage;
  }

  async sendEmail() {
    if (this.handleSelfGuideValidation()) {

      if (this.state.userFirstName.trim() === '' || this.state.selectedEmail.trim() === '' || this.state.userLastName.trim() === '') {
        toast.error('You need to fill the form');
        return;
      }
      this.setState({ loading: true });
      let localHero = await getDatafromApi(ApiData.apiUrl + `/local-hero/country/${this.state.trip.country}`);
      let selfGuidePackage = await getDatafromApi(ApiData.apiUrl + `/self-guided-packages/${this.state.editableSPId}`)
      selfGuidePackage.price_includes = this.state.priceIncludes
      selfGuidePackage.price_description = this.state.price_description
      // PAYMENT LINK 
      const SYMBOLS_TO_CURRENCY = {};
      for (const key in CURRENCIES) {
        const symbol = CURRENCIES[key];
        SYMBOLS_TO_CURRENCY[symbol] = key;
      }
      let currencyValue = SYMBOLS_TO_CURRENCY[this.state.selectedCurrency];
      const getPaymentLinkUrl = `${ApiData.baseURL}/payment-links`;
      const paymentLinkData = {
        email: this.state.selectedEmail,
        type: 'SelfGuidedPackage',
        userFirstName: this.state.userFirstName,
        currency: currencyValue,
        metadata: this.state.trip.internal_name,
        entityId: Number(this.state.editableSPId),
        reference: this.state.trip.internal_name + "-" + this.state.userFirstName + "-" + this.state.userLastName
      };
      const linkData = await handleCallApi('POST', getPaymentLinkUrl, JSON.stringify(paymentLinkData), "Success", 'Error')
      // const coordinates = [];
      // this.state.trip.days.forEach(day => {
      //   const data = {
      //     longitude: parseFloat(day.starting_point_longitude),
      //     latitude: parseFloat(day.starting_point_latitude)
      //   }
      //   coordinates.push(data)
      // });
      // const mapImage = await this.generateMapImage(coordinates);
      // const textToAdd = `${this.state.trip.country} - ${this.state.trip.days_count} Days`;
      // const packagePhotoWithText = await this.addTextToImage(this.state.selfGuide_photo, textToAdd);
      // const mapImageUrl = await this.handlePhotoForEmail(mapImage);
      // const packagePhotoUrl = await this.handlePhotoForEmail(packagePhotoWithText);

      const payload = {
        subject: 'Pay by Link',
        email: this.state.selectedEmail,
        reference: this.state.trip.internal_name + "-" + this.state.userFirstName + "-" + this.state.userLastName,
        firstName: this.state.userFirstName,
        option: 'SelfGuidedPackage',
        currencyForEmail: this.state.selectedCurrency,
        price: Number(this.state.selfGuide_price),
        package: selfGuidePackage,
        hotelNames: this.state.hotelNames,
        hotelSites: this.state.hotelSites,
        packageImg: this.state.selfGuide_photo,
        link_payment: linkData.url,
        overnightPreCity: this.state.overnightPreCity,
        overnightPostNight: this.state.overnightPostNight,
        overnightPreHotel: this.state.overnightPreHotel,
        overnightPostHotel: this.state.overnightPostHotel,
        contentEmail: this.state.contentEmail,
        overnightPreHotelSiteLink: this.state.overnightPreHotelSiteLink,
        overnightPostHotelSiteLink: this.state.overnightPostHotelSiteLink,
      };

      // const sendEmailUrl = `${ApiData.baseURL}/web/send-email-paybylink`;
      //  await handleCallApi('POST', sendEmailUrl, JSON.stringify(payload), "Success", 'Error');
      const template = Handlebars.compile(paybylinkTemplate);
      const days = this.state.trip.days.map((day, i) => {
        const isFirstDay = i === 0;
        const isLastDay = i === this.state.trip.days.length - 1;
        return {
          ...day,
          dayIndex: i + 1,
          firstDay: isFirstDay,
          lastDay: isLastDay,
          hotelName: (this.state.hotelNames && this.state.hotelNames[i])
            ? this.state.hotelNames[i] : '',
          siteLinkHotel: (this.state.days_data && this.state.days_data.length > 0 && this.state.days_data[i]) ? this.state.days_data[i].hotelSite : '',
        };
      });

      const data = {
        reference: (this.state.userFirstName && this.state.userLastName) ? this.state.trip.internal_name + "-" + this.state.userFirstName + "-" + this.state.userLastName : this.state.trip.internal_name,
        username: (this.state.userFirstName && this.state.userLastName) ? `${this.state.userFirstName} ${this.state.userLastName}` : 'username',
        country: this.state.trip.country,
        days_count: this.state.trip.days_count,
        days: days,
        link: linkData.url,
        price: this.state.selfGuide_price,
        price_description: this.state.price_description,
        price_includes: this.state.selfGuide_price_includes,
        price_excludes: this.state.selfGuide_price_excluded,
        local_hero_img: localHero.profile_pic,
        package_img: this.state.selfGuide_photo,
        currency: this.state.selectedCurrency,
        link_payment: linkData.url,
        overnightPreCity: this.state.overnightPreCity,
        overnightPostNight: this.state.overnightPostNight,
        overnightPreHotel: this.state.overnightPreHotel,
        overnightPostHotel: this.state.overnightPostHotel,
        contentEmail: this.state.contentEmail,
        overnightPreHotelSiteLink: this.state.overnightPreHotelSiteLink,
        overnightPostHotelSiteLink: this.state.overnightPostHotelSiteLink,
      };

      const pdf = new PdfGenerator(data);
      await pdf.initializePdf();
      const pdfBlob = pdf.getPdf(true);

      const formData = new FormData();

      formData.append("attachment", pdfBlob, `${data.reference.replace(/ /g, "-")}_planyourtrip.pdf`);
      formData.append('email', this.state.selectedEmail);
      formData.append('firstName', this.state.userFirstName);
      formData.append('lastName', this.state.userLastName);
      formData.append('contentEmail', data.contentEmail);
      formData.append('subject', `${data.reference} - Plan Your Trip`);

      const url = `${ApiData.apiUrl}/send-email`;
      let requestOptions = {
        method: 'POST',
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: formData,
      };

      fetch(url, requestOptions)
        .then((response) => response.json())
        .then((data) => {
        });
      this.setState({ loading: false });
    }
  }

  async addTextToImage(imageUrl, text) {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      const img = new Image();

      img.crossOrigin = 'anonymous';

      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0);
        context.font = 'bold 70px Poppins';
        context.fillStyle = '#FFF';
        const paddingX = 50; // Ajuste horizontal
        const paddingY = 50; // Ajuste vertical
        const textX = paddingX;
        const textY = canvas.height - paddingY;
        context.fillText(text, textX, textY);
        const modifiedImageUrl = canvas.toDataURL('image/png');
        resolve(modifiedImageUrl);
      };

      img.onerror = function () {
        console.error('Error cargando la imagen:', imageUrl);
        resolve(null); // Resuelve con null en caso de error
      };

      img.src = imageUrl;
    });
  }

  async viewEmail() {
    if (this.handleSelfGuideValidation()) {
      let localHero = await getDatafromApi(ApiData.apiUrl + `/local-hero/country/${this.state.trip.country}`)
      const template = Handlebars.compile(paybylinkTemplate);
      const days = this.state.trip.days.map((day, i) => {
        const isFirstDay = i === 0;
        const isLastDay = i === this.state.trip.days.length - 1;
        return {
          ...day,
          dayIndex: i + 1,
          firstDay: isFirstDay,
          lastDay: isLastDay,
          hotelName: (this.state.hotelNames && this.state.hotelNames[i])
            ? this.state.hotelNames[i] : '',
          siteLinkHotel: (day.hotel && day.hotel.length) > 0 ? day.hotel[0].site_link : '',
        };
      });

      const data = {
        reference: (this.state.userFirstName && this.state.userLastName) ? this.state.trip.internal_name + "-" + this.state.userFirstName + "-" + this.state.userLastName : this.state.trip.internal_name,
        username: (this.state.userFirstName && this.state.userLastName) ? `${this.state.userFirstName} ${this.state.userLastName}` : 'username',
        country: this.state.trip.country,
        days_count: this.state.trip.days_count,
        days: days,
        price: this.state.selfGuide_price,
        price_description: this.state.price_description,
        price_includes: this.state.selfGuide_price_includes,
        price_excludes: this.state.selfGuide_price_excluded,
        local_hero_img: localHero.profile_pic,
        // local_hero_img: "https://planmytrip-assets.s3.eu-central-1.amazonaws.com/a-tour-between-the-walls-from-jaffa-gate-to-the-1663869949204.jpg",
        package_img: this.state.selfGuide_photo,
        currency: this.state.selectedCurrency,
        // link_payment: '',
        overnightPreCity: this.state.overnightPreCity,
        overnightPostNight: this.state.overnightPostNight,
        overnightPreHotel: this.state.overnightPreHotel,
        overnightPostHotel: this.state.overnightPostHotel,
        contentEmail: this.state.contentEmail,
        overnightPreHotelSiteLink: this.state.overnightPreHotelSiteLink,
        overnightPostHotelSiteLink: this.state.overnightPostHotelSiteLink,
      };
      // const coordinates = [];
      // this.state.trip.days.forEach(day => {
      //   const data = {
      //     longitude: parseFloat(day.starting_point_longitude),
      //     latitude: parseFloat(day.starting_point_latitude)
      //   }
      //   coordinates.push(data)
      // });
      // const mapImage = await this.generateMapImage(coordinates);
      // const textToAdd = `${this.state.trip.country} - ${this.state.trip.days_count} Days`;
      // const selfGuidePhotoWithText = await this.addTextToImage(this.state.selfGuide_photo, textToAdd);


      const compiledHtml = template({
        reference: (this.state.userFirstName && this.state.userLastName) ? this.state.trip.internal_name + "-" + this.state.userFirstName + "-" + this.state.userLastName : this.state.trip.internal_name,
        username: (this.state.userFirstName && this.state.userLastName) ? `${this.state.userFirstName} ${this.state.userLastName}` : 'username',
        country: this.state.trip.country,
        days_count: this.state.trip.days_count,
        days: days,
        price: this.state.selfGuide_price,
        price_description: this.state.price_description,
        price_includes: this.state.selfGuide_price_includes,
        price_excludes: this.state.selfGuide_price_excluded,
        local_hero_img: localHero.profile_pic,
        package_img: this.state.selfGuide_photo,
        currency: this.state.selectedCurrency,
        // link_payment: '',
        overnightPreCity: this.state.overnightPreCity,
        overnightPostNight: this.state.overnightPostNight,
        overnightPreHotel: this.state.overnightPreHotel,
        overnightPostHotel: this.state.overnightPostHotel,
        contentEmail: this.state.contentEmail,
        overnightPreHotelSiteLink: this.state.overnightPreHotelSiteLink,
        overnightPostHotelSiteLink: this.state.overnightPostHotelSiteLink,
      });
      const emailWindow = window.open();
      emailWindow.document.write(compiledHtml);
      emailWindow.document.close();
    }
  }

  handleEmailSelect(event) {
    if (event.target && event.target.value !== undefined) {
      this.setState({ selectedEmail: event.target.value });
    } else {
      console.error('Invalid event or value:', event);
    }
  }

  async handleSearchTermChange(event) {
    this.setState({ searchTerm: event.target.value, selectedEmail: event.target.value });
    if (this.state.searchTerm === '') {
      return;
    }
    let emailsData = await getDatafromApi(ApiData.baseURL + `/user/emails?q=${this.state.searchTerm}`)
    if (emailsData) {
      this.setState({ emails: emailsData });
    }
  };

  handleCurrencyChange(event) {
    this.setState({ selectedCurrency: event.target.value });
  }

  setEditableSelfPackageId = (id) => {
    this.setState({ editableSPId: id });
  }
  setSGSearchData = async (data) => {
    this.setState({ searchData: data });
  };

  handlePhoto = async (event) => {
    try {
      const url = await handlePhotoUpload(event, 'planmytrip-assets', 'selfGuidedPackages');
      if (url) {
        this.setState({ selfGuide_photo: url });
      }
    } catch (error) {
      console.log('Error uploading photo:', error);
    }
  };

  deletePopUpData = () => {
    this.setState({
      contentEmail: '',
      hotelNames: [],
      hotelOptions: [],
      hotelSites: [],
      overnightPostHotel: '',
      overnightPostHotelSiteLink: '',
      overnightPostNight: '',
      overnightPreCity: '',
      overnightPreHotel: '',
      overnightPreHotelSiteLink: '',
      searchTerm: '',
      searchTermHotel: '',
      searchTermOvernightHotel: '',
      selectedEmail: '',
      userFirstName: '',
      userLastName: '',
      contentEmail: '',
      days_data: [],
      user_data: [],
    })
  };

  setSelfGuideData = async (confirmation_policy, price_description, trip, photo, selfGuide_price, selfGuide_price_includes, selfGuide_price_excluded, starting_date, save_type, updateSGRowIndex, number_travelers, days_data, user_data) => {
    const dateFormated = this.convertToISODate(starting_date);
    let tripData = await getDatafromApi(ApiData.apiUrl + `/trips/${trip}`);
    let priceIncludes = selfGuide_price_includes;
    let carData = [];
    let overnightPreCityData = '';
    let overnightPostNightData = '';
    let overnightPreHotelData = '';
    let overnightPostHotelData = '';
    let overnightPreHotelSiteLinkData = '';
    let overnightPostHotelSiteLinkData = '';
    let contentEmailData = '';
    let userLastName = '';
    let selectedEmail = '';
    let userFirstName = '';
    let selectedCurrency = '';
    let hotelNamesData = [];
    let hotelSitesData = [];
    if (tripData) {
      carData = await getDatafromApi(ApiData.baseURL + `/car-rental-prices-list/country/${tripData.country}/true`);
      let nightsInTrip = tripData ? tripData.days_count - 1 : 0;
      let daysInTrip = tripData ? tripData.days_count : 0;
      priceIncludes = nightsInTrip > 0 ? priceIncludes.replace(/&lt;number_of_nights&gt;/g, String(nightsInTrip)) : '';
      priceIncludes = daysInTrip > 0 ? priceIncludes.replace(/&lt;number_of_days&gt;/g, String(daysInTrip)) : '';
      if (carData.length > 0 && carData[0].car_name) {
        priceIncludes = priceIncludes.replace(/&lt;car_model&gt;/g, carData[0].car_name);
      }
      this.setState({ priceIncludes: priceIncludes });
      if (Array.isArray(days_data) && days_data.length > 0) {
        days_data.forEach(data => {
          hotelNamesData.push(data.hotelName);
          hotelSitesData.push(data.hotelSite);
        });
        overnightPreCityData = days_data[0].overnightPreCity;
        overnightPostNightData = days_data[0].overnightPostNight;
        overnightPreHotelData = days_data[0].overnightPreHotel;
        overnightPostHotelData = days_data[0].overnightPostHotel;
        overnightPreHotelSiteLinkData = days_data[0].overnightPreHotelSiteLink;
        overnightPostHotelSiteLinkData = days_data[0].overnightPostHotelSiteLink;
        contentEmailData = days_data[0].contentEmail;
      }
      if (Array.isArray(user_data) && user_data.length > 0) {
        userLastName = user_data[0].userLastName;
        selectedEmail = user_data[0].selectedEmail;
        userFirstName = user_data[0].userFirstName;
        selectedCurrency = user_data[0].selectedCurrency;
      }


    }

    this.setState({
      confirmation_policy,
      showModal: true,
      selfGuide_photo: photo,
      selfGuide_trip: trip,
      selfGuide_price: selfGuide_price,
      selfGuide_price_includes: priceIncludes,
      price_description: price_description,
      selfGuide_price_excluded: selfGuide_price_excluded,
      starting_date: dateFormated,
      save_type: save_type,
      updateSGRowIndex: updateSGRowIndex,
      number_travelers,
      trip: tripData,
      days_data,
      user_data,
      overnightPreCity: overnightPreCityData,
      overnightPostNight: overnightPostNightData,
      overnightPreHotel: overnightPreHotelData,
      overnightPostHotel: overnightPostHotelData,
      overnightPreHotelSiteLink: overnightPreHotelSiteLinkData,
      overnightPostHotelSiteLink: overnightPostHotelSiteLinkData,
      hotelNames: hotelNamesData,
      hotelSites: hotelSitesData,
      contentEmail: contentEmailData,
      userLastName: userLastName,
      selectedEmail: selectedEmail,
      userFirstName: userFirstName,
      selectedCurrency: selectedCurrency,
    });
  };

  async handleHotelChange(event, i) {
    this.setState({ searchTermHotel: event.target.value }, async () => {
      if (this.state.searchTermHotel === '') {
        return;
      }
      const hotelData = await getDatafromApi(ApiData.baseURL + `/hotels-prices-list/name/${this.state.searchTermHotel}/${this.state.trip.country}/true/true`);
      const options = [];
      if (hotelData) {
        hotelData.forEach(hotel => {
          options.push(hotel.hotel_name);
        });
        this.setState({ hotelOptions: options });
      }
    });
  }

  async handleHotelSelect(event, i) {
    if (event.target && event.target.value !== undefined) {
      const hotelData = await getDatafromApi(ApiData.baseURL + `/hotels-prices-list/name/${event.target.value}/${this.state.trip.country}/true/true`);
      if (hotelData) {
        const updatedHotelNames = [...this.state.hotelNames];
        const updatedhotelSites = [...this.state.hotelSites];
        updatedHotelNames[i] = hotelData[0].hotel_name;
        updatedhotelSites[i] = hotelData[0].site_link;
        this.setState({ hotelNames: updatedHotelNames, hotelSites: updatedhotelSites });
      }
    } else {
      console.error('Invalid event or value:', event);
    }
  }

  async handleOverNightHotelChange(event, isPre) {
    this.setState({ searchTermOvernightHotel: event.target.value }, async () => {
      if (this.state.searchTermOvernightHotel === '') {
        return;
      }
      const hotelData = await getDatafromApi(ApiData.baseURL + `/hotels-prices-list/name/${this.state.searchTermOvernightHotel}/${this.state.trip.country}/true/true`);
      const options = [];
      if (hotelData) {
        hotelData.forEach(hotel => {
          options.push(hotel.hotel_name);
        });
        this.setState({ hotelOptions: options });
      }
    });
  }

  async handleOverNightHotelSelect(event, isPre) {
    if (event.target && event.target.value !== undefined) {
      const hotelData = await getDatafromApi(ApiData.baseURL + `/hotels-prices-list/name/${event.target.value}/${this.state.trip.country}/true/true`);
      if (hotelData) {
        if (isPre) {
          this.setState({ overnightPreHotel: event.target.value, overnightPreHotelSiteLink: hotelData[0].site_link });
        } else {
          this.setState({ overnightPostHotel: event.target.value, overnightPostHotelSiteLink: hotelData[0].site_link });
        }
      }
    } else {
      console.error('Invalid event or value:', event);
    }
  }

  convertToISODate = (ddmmyyyy) => {
    const parts = ddmmyyyy.split('/');
    if (parts.length === 3) {
      const [day, month, year] = parts;
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    }
    return ddmmyyyy;
  }

  setSelfGuideTableData = async (data) => {
    this.setState({ selfGuideTableData: data });
  };

  handleFromDateChange = (event) => {
    const fromDate = event.target.value;
    this.setState({ starting_date: fromDate });
  }

  handleClickOpen = () => {
    if (selectedRowIndex.length > 0)
      this.setState({ openDeleteCnfrm: true });
  };

  handleDelete = () => {
    this.setState({ openDeleteCnfrm: false });

    var array = [...this.state.selfGuideTableData];
    selectedRowIndex.sort(function (a, b) {
      return b - a;
    });
    selectedRowIndex.forEach(async (i) => {
      let delid = array[i].id;
      array.splice(i, 1);
      await handleCallApi('DELETE', `/self-guided-packages/${delid}/`, {}, '', '')
    })
    this.setSelfGuideTableData(array);
    selectedRowIndex = [];
  };

  handleClose = () => {
    this.setState({
      openDeleteCnfrm: false,
    });
    selectedRowIndex = [];
  };

  handleSelectTrip = async (tripId) => {
    if (tripId) {
      this.setState({ selfGuide_trip: tripId, editableSPId: tripId });
      const data = await getDatafromApi(ApiData.apiUrl + `/trips/${tripId}`);
      if (data) {
        this.setState({ trip: data });
        this.setState({ overnightPreCity: data.overnight_pre_night });
        this.setState({ overnightPostNight: data.overnight_post_night });
      }
    }
  };

  render() {
    const config = {
      buttons: [
        'source', '|',
        'undo', 'redo', '|',
        'font', 'fontsize', 'bold', 'italic', 'underline', '|',
        'ul',
        'ol', '|',
        'outdent', 'indent', '|',
        'align',
      ],
    }
    return (
      <div>

        <Dialog
          open={this.state.openDeleteCnfrm}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are You Sure You Want to delete this Self Guide Package
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose}>Cancel</Button>
            <Button onClick={this.handleDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        <div className="modal">
          <div className="modal_content">
            <span onClick={() => this.deletePopUpData()} className="close">&times;</span>
            <div className="model_html">
              {this.state.save_type !== 'add' ? <h2>EDIT A TRIP TO COUNTRY'S PACKAGE</h2>
                : <h2>ADD A TRIP TO COUNTRY'S PACKAGE</h2>}
              {this.state.showModal &&
                <table cellPadding="8" >
                  <tbody>
                    <tr style={{ display: 'none' }}>
                      <td width="35%" style={{ paddingRight: 30 }}>
                        <label>Country</label><br />
                        <ReactFlagsSelect
                          selected={this.state.selfGuide_country}
                          id={'selectCountriesDay'}
                          style={{ width: '70%' }}
                          showSelectedLabel={true}
                          showOptionLabel={true}
                          onSelect={(code) => { this.setState({ selfGuide_country: code }); }}
                          placeholder={'Select a Country'}
                          countries={['GR', 'CR', 'NO', 'IS', 'MX', 'ME', 'DK', 'IL', 'PL', 'PT', 'KR', 'EC', 'PE', 'MA', 'IN', 'ES', 'RO', 'ZA', 'GE', 'LK', 'CL', 'AR', 'AE', 'TZ', 'BG', 'IT']}
                        />
                        <span className="errorMsg">{this.state.errors['selfGuide_country']}</span>
                      </td>
                      <td style={{ verticalAlign: 'baseline' }}>
                      </td>
                    </tr>
                    <tr>
                      <td width="35%" style={{ paddingRight: 30, verticalAlign: 'baseline' }}>
                        <label>SELECT TRIP</label><br />
                        <FormControl className="dropdownWithborder">
                          <Select labelId="demo-simple-select-label" id="demo-simple-select" label="Type"
                            value={this.state.selfGuide_trip}
                            onChange={(event) => {
                              this.handleSelectTrip(event.target.value);
                            }}
                            multiple={false}
                          >
                            {this.state.allTrip.map((item, i) =>
                            (
                              <MenuItem value={item.id}>{item.internal_name}({item.id})</MenuItem>
                            )
                            )}
                          </Select>
                        </FormControl>
                        <span className="errorMsg">{this.state.errors['selfGuide_trip']}</span>
                      </td>
                      <td style={{ verticalAlign: 'baseline' }}>
                        <label>COVER PHOTO</label><br />
                        {this.state.selfGuide_photo ? [
                          <div><span className="labelsmall"> Currently:<span className="colorgreen"> {this.state.selfGuide_photo}</span></span><br /><br />
                          </div>] : null}
                        <div className="divUpload">
                          <img width="20px" src={uploadIcon} />
                          <Button variant="contained" component="label" style={{ float: 'right', fontSize: 9, }} >
                            UPLOAD FILE
                            <input
                              type="file"
                              hidden
                              id="fileSNPhoto"
                              size="small"
                              value=""
                              onChange={this.handlePhoto}
                            />
                          </Button>

                        </div>
                        <span className="errorMsg">{this.state.errors['selfGuide_photo']}</span>
                        <div>
                          {this.state.selfGuide_photo && <img style={{ height: '140px', width: '180px' }} src={this.state.selfGuide_photo}></img>}
                        </div>

                      </td>
                    </tr>
                    <tr style={{ display: 'none' }}>
                      <td colSpan="2">
                        <label>Trip Name</label><br />
                        <TextField
                          id="txtSNTitle"
                          style={{ width: '40%' }}
                          variant="outlined"
                          value={this.state.selfGuide_tripName}
                          error={!!this.state.errors['selfGuide_tripName']}
                          onChange={(event) =>
                            this.setState({ selfGuide_tripName: event.target.value })
                          }
                        />
                      </td>
                      <td>

                      </td>
                    </tr>
                    <tr style={{ display: 'none' }}>
                      <td style={{ paddingRight: 50 }}>
                        <label>SUB- TITLE (Trip Type Sescription)</label><br />
                        <TextField
                          id="txtSNTitle"
                          style={{ width: '100%' }}
                          variant="outlined"
                          value={this.state.selfGuide_subTitle}
                          error={!!this.state.errors['selfGuide_subTitle']}
                          onChange={(event) =>
                            this.setState({ selfGuide_subTitle: event.target.value })
                          }
                        />
                      </td>
                    </tr>
                    <tr style={{ display: 'none' }}>
                      <td colspan="2">
                        <label>TRIP ITINERARY (DESCRIPTION)</label><br />
                        <JoditEditor
                          value={this.state.selfGuide_desc}
                          tabIndex={1}
                          style={{ innerHeight: 500 }}
                          onBlur={(value) => {
                            if (value) this.setState({ selfGuide_desc: value })
                          }}
                          config={config}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td >
                        <h3>PRICING</h3>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <label>Price</label><br />
                        <TextField
                          id="txtSNTitle"
                          style={{ width: '40%' }}
                          variant="outlined"
                          value={this.state.selfGuide_price}
                          error={!!this.state.errors['price']}
                          onChange={(event) =>
                            this.setState({ selfGuide_price: parseInt(event.target.value) })
                          }
                        />
                        <br />
                        <span className="errorMsg">{this.state.errors['price']}</span>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <label>Starting Date</label><br />
                        <input
                          type="date"
                          id="starting_date"
                          value={this.state.starting_date}
                          onChange={this.handleFromDateChange}
                        />
                        <br />
                        <span className="errorMsg">{this.state.errors['starting_date']}</span>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2">
                        <label>Number Of Travelers</label><br />
                        <input
                          type="number"
                          id="number_travelers"
                          value={this.state.number_travelers}
                          onChange={(event) =>
                            this.setState({ number_travelers: parseInt(event.target.value) })
                          }
                        />
                        <br />
                        <span className="errorMsg">{this.state.errors['number_travelers']}</span>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <label>Price Description</label><br />
                        <JoditEditor
                          value={this.state.price_description}
                          tabIndex={1}
                          onBlur={(value) => { if (value) this.setState({ price_description: value }) }}
                          config={config}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <label>Price includes</label><br />
                        <JoditEditor
                          value={this.state.selfGuide_price_includes}
                          tabIndex={1}
                          onBlur={(value) => { if (value) this.setState({ selfGuide_price_includes: value }) }}
                          config={config}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <label>Excluded:</label><br />
                        <JoditEditor
                          value={this.state.selfGuide_price_excluded}
                          tabIndex={1}
                          onBlur={(value) => { if (value) this.setState({ selfGuide_price_excluded: value }) }}
                          config={config}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <label>Confirmation Policy:</label><br />
                        <JoditEditor
                          value={this.state.confirmation_policy}
                          tabIndex={1}
                          onBlur={(value) => { if (value) this.setState({ confirmation_policy: value }) }}
                          config={config}
                        />
                      </td>
                    </tr>

                    {this.state.trip && Object.keys(this.state.trip).length > 0 &&
                      <tr>
                        <td colSpan="4">
                          <table cellPadding="8" className="dayHotelTable" style={{ borderCollapse: 'collapse', width: '100%' }}>
                            <thead>
                              <tr>
                                <th style={{ textAlign: 'center', border: '1px solid #ddd' }}>Day</th>
                                <th style={{ textAlign: 'center', border: '1px solid #ddd' }}>Overnight</th>
                                <th style={{ textAlign: 'center', border: '1px solid #ddd', width: '20%' }}>Hotel Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.trip.days.map((day, i) => (
                                <tr key={i} style={{ borderBottom: '1px solid #ddd' }}>
                                  <td style={{ textAlign: 'center', border: '1px solid #ddd' }}>{`Day ${i + 1}`}</td>
                                  <td style={{ textAlign: 'center', border: '1px solid #ddd', width: '15%' }}>{day.overnight}</td>
                                  <td style={{ textAlign: 'center', border: '1px solid #ddd', width: '20%' }}>
                                    {i !== this.state.trip.days.length - 1 ? (
                                      <Autocomplete
                                        freeSolo
                                        options={this.state.hotelOptions}
                                        defaultValue={this.state.hotelNames && this.state.hotelNames.length > 0 ? this.state.hotelNames[i] : ''}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Hotel Name"
                                            onChange={(event) => this.handleHotelChange(event, i)}
                                            onSelect={(event) => this.handleHotelSelect(event, i)}
                                            value={this.state.searchTermHotel}
                                            sx={{ width: '100%' }}
                                          />
                                        )}
                                      />
                                    ) : (
                                      'End of the trip'
                                    )}
                                  </td>
                                </tr>
                              ))}
                              <tr>
                                <td colSpan="1" style={{ textAlign: 'center', border: '1px solid #ddd' }}>Overnight pre city</td>
                                <td style={{ textAlign: 'center', border: '1px solid #ddd', width: '20%' }}>
                                  <TextField
                                    type="text"
                                    value={this.state.overnightPreCity}
                                    onChange={(event) => this.setState({ overnightPreCity: event.target.value })}
                                  />
                                </td>
                                <td style={{ width: '20%' }}>
                                  <Autocomplete
                                    freeSolo
                                    options={this.state.hotelOptions}
                                    defaultValue={this.state.overnightPreHotel}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Hotel Name"
                                        onChange={(event) => this.handleOverNightHotelChange(event, true)}
                                        onSelect={(event) => this.handleOverNightHotelSelect(event, true)}
                                        value={this.state.searchTermHotelPre}
                                        sx={{ width: '100%' }}
                                      />
                                    )}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="1" style={{ textAlign: 'center', border: '1px solid #ddd' }}>Overnight post night</td>
                                <td style={{ textAlign: 'center', border: '1px solid #ddd', width: '20%' }}>
                                  <TextField
                                    type="text"
                                    value={this.state.overnightPostNight}
                                    onChange={(event) => this.setState({ overnightPostNight: event.target.value })}
                                  />
                                </td>
                                <td style={{ width: '20%' }}>
                                  <Autocomplete
                                    freeSolo
                                    options={this.state.hotelOptions}
                                    defaultValue={this.state.overnightPostHotel}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Hotel Name"
                                        onChange={(event) => this.handleOverNightHotelChange(event, false)}
                                        onSelect={(event) => this.handleOverNightHotelSelect(event, false)}
                                        value={this.state.searchTermHotelPost}
                                        sx={{ width: '100%' }}
                                      />
                                    )}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="1" style={{ textAlign: 'center', border: '1px solid #ddd' }}>Content of the email</td>
                                <td colSpan="2" style={{ textAlign: 'center', border: '1px solid #ddd', width: '20%' }}>
                                  <JoditEditor
                                    value={this.state.contentEmail}
                                    tabIndex={1}
                                    onBlur={(value) => { if (value) this.setState({ contentEmail: value }) }}
                                    config={config}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>}

                    {this.state.trip && Object.keys(this.state.trip).length > 0 &&
                      <table cellPadding="8" style={{ borderCollapse: 'collapse', marginTop: '20px', border: '1px solid #ddd' }}>
                        <tbody>
                          <tr>
                            <td colSpan="2">
                              <h3>Fill form to send email</h3>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                              <TextField
                                type="text"
                                label="First name"
                                value={this.state.userFirstName}
                                sx={{ width: 400 }}
                                inputProps={{ style: { minHeight: 38 } }}
                                onChange={(event) => this.setState({ userFirstName: event.target.value })}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                              <TextField
                                type="text"
                                label="Last name"
                                value={this.state.userLastName}
                                sx={{ width: 400 }}
                                inputProps={{ style: { minHeight: 38 } }}
                                onChange={(event) => this.setState({ userLastName: event.target.value })}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                              <Autocomplete
                                freeSolo
                                options={this.state.emails}
                                value={this.state.selectedEmail} // Establece selectedEmail como el valor del Autocomplete
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Please type email"
                                    onChange={this.handleSearchTermChange.bind(this)}
                                    onSelect={this.handleEmailSelect.bind(this)}
                                    value={this.state.searchTerm}
                                    sx={{ width: 400 }}
                                  />
                                )}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                              <Select
                                value={this.state.selectedCurrency}
                                onChange={this.handleCurrencyChange.bind(this)}
                                sx={{ width: 400 }}
                              >
                                {this.state.currencies.map((currency, index) => (
                                  <MenuItem key={currency} value={CURRENCIES[currency]}>
                                    {currency}
                                  </MenuItem>
                                ))}
                              </Select>
                            </td>
                          </tr>
                        </tbody>
                      </table>}

                    <tr style={{ marginTop: '20px' }}>
                      <td colSpan="4">
                        <label style={{ paddingLeft: '0px' }}>
                          <strong>
                            To make sure PDF creation succeeds, please install and activate the following plugin for Google Chrome Browser: 
                          </strong>
                          <a href="https://chromewebstore.google.com/detail/cors-unblock/lfhmikememgdcahcdlaciloancbhjino?pli=1" target='_blank'>
                            https://chromewebstore.google.com/detail/cors-unblock/lfhmikememgdcahcdlaciloancbhjino?pli=1
                          </a>
                        </label>
                        {this.state.save_type === 'add' && (
                          <Button
                            id="btnSaveSN"
                            variant="contained"
                            color="primary"
                            style={{ marginRight: '8px' }}
                            onClick={this.handleSaveSelfGuide.bind(this)}
                          >
                            CREATE
                          </Button>
                        )}
                        {this.state.save_type !== 'add' && (
                          <Button
                            id="btnSaveSN"
                            variant="contained"
                            color="primary"
                            style={{ marginRight: '8px' }}
                            onClick={this.handleSaveSelfGuide.bind(this)}
                          >
                            SAVE CHANGES
                          </Button>
                        )}
                        {this.state.trip && Object.keys(this.state.trip).length > 0 &&
                          <>
                            <Button
                              id="btnViewSN"
                              variant="contained"
                              color="primary"
                              style={{ marginRight: '8px' }}
                              onClick={this.viewEmail.bind(this)}
                            >
                              VIEW
                            </Button>
                            <Button
                              id="btnSendEmailSN"
                              variant="contained"
                              color="primary"
                              onClick={this.sendEmail.bind(this)}
                            >
                              SEND EMAIL
                            </Button>
                            {this.state.loading === true ? <LoadingSpinner>Generating PDF...</LoadingSpinner> : null}
                          </>
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              }
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />

        <table cellPadding="10" className="viewdata">
          <tbody>
            <tr>
              <td>
                <h2>Personalized packages</h2>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colSpan="3"  ></td>
              <td>&nbsp;
                <Button
                  id="btnSave"
                  variant="contained"
                  className='deleteBtn'
                  onClick={() => { this.handleClickOpen(); }}
                  style={{ float: 'right', fontSize: 12, color: '#fff' }}
                >
                  DELETE SELECTED
                </Button>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <SelfGuidesTable setSGSearchData={this.setSGSearchData} setEditableSelfPackageId={this.setEditableSelfPackageId} allTrip={this.state.allTrip} selfGuideData={this.state.selfGuideTableData} setSelfGuideTableData={this.setSelfGuideTableData} setSelfGuideData={this.setSelfGuideData} />
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <Link href="#" onClick={() => {
                  this.setSelfGuideData(``, `<div>
<strong>Accommodation</strong>
<ul>
<li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;Price is based on 2 adults sharing a double / twin room</li>
<li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;4 nights in Moderate (3 stars) and First Class (4 stars) Hotels</li>
<li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;Adjustments can be made in additional services section</li>
</ul>
</div>

<div>
<strong>Meals</strong>
<ul>
<li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;4 breakfasts in the hotels</li>
</ul>
</div>

<div>
<strong>Car Rental</strong>
<ul>
<li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;Price is based on 2 adults Sharing a car</li>
<li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;Toyota Yaris or similar for 5 days</li>
<li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;A local driver is optional with an additional payment</li>
</ul>
</div>`, `<ul>
 <li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;Price is based on 2 person sharing a double or twin room in one car</span></span></li><li><span style="font-size: 18px;"><span style="font-size: 16px;">&nbsp;Price is based on 1 person in a single&nbsp;room in one car<br></span></span></li>
</ul>`, ``, ``, `<div>
<strong>On-Trip Support</strong><br></br>
Help during your travels by
our Local Hero, </br>service hours 09:00 - 18:00.<br></br>
<strong>Navigational Tour Guide including:</strong>
<ul>
  <li>Seamless Navigation of Your Personalized Trip</li>
  <li>Seamless Navigation of Walking city trails</li>
  <li>Visual & Audio Tips Based on Location</li>
  <li>Curated Hiking Trails</li>
  <li>Content Info About Your Personalized Itinerary</li>
  <li>Flexible adjustments According to Your Personal Preferences</li>
</ul>
</div>

<div>`, `<div>
<strong>Flights</strong>
<ul>
  <li>Airfare - It's your responsibility to arrange your own international and/or domestic flights</li>
  <li>We advise against securing your flight tickets until you get our written confirmation for the Services you have booked</li>
</ul>
</div>

<div>
<strong>Insurance</strong>
<ul>
  <li>When booking one of our Self-Guided Trips, you are required to secure adequate travel insurance cover for the duration of your trip</li>
</ul>
</div>

  <div>
  <strong>Others</strong>
  <ul>
    <li>Peak season supplement in hotels</li>
    <li>Gas for Car rental (car rental is subject to terms & conditions of local rental companies and local laws)</li>
    <li>Local tourist taxes</li>
    <li>All personal expenses & any items not explicitly listed as included</li>
  </ul>
  </div>`, "", 'add', '', 0); openPopup(); this.setState({
                    showModal: true, contentEmail: '',
                    hotelNames: [],
                    hotelOptions: [],
                    hotelSites: [],
                    overnightPostHotel: '',
                    overnightPostHotelSiteLink: '',
                    overnightPostNight: '',
                    overnightPreCity: '',
                    overnightPreHotel: '',
                    overnightPreHotelSiteLink: '',
                    searchTerm: '',
                    searchTermHotel: '',
                    searchTermOvernightHotel: '',
                    selectedEmail: '',
                    userFirstName: '',
                    userLastName: '',
                  })
                }}> + ADD NEW SELF GUIDED PACKAGE  </Link>
              </td>
            </tr>
            <tr>
              <td colSpan="4">
                <div className="borderDiv" />
              </td>
            </tr>

          </tbody>
        </table>
      </div>


    );
  }
}

export default SelfGuidedPackagePersonalized;

mapboxgl.accessToken = MapboxKey;
let mapMarkersOne = [];
let mapMarkersTwo = [];
let webmapMarkers = [];
let vgmapMarkers = [];

let webMapOne;
let webMapTwo;
let webMap;
let vgMap;


function addWebMarker(coordinates) {

  const id = _uniqueId('webmarker-');

  let marker;
  var el = document.createElement('div');
  el.className = 'marker';
  el.id = id;
  el.addEventListener(
    'click',
    (e) => {
      e.stopPropagation();
      let target_id = e.target.id;
      // let queriedMarkerObj = webmapMarkers.find((marker) => marker.id === query);
      // queriedMarkerObj.marker.remove();
      for (let i = 0; i < webmapMarkers.length; i++) {
        if (webmapMarkers[i].id === target_id) {
          document.getElementById(webmapMarkers[i].id).remove();
          webmapMarkers.splice(i, 1);
        }
      }
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(webMap);
  marker.properties = { id: id };
  webmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}

function addVgMarker(coordinates, type = false) {
  const id = _uniqueId('vgmarker-');
  let marker;
  var el = document.createElement('div');
  el.className = 'marker';
  el.id = id;
  if (type) {
    el.style.backgroundImage = `url('https://planmytrip-assets.s3.amazonaws.com/assets/resources/countries/${getSvg(
      type
    )}.svg')`;
  }
  el.addEventListener(
    'click',
    (e) => {
      e.stopPropagation();
      let query = e.target.id;

      let queriedMarkerObj = vgmapMarkers.find((marker) => marker.id === query);
      queriedMarkerObj.marker.remove();
      pointNumbers.splice(
        pointNumbers.findIndex((v) => v.id === query),
        1
      );
    },
    true
  );
  marker = new mapboxgl.Marker(el).setLngLat(coordinates).addTo(vgMap);
  vgmapMarkers.push({
    id: id,
    coordinates: coordinates,
    marker,
  });
}

function getSvg(type) {
  type = parseInt(type);
  switch (type) {
    case 1: {
      return 'straight';
    }
    case 2: {
      return 'right';
    }
    case 3: {
      return 'left';
    }
    case 4: {
      return 'fork-left';
    }
    case 5: {
      return 'fork-right';
    }
    case 6: {
      return 'poi';
    }
    case 7: {
      return 'info';
    }
    case 8: {
      return 'swimming';
    }
    case 9: {
      return 'rafting';
    }
    case 10: {
      return 'hotel';
    }
    default: {
      return 'poi';
    }
  }
}

const initializeMap = (z, id) => {
  if (id == 'mapDayVG') {
    vgMap = new mapboxgl.Map({
      container: id,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: z,
    });
    // Add navigation controls to the mapbox if zoom is lower
    if (z < 11) vgMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    vgMap.on('click', function (e) {
      if (selectedRowIndex) {
        addVgMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      } else {
        toast.error('Please select a trip first by clicking VG button against the record');
      }
    });
  } else if (id == 'mapDayStop') {
    webMap = new mapboxgl.Map({
      container: id,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: z,
    });
    // Add navigation controls to the mapbox if zoom is lower
    if (z < 11) webMap.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    webMap.on('click', function (e) {
      if (selectedRowIndex) {
        addWebMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
      } else {
        addWebMarker({ lng: e.lngLat.lng, lat: e.lngLat.lat });
        // toast.error('11Please select a trip first by clicking Web button against the record');
      }
    });
  } else {
    if (id == 'mapDay-1') {
      webMapOne = new mapboxgl.Map({
        container: id,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: z,
      });
      webMapOne.on('click', function (e) {
        window.bd.setPoiLocation(e.lngLat.lat.toFixed(6), e.lngLat.lng.toFixed(6));
        $('#txtStartLocDay').val(e.lngLat.lat.toFixed(6) + ',' + e.lngLat.lng.toFixed(6));
        // this.setState({ startloc: e.lngLat.lng.toFixed(4) + ',' + e.lngLat.lat.toFixed(4) });
        mapMarkersOne.forEach((marker) => marker.remove());
        mapMarkersOne = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapOne);
        mapMarkersOne.push(marker);
      });
    } else {
      webMapTwo = new mapboxgl.Map({
        container: id,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: z,
      });

      webMapTwo.on('click', function (e) {
        $('#txtEndLocDay').val(e.lngLat.lng.toFixed(6) + ',' + e.lngLat.lat.toFixed(6));
        mapMarkersTwo.forEach((marker) => marker.remove());
        mapMarkersTwo = [];
        const marker = new mapboxgl.Marker()
          .setLngLat([e.lngLat.lng, e.lngLat.lat])
          .addTo(webMapTwo);
        mapMarkersTwo.push(marker);
      });
    }
  }
};

class MapboxGLMap extends Component {
  constructor(props) {
    super(props);
    // this.id = _uniqueId(props.prefix + "-");
    this.id = props.prefix;
    this.latitude = 0;
    this.longitude = 0;
  }

  componentDidMount() {
    initializeMap(-1, this.id)
  }
  render() {
    const id = this.id;
    return (
      <div id={id} className="mapEA">
        {' '}
      </div>
    );
  }
}

class SelfGuidesTable extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [['Loading Data...']],
      loading: false,
      localHeroRowsSelected: [],
      rowsPerPage: 10,
      page: 0,
      count: 0,
      allTrips: [],
      filterData: [],
      filterBy: '',
      filterValue: '',
      searchTerm: '',
      filterState: false
    };
  }

  async componentDidMount() {
    await this.getTrip();
    let data = await this.getData();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has('id')) {
      let id = urlParams.getAll('id');
      this.props.setEditableSelfPackageId(id[0])
      this.setSGPackageData(id, data)
    }
  }

  async componentDidUpdate(prevProps, prevState) {

    if (this.state.page !== prevState.page || this.state.rowsPerPage !== prevState.rowsPerPage) {
      let data = await this.getData();
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.has('id')) {
        let id = urlParams.getAll('id');
        this.props.setEditableSelfPackageId(id[0])
        this.setSGPackageData(id, data)
      }
    }
  }

  async getTrip() {
    let data1 = await getDatafromApi(ApiData.apiUrl + '/trips/?personalized=true&take=3000');
    let data = data1.results;
    if (data && data.length > 2) {
      data.sort((a, b) => a.id - b.id);
      this.setState({ allTrip: data });
    }
    else if (data)
      this.setState({ allTrip: data });
  }

  setSGPackageData = async (id, data) => {
    let filterId = parseInt(id[0])
    let filterRow = await this.getSGById(filterId)
    let getIndexRow = data.findIndex(row => row.id === filterId)
    this.props.setSelfGuideData(filterRow.confirmation_policy, filterRow.price_description, filterRow.trip_id, filterRow.photo, filterRow.price, filterRow.price_includes, filterRow.excluded, filterRow.starting_date, 'update', getIndexRow, filterRow.number_travelers, filterRow.days_data, filterRow.user_data);
    openPopup();
  }

  getData = () => {
    this.setState({ loading: true });
    let dataRows = this.xhrRequest().then((data) => {
      this.setState({ data, loading: false });
      this.props.setSelfGuideTableData(data);
      return data
    });
    return dataRows
  };

  xhrRequest = async () => {
    try {
      let data = await getDatafromApi(ApiData.baseURL + `/self-guided-packages?take=3000&personalized=true`);
      if (data) {
        data.results.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        const tripsData = this.state.allTrip;
        let filteredSGP = data.results.filter(sgp => {
          return tripsData && tripsData.some(trip => trip.id === sgp.trip_id);
        });
        return filteredSGP;
      } else {
        throw new Error("No data received from the API.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  getFilteredData = () => {
    if (this.state.searchTerm.length || this.state.filterValue.length) {
      this.xhrRequestFilterData().then((data) => {
        this.setState({ filterData: data, filterState: true });
        this.props.setSGSearchData(data)
        return data;
      });
    } else {
      this.setState({ filterData: this.props.tripData, filterState: false });
      this.props.setSGSearchData(this.props.tripData)
    }
  };

  xhrRequestFilterData = () => {
    return new Promise(async (resolve, reject) => {
      let data = await getDatafromApi(ApiData.apiUrlLogin + `/trips/findTrips?${this.state.searchTerm ? `internal_name=${this.state.searchTerm.toLocaleUpperCase()}&` : ''}${this.state.filterValue ? `${this.state.filterBy}=${this.state.filterValue}` : ''}`)
      if (data) {
        if (data.length) {
          data.sort((a, b) => a.id - b.id);
          resolve(data);
        } else {
          this.setState({ filterData: [] })
          this.props.setSGSearchData([])
        }
      }
    });
  };

  getSGById = (id) => {
    return new Promise(async (resolve, reject) => {
      let data = await getDatafromApi(ApiData.apiUrl + `/self-guided-packages/${id}`)
      if (data) {
        resolve(data);
      }
    });
  };

  render() {
    const columns = [
      {
        name: 'id', options: {
          label: 'ID', filter: false,

          customBodyRender: (value, tableMeta, updateValue) => {
            // const editrow = tableMeta.tableData[tableMeta.rowIndex];
            var editrow = {};
            var rowIndexForUpdate = 0;
            var i = 0;
            tableMeta.tableData.forEach((row) => {
              if (row.id == value) {
                editrow = row;
                rowIndexForUpdate = i;
              }
              i++;
            })

            return (
              <Link href="#" onClick={() => {
                this.props.setSelfGuideData(editrow.confirmation_policy, editrow.price_description, editrow.trip_id, editrow.photo, editrow.price, editrow.price_includes, editrow.excluded, editrow.starting_date, 'update', rowIndexForUpdate, editrow.number_travelers, editrow.days_data, editrow.user_data); this.props.setEditableSelfPackageId(value); openPopup(editrow.id);
              }}>  {value}</Link>
            );
          },

        }
      },

      { name: 'trip_id', label: 'Trip Id', options: { filter: false } },
      {
        name: 'trip_id',
        options: {
          label: 'TripName',
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            const { allTrip } = this.props;
            const selectedTrip = allTrip.find(trip => trip.id === value);
            let tripName = '';
            if (selectedTrip) {
              tripName = selectedTrip.internal_name;
            } else {
              tripName = 'Loading...';
            }
            return tripName;
          },
        },
      },
      { name: 'starting_date', label: 'Starting Date', options: { filter: false } },
      { name: 'number_travelers', label: 'Number of travelers', options: { filter: false } },
    ]

    const onRowsSelect = (curRowSelected, rowsSelected) => {
      selectedRowIndex = [];
      for (let i = 0; i < rowsSelected.length; i++) {
        selectedRowIndex.push(rowsSelected[i].index);
      }
      activeDeactiveDeleteBtn();
    };

    const handleChangePage = (event, newPage) => {
      this.setState({ page: event });
    };

    const handleChangeRowsPerPage = (event) => {
      this.setState({ rowsPerPage: event, page: 0 });
    };
    const handleOnFilterChange = (changedColumn, filterList) => {
      let filterBy = changedColumn;
      let filterValue = (filterList[3].join('') || filterList[4].join(''));
      this.setState({ filterBy, filterValue }, () => {
        this.getFilteredData()
      });
    };

    const handleOnFilterClose = () => {
      if (this.state.filterBy.length && !this.state.filterValue.length) {
        deleteFilterData()
      }
    }

    const deleteFilterData = () => {
      this.setState({ filterData: [], filterState: false, filterBy: '', filterValue: '' });
      this.props.setSGSearchData([])
    }

    const deleteSearchData = () => {
      this.setState({ filterData: [], filterState: false, searchTerm: '' });
      this.props.setSGSearchData([])
    }

    let searchTimer;
    const searchBySGPName = (searchTerm) => {
      if (searchTerm) {
        if (!isNaN(Number(searchTerm))) {
          clearTimeout(searchTimer);
          searchTimer = setTimeout(() => {
            this.setState({ searchTerm }, () => {
              this.getFilteredData();
            });
          }, 1000);
        }
        if (isNaN(Number(searchTerm)) && searchTerm.length > 3) {
          clearTimeout(searchTimer);
          searchTimer = setTimeout(() => {
            this.setState({ searchTerm }, () => {
              this.getFilteredData();
            });
          }, 1000);
        }
      } else {
        this.setState({ searchTerm: '' });
        deleteSearchData();
        selectedRowIndex = [];
        selectedRowIds = [];
        activeDeactiveDeleteBtn();
      }
    };

    const { page, rowsPerPage, count, filterState } = this.state;
    const { searchData } = this.props;

    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      download: false,
      print: false,
      selectableRowsOnClick: false,
      selectToolbarPlacement: 'none',
      rowsSelected: selectedRowIndex,
      rowsPerPage: rowsPerPage,
      page: page,
      count: count,
      pagination: filterState ? false : true,
      onRowSelectionChange: onRowsSelect,
      onChangePage: handleChangePage,
      onChangeRowsPerPage: handleChangeRowsPerPage,
      onSearchChange: searchBySGPName,
      onFilterChange: handleOnFilterChange,
      onFilterDialogClose: handleOnFilterClose,
      onFilterChipClose: deleteFilterData,
      onSearchClose: deleteSearchData
    };

    return (
      <div>
        <MUIDataTable
          title={
            <Typography variant="subtitle2">
              {this.state.loading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: 'relative', top: 4 }}
                />
              )}
            </Typography>
          }
          data={filterState ? searchData : this.props.selfGuideData}
          columns={columns}
          options={options}
          rowsSelected={onRowsSelect}
        />
      </div>
    );
  }
}




function openPopup(id = '') {
  if (id !== '') {
    window.history.pushState(null, null, `?page=SelfGuidedPackagePersonalized&id=${id}`);
  }

  const modal = document.querySelector(".modal")
  const closeBtn = document.querySelector(".close")
  modal.style.display = "block";
  document.querySelector(".viewdata").style.display = "none";
  closeBtn.addEventListener("click", () => {
    window.history.pushState(null, null, `?page=SelfGuidedPackagePersonalized`);
    modal.style.display = "none";
    document.querySelector(".viewdata").style.display = "inline-table";
  })
}

function closePopoup() {
  document.querySelector(".modal").style.display = "none";
  document.querySelector(".viewdata").style.display = "inline-table";
}

function activeDeactiveDeleteBtn() {
  const btn = document.querySelector(".deleteBtn")
  if (selectedRowIndex.length > 0) btn.style.backgroundColor = 'blue';
  else btn.style.backgroundColor = '#e0e0e0';
}
