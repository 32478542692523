//import references
import React, { Component } from 'react';
import { handleCallApi } from '../calls'
import { ApiData } from "../config";
import { Grid, Chip, Stack, Link } from '@mui/material';
import styled from "styled-components";

class RelatedInformation extends Component {
    type = '';
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true
        }
    }

    async componentDidUpdate(prevProps) {
        let itemId = 0;
        if (this.props.selectedId !== prevProps.selectedId) {
            if (typeof this.props.selectedId) {
                itemId = +[this.props.selectedId];
            } else {
                itemId = this.props.selectedId;
            }

            console.log('='.repeat(1000));
            console.log(this.props.type);


            if (this.props.type) {
                this.type = this.props.type === 'trips' ? 'Trips': 'Days';
                const url = ApiData.baseURL + `/${this.props.type}/related-${this.props.type}`;
                const body = this.props.type === 'trips' ? { dayId: itemId }: { poiId: itemId };
                let data = await handleCallApi('POST', url, body);
                if(data){
                    this.setState({ data, loading: false }); 

                }
            }
        }
    }


    render() {
        return (
            <>
                <h2>Related {this.type}</h2>
        
                <Stack direction="row" spacing={1}>
                {this.props.loading ? (
                    <Chip label='Loading...' color="primary" />
                ) : (
                    this.state.data.length ? (
                    <ContainerTrips>
                        {
                            this.state.data.map((item) => (
                                <Chip key={item.id} label={`${item.id} - ${item.name}`} color="primary" className="chip-spaces" />
                            ))
                        }
                    </ContainerTrips>
                    ) : (
                    `No ${this.type} related`
                    )
                )}
                </Stack>
         </>

        );
    }
}

const ContainerTrips = styled.div`
    width: 50%;
    gap: 1rem;
    display: grid;
    grid-template-columns: repeat(3, auto);
`

export default RelatedInformation;

